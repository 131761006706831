import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { defaultAPI } from '../api';
import { AppState } from '../reducers';
import { InstanceResponse } from '../types/rest-api';

interface FetchInstancesRequestAction extends ReduxAction {
	type: 'FETCH_INSTANCES_REQUEST';
}

interface FetchInstancesAction extends ReduxAction {
	type: 'FETCH_INSTANCES';
	payload: InstanceResponse[];
}

interface FetchInstancesErrorAction extends ReduxAction {
	type: 'FETCH_INSTANCES_ERROR',
	payload: Error,
}

export type Action = FetchInstancesRequestAction | FetchInstancesAction | FetchInstancesErrorAction;

export default function fetchInstances(): ThunkAction<Promise<InstanceResponse[]>, AppState, null, Action> {
	return async dispatch => {
		dispatch( {
			type: 'FETCH_INSTANCES_REQUEST',
		} );

		try {
			const instances: InstanceResponse[] = await defaultAPI.get( '/stack/instances' );

			dispatch( {
				type: 'FETCH_INSTANCES',
				payload: instances,
			} );
			return instances;
		} catch ( err ) {
			dispatch( {
				type: 'FETCH_INSTANCES_ERROR',
				payload: err,
			} );

			throw err;
		}
	};
}

export function fetchInstancesOnce(): ThunkAction<Promise<any>, AppState, null, Action> {
	return async ( dispatch, getState ) => {
		const state = getState();
		console.log( state );
		if ( ! state.instances.archives.all ) {
			return dispatch( fetchInstances() );
		}
	};
}
