import React from 'react';
import { FormattedNumber } from 'react-intl';
import {
	Line,
	LineChart as RechartLineChart,
	ResponsiveContainer,
	Tooltip,
	YAxis,
} from 'recharts';

import './LineChart.css';

function LineChart( props ) {
	// Provide default value of 0 for any missing data.
	const data = props.data.map( item => ( {
		...item,
		value: item.value || 0,
	} ) );

	return (
		<div className={ `LineChart ${props.fadeOut ? 'fade-out' : ''}` }>
			<svg height={ 0 } width={ 0 }>
				<defs>
					<linearGradient gradientUnits="userSpaceOnUse" id="linear" x1="0%" x2="0" y1="0" y2={ props.height }>
						<stop offset="0" stopColor="#DB4D33" />
						<stop offset="30%" stopColor="orange" />
						<stop offset="50%" stopColor="#5CD179" />
					</linearGradient>
				</defs>
			</svg>
			<ResponsiveContainer height={ props.height - 6 }>
				<RechartLineChart
					data={ data }
					margin={ {
						top: 6,
						right: 6,
						bottom: 6,
						left: 6,
					} }
				>
					<YAxis domain={ props.domain.y } hide type="number" />
					<Tooltip
						content={ value => {
							if ( ! value.payload || ! value.payload[0] ) {
								return null;
							}
							const payload = value.payload.length === 1 ? value.payload[0] : value.payload[1];
							if ( payload.dataKey === 'compare' ) {
								return null;
							}
							return (
								<div
									style={ {
										background: 'white',
										padding: '5px',
									} }
								>
									<FormattedNumber value={ payload.value } />{ props.unit }
								</div>
							);
						} }
					/>
					<Line
						dataKey="value"
						dot={ false }
						isAnimationActive={ false }
						stroke="url(#linear)"
						strokeWidth={ 2 }
						type="monotone"
					/>
				</RechartLineChart>
			</ResponsiveContainer>
		</div>
	);
}

LineChart.defaultProps = {
	fadeOut: true,
	domain: {
		y: [ 0, 100 ],
		x: [ new Date( new Date().getTime() - 60 * 60 * 1000 ), new Date().getTime() ],
	},
	unit: '%',
};
export default LineChart;
