import React, { Component } from 'react';

export default class ExpandableText extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			expanded: false,
		};
	}
	render() {
		const text = this.state.expanded ? this.props.children : this.props.children.substr( 0, 40 );
		return (
			<span className="ExpandableText">
				<span
					style={
						! this.state.expanded
							? {
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'hidden',
								display: 'inline-block',
								wordBreak: 'break-all',
								overflowWrap: 'break-word',
							  }
							: {}
					}
				>
					{ text }{ ' ' }
				</span>
				{ this.props.children.length > 40 && ! this.state.expanded && (
					<a
						href="#info"
						title={ 'More information' }
						onClick={ e => {
							e.preventDefault();
							this.setState( { expanded: true } );
						} }
					>
						...
					</a>
				) }
			</span>
		);
	}
}
