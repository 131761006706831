import { Action as ReduxAction } from 'redux';

interface PinAction extends ReduxAction {
	type: 'PIN_APPLICATION';
	id: string;
}

interface UnpinAction extends ReduxAction {
	type: 'UNPIN_APPLICATION';
	id: string;
}

interface State {
	pinned: string[];
}

export default function preferences(
	state: State = {
		pinned: [],
	},
	action: PinAction | UnpinAction
) : State {
	switch ( action.type ) {
		case 'PIN_APPLICATION':
			return {
				...state,
				pinned: [
					...state.pinned,
					action.id,
				],
			};

		case 'UNPIN_APPLICATION':
			return {
				...state,
				pinned: state.pinned.filter( id => id !== action.id ),
			};

		default:
			return state;
	}
}
