import React from 'react';

import ActivityIndicator from '../ActivityIndicator';
import GroupedLog from '../GroupedLog';

import './QueueIndicator.css';

interface Props {
	children: React.ReactNode;
}

export default function QueueIndicator( props: Props ) {
	return (
		<GroupedLog>
			<p className="UnifiedDeployListItem-QueueIndicator">
				<ActivityIndicator
					size={ 16 }
				/>

				{ props.children }
			</p>
		</GroupedLog>
	);
}
