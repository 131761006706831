import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
	withBack?: boolean,
}

export default function Header( props: Props ) {
	return (
		<div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
			{ props.withBack ? (
				<div className="text-lg">
					<Link to="/blog">&larr; Back to all posts</Link>
				</div>
			) : (
				<h3 className="text-2xl font-medium leading-6 text-gray-900">Latest Updates</h3>
			) }
			<div className="mt-3 sm:mt-0 sm:ml-4">
				<a
					className="inline-flex space-x-2 items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium !text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
					href="/feed/"
				>
					<svg
						className="w-5 h-5"
						fill="none"
						stroke="currentColor"
						strokeWidth={ 1.5 }
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.75 19.5v-.75a7.5 7.5 0 00-7.5-7.5H4.5m0-6.75h.75c7.87 0 14.25 6.38 14.25 14.25v.75M6 18.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>

					<span>Subscribe to RSS</span>
				</a>
			</div>
		</div>
	);
}
