import React from 'react';

import './ObjectCacheServerListItem.css';
import LiveLineChart from './LiveLineChart';
import ServerStatusIndicator from './ServerStatusIndicator';

export default function ObjectCacheServerListItem( props ) {
	return (
		<div className="ObjectCacheServerListItem">
			<ServerStatusIndicator status={ props.status } />
			<div className="server-name">
				<span>{ props.name }</span>
				<span className="type">{ props.type }</span>
			</div>
			{ props.metricEndpoint ? <LiveLineChart endpoint={ props.metricEndpoint } height={ 60 } /> : null }
		</div>
	);
}
