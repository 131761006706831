import React from 'react';

import { withApiData } from '../../lib/with-api-data';
import PageTitle from '../PageTitle';
import SolidButton from '../SolidButton';

import Console, { Connection } from './Console';
import Sessions from './Sessions';

import './index.css';

interface Props {
	application: string,
	fetch: typeof window.fetch,
}

interface State {
	connection?: Connection,
	connError?: string,
	starting: boolean,
}

export class ApplicationShell extends React.Component<Props, State> {
	state: State = {
		connection: undefined,
		connError: undefined,
		starting: false,
	};

	onStartSSHShell = async () => {
		this.setState( { starting: true } );
		const url = `/stack/applications/${ this.props.application }/cli/sessions`;
		try {
			const resp = await this.props.fetch( url, { method: 'POST' } );
			const connection = await resp.json();

			if ( ! resp.ok ) {
				if ( connection && connection.message ) {
					throw new Error( connection.message );
				} else {
					throw new Error( 'Unknown error occurred while connecting.' );
				}
			}

			this.setState( { connection } );
		} catch ( err ) {
			this.setState( {
				starting: false,
				connError: err.message,
			} );
		}
	};

	render() {
		return (
			<div className="ApplicationShell">
				<PageTitle title="CLI" />

				{ ( this.state.connection || this.state.starting ) ? (
					<div className="ApplicationShell__running">
						<Console
							application={ this.props.application }
							connection={ this.state.connection }
						/>
					</div>
				) : (
					<div className="ApplicationShell__start">
						{ this.state.connError && (
							<p className="ApplicationShell__error">{ this.state.connError }</p>
						) }
						<SolidButton
							name="Start new shell session"
							onClick={ this.onStartSSHShell }
						/>
					</div>
				) }
				<Sessions
					application={ this.props.application }
				/>
			</div>
		);
	}
}

export default withApiData( ( props: Props ) => ( {
	sessions: `/stack/applications/${ props.application }/cli/sessions`,
} ) )( ApplicationShell );
