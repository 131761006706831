import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AccelerateSiteResponse } from '../api';
import { defaultAPI as api } from '../api';
import { AppState } from '../reducers';

interface AccelerateSiteCreatingAction extends ReduxAction {
	type: 'ACCELERATE_SITE_CREATING';
	payload: {
		url: string;
	};
}

interface AccelerateSiteCreatedAction extends ReduxAction {
	type: 'ACCELERATE_SITE_CREATED';
	payload: {
		site: AccelerateSiteResponse;
	};
}

interface AccelerateSiteFailedAction extends ReduxAction {
	type: 'ACCELERATE_SITE_FAILED';
	payload: {
		url: string;
		error: Error;
	};
}

export type Action = AccelerateSiteCreatingAction | AccelerateSiteCreatedAction | AccelerateSiteFailedAction;

export default function createAccelerateSite(
	url: string
): ThunkAction<Promise<AccelerateSiteResponse>, AppState, null, Action> {
	return async dispatch => {
		dispatch( {
			type: 'ACCELERATE_SITE_CREATING',
			payload: {
				url,
			},
		} );
		const params = {
			url,
		};

		try {
			const site = await api.post( '/stack/accelerate/sites', params ) as AccelerateSiteResponse;
			dispatch( {
				type: 'ACCELERATE_SITE_CREATED',
				payload: {
					site,
				},
			} );

			return site;
		} catch ( e ) {
			dispatch( {
				type: 'ACCELERATE_SITE_FAILED',
				payload: {
					url,
					error: e,
				},
			} );
			throw e;
		}
	};
}
