import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withApiData } from '../lib/with-api-data';

import CronLogItem from './CronLogItem';
import ErrorBlock from './ErrorBlock';
import './CronTasksRunning.css';

class CronTasksRunning extends Component {
	static propTypes = {
		counts: PropTypes.object.isRequired,
		runningTasks: PropTypes.object.isRequired,
	};
	state = {
		isShowingRunningJobs: false,
	};
	render() {
		if ( this.props.counts.error ) {
			return <ErrorBlock message={ this.props.counts.error.message } />;
		}
		return (
			<div className="CronTasksRunning">
				{ this.props.counts.data && (
					<ul className="counts">
						<li>
							<strong>Future Jobs</strong>
							<span>{ this.props.counts.data.future }</span>
						</li>
						<li>
							<strong>Past Due Jobs</strong>
							<span>{ this.props.counts.data.past_due }</span>
						</li>
						<li>
							<strong>Running Jobs</strong>
							<button onClick={ () => this.setState( { isShowingRunningJobs: ! this.state.isShowingRunningJobs } ) }>
								{ this.props.counts.data.running }
							</button>
						</li>
					</ul>
				) }
				{ this.state.isShowingRunningJobs &&
					this.props.runningTasks.data &&
					this.props.runningTasks.data.map( runningTask => (
						<CronLogItem
							key={ runningTask.id }
							date={ runningTask.start + 'Z' }
							hook={ runningTask.hook }
							site_url={ runningTask.site }
						/>
					) ) }
			</div>
		);
	}
}

export default withApiData( props => ( {
	counts: `/stack/applications/${props.application}/cron/counts`,
	runningTasks: `/stack/applications/${props.application}/cron/running`,
} ) )( CronTasksRunning );
