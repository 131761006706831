export type ColorSpec = {
	fill: string,
	stroke: string,
	bg: string,
}
export const COLORS: { [ k: string ]: ColorSpec } = {
	blue: {
		fill: 'fill-blue-500',
		stroke: 'stroke-blue-500',
		bg: 'bg-blue-500',
	},
	teal: {
		fill: 'fill-teal-500',
		stroke: 'stroke-teal-500',
		bg: 'bg-teal-500',
	},
	amber: {
		fill: 'fill-amber-500',
		stroke: 'stroke-amber-500',
		bg: 'bg-amber-500',
	},
	rose: {
		fill: 'fill-rose-500',
		stroke: 'stroke-rose-500',
		bg: 'bg-rose-500',
	},
	indigo: {
		fill: 'fill-indigo-500',
		stroke: 'stroke-indigo-500',
		bg: 'bg-indigo-500',
	},
	emerald: {
		fill: 'fill-emerald-500',
		stroke: 'stroke-emerald-500',
		bg: 'bg-emerald-500',
	},
};
export const AVAILABLE_COLORS = Object.keys( COLORS );
export const getColor = ( i: number ) => COLORS[ AVAILABLE_COLORS[ i % AVAILABLE_COLORS.length ] as keyof typeof COLORS ];
