import React, { useState } from 'react';

import './DropUpload.css';

interface Props {
	allowMultiple?: boolean,
	children: React.ReactNode,
	className?: string,
	uploading?: File[],
	onUpload( files: File[] ): void,
}

export default function DropUpload( props: Props ) {
	const { allowMultiple, children, className, uploading, onUpload } = props;

	const [ dropping, setDropping ] = useState<boolean>( false );

	const onDragOver = ( e: React.DragEvent<HTMLDivElement> ) => {
		e.preventDefault();

		// Explicitly show this is a copy.
		e.dataTransfer.dropEffect = 'copy';

		setDropping( true );
	};
	const onDragLeave = ( e: React.DragEvent<HTMLDivElement> ) => {
		e.preventDefault();

		setDropping( false );
	};

	const onDrop = ( e: React.DragEvent<HTMLDivElement> ) => {
		e.preventDefault();

		// If there's no files, ignore it.
		if ( ! e.dataTransfer.files.length ) {
			setDropping( false );
			return;
		}

		setDropping( false );
		onUpload( Array.from( e.dataTransfer.files ) );
	};

	const onInputChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		if ( ! e.target.files ) {
			return;
		}

		onUpload( Array.from( e.target.files ) );
	};

	return (
		<div
			className={ `DropUpload ${ dropping ? 'dropping' : ''} ${ className || '' }` }
			onDragLeave={ onDragLeave }
			onDragOver={ onDragOver }
			onDrop={ onDrop }
		>
			{ children }

			<div className="DropUpload-status">
				<p className="buttons">
					<label className="DropUpload-uploader">
						<input
							multiple={ allowMultiple }
							type="file"
							onChange={ onInputChange }
						/>
						Upload an attachment
					</label>
					<span> or drop files here.</span>
				</p>
				{ uploading && uploading.map( file => (
					<p key={ `${ file.name }-${ file.lastModified }` }>
						<span className="Loading loading--active"></span>

						Uploading { file.name }…
					</p>
				) ) }
			</div>
		</div>
	);
}
