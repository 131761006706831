import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchInstance } from '../../actions';
import { InstanceResponse } from '../../api';
import { AppState } from '../../reducers';

interface OwnProps {
	id: string,
}
interface StateProps {
	data: InstanceResponse | null,
	didLoad: boolean,
	loading: boolean,
}
interface DispatchProps {
	onLoad(): void,
}
type AllProps = OwnProps & StateProps & DispatchProps;

export function InstanceName( props: AllProps ) {
	const { data, didLoad, id, onLoad } = props;
	useEffect( () => {
		if ( id && ! didLoad ) {
			onLoad();
		}
	}, [ didLoad, id, onLoad ] );

	return (
		<>
			{ data ? (
				<Link
					to={ `/i/${ data.id }` }
				>
					{ data.name }
				</Link>
			) : (
				<ContentLoader
					height="12"
					width="60"
				>
					<rect
						height="12"
						width="60"
						x="0"
						y="0"
					/>
				</ContentLoader>
			) }
		</>
	);
}

const mapStateToProps = ( state: AppState, props: OwnProps ): StateProps => {
	return {
		data: state.instances.data[ props.id ],
		didLoad: typeof state.instances.data[ props.id ] !== 'undefined',
		loading: state.instances.loading[ props.id ],
	};
};
const mapDispatchToProps = ( dispatch: any, props: OwnProps ) => ( {
	onLoad: () => dispatch( fetchInstance( props.id ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( InstanceName );
