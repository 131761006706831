import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackupApplicationModal from './BackupApplicationModal';
import ImportApplicationModal from './ImportApplicationModal';
import SolidButton from './SolidButton';

import './ImportExportActionBlock.css';

export default class ImportExportActionBlock extends Component {
	static propTypes = {
		application: PropTypes.object.isRequired,
		showExport: PropTypes.bool,
		showImport: PropTypes.bool,
	};
	constructor( props ) {
		super( props );
		this.state = {
			isImportModalOpen: false,
			isBackupModalOpen: false,
		};
	}
	onClickImportButton() {
		this.setState( {
			isImportModalOpen: true,
		} );
	}
	onClickBackupButton() {
		this.setState( {
			isBackupModalOpen: true,
		} );
	}
	onImport( importOptions ) {
		this.setState( {
			isImportModalOpen: false,
		} );
		this.props.onImport( importOptions );
	}
	onBackup( backupOptions ) {
		this.setState( {
			isBackupModalOpen: false,
		} );
		this.props.onBackup( backupOptions );
	}
	render() {
		if ( ! this.props.showExport && ! this.props.showImport ) {
			return null;
		}

		return (
			<div className="ImportExportActionBlock">
				<p>Altis <Link to="./data">automatically backs up your database</Link> for disaster recovery.</p>
				<p>You can take an SQL export of your database and zip of your assets at any time below.</p>
				<div className="ImportExportActionBlock__actions">
					{ this.props.showExport && (
						<div className="ImportExportActionBlock__action export">
							<h4>Create an Export</h4>
							<p>Export your database, assets, or both</p>
							<div className="buttons">
								<SolidButton
									name="Export..."
									onClick={ () => this.onClickBackupButton() }
								/>

								{ this.state.isBackupModalOpen && (
									<BackupApplicationModal
										application={ this.props.application.id }
										onBackup={ a => this.onBackup( a ) }
										onCancel={ () => this.setState( { isBackupModalOpen: false } ) }
									/>
								) }
							</div>
						</div>
					) }
					{ this.props.showImport && (
						<div className="ImportExportActionBlock__action import">
							<h4>Import from App</h4>
							<p>Import from another application</p>
							<SolidButton name="Import..." onClick={ () => this.onClickImportButton() } />
							{ this.state.isImportModalOpen && (
								<ImportApplicationModal
									application={ this.props.application }
									onCancel={ () => this.setState( { isImportModalOpen: false } ) }
									onImport={ a => this.onImport( a ) }
								/>
							) }
						</div>
					) }
				</div>
			</div>
		);
	}
}
