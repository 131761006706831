import React, { ReactNode } from 'react';

import Loader from './Loader';
import Log from './Log';

import './GroupedLog.css';

interface GroupData {
	className?: string;
	icon?: ReactNode;
	name: ReactNode;
	note?: ReactNode;
	inProgress?: boolean;

	// todo: replace with Log's type.
	messages: {
		ansi?: boolean,
		text: string,
		className?: string,
	}[];
}

export function Group( props: GroupData ) {
	const classes = [
		'GroupedLog__group',
		props.className,
		props.messages.length === 0 && 'GroupedLog__group-empty',
	];
	return (
		<details
			className={ classes.filter( Boolean ).join( ' ' ) }
			open={ props.inProgress }
		>
			<summary className="GroupedLog__group-title">
				{ props.icon }
				<span className="GroupedLog__group-name">
					{ props.name }
				</span>
				{ props.note && (
					<span className="GroupedLog__group-note">
						{ props.note }
					</span>
				) }
			</summary>

			{ props.messages.length > 0 && (
				<Log
					ansi
					follow={ props.inProgress }
					lines={ props.messages }
					loading={ false }
				/>
			) }
		</details>
	);
}

interface Props {
	children?: ReactNode;
	className?: string;
	loading?: boolean;
}

export default function GroupedLog( props: Props ) {
	const className = `GroupedLog ${ props.className || '' }`;
	return (
		<div className={ className }>
			{ props.loading ? (
				<Loader />
			) : (
				props.children
			) }
		</div>
	);
}
