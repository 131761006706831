import React from 'react';

import {
	groupBuildLogMessages,
	CodeBuildPhase,
	Message,
	Step as StepData,
	StepName,
} from '../../lib/builds';
import { formatDuration } from '../../lib/utils';
import GroupedLog, { Group } from '../GroupedLog';

import QueueIndicator from './QueueIndicator';
import StatusIcon from './StatusIcon';

import './BuildSteps.css';

const getStepName = ( step: StepName ) => {
	switch ( step ) {
		case 'SETUP':
			return 'Setting up build';

		case 'PREPARE':
			return 'Preparing build environment';

		case 'BUILD':
			return 'Building application';

		case 'CLEANUP':
			return 'Cleaning up';

		default: {
			// Check we're exhaustive.
			const _exhaustiveCheck: never = step;
			return _exhaustiveCheck;
		}
	}
};

const getFailureMessages = ( step: StepName, data: StepData ) => {
	if ( ! data.contexts || data.contexts.length === 0 ) {
		return;
	}

	if ( step !== 'BUILD' ) {
		return data.contexts.map( context => ( {
			className: 'error',
			text: context.message || context.statusCode || 'Unknown error',
		} ) );
	}

	return data.contexts.map( context => {
		switch ( context.statusCode ) {
			case 'COMMAND_EXECUTION_ERROR': {
				// Find exit code if we can.
				const statusMatch = context.message && context.message.match( /Reason: exit status (\d+)/ );
				return {
					className: 'error ansi-red-fg',
					text: `Build failed: Your build script returned ${ statusMatch ? statusMatch[1] : 'a non-zero error code' }.`,
				};
			}

			default:
				return {
					className: 'error ansi-red-fg',
					text: context.message || context.statusCode || 'Unknown error',
				};
		}
	} );
};

const getLogLines = ( name: StepName, data: StepData ) => {
	const messages = data.messages.map( m => ( { text: m.message } ) );

	// If we've failed, maybe add additional messages.
	if ( data.status === 'FAILED' || data.status === 'FAULT' ) {
		const additional = getFailureMessages( name, data );
		if ( additional ) {
			messages.push( ...additional );
		}
	}

	return messages;
};

interface StepProps {
	name: StepName,
	data: StepData;
}

function Step( props: StepProps ) {
	const { data, name } = props;

	const duration = data.status === 'SKIPPED' ? (
		<span>
			Skipped
		</span>
	) : (
		data.duration !== null && (
			<span className="UnifiedDeployListItem-BuildSteps__step-duration">
				<abbr
					title={ `${ data.start } / ${ data.end || 'In progress…' }` }
				>
					{ formatDuration( data.duration ) }
				</abbr>
			</span>
		)
	);

	return (
		<Group
			icon={ <StatusIcon status={ data.status } /> }
			inProgress={ data.status === 'IN_PROGRESS' }
			messages={ getLogLines( name, data ) }
			name={ getStepName( name ) }
			note={ duration }
		/>
	);
}

interface Props {
	loading: boolean;
	messages: Message[] | false;
	phases: CodeBuildPhase[];
}

export default function BuildSteps( props: Props ) {
	if ( props.loading ) {
		return (
			<GroupedLog
				loading
			/>
		);
	}

	if ( ! props.messages ) {
		return null;
	}

	const provisioningStep = props.phases.find( phase => phase.phaseType === 'PROVISIONING' );
	if ( provisioningStep && provisioningStep.phaseStatus === 'QUEUED' ) {
		return (
			<QueueIndicator>
				Your build is queued.
			</QueueIndicator>
		);
	}

	const steps = groupBuildLogMessages( props.phases, props.messages );
	return (
		<GroupedLog className="UnifiedDeployListItem-BuildSteps">
			{ Object.keys( steps ).map( key => (
				<Step
					key={ key }
					data={ steps[ key ] }
					name={ key as StepName }
				/>
			) ) }
		</GroupedLog>
	);
}
