import React from 'react';
import './DeployListLoading.css';

export default function DeployListLoading() {
	return (
		<div className="DeployListLoading">
			<div className="list-item">
				<div className="avatar animated-background" />
				<div className="details animated-background" />
			</div>
			<div className="list-item">
				<div className="avatar animated-background" />
				<div className="details animated-background" />
			</div>
			<div className="list-item">
				<div className="avatar animated-background" />
				<div className="details animated-background" />
			</div>
			<div className="list-item">
				<div className="avatar animated-background" />
				<div className="details animated-background" />
			</div>
		</div>
	);
}
