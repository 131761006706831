import PropTypes from 'prop-types';
import React from 'react';

import ErrorBlock from './ErrorBlock';
import PHPLogLoading from './PHPLogLoading';

export default function ApplicationLogsWarning( props ) {
	if ( props.logs.error ) {
		return <ErrorBlock message={ props.logs.error.message } />;
	}

	if ( ! props.logs.data ) {
		return <PHPLogLoading />;
	}

	return (
		<React.Fragment>
			{ props.logs.data.length >= 1000 && (
				<ErrorBlock message="More than 1,000 log entries found, limiting to 1,000 items. Limit the date range to return fewer results." />
			) }
			{ props.renderLogs( props.logs.data ) }
		</React.Fragment>
	);
}

ApplicationLogsWarning.propTypes = {
	logs: PropTypes.shape( {
		error: PropTypes.object,
		data: PropTypes.array,
	} ).isRequired,
	renderLogs: PropTypes.func.isRequired,
};
