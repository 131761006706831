import React from 'react';

import { TYPES } from '../Support/SelectType';

import './Type.css';

interface Props {
	type: 'incident' | 'problem' | 'task' | 'question' | '-';
}

export default function Type( props: Props ) {
	const type = TYPES.find( desc => desc.type === props.type );
	if ( ! type ) {
		return (
			<span>Type: { props.type }</span>
		);
	}

	const Icon = type.icon;
	return (
		<span className={ `Support-Type Support-Type--${ props.type }` }>
			<Icon
				className="Support-Type__icon"
				height={ 16 }
			/>
			{ type.noun }
		</span>
	);
}
