import React from 'react';
import { Route } from 'react-router-dom';

import PageTitle from './PageTitle';
import SelectType from './Support/SelectType';
import SupportList from './SupportList';
import SupportNew from './SupportNew';
import SupportTicket from './SupportTicket';

export default function SupportDashboard() {
	return (
		<div className="Support">
			<PageTitle title="Support" />
			<Route component={ SupportList } exact path="/support" />
			<Route component={ SelectType } exact path="/support/new" />
			<Route component={ SupportNew } path="/support/new/:type" />
			<Route component={ SupportTicket } path="/support/ticket/:ticket" />
		</div>
	);
}
