import React, { useState } from 'react';
import DeleteIcon from 'react-feather/dist/icons/x-circle';

import { InstanceAccessResponse } from '../../types/rest-api';
import Avatar from '../Avatar';

import RoleSelect from './RoleSelect';
import { ROLE_OPTIONS, WithEmbeddedUser } from './util';

import './User.css';

type UserProps = {
	access: InstanceAccessResponse & WithEmbeddedUser,
	canEdit: boolean,
	isCurrentUser: boolean,
	onChangeRole( role: string ): Promise<void>,
	onRevokeAccess(): Promise<boolean>,
}

export default function User( props: UserProps ) {
	const { role } = props.access;
	const user = props.access._embedded.author[0];

	const [ isLoading, setIsLoading ] = useState<boolean>( false );
	const onChangeRole = async ( role: string ) => {
		setIsLoading( true );
		try {
			await props.onChangeRole( role );
		} catch ( err ) {
			setIsLoading( false );
		}
	};
	const onRevokeAccess = async () => {
		setIsLoading( true );
		try {
			const res = await props.onRevokeAccess();
			if ( ! res ) {
				setIsLoading( false );
			}
		} catch ( err ) {
			setIsLoading( false );
		}
	};

	return (
		<li className="InstanceUsers-User">
			<Avatar
				size={ 40 }
				user={ user }
			/>

			<div className="InstanceUsers-User__primary">
				<span className="InstanceUsers-User__name">{ user.name }</span>
				{ user.email && (
					<span className="InstanceUsers-User__email">{ user.email }</span>
				) }
			</div>

			{ ( props.canEdit && ! props.isCurrentUser ) ? (
				<div className="InstanceUsers-User__actions">
					<RoleSelect
						isDisabled={ isLoading }
						isLoading={ isLoading }
						value={ role }
						onChange={ onChangeRole }
					/>
					<button
						className="InstanceUsers-User__delete"
						disabled={ isLoading }
						title="Revoke access"
						onClick={ onRevokeAccess }
					>
						<DeleteIcon
							size={ 20 }
						/>
					</button>
				</div>
			) : (
				<div className="InstanceUsers-User__role">
					{ ROLE_OPTIONS[ role ] }
				</div>
			) }
		</li>
	);
}
