import floor from 'lodash/floor';
import round from 'lodash/round';
import React from 'react';
import {
	CartesianGrid,
	LineChart,
	Line,
	ReferenceArea,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import CustomTooltip from './Tooltip';
import { APDEX_GROUPS, formatDate, formatPercentage, StatEntry } from './util';

function ApdexTooltip( props: any ) {
	const { active, payload } = props;
	if ( ! active || ! payload || ! payload[0] ) {
		return null;
	}

	const item: StatEntry = payload[0].payload;
	const group = Object.values( APDEX_GROUPS ).find( group => item.apdex.value >= group.min && item.apdex.value <= group.max );
	return (
		<CustomTooltip>
			<p>{ round( item.apdex.value, 2 ) } { group?.name || 'Unknown' }</p>
			<ul>
				<li>Satisfied: { item.apdex.satisfied } ({ formatPercentage( item.apdex.satisfied / item.requests ) })</li>
				<li>Tolerating: { item.apdex.tolerating } ({ formatPercentage( item.apdex.tolerating / item.requests ) })</li>
				<li>Frustrated: { item.apdex.frustrated } ({ formatPercentage( item.apdex.frustrated / item.requests ) })</li>
			</ul>
		</CustomTooltip>
	);
}

interface Props {
	data: StatEntry[],
	isLoading: boolean,
}

export default function ApdexChart( props: Props ) {
	if ( props.isLoading ) {
		return (
			<div
				className="animated-background"
				style={ {
					height: 200,
					width: '100%',
				} }
			/>
		);
	}

	return (
		<ResponsiveContainer
			className="XRayOverview-Statistics__apdex"
			height={ 360 }
			width="100%"
		>
			<LineChart
				data={ props.data }
				syncId="XRayOverview-Statistics"
			>
				<CartesianGrid
					stroke="#aaa"
					strokeDasharray="3 3"
					strokeWidth="1"
					vertical={ false }
				/>

				{ Object.values( APDEX_GROUPS ).map( group => (
					<ReferenceArea
						key={ group.name }
						fill={ group.color }
						fillOpacity={ group.opacity }
						ifOverflow="hidden"
						y1={ group.min }
						y2={ group.max }
					/>
				) ) }

				<XAxis
					axisLine={ false }
					className="text-gray-400 text-sm"
					dataKey="time"
					tick={ {
						fill: 'currentColor',
						transform: 'translate(0, 6)',
					} }
					tickFormatter={ formatDate }
				/>
				<YAxis
					axisLine={ false }
					className="text-gray-400 text-sm"
					dataKey="apdex.value"
					domain={ [ ( dataMin: number ) => floor( Math.max( 0, Math.min( dataMin - 0.1, 0.6 ) ), 1 ), 1 ] }
					tick={ {
						fill: 'currentColor',
						transform: 'translate(-3, 0)',
					} }
					tickLine={ false }
				/>
				<Tooltip
					content={ <ApdexTooltip /> }
					isAnimationActive={ false }
					position={ {
						y: 0,
					} }
				/>
				<Line
					dataKey="apdex.value"
					dot={ false }
					isAnimationActive={ false }
					stroke="#8884d8"
					strokeWidth={ 2 }
					type="linear"
				/>
			</LineChart>
		</ResponsiveContainer>
	);
}
