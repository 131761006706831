import { AnyAction } from 'redux';

interface State {
	users: User[],
}

export default function onCall( state: State = { users: [] }, action: AnyAction ) {
	switch ( action.type ) {
		case 'UPDATED_ON_CALL':
			return {
				...state,
				users: [ action.onCall ],
			};
		default:
			return state;
	}
}
