import React from 'react';
import './Pill.css';

export default function Pill( props ) {
	return (
		<span
			className="Pill"
			style={ {
				backgroundColor: props.color,
				...props.style,
			} }
			title={ props.title }
		>
			{ props.children }
		</span>
	);
}

Pill.defaultProps = {
	color: '#4667DE',
	style: {},
};

