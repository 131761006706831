import React from 'react';
import { Route, Switch } from 'react-router';

import Nav from './components/Nav';
import Dashboard from './Dashboard';
import Login from './Login';
import Settings from './Settings';
import SignupEmbed from './SignupEmbed';

import './styles/globals.css';

interface Props {
	onClickLogout(): void;
	onLoggedIn( response: any ): void;
}

function App( props: Props ) {
	return (
		<Switch>
			<Route component={ SignupEmbed } exact path="/accelerate/signup/embedded" />
			<Route>
				<div className="Accelerate h-screen bg-gray-50">
					<Nav onClickLogout={ props.onClickLogout } />
					<Switch>
						<Route exact path="/accelerate/login/" render={ () => <Login onLogin={ props.onLoggedIn } /> } />
						<Route component={ Settings } exact path="/accelerate/settings/" />
						<Route component={ Dashboard } path="/accelerate/" />
					</Switch>
				</div>
			</Route>
		</Switch>
	);
}

export default App;
