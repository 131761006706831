import { DateTime } from 'luxon';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import {
	Legend,
	LegendProps,
	Line,
	LineChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	TooltipProps,
	XAxis,
	YAxis,
} from 'recharts';

import { ColorSpec, getColor } from '../../lib/graph';
import ErrorBlock from '../ErrorBlock';
import Loader from '../Loader';

import { AvailableMetricMap } from './util';
import withMetricData, { MetricComponentProps } from './withMetricData';

interface Props extends MetricComponentProps {
	metrics: AvailableMetricMap,
}

const DURATION_THRESHOLD = 48 * 60 * 60;
const DURATION_THRESHOLD_MS = DURATION_THRESHOLD * 1000;
export const formatDateAsTime = ( date: number ) => DateTime.fromMillis( date ).toLocaleString( DateTime.TIME_SIMPLE );
export const formatDate = ( date: number ) => DateTime.fromMillis( date ).toLocaleString( {
	day: '2-digit',
	month: '2-digit',
} );

type ExtraProps = {
	categoryColors: {
		[ k: string ]: ColorSpec,
	},
	metrics: AvailableMetricMap,
}

const CustomLegend = ( props: LegendProps & ExtraProps ) => {
	return (
		<div className="flex items-center justify-end my-4">
			<div className="mt-0">
				<ol className="list-element flex flex-wrap overflow-hidden truncate gap-2">
					{ props.payload?.map( item => (
						<li
							className="inline-flex items-center truncate text-gray-500 mr-2.5"
						>
							<svg
								// @ts-ignore
								className={ `${ props.categoryColors[ item.dataKey ]?.fill } flex-none text-blue-500 h-2 w-2 mr-1.5` }
								fill="currentColor"
								viewBox="0 0 8 8"
							>
								<circle cx="4" cy="4" r="4"></circle>
							</svg>
							<p className="text-elem whitespace-nowrap truncate text-sm font-normal">
								{ item.value }
							</p>
						</li>
					) ) }
				</ol>
			</div>
		</div>
	);
};

const CustomTooltip = ( { active, categoryColors, label, payload }: TooltipProps<number, string> & ExtraProps ) => {
	if ( ! active || ! payload || ! payload[0]?.payload ) {
		return null;
	}

	return (
		<div
			className="bg-white text-sm rounded-md border shadow-lg"
		>
			<div className="border-gray-200 pl-4 pr-4 pt-2 pb-2 border-b">
				<p className="text-elem text-gray-700 font-medium">
					{ DateTime.fromMillis( label as number ).toLocaleString( DateTime.DATETIME_FULL ) }
				</p>
			</div>
			<div className="pl-4 pr-4 pt-2 pb-2 space-y-1">
				{ payload.map( item => (
					<div className="flex items-center justify-between space-x-8">
						<div className="flex items-center space-x-2">
							<span
								className={ `${ categoryColors[item.dataKey as string]?.bg } shrink-0 border-white h-3 w-3 rounded-full border-2 shadow` }
							/>
							<p className="text-elem font-medium tabular-nums text-right whitespace-nowrap text-gray-700">
								{ item.unit === 'bytes' ? (
									<>
										<FormattedNumber value={ formatBytes( item.value! ).number } />
										{ ' ' }
										{ formatBytes( item.value! ).unit }
									</>
								) : (
									<FormattedNumber value={ item.value! } />
								) }
							</p>
						</div>
						<p className="text-elem text-right whitespace-nowrap text-gray-500 font-normal">
							{ item.name }
						</p>
					</div>
				) ) }
			</div>
		</div>
	);
};

function formatBytes( bytes: number, decimals = 2 ): { number: number, unit: string } {
	if ( ! +bytes ) {
		return {
			number: 0,
			unit: 'Bytes',
		};
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

	const i = Math.floor( Math.log( bytes ) / Math.log( k ) );

	return {
		number: parseFloat( ( bytes / Math.pow( k, i ) ).toFixed( dm ) ),
		unit: sizes[i],
	};
}

function Graph( props: Props ) {
	const { metricData, metrics } = props;
	if ( ! metricData || metricData.isLoading ) {
		return (
			<Loader />
		);
	}
	if ( metricData.isLoading ) {
		return (
			<div className="h-full w-full border-gray-100 rounded-lg" />
		);
	}

	if ( metricData.error ){
		return (
			<ErrorBlock
				message={ metricData.error.message }
			/>
		);
	}

	const parsedData = metricData.data.map( item => ( {
		date: DateTime.fromISO( item.date ).toMillis(),
		...item.values,
	} ) );
	const columns = Object.keys( props.metrics );
	const min = parsedData.reduce<number | null>( ( min, item ) => ( ! min || item.date < min ) ? item.date : min, null );
	const max = parsedData.reduce<number | null>( ( max, item ) => ( ! max || item.date > max ) ? item.date : max, null );

	if ( ! min || ! max ) {
		// Should never occur given backend will fill, but just in case:
		return (
			<ErrorBlock
				message="No data found, or the date period was invalid."
			/>
		);
	}

	const duration = max - min;

	const categoryColors = columns.reduce( ( colors: { [ k: string ]: ColorSpec }, col, idx ) => ( {
		...colors,
		[ col ]: getColor( idx ),
	} ), {} );

	return (
		<ResponsiveContainer
			height="100%"
			width="100%"
		>
			<LineChart
				data={ parsedData }
			>
				<CartesianGrid
					stroke="#aaa"
					strokeDasharray="3 3"
					vertical={ false }
				/>

				<XAxis
					axisLine={ false }
					dataKey="date"
					domain={ [ min || 0, max || Date.now() ] }
					minTickGap={ 30 }
					scale="time"
					stroke="#aaa"
					tickCount={ 5 }
					tickFormatter={ duration > DURATION_THRESHOLD_MS ? formatDate : formatDateAsTime }
					tickLine={ false }
					tickMargin={ 10 }
					type="number"
				/>
				<YAxis
					axisLine={ false }
					stroke="#aaa"
					// @ts-ignore
					style={ {
						fontSize: '12px',
					} }
					tick={ {
						fill: '#152A4E',
						transform: 'translate(-3, 0)',
					} }
					tickLine={ false }
					// tickFormatter={ props.formatY }
					width={ 40 }
				/>
				<Tooltip
					allowEscapeViewBox={ {
						x: false,
						y: false,
					} }
					content={ ( props: TooltipProps<number, string> ) => (
						<CustomTooltip
							{ ...props }
							categoryColors={ categoryColors }
							metrics={ metrics }
						/>
					) }
					cursor={ {
						fill: '#d1d5db',
						opacity: '0.15',
					} }
					isAnimationActive={ false }
					// @ts-ignore
					position={ { y: 0 } }
					wrapperStyle={ { outline: 'none' } }
				/>

				{ columns.map( col => (
					<Line
						key={ col }
						className={ categoryColors[ col ].stroke }
						dataKey={ col }
						dot={ false }
						fill="inherit"
						name={ `${ metrics[ col ].group } > ${ metrics[ col ].title }` }
						stroke="inherit"
						strokeWidth={ 2 }
						type="linear"
						unit={ metrics[ col ].unit }
					/>
				) ) }

				<Legend
					align="right"
					content={ ( props: Omit<LegendProps, 'width' | 'height'> ) => (
						<CustomLegend
							{ ...props }
							categoryColors={ categoryColors }
							metrics={ metrics }
						/>
					) }
				/>
			</LineChart>
		</ResponsiveContainer>
	);
}

export default withMetricData( Graph );
