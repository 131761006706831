import React, { useEffect, useState } from 'react';
import BookOpenIcon from 'react-feather/dist/icons/book-open';
import HelpCircleIcon from 'react-feather/dist/icons/help-circle';
import MenuIcon from 'react-feather/dist/icons/menu';
import NavigationIcon from 'react-feather/dist/icons/navigation';
import PenToolIcon from 'react-feather/dist/icons/pen-tool';
import { useLocation } from 'react-router-dom';

import Link from './Link';
import { ReactComponent as Logo } from './logo-white.svg';
import User from './User';

import './Layout.css';

interface Props {
	children?: React.ReactNode,
	footer?: React.ComponentType,
	title?: string,
}

export default function Sidebar( props: Props ) {
	const [ expanded, setExpanded ] = useState<boolean>( false );

	// Close when browsing.
	const location = useLocation();
	useEffect( () => {
		setExpanded( false );
	}, [ location ] );

	const Footer = props.footer || ( () => null );

	return (
		<>
			<div
				className={ `Sidebar ${ expanded ? 'Sidebar--expanded' : '' }`.trim() }
			>
				<h1 className="Sidebar__title">
					<button
						className="Sidebar__toggle"
						onClick={ () => setExpanded( ! expanded ) }
					>
						<MenuIcon
							color="#fff"
							size={ 24 }
						/>
					</button>

					<Logo
						className="Sidebar__logo"
						title="Altis"
					/>

					<span>Dashboard</span>

					{ props.title && (
						<span className="Sidebar__mobile-title">
							<span className="Sidebar__mobile-title-sep">{ ' > ' }</span>
							<span>{ props.title }</span>
						</span>
					) }
				</h1>

				<div className="Sidebar__contents">
					<div className="Sidebar__primary">
						{ props.title && (
							<h2>{ props.title }</h2>
						) }

						{ props.children }
					</div>

					<div className="Sidebar__footer">
						<Footer />

						<Link activeIncludesChildren to="/blog">
							<PenToolIcon />

							Blog
						</Link>

						<Link to="/roadmap">
							<NavigationIcon />

							Roadmap
						</Link>

						<Link to="/support">
							<HelpCircleIcon />

							Support
						</Link>

						<Link to="https://docs.altis-dxp.com/">
							<BookOpenIcon />

							Documentation
						</Link>

						<User />
					</div>
				</div>
			</div>

			{ expanded && (
				<div
					className="Sidebar__open-overlay"
					onClick={ () => setExpanded( false ) }
				/>
			) }
		</>
	);
}
