import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import ArrowLeftIcon from 'react-feather/dist/icons/arrow-left';
import BarChartIcon from 'react-feather/dist/icons/bar-chart';
import FileIcon from 'react-feather/dist/icons/file';
import HomeIcon from 'react-feather/dist/icons/home';
import SettingsIcon from 'react-feather/dist/icons/settings';
import UserIcon from 'react-feather/dist/icons/user';
import { connect } from 'react-redux';

import { fetchInstance, fetchApplications } from '../../actions';
import { AppState } from '../../reducers';
import { ApplicationResponse, InstanceResponse } from '../../types/rest-api';

import ApplicationSection from './Application';
import SidebarLayout from './Layout';
import SidebarLink from './Link';

interface Props {
	title?: string,
}

type StateProps = {
	applications: ApplicationResponse[],
	applicationsLoading: boolean,
	error?: Error,
	instance?: InstanceResponse,
	loading: boolean,
	selectedInstance: string | null,
}

type DispatchProps = {
	loadInstanceData( instance: string ): Promise<any> | null,
}

type AllProps = Props & StateProps & DispatchProps;

export function InstanceSidebar( props: AllProps ) {
	const { applications, instance, loading, selectedInstance, title, loadInstanceData } = props;

	useEffect( () => {
		if ( ! selectedInstance ) {
			return;
		}
		loadInstanceData( selectedInstance );
	}, [ selectedInstance, loadInstanceData ] );

	if ( ! selectedInstance ) {
		return (
			<SidebarLayout>
				<SidebarLink to="/">
					<ArrowLeftIcon />
					Switch Instance
				</SidebarLink>
			</SidebarLayout>
		);
	}

	if ( loading && ! instance ) {
		return (
			<SidebarLayout
				footer={ () => (
					<SidebarLink to="/">
						<ArrowLeftIcon />
						Switch Instance
					</SidebarLink>
				) }
				title="Loading…"
			/>
		);
	}

	const baseUrl = `/i/${ selectedInstance }`;
	const hasApps = applications;

	return (
		<SidebarLayout
			footer={ () => (
				<SidebarLink to="/">
					<ArrowLeftIcon />
					Switch Instance
				</SidebarLink>
			) }
			title={ title || instance?.name }
		>
			<SidebarLink to={ baseUrl }>
				<HomeIcon />
				Overview
			</SidebarLink>

			<SidebarLink
				activeIncludesChildren
				to={ `${ baseUrl }/metrics` }
			>
				<BarChartIcon />
				Metrics
			</SidebarLink>

			<SidebarLink to={ `${ baseUrl }/reports` }>
				<FileIcon />
				Reports
			</SidebarLink>

			<SidebarLink to={ `${ baseUrl }/users` }>
				<UserIcon />
				Team
			</SidebarLink>

			<SidebarLink to={ `${ baseUrl }/settings` }>
				<SettingsIcon />
				Settings
			</SidebarLink>

			{ hasApps && (
				<>
					{ props.applicationsLoading && props.applications.length === 0 ? (
						<ContentLoader
							backgroundOpacity={ 0.2 }
							className="InstanceContainer__loading-apps"
							foregroundOpacity={ 0.3 }
							height="52"
							speed={ 2 }
						>
							<circle
								cx="4"
								cy="12"
								r="4"
							/>
							<rect
								height="13"
								width="90"
								x="16"
								y="6"
							/>
							<circle
								cx="4"
								cy={ 26 + 12 }
								r="4"
							/>
							<rect
								height="13"
								width="120"
								x="16"
								y={ 26 + 6 }
							/>
						</ContentLoader>
					) : (
						props.applications?.map( app => (
							<ApplicationSection
								key={ app.id }
								application={ app }
								baseUrl={ `${ baseUrl }/e/${ app.id }` }
							/>
						) )
					) }
				</>
			) }
		</SidebarLayout>
	);
}

const mapStateToProps = ( state: AppState ): StateProps => {
	const id = state.instances.selected;
	if ( ! id ) {
		return {
			loading: false,
			selectedInstance: null,
			applicationsLoading: false,
			applications: [],
		};
	}

	return {
		error: state.instances.errors[ id ],
		instance: state.instances.data[ id ],
		loading: state.instances.loading[ id ],
		applications: Object.values( state.applications ).filter( application => application.instance === id ),
		applicationsLoading: state.instances.loadingApplications[ id ],
		selectedInstance: id,
	};
};

const mapDispatchToProps = ( dispatch: any ): DispatchProps => ( {
	loadInstanceData: ( instance: string ) => {
		return Promise.all( [
			dispatch( fetchInstance( instance ) ),
			dispatch( fetchApplications( { instance } ) ),
		] );
	},
} );

export default connect( mapStateToProps, mapDispatchToProps )( InstanceSidebar );
