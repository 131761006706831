import PropTypes from 'prop-types';
import React from 'react';

import './DeployList.css';
import ActivityListItemLoading from './ActivityListItemLoading';
import DeployListItem from './DeployListItem';

export default function DeployList( props ) {
	const { current, items, isLoading, onToggleDeployDetails, loadingItems = 5 } = props;
	return (
		<div className="DeployList">
			{ isLoading ? (
				Array.apply( null, { length: loadingItems } ).map( ( _, index ) => <ActivityListItemLoading key={ index } /> )
			) : (
				items.map( deploy => (
					<DeployListItem
						key={ deploy.id }
						isCurrent={ deploy.id === current }
						onToggleDeployDetails={ () => onToggleDeployDetails( deploy ) }
						{ ...deploy } />
				) )
			) }
		</div>
	);
}

DeployList.propTypes = {
	current: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape( {
			id: PropTypes.string.isRequired,
			status: PropTypes.string.isRequired,
			isCurrent: PropTypes.bool,
			rev: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			deployTask: PropTypes.shape( {
				messages: PropTypes.arrayOf(
					PropTypes.shape( {
						message: PropTypes.string.isRequired,
					} )
				).isRequired,
			} ),
		} )
	).isRequired,
	isLoading: PropTypes.bool,
	loadingItems: PropTypes.number,
	onToggleDeployDetails: PropTypes.func.isRequired,
};
