import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';
import { SupportTicketCommentResponse } from '../types/rest-api';

interface UpdatingSupportTicketCommentsAction extends ReduxAction {
	type: 'UPDATING_SUPPORT_TICKET_COMMENTS';
	ticket: string;
}

interface UpdatedSupportTicketCommentsAction extends ReduxAction {
	type: 'UPDATED_SUPPORT_TICKET_COMMENTS';
	comments: SupportTicketCommentResponse[];
	ticket: string;
}

export type Action = UpdatingSupportTicketCommentsAction | UpdatedSupportTicketCommentsAction;

export default function fetchSupportTicketsComments(
	api: API,
	ticketId: string
): ThunkAction<Promise<SupportTicketCommentResponse[]>, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'UPDATING_SUPPORT_TICKET_COMMENTS',
			ticket: ticketId,
		} );
		return api.get( `/stack/support-tickets/${ticketId}/comments` ).then( ( comments: SupportTicketCommentResponse[] ) => {
			comments = comments.map( comment => {
				if ( comment.author && comment.author.avatar_urls['96'] === null ) {
					comment.author.avatar_urls = {
						'96': 'https://secure.gravatar.com/avatar/83888eb8aea456e4322577f96b4dbaab?s=96&d=mm&r=g',
					};
				}
				return comment;
			} );

			dispatch( {
				type: 'UPDATED_SUPPORT_TICKET_COMMENTS',
				comments,
				ticket: ticketId,
			} );
			return comments;
		} );
	};
}
