import filter from 'lodash/filter';
import qs from 'qs';
import React from 'react';
import { Link } from 'react-router-dom';

import { ApplicationResponse } from '../../types/rest-api';
import SortableTable from '../SortableTable';

import './index.css';

type AppMap = {
	[ id: string ]: ApplicationResponse;
}

type locationMap = {
	search: string;
}

type queryArgsMap = {
	amis: string;
}

type matchMap = {
	url: string;
}

interface Props {
	applications: AppMap;
	location: locationMap;
	match: matchMap;
}

interface Columns {
	[ key: string ]: {
		title: string;
		sort?: string[];
	}
}

const COLUMNS: Columns = {
	id: {
		title: 'ID',
	},
	region: {
		title: 'Region',
	},
	architecture: {
		title: 'Architecture',
	},
	version: {
		sort: [ 'architecture', 'num_version' ],
		title: 'Version',
	},
	'php-version': {
		title: 'PHP',
	},
	ami: {
		title: 'AMI',
	},
	'instance-type': {
		title: 'Instance',
	},
	tfc: {
		title: 'TFC',
	},
	app_version: {
		sort: [ 'hm-platform-version', 'altis-version' ],
		title: 'App Version',
	},
};

const getColumn = ( app: ApplicationResponse, col: keyof Columns ) => {
	switch ( col ) {
		case 'id':
			return (
				<Link
					to={ `/${ app.region }/${ app.id }` }
				>
					{ app.id }
				</Link>
			);

		case 'architecture':
			// Change the architecture displayed to help visual scanning.
			if ( app.architecture === 'terraform-ecs-application' ) {
				return 'Terraform (ECS)';
			}
			if ( app.architecture === 'ami-application-stack' ) {
				return 'AMI (EC2)';
			}
			return app.architecture;

		case 'app_version':
			if ( app['altis-version'] ) {
				return (
					<>
						<abbr title="Altis">A</abbr>
						{ app['altis-version'] }
					</>
				);
			}
			return (
				<>
					<abbr title="HM Platform">HM</abbr>
					{ app['hm-platform-version'] }
				</>
			);

		case 'tfc':
			if ( app._internal && app._internal.tfc ) {
				return (
					<>
						{ app._internal.tfc.provider || (
							<em>
								terraform-cloud
								<abbr title="Default, not explicitly set">*</abbr>
							</em>
						) }
					</>
				);
			}
			return null;

		default:
			if ( ! ( col in app ) ) {
				return null;
			}

			return app[ col as keyof ApplicationResponse ] as string;
	}
};

/**
 * Converts a version string to a number to allow sorting numerically.
 *
 * Uses a base-10 left-shift of the version parts to build a number. Shifting
 * the components avoids large minor versions "overriding" a major version.
 *
 * For example, `1.2.3` becomes `10203` (1 * 10000 + 2 * 100 + 3).
 *
 * This allows each component to go up to 99 without conflicting.
 */
const parseVersionToNumber = ( version: string ) => {
	const [ maj, min = '0', patch = '0' ] = version.split( '.' );
	return ( parseInt( maj, 10 ) * 10000 ) + ( parseInt( min, 10 ) * 100 ) + parseInt( patch, 10 );
};

export default function Overview( props: Props ) {
	const { location, match } = props;
	const args =  qs.parse( location.search.substr( 1 ) ) as queryArgsMap;
	let data = props.applications;
	let viewAll;
	if ( args.amis ) {
		const amis = args.amis.split( ',' );
		data = filter( data, app => {
			return amis.includes( app.ami );
		} ) as unknown as AppMap;
		viewAll = <Link className="viewall" to={ `${ match.url }` }>&larr; View All</Link>;
	}

	const fullItems = Object.values( data ).map( item => ( {
		...item,
		num_version: parseVersionToNumber( item.version ),
	} ) );
	return (
		<>
			{ viewAll }
			<SortableTable
				columns={ COLUMNS }
				data={ fullItems }
				defaultSort="id"
			>
				{ app => (
					<tr
						key={ app.id }
					>
						{ Object.keys( COLUMNS ).map( key => (
							<td key={ key }>
								{ getColumn( app, key ) }
							</td>
						) ) }
					</tr>
				) }
			</SortableTable>
		</>
	);
}
