import React from 'react';
import HexagonIcon from 'react-feather/dist/icons/hexagon';
import { Route, Switch } from 'react-router';

import { ApplicationResponse } from '../../api';
import ApplicationHeader from '../ApplicationHeader';

import Deploys from './Deploys';

interface Props {
	application: ApplicationResponse,
	basePath: string,
	isLoading: boolean,
}

export default function Nodejs( props: Props ) {
	const nodeBase = `${ props.basePath }/nodejs`;
	return (
		<Switch>
			<Route
				path={ `${ nodeBase }/deploys` }
				render={ routeProps => (
					<>
						<ApplicationHeader
							application={ props.application }
							sections={ [
								{
									title: 'Node.js',
									link: `${ nodeBase }/nodejs`,
								},
							] }
							title={ (
								<>
									<HexagonIcon
										className="mr-2 text-[#393] stroke-[3]"
									/>

									Deploys
								</>
							) }
						/>
						<Deploys
							application={ props.application }
							{ ...routeProps }
						/>
					</>
				) }
			/>
			<Route>404!</Route>
		</Switch>
	);
}
