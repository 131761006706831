import React, { useRef } from 'react';

import { ApplicationResponse } from '../../api';
import DurationSelector, { DurationFilter, ManualTimeFilter, TimeFilter } from '../DurationSelector';
import Infotip from '../Infotip';
import OutlineButton from '../OutlineButton';
import SolidButton from '../SolidButton';
import useSearchParams from '../useSearchParams';

import Graph from './Graph';
import PeriodSelector from './PeriodSelector';
import Table from './Table';
import { AvailableMetricMap } from './util';

const AVAILABLE: AvailableMetricMap = {
	'cdn/bandwidth': {
		'group': 'CDN',
		'title': 'Bandwidth',
		'description': 'Downloaded and uploaded bytes from the CDN',
		'unit': 'bytes',
	},
	'cdn/requests': {
		'group': 'CDN',
		'title': 'HTTP Requests',
		'description': 'Number of HTTP requests for all assets from the CDN',
	},
	'cdn/4xx_rate': {
		'group': 'CDN',
		'title': '4xx Error Rate',
		'description': 'Total 4xx error rate for all requests from the CDN (including 401, 403, and 404 errors)',
		'unit': 'percent',
	},
	'cdn/5xx_rate': {
		'group': 'CDN',
		'title': '5xx Error Rate',
		'description': 'Total 5xx error rate for all requests from the CDN (including 500 errors)',
		'unit': 'percent',
	},
	'cdn/error_rate': {
		'group': 'CDN',
		'title': 'Total Error Rate',
		'description': 'Total 4xx and 5xx error rate for all requests from the CDN',
		'unit': 'percent',
	},

	'cache/items': {
		'group': 'Cache',
		'title': 'Total Items',
		'description': 'Total number of items stored in Redis',
	},
	'cache/evictions': {
		'group': 'Cache',
		'title': 'Evictions',
		'description': 'Number of items evicted during this period',
	},
	'cache/reads': {
		'group': 'Cache',
		'title': 'Item Reads',
		'description': 'Number of read/get commands executed (using read-only type commands from Redis\' commandstats statistic)',
	},
	'cache/writes': {
		'group': 'Cache',
		'title': 'Item Writes',
		'description': 'Number of write/set commands executed (using write type commands from Redis\' commandstats statistic)',
	},

	'firewall/blocked': {
		'group': 'Firewall',
		'title': 'Blocked Requests',
		'description': 'Number of requests blocked by the Web Application Firewall',
	},

	'lb/requests': {
		'group': 'Load Balancer',
		'title': 'Requests',
		'description': 'Number of requests served by the load balancer',
	},
	'lb/response_time': {
		'group': 'Load Balancer',
		'title': 'Response Time (Average)',
		'description': 'Average response time in seconds for requests served by the load balancer',
	},

	'xray/requests': {
		'group': 'X-Ray (PHP)',
		'title': 'Requests',
		'description': 'Number of requests served by PHP (uncached)',
	},
	'xray/response_time_avg': {
		'group': 'X-Ray (PHP)',
		'title': 'Response Time (Average)',
		'description': 'Average response time in seconds for requests served by PHP',
		'aggregation': 'Average',
	},
	'xray/response_time_p90': {
		'group': 'X-Ray (PHP)',
		'title': 'Response Time (p90)',
		'description': '90th percentile response time in seconds for requests served by PHP',
		'aggregation': 'p90',
	},
	'xray/response_time_p95': {
		'group': 'X-Ray (PHP)',
		'title': 'Response Time (p95)',
		'description': '95th percentile response time in seconds for requests served by PHP',
		'aggregation': 'p95',
	},
};

const SHORT_PERIODS = [
	60,  // 1m
	300, // 5m
];
const LONG_PERIODS = [
	900,   // 15m
	3600,  // 1h
	10800, // 3h
	43200, // 12h
	86400, // 24h
	86400 * 7, // 7 days
	86400 * 30, // 30d
];
const ALL_PERIODS = [
	...SHORT_PERIODS,
	...LONG_PERIODS,
];

const TIMES = [
	60 * 60,
	3 * 60 * 60,
	12 * 60 * 60,
	24 * 60 * 60,
	72 * 60 * 60,
	7 * 24 * 60 * 60,
	30 * 24 * 60 * 60,
	90 * 24 * 60 * 60,
	180 * 24 * 60 * 60,
	365 * 24 * 60 * 60,
];

const shouldAllowShort = ( time: TimeFilter ) => {
	if ( ( time as DurationFilter ).duration ) {
		const dur = ( time as DurationFilter ).duration;
		return dur <= ( 3 * 60 * 60 );
	}

	// todo: apply some better logic here.
	return false;
};

const parseTimeFilter = ( params: URLSearchParams ) : TimeFilter => {
	if ( params.has( 'before' ) || params.has( 'after' ) ) {
		return {
			before: params.get( 'before' ) || undefined,
			after: params.get( 'after' ) || undefined,
		};
	}
	return {
		duration: parseInt( params.get( 'duration' ) ?? '900', 10 ),
	};
};

interface Props {
	application: ApplicationResponse | null,
}

type Query = {
	mode: 'table' | 'graph',
	period: number,
	selected: string[],
	time: TimeFilter,
}

const useQuery: () => [ Query, ( nextQuery: Partial<Query> ) => void ] = () => {
	const [ params, setParams ] = useSearchParams();
	const parsed: Query = {
		mode: params.get( 'mode' ) === 'table' ? 'table' : 'graph',
		period: parseInt( params.get( 'period' ) ?? '60', 10 ),
		selected: params.getAll( 'selected' ),
		time: parseTimeFilter( params ),
	};

	const updateParams = ( query: Partial<Query> ) => {
		const nextParams: any = {
			mode: query.mode || parsed.mode,
			period: query.period || parsed.period,
			selected: query.selected || parsed.selected,
		};

		const nextTime = query.time || parsed.time;
		if ( ( nextTime as DurationFilter ).duration ) {
			nextParams.duration = ( nextTime as DurationFilter ).duration;
		} else {
			nextParams.before = ( nextTime as ManualTimeFilter ).before;
			nextParams.after = ( nextTime as ManualTimeFilter ).after;
		}
		if ( query.selected ) {
			nextParams.selected = query.selected;
		}

		// Enforce valid period when changing duration.
		const allowShort = shouldAllowShort( nextTime );
		const allowedPeriods = allowShort ? ALL_PERIODS : LONG_PERIODS;
		if ( allowedPeriods.indexOf( nextParams.period ) === -1 ) {
			nextParams.period = allowShort ? 60 : 3600;
		}

		// Don't set mode=graph explicitly for cleaner URLs.
		if ( nextParams.mode === 'graph' ) {
			delete nextParams.mode;
		}
		setParams( nextParams );
	};

	return [ parsed, updateParams ];
};

export default function ApplicationMetrics( props: Props ) {
	const [ query, setQuery ] = useQuery();
	const { mode, period, selected, time } = query;

	const graphRef = useRef<React.ElementRef<typeof Graph> | null>( null );
	const selectedMetrics = Object.fromEntries( Object.entries( AVAILABLE ).filter( ( [ k, _ ] ) => selected.indexOf( k ) >= 0 ) );

	const setSelected = ( id: string, enabled: boolean ) => {
		if ( selected.indexOf( id ) >= 0 ) {
			if ( ! enabled ) {
				setQuery( {
					selected: selected.filter( v => v !== id ),
				} );
			}
		} else {
			if ( enabled ) {
				setQuery( {
					selected: [
						...selected,
						id,
					],
				} );
			}
		}
	};

	const allowShort = shouldAllowShort( query.time );

	return (
		<div className="flex flex-col space-y-4">
			<div className="flex items-center space-x-4">
				<div className="shrink-0">
					<OutlineButton
						name={ mode === 'table' ? 'View as Graph' : 'View as Table' }
						onClick={ () => mode === 'table' ? setQuery( { mode: 'graph' } ) : setQuery( { mode: 'table' } ) }
					/>
				</div>
				<div className="grow" />
				<div className="shrink-0">
					<PeriodSelector
						periods={ allowShort ? ALL_PERIODS : LONG_PERIODS }
						selected={ period }
						onSelect={ period => setQuery( { period } ) }
					/>
				</div>
				<div className="shrink-0">
					<DurationSelector
						flip
						selected={ time }
						times={ TIMES }
						onSelect={ time => setQuery( { time } ) }
					/>
				</div>
				<div className="shrink-0">
					<SolidButton
						name="Refresh"
						onClick={ () => {
							if ( ! graphRef.current ) {
								return;
							}

							// @ts-ignore
							graphRef.current.onRefreshData();
						} }
					/>
				</div>
			</div>
			<div className="h-[450px] w-full">
				{ props.application ? (
					Object.keys( selectedMetrics ).length > 0 ? (
						mode === 'table' ? (
							<Table
								// @ts-ignore
								ref={ ref => graphRef.current = ref }
								application={ props.application }
								metrics={ selectedMetrics }
								period={ period }
								time={ time }
							/>
						) : (
							<Graph
								// @ts-ignore
								ref={ ref => graphRef.current = ref }
								application={ props.application }
								metrics={ selectedMetrics }
								period={ period }
								time={ time }
							/>
						)
					) : (
						<div className="box-border flex flex-col justify-center h-full w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
							<svg
								className="mx-auto h-12 w-12 text-gray-400"
								fill="none"
								stroke="currentColor"
								strokeWidth={ 1.5 }
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<span className="mt-2 block text-sm font-medium text-gray-900">
								Select metrics to graph below
							</span>
						</div>
					)
				) : (
					<div className="h-full w-full border-gray-100 rounded-lg" />
				) }
			</div>
			<div className="grid grid-cols-3 gap-3">
				{ Object.entries( AVAILABLE ).map( ( [ id, metric ] ) => (
					<label
						key={ id }
						className={ [
							'group relative flex p-2 items-start rounded cursor-pointer',
							selected.indexOf( id ) >= 0 && 'bg-blue-100',
						].filter( Boolean ).join( ' ' ) }
					>
						<div className="flex h-5 items-center">
							<input
								checked={ selected.indexOf( id ) >= 0 }
								className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
								id="comments"
								name="comments"
								type="checkbox"
								onChange={ e => setSelected( id, e.target.checked ) }
							/>
						</div>
						<div className="ml-3 text-sm">
							<p className="font-medium text-gray-700">
								{ metric.title }
								<Infotip className="text-gray-700/50">
									{ metric.description }
								</Infotip>
							</p>
							<p className="text-gray-500">
								{ metric.group }
							</p>
						</div>
					</label>
				) ) }
			</div>
		</div>
	);
}
