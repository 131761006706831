import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';

const TEXT_HEIGHT = 24;
const TEXT_HEIGHT_SMALL = 18;
const COL_WIDTH_STATUS = 80;
const COL_WIDTH_DATE = 100;
const COL_WIDTH_ASSIGNEE = 130;

export default function SupportListItemLoading( props ) {
	const { titleModifier, ...otherProps } = props;
	return (
		<ContainerDimensions>
			{ ( { width } ) => (
				<ContentLoader
					className="SupportListItemLoading"
					height={ 75 }
					primaryColor="#f3f3f3"
					secondaryColor="#ecebeb"
					speed={ 2 }
					width={ width }
					{ ...otherProps }
				>
					{ /* Status pill */ }
					<rect
						height={ TEXT_HEIGHT }
						rx="2"
						ry="2"
						width={ COL_WIDTH_STATUS * 0.6 }
						x="0"
						y="11"
					/>

					{ /* Issue title */ }
					<rect
						height={ TEXT_HEIGHT }
						rx="2"
						ry="2"
						width={ ( width - COL_WIDTH_STATUS - COL_WIDTH_DATE - 20 ) * titleModifier }
						x={ COL_WIDTH_STATUS }
						y="11"
					/>

					{ /* Assignee */  }
					<rect
						height={ TEXT_HEIGHT_SMALL }
						rx="2"
						ry="2"
						width={ COL_WIDTH_ASSIGNEE }
						x={ COL_WIDTH_STATUS }
						y="50"
					/>

					{ /* Date */ }
					<rect
						height={ TEXT_HEIGHT }
						rx="2"
						ry="2"
						width={ COL_WIDTH_DATE }
						x={ width - COL_WIDTH_DATE }
						y="11"
					/>
				</ContentLoader>
			) }
		</ContainerDimensions>
	);
}

SupportListItemLoading.defaultProps = {
	titleModifier: 1.0,
};
