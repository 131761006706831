import PropTypes from 'prop-types';
import React, { Component } from 'react';

import IpAddressInformation from './IpAddressInformation';
import './IpAddress.css';

export default class IPAddress extends Component {
	static propTypes = {
		ip: PropTypes.string.isRequired,
	};
	state = {
		isShowingIpAddressInformation: false,
	};
	render() {
		return (
			<span
				className="IpAddress"
				onMouseOut={ () => this.setState( { isShowingIpAddressInformation: false } ) }
				onMouseOver={ () => this.setState( { isShowingIpAddressInformation: true } ) }
			>
				<span className="IpAddress__short">{ this.props.ip }</span>
				{ this.state.isShowingIpAddressInformation && <IpAddressInformation ipAddress={ this.props.ip } /> }
			</span>
		);
	}
}
