import React from 'react';
import './BigTitle.css';

interface Props {
	isLoading?: boolean,
	name: string,
}

export default function BigTitle( props: Props ) {
	return <h1 className={ `BigTitle ${props.isLoading ? 'isLoading animated-background' : ''}` }>{ props.name }</h1>;
}
