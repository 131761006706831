import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { createBackup, createImport } from '../actions';
import ApplicationImportExport from '../components/ApplicationImportExport';
import { withApiData } from '../lib/with-api-data';

class ConnectedApplicationImportExport extends Component {
	onDownload( backup ) {
		window.open( backup.url );
	}
	onExport( options ) {
		this.props.dispatch( createBackup( this.props.application, options, this.props ) ).then( () => this.props.refreshData() );
	}
	onImport( importOptions ) {
		return this.props.dispatch( createImport( this.props.application, importOptions, this.props ) );
	}
	render() {
		if ( ! this.props.application ) {
			return '';
		}

		// slice won't mutate the original props.backups.data
		const sortedBackups = ( this.props.backups.data || [] )
			.slice()
			.sort( ( backupA, backupB ) => new Date( backupB.date ).getTime() - new Date( backupA.date ).getTime() );

		return (
			<ApplicationImportExport
				application={ this.props.application }
				backups={ sortedBackups || [] }
				isLoading={ this.props.backups.isLoading }
				showExport={ this.props.application.can.develop }
				showImport={ this.props.application.can.develop }
				onDownload={ this.props.application.can.read ? backup => this.onDownload( backup ) : null }
				onExport={ options => this.onExport( options ) }
				onImport={ a => this.onImport( a ) }
			/>
		);
	}
}

const mapStateToProps = ( state, props ) => {
	return {
		application: state.applications[props.match.params.application],
	};
};

export default compose(
	connect( mapStateToProps ),
	withApiData( props => ( {
		backups: `/stack/applications/${ props.match.params.application }/backups`,
	} ) )
)( ConnectedApplicationImportExport );
