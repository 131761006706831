import memoize from 'lodash/memoize';

import { LogResponse } from '../types/rest-api';

export function sortLogItems( a: LogResponse, b: LogResponse ) {
	if ( a.date < b.date ) {
		return 1;
	}
	if ( a.date > b.date ) {
		return -1;
	}
	return 0;
}

export function formatDuration( millis: number ): string {
	if ( millis < 1000 ) {
		return `${ millis }ms`;
	}

	const sec = Math.floor( millis / 1000 );
	if ( sec < 60 ) {
		return `${ sec }s`;
	}

	const min = Math.floor( sec / 60 );
	return `${ min }m${ sec % 60 }s`;
}

interface ApplicationState {
	[s: string]: Application;
}

/**
 * Get application IDs for a given region.
 *
 * This function is memoized, ensuring that the same object is returned each
 * time, and avoiding re-renders. To clear the cache,
 * use appIdsForRegion.cache.clear()
 *
 * @param {ApplicationState} applications Full set of applications from the store.
 * @param {string} region Region ID.
 * @returns string[]
 */
export const appIdsForRegion = memoize(
	( applications: ApplicationState, _region: string ) => {
		const inRegion = Object.values( applications ).map( app => app.id );
		inRegion.sort();
		return inRegion;
	},
	( applications: ApplicationState, region: string ) => {
		const keys = Object.keys( applications );
		keys.sort();
		return region + ':' + keys.join( ',' );
	}
);
