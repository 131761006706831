import PropTypes from 'prop-types';
import React from 'react';

import './BackupList.css';
import BackupListItem from './BackupListItem';

export default function BackupList( props ) {
	return (
		<div className="BackupList">
			{ props.items.map( backup => (
				<BackupListItem
					key={ backup.id }
					{ ...backup }
					date={ new Date( backup.date ) }
					onDownload={ props.onDownload ? () => props.onDownload( backup ) : null }
				/>
			) ) }
		</div>
	);
}

BackupList.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape( {
			id: PropTypes.string.isRequired,
			date: PropTypes.string.isRequired,
			onDownload: PropTypes.func,
		} )
	).isRequired,
};
