import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';

export default function Loader() {
	return (
		<ContainerDimensions>
			{ ( { width } ) => (
				<ContentLoader
					backgroundColor="#ecebeb"
					foregroundColor="#f3f3f3"
					height={ 400 }
					speed={ 2 }
					width={ width }
				>
					<rect height={ 400 } rx="2" ry="2" width={ width } x="0" y="0" />
				</ContentLoader>
			) }
		</ContainerDimensions>
	);
}
