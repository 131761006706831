import React, { Component } from 'react';
import { FlameGraph as ReactFlameGraph } from 'react-flame-graph';

import Tooltip from './Tooltip';

import './index.css';

// Show 25 rows, plus a half to indicate you can scroll.
const HEIGHT = 20;
const ROWS = 25.5;

class Flamegraph extends Component {
	state = {
		height: HEIGHT * ROWS,
		width: 850,
		tipItem: null,
		tipVisible: false,
		mouse: null,
	};

	el = null;

	componentDidMount() {
		this.setState( {
			width: this.el.getBoundingClientRect().width,
		} );
	}

	onMouseOver = ( _, item ) => {
		this.setState( {
			tipItem: item,
			tipVisible: true,
		} );
	};

	onMouseOut = () => {
		this.setState( {
			tipVisible: false,
		} );
	};

	render() {
		return (
			<div
				ref={ el => this.el = el }
				className="Flamegraph"
			>
				<ReactFlameGraph
					data={ this.props.data }
					disableDefaultTooltips
					height={ this.state.height }
					width={ this.state.width }
					onMouseOut={ this.onMouseOut }
					onMouseOver={ this.onMouseOver }
				/>
				<Tooltip
					item={ this.state.tipItem }
					mouse={ this.state.mouse }
					parentEl={ this.el }
					total={ this.props.data.value }
					visible={ this.state.tipVisible }
				/>
			</div>
		);
	}
}

export default Flamegraph;
