import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';

import { defaultAPI } from '../../api';
import DropUpload from '../DropUpload';
import ErrorBlock from '../ErrorBlock';
import OutlineButton from '../OutlineButton';
import SolidButton from '../SolidButton';

import FollowersSelector from './FollowersSelector';
import MessageHeader from './MessageHeader';

import './Reply.css';

const optionForFollower = user => ( {
	label: user.name,
	value: user.id || user.name, // Cast zd_id to a string so react-select doesn't complain.
	user,
} );

export default class SupportTicketReply extends Component {
	static propTypes = {
		author: PropTypes.shape( {
			name: PropTypes.string.isRequired,
			avatar_urls: PropTypes.shape( {
				'96': PropTypes.string,
			} ).isRequired,
		} ).isRequired,
		canReplyInternally: PropTypes.bool.isRequired,
		canSolve: PropTypes.bool.isRequired,
		onSubmit: PropTypes.func.isRequired,
		isSubmitting: PropTypes.bool,
		errorMessage: PropTypes.string,
	};

	state = {
		content: '',
		public: true,
		followers: null,
		showingFollowers: false,
		uploading: [],
		attachments: [],
	};

	onUpdatePublic = value => {
		this.setState( { public: value } );
	};

	onChangeFollowers = ( value, action ) => {
		switch ( action.action ) {
			case 'pop-value':
			case 'remove-value':
				// Only allow removing new followers.
				if ( ! action.removedValue.__isNew__ ) {
					return;
				}

				// Fallthrough.
			default:
				this.setState( {
					followers: value,
				} );
		}
	};

	onSubmitAsSolved = e => {
		e.preventDefault();

		this.onSubmit( true );
	};

	onSubmitForm = e => {
		e.preventDefault();

		this.onSubmit();
	};

	async onSubmit( solved = false ) {
		const data = {
			content: this.state.content,
			public: this.state.public,
			attachments: this.state.attachments.map( a => a.token ),
		};
		if ( this.state.followers ) {
			// Build operations from diff.
			data.followers = this.state.followers
				.filter( f => f.__isNew__ )
				.map( f => ( {
					action: 'put',
					email: f.value,
				} ) );
		}
		if ( solved ) {
			data.solved = true;
		}

		const res = await this.props.onSubmit( data );

		if ( res ) {
			this.setState( {
				content: '',
				followers: null,
				showingFollowers: false,
				uploading: [],
				attachments: [],
			} );
		}
	}

	onUpload = files => {
		this.setState( s => ( {
			uploading: [
				...s.uploading,
				...files,
			],
		} ) );

		files.forEach( async file => {
			// Upload to the REST API.
			const options = {
				method: 'POST',
				body: new FormData(),
			};
			options.body.append( 'file', file );
			const resp = await defaultAPI.fetch( '/stack/support-tickets/upload', options );
			const data = await resp.json();

			// Remove from uploading, add to attachments.
			this.setState( s => ( {
				uploading: s.uploading.filter( f => f !== file ),
				attachments: [
					...s.attachments,
					data,
				],
			} ) );
		} );
	};

	render() {
		if ( this.props.status === 'closed' ) {
			return null;
		}

		const followerValue = this.state.followers || this.props.followers.map( optionForFollower );
		const replyButton = this.props.willReopen ? 'Reply and reopen ticket' : 'Reply';
		return (
			<div
				className={ `SupportTicketReply ${ this.state.public ? 'public' : 'private' }` }
			>
				<MessageHeader
					author={ this.props.author }
					public={ this.state.public }
					onSetPublic={ this.props.canReplyInternally && this.onUpdatePublic }
					onShowFollowers={ this.state.showingFollowers ? null : () => this.setState( { showingFollowers: true } ) }
				/>

				<form
					className="SupportTicketReply__content"
					onSubmit={ this.onSubmitForm }
				>
					{ this.state.showingFollowers && (
						<div className="SupportTicketReply__followers">
							<span>CC Emails:</span>

							<FollowersSelector
								autoFocus
								className="SupportTicketReply__followers-input"
								value={ followerValue }
								onChange={ followers => this.setState( { followers } ) }
							/>
						</div>
					) }

					<DropUpload
						className="SupportTicketReply__content-uploader"
						uploading={ this.state.uploading }
						onUpload={ this.onUpload }
					>
						<textarea
							disabled={ this.props.isSubmitting }
							value={ this.state.content }
							onChange={ e => this.setState( { content: e.target.value } ) }
						/>
						<p className="SupportTicketReply__markdown">
							<a
								href="https://commonmark.org/help/"
								rel="noopener noreferrer"
								target="_blank"
							>
								Format with Markdown

								<ExternalLinkIcon size={ 12 } />
							</a>
						</p>
					</DropUpload>

					{ this.state.attachments && this.state.attachments.length > 0 && (
						<ul className="SupportTicketMessage__attachments">
							<p className="SupportTicketMessage__attachments-header">Attached files:</p>
							{ this.state.attachments.map( attachment => (
								<li key={ attachment.token }>
									<a href={ attachment.url } rel="noopener noreferrer" target="_blank">
										{ attachment.filename }

										<ExternalLinkIcon size={ 12 } />
									</a>
								</li>
							) ) }
						</ul>
					) }

					{ this.props.errorMessage && <ErrorBlock message={ this.props.errorMessage } /> }

					<div className="SupportTicketReply__footer">
						{ ! this.state.public && (
							<span>Note: this internal reply will only be seen by Human Made.</span>
						) }

						{ ( ! this.props.isSubmitting && this.props.canSolve ) && (
							<OutlineButton
								disabled={ ! this.state.content || this.props.isSubmitting || this.state.uploading.length > 0 }
								name="Reply and mark as solved"
								onClick={ this.onSubmitAsSolved }
							/>
						) }

						<SolidButton
							disabled={ ! this.state.content || this.props.isSubmitting || this.state.uploading.length > 0 }
							name={ this.props.isSubmitting ? 'Sending...' : replyButton }
							type="submit"
						/>
					</div>
				</form>
			</div>
		);
	}
}
