import React from 'react';

const maybeTruncate = ( str: string, len: number ) => {
	if ( str.length < len ) {
		return str;
	}

	return '…' + str.substring( str.length - len + 1 );
};

type LogLocationProps = {
	file: string,
	line: number,
	root: string,
	rootUrl: string,
	maxLength?: number,
}
export default function LogLocation( props: LogLocationProps ) {
	const isInRoot = props.file.substring( 0, props.root.length ) === props.root;
	if ( ! isInRoot ) {
		const truncated = props.maxLength ? maybeTruncate( props.file, props.maxLength ) : props.file;
		return (
			<span
				title={ props.file }
			>
				{ truncated }
			</span>
		);
	}

	const relative = props.file.substring( props.root.length );
	const text = `${ relative }#${ props.line }`;
	const truncated = props.maxLength ? maybeTruncate( text, props.maxLength ) : text;
	return (
		<a
			href={ `${ props.rootUrl }${ relative }#L${ props.line }` }
			rel="noreferrer"
			target="_blank"
			title={ text }
		>
			{ truncated }
		</a>
	);
}
