import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { defaultAPI } from '../api';
import { AppState } from '../reducers';

interface RemovingTaskAction extends ReduxAction {
	type: 'REMOVING_TASK';
	payload: {
		task: Task;
	};
}

interface RemoveRunningTaskAction extends ReduxAction {
	type: 'REMOVE_RUNNING_TASK';
	payload: {
		task: Task;
	};
}

interface RemovingTaskFailedAction extends ReduxAction {
	type: 'REMOVING_TASK_FAILED';
	payload: {
		task: Task;
		error: any;
	};
}

export type Action = RemovingTaskAction | RemoveRunningTaskAction | RemovingTaskFailedAction;
export default function deleteRunningTask( task: Task ): ThunkAction<Promise<any>, AppState, null, Action> {
	const urlEncodedId = encodeURIComponent( task.id );
	return dispatch => {
		dispatch( {
			type: 'REMOVING_TASK',
			payload: {
				task,
			},
		} );

		return defaultAPI
			.request( 'DELETE', '/stack/running-tasks/' + urlEncodedId )
			.then( () => {
				dispatch( {
					type: 'REMOVE_RUNNING_TASK',
					payload: {
						task,
					},
				} );
			} )
			.catch( error => {
				dispatch( {
					type: 'REMOVING_TASK_FAILED',
					payload: {
						task,
						error,
					},
				} );
				if ( Notification ) {
					new Notification( 'Failed removing task.', {
						body: error.message,
					} );
				}
			} );
	};
}
