import React, { Component } from 'react';

import LogGroup from './RegionLogsLogGroup';
import './ApplicationLogs.css';

export default class ApplicationLogs extends Component {
	state = {
		tab: 'php',
	};

	render() {
		const tabs = [
			{
				key: 'php',
				title: 'PHP',
			},
			{
				key: 'cavalcade',
				title: 'Cavalcade',
			},
			{
				key: 'syslog',
				title: 'Syslog',
			},
			{
				key: 'ssh-auth',
				title: 'Auth',
			},
			{
				key: 'kernel',
				title: 'Kernel',
			},
		];
		return (
			<div className="ApplicationLogs">
				<div className="left">
					{ tabs.map( tab => (
						<button
							className={ `${this.state.tab === tab.key ? 'active' : ''}` }
							onClick={ () => this.setState( { tab: tab.key } ) }
						>
							{ tab.title }
						</button>
					) ) }
				</div>
				<div className="right">
					{ this.state.tab === 'php' ? (
						<LogGroup name="php" openIssues="true" region={ this.props.match.params.region } />
					) : (
						<LogGroup name={ this.state.tab } region={ this.props.match.params.region } />
					) }
				</div>
			</div>
		);
	}
}
