import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ActivityListItem.css';
import ChevronDown from 'react-feather/dist/icons/chevron-down';
import ChevronUp from 'react-feather/dist/icons/chevron-up';

import Avatar from './Avatar';
import FormattedRelativeTime from './FormattedRelativeTime';

export default class ActivityListItem extends Component {
	static propTypes = {
		date: PropTypes.string.isRequired,
		user: PropTypes.object,
		description: PropTypes.string.isRequired,
		details: PropTypes.object,
	};
	state = {
		isExpanded: false,
	};
	render() {
		return (
			<div className="ActivityListItem">
				<div className="summary">
					{ this.props.user && <Avatar size={ 40 } user={ this.props.user } /> }
					<div className="description">
						<header>
							{ this.props.date && (
								<>
									<strong className="date">
										<FormattedRelativeTime value={ this.props.date } />
									</strong>
									{ ' ' }
								</>
							) }
							{ this.props.user && (
								<span>
									by <span className="ActivityListItem__username">{ this.props.user.name }</span>
								</span>
							) }
						</header>
						<p>{ this.props.description }</p>
					</div>
					{ this.props.details && (
						<button
							className="expand-button"
							onClick={ () =>
								this.setState( {
									isExpanded: ! this.state.isExpanded,
								} ) }
						>
							{ this.state.isExpanded ? (
								<ChevronUp color="#436CFF" size={ 30 } />
							) : (
								<ChevronDown color="#436CFF" size={ 26 } />
							) }
						</button>
					) }
				</div>
				{ this.state.isExpanded && (
					<div className="details">
						<JSONTable data={ this.props.details } />
					</div>
				) }
			</div>
		);
	}
}

function JSONTable( props ) {
	return (
		<table>
			<tbody>
				{ Object.entries( props.data ).map( ( [ key, value ] ) => (
					<tr key={ key }>
						<td>
							<code>{ key }</code>
						</td>
						<td>{ value && typeof value === 'object' ? <JSONTable data={ value } /> : <code>{ String( value ) }</code> }</td>
					</tr>
				) ) }
			</tbody>
		</table>
	);
}
