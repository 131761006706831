import { DateTime } from 'luxon';
import React from 'react';
import {
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';

import { ApiResponse } from '../../types/api';

import Loader from './Loader';
import { formatDate } from './util';

import './Graph.css';

export type Metric = {
	date: string,
	value: number,
}

interface Props {
	children?: React.ReactNode,
	formatY( value: number ): string,
	metrics?: ApiResponse<Metric[]>,
	period: {
		start: DateTime,
		end: DateTime,
	},
}

export default function Graph( props: Props ) {
	const { metrics, period } = props;
	if ( ! metrics || metrics.isLoading ) {
		return (
			<Loader />
		);
	}
	if ( metrics.isLoading || metrics.error ) {
		return null;
	}

	const start = period.start;
	const end = period.end;
	const numDays = end.diff( start, 'days' ).plus( 1 );
	const parsedData = metrics.data.map( item => ( {
		...item,
		parsedDate: DateTime.fromISO( item.date ).toMillis(),
	} ) ).filter( item => item.value !== null );

	return (
		<ResponsiveContainer
			height={ 400 }
			width="100%"
		>
			<BarChart
				data={ parsedData }
			>
				<CartesianGrid
					stroke="#aaa"
					strokeDasharray="3 3"
					vertical={ false }
				/>

				<XAxis
					allowDataOverflow
					dataKey="parsedDate"
					domain={ [ period.start.toMillis(), period.end.toMillis() ] }
					padding={ {
						left: 20,
						right: 20,
					} }
					scale="time"
					stroke="#aaa"
					tick={ { fill: '#152A4E' } }
					tickCount={ numDays.as( 'days' ) }
					tickFormatter={ formatDate }
					tickMargin={ 10 }
					type="number"
				/>
				<YAxis
					stroke="#aaa"
					tick={ { fill: '#152A4E' } }
					tickFormatter={ props.formatY }
					width={ 40 }
				/>

				{ props.children }
			</BarChart>
		</ResponsiveContainer>
	);
}
