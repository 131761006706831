import React, { useState } from 'react';

import { PostResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import ErrorBlock from '../ErrorBlock';
import Loader from '../Loader';
import PageTitle from '../PageTitle';
import Pagination from '../Pagination';

import Content from './Content';
import Header from './Header';

import './index.css';

type PaginationProps = {
	page: number,
	onSetPage: ( page: number ) => void,
};
type Props = PaginationProps & {
	archiveData: ApiResponse<PostResponse[]>,
	page: number,
	onSetPage: ( page: number ) => void,
};

export function Archive( props: Props ) {
	const {
		archiveData,
		page,
		onSetPage,
	} = props;
	if ( archiveData.isLoading ) {
		return (
			<div className="BlogArchive">
				<Loader />
			</div>
		);
	}
	if ( archiveData.error )  {
		return (
			<ErrorBlock message={ archiveData.error.message } />
		);
	}

	const totalPages = parseInt( archiveData.response?.headers.get( 'X-WP-TotalPages' ) || '1', 10 );

	return (
		<section className="BlogArchive">
			<PageTitle
				title="Blog"
			/>

			<Header />

			{ archiveData.isLoading ? (
				<>
					<div className="BlogPost-Content animated-background"></div>
					<div className="BlogPost-Content animated-background"></div>
				</>
			) : (
				<>
					{ archiveData.data.map( postObject => {
						return (
							<Content
								key={ postObject.slug }
								postObject={ postObject }
							/>
						);
					} ) }
					{ totalPages > 1 && (
						<Pagination
							currentPage={ page }
							totalPages={ totalPages }
							onNewer={ () => onSetPage( page - 1 ) }
							onOlder={ () => onSetPage( page + 1 ) }
						/>
					) }
				</>
			) }
		</section>
	);
}

export const BlogArchiveWithData = withApiData( ( props: PaginationProps ) => ( {
	archiveData: `wp/v2/posts?per_page=10&page=${ props.page }&_embed=author`,
} ) )( Archive );

export default function ConnectedArchive() {
	const [ page, setPage ] = useState<number>( 1 );

	return (
		<BlogArchiveWithData
			page={ page }
			onSetPagination={ setPage }
		/>
	);
}
