import PropTypes from 'prop-types';
import React from 'react';

import ApplicationRunningTasks from '../containers/ApplicationRunningTasks';

import ApplicationHeader from './ApplicationHeader';
import BackupList from './BackupList';
import DeployListLoading from './DeployListLoading';
import ImportExportActionBlock from './ImportExportActionBlock';
import PageTitle from './PageTitle';

import './ApplicationImportExport.css';

export default function ApplicationImportExport( props ) {
	return (
		<>
			<PageTitle title="Import & Export" />
			<ApplicationHeader
				application={ props.application }
				title="Import & Export"
			/>
			<div className="ApplicationImportExport">
				<ImportExportActionBlock
					application={ props.application }
					showExport={ props.showExport }
					showImport={ props.showImport }
					onBackup={ props.onExport }
					onImport={ props.onImport }
				/>

				{ /* Hack, until we have proper backup progress in here */ }
				<ApplicationRunningTasks
					application={ props.application.id }
				/>

				<h2>Previous Exports</h2>
				{ props.isLoading ? <DeployListLoading /> : <BackupList items={ props.backups } onDownload={ props.onDownload } /> }
			</div>
		</>
	);
}

ApplicationImportExport.propTypes = {
	application: PropTypes.object.isRequired,
	showExport: PropTypes.bool,
	showImport: PropTypes.bool,
	isLoading: PropTypes.bool,
	onImport: PropTypes.func,
	onExport: PropTypes.func,
	onDownload: PropTypes.func,
	backups: PropTypes.arrayOf(
		PropTypes.shape( {
			id: PropTypes.string.isRequired,
			date: PropTypes.string.isRequired,
			onDownload: PropTypes.func,
		} )
	).isRequired,
};
