import React from 'react';
import Select from 'react-select';

import { ROLE_DESCRIPTIONS, ROLE_OPTIONS, RoleOptionValue } from './util';

import './RoleSelect.css';

type OptionType = { label: string; value: string };

const roleOptionsSelect = Object.keys( ROLE_OPTIONS ).map( value => ( {
	value,
	label: ROLE_OPTIONS[ value as RoleOptionValue ],
} ) );

type RoleSelectProps = {
	isDisabled?: boolean,
	isLoading?: boolean,
	value: RoleOptionValue | null,
	onChange( role: RoleOptionValue ): void,
};

export default function RoleSelect( props: RoleSelectProps ) {
	const fullValue = props.value ? {
		value: props.value,
		label: ROLE_OPTIONS[ props.value ],
	} : null;

	return (
		<Select
			className="InstanceUsers-RoleSelect"
			formatOptionLabel={ ( option, meta ) => (
				meta.context === 'value' ? option.label : (
					<>
						<span className="InstanceUsers-RoleSelect__opt-label">{ option.label }</span>
						<span className="InstanceUsers-RoleSelect__opt-desc">{ ROLE_DESCRIPTIONS[ option.value as RoleOptionValue ] }</span>
					</>
				)
			) }
			isDisabled={ props.isDisabled }
			isLoading={ props.isLoading }
			isSearchable={ false }
			options={ roleOptionsSelect }
			styles={ {
				menu: ( css: any ) => ( {
					...css,
					minWidth: '400px',
				} ),
			} }
			value={ fullValue }
			onChange={ item => item && props.onChange( ( item as OptionType ).value as RoleOptionValue ) } />
	);
}
