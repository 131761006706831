import PropTypes from 'prop-types';
import React from 'react';
import InfoIcon from 'react-feather/dist/icons/info';

import ActivityListItem from './ActivityListItem';
import TaskLog from './TaskLog';

import './DeployListItem.css';

const formatDescription = item => {
	const shortRev = item.rev.substr( 0, 7 );
	const shortDesc = item.description.split( '\n' )[0];
	return `${ shortRev } | ${ shortDesc }`;
};

export default function DeployListItem( props ) {
	return (
		<div className={ `DeployListItem ${props.isCurrent ? 'isCurrent' : ''} ${props.status}` }>
			<div className="summary">
				<ActivityListItem
					date={ props.date }
					description={ formatDescription( props ) }
					user={ props.user }
				/>

				{ props.isCurrent && (
					<strong>Current</strong>
				) }

				<button className="InfoIcon" onClick={ props.onToggleDeployDetails }>
					<InfoIcon />
				</button>
				{ props.status === 'active' ? null : props.status }
			</div>
			{ props.deployTask && (
				<TaskLog
					task={ props.deployTask }
				/>
			) }
		</div>
	);
}

DeployListItem.propTypes = {
	status: PropTypes.string.isRequired,
	isCurrent: PropTypes.bool,
	rev: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	onToggleDeployDetails: PropTypes.func.isRequired,
	deployTask: PropTypes.shape( {
		messages: PropTypes.arrayOf(
			PropTypes.shape( {
				message: PropTypes.string.isRequired,
			} )
		).isRequired,
		phases: PropTypes.arrayOf(
			PropTypes.shape( {
				phaseType: PropTypes.string.isRequired,
				phaseStatus: PropTypes.string.isRequired,
			} )
		),
	} ),
};
