import AnsiUp from 'ansi_up';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Composer from 'react-scroll-to-bottom/lib/ScrollToBottom/Composer';
import ScrollContext from 'react-scroll-to-bottom/lib/ScrollToBottom/InternalContext';

import Loader from './Loader';
import PillButton from './PillButton';

import './Log.css';

function LogInner( props ) {
	const { ansi, className, follow, lines, loading } = props;
	const [ copied, setCopied ] = useState( false );
	const { setTarget } = useContext( ScrollContext );

	if ( loading ) {
		return (
			<div className="Log loading">
				<Loader />
			</div>
		);
	}

	// Format as plain text for copying.
	const plainText = lines.map( line => line.text ).join( '\n' );

	const ansiFormatter = new AnsiUp();
	ansiFormatter.use_classes = true;

	return (
		<pre
			ref={ follow ? setTarget : undefined }
			className={ [ 'Log', className ].filter( Boolean ).join( ' ' ) }
		>
			<div className="Log__copy">
				<CopyToClipboard
					text={ plainText }
					onCopy={ () => setCopied( true ) }
				>
					<PillButton
						name={ copied ? 'Copied!' : 'Copy' }
					/>
				</CopyToClipboard>
			</div>

			{ lines.map( ( item, idx ) => (
				<div
					key={ idx }
					className={ [ 'Log__line', item.className ].filter( Boolean ).join( ' ' ) }
					data-number={ idx }
				>
					{ ( 'ansi' in item ? item.ansi : ansi ) ? (
						<span
							dangerouslySetInnerHTML={ { __html: ansiFormatter.ansi_to_html( item.text ) } }
						/>
					) : (
						<span>{ item.text }</span>
					) }
				</div>
			) ) }
			<br className="Log__overscroll" />
		</pre>
	);
}

LogInner.defaultProps = {
	ansi: false,
};

LogInner.propTypes = {
	ansi: PropTypes.bool,
	className: PropTypes.string,
	follow: PropTypes.bool,
	loading: PropTypes.bool,
	lines: PropTypes.arrayOf( PropTypes.shape( {
		ansi: PropTypes.bool,
		text: PropTypes.string.isRequired,
		className: PropTypes.string,
	} ) ),
};

export default function Log( props ) {
	if ( ! props.follow ) {
		return (
			<LogInner
				{ ...props }
			/>
		);
	}

	// If following is enabled, wrap the log component in a Composer. This
	// provides automatic scrolling capability via context.
	//
	// We use Composer/ScrollContext directly here, as the official component
	// doesn't support pre tags, and uses Glamor, which is a large dependency.
	// https://github.com/compulim/react-scroll-to-bottom/issues/38
	return (
		<Composer
			mode="bottom"
		>
			<LogInner
				{ ...props }
			/>
		</Composer>
	);
}

Log.defaultProps = {
	follow: false,
};

Log.propTypes = {
	...LogInner.propTypes,

	// Make follow optional.
	follow: PropTypes.bool,
};
