import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import createAccelerateSite from '../../../../actions/createAccelerateSite';
import fetchAccelerateSites from '../../../../actions/fetchAccelerateSites';
import { AccelerateSiteResponse, UserResponse } from '../../../../api';
import { AppState } from '../../../../reducers';

import ActivatePlugin from './ActivatePlugin';
import CreatingSite from './CreatingSite';
import InstallPlugin from './InstallPlugin';
import Nav from './Nav';
import VerifyInstall from './VerifyInstall';
import WebsiteURL from './WebsiteURL';

interface Props {
	sites: AppState['accelerateSites'],
	user: undefined | UserResponse,
}
function AddSite( props: Props ) {
	const [ step, setStep ] = useState( 1 );
	const [ selectedSite, setSelectedSite ] = useState<undefined | number>();
	const dispatch = useDispatch();

	const site = selectedSite ? props.sites[ selectedSite ] : undefined;

	// Refresh sites endpoint every 5 seconds. When sites are being added we want to poll
	// the site's statuses.
	useEffect( () => {
		const interval = setInterval( () => {
			dispatch( fetchAccelerateSites() );
		}, 5000 );
		return () => clearInterval( interval );
	}, [ dispatch ] );

	// Track when the end step is reached in Segment.
	useEffect( () => {
		if ( step === 4 && site?.url ) {
			window.analytics.track( 'Site Added', {
				url: site.url,
			} );
			window.analytics.group( site.url.replace( /^https?:\/\/(.*?)\/*$/, '$1' ), {
				account_id: props.user?.id || 0,
			} );
		}
	}, [ step, site?.url, props.user?.id ] );

	async function createSite( url: string ) {
		const site = await dispatch( createAccelerateSite( url ) ) as unknown as AccelerateSiteResponse; // todo: this isn't detecting the right type for some reason.
		setSelectedSite( site.id );
		setStep( 2 );
	}

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<div className="grid grid-cols-4 gap-4">
				<div className="AddSiteNav">
					<Nav currentStep={ step } />
				</div>
				<div className="AddSiteContent col-span-3">
					{ ! site &&
						<WebsiteURL onSubmit={ createSite } /> }
					{ site && step === 2 &&
						<InstallPlugin site={ site } onDone={ () => setStep( 3 ) } /> }

					{ site && step === 3 && (
						site.status === 'creating' ? (
							<CreatingSite />
						) : (
							<ActivatePlugin site={ site } onDone={ () => setStep( 4 ) } />
						)
					) }

					{ site && step === 4 &&
						<VerifyInstall /> }
				</div>
			</div>
		</div>
	);
}

function mapStateToProps( state: AppState ) : Props {
	return {
		sites: state.accelerateSites,
		user: state.currentUser.user,
	};
}
export default connect( mapStateToProps )( AddSite );
