import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';

import { Segment, Subsegment, Trace } from './types';

export const getXhprofFromTrace = ( trace: Trace ) => {
	const Document = trace.Segments[0].Document;
	return Document.subsegments && Document.subsegments.filter( s => s.name === 'xhprof' )[0];
};

type Item = {
	name: string;
	value: number;
	children: Item[];
	start?: number;
	end?: number;
}

export const segmentToItem = ( segment: Subsegment ): Item => {
	const wallTime = segment.end_time ? segment.end_time - segment.start_time : 0;
	return {
		name: segment.name,
		value: wallTime,
		children: segment.subsegments ? sortBy( segment.subsegments, s => s.start_time ).map( segmentToItem ) : [],
		start: segment.start_time,
		end: segment.end_time,
	};
};

export const getQuerySegments = ( segments: Segment[] ): Segment[] => {
	const matching = segments.filter( segment => !! segment.Document.sql );
	matching.sort( ( a, b ) =>
		a.Document.start_time < b.Document.start_time ? -1 : 1
	);
	return matching;
};

export const orderByWeight = memoize( ( children: Item[] ) : Item[] => {
	const unique: { [ k: string ]: Item } = {};
	for ( let child of children ) {
		const id = child.name;
		if ( ( typeof unique[ id ] ) !== 'undefined' ) {
			// Merge with existing.
			unique[ id ].children = [
				...unique[ id ].children,
				...child.children,
			];
			unique[ id ].value += child.value;
		} else {
			// Use our new one.
			unique[ id ] = {
				...child,
				start: undefined,
				end: undefined,
			};
		}
	}

	const uniqued = Object.values( unique );
	const mapped = uniqued.map( item => ( {
		...item,
		children: orderByWeight( item.children ),
	} ) );
	const sorted = sortBy( mapped, i => -i.value );
	return sorted;
} );
