import React, { useState } from 'react';
import BellIcon from 'react-feather/dist/icons/bell';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppState } from '../../reducers';
import Avatar from '../Avatar';

import './User.css';

interface Props {
	showNotifications?: boolean,
}

type AllProps = Props & ReturnType<typeof mapStateToProps>;

function User( props: AllProps ) {
	const [ numNotifs, setNotifs ] = useState<number>( 0 );

	if ( ! props.user ) {
		return null;
	}

	const notifsClasses = [
		'Sidebar-User__notifications',
		numNotifs > 0 && 'Sidebar-User__notifications--has-notifs',
	];

	return (
		<div className="Sidebar-User">
			<Link
				className="Sidebar-User__profile"
				title="Change profile and settings"
				to="/profile"
				type="button"
			>
				<Avatar
					size={ 32 }
					user={ props.user }
				/>
				<div>
					<span className="Sidebar-User__logged-in">Logged in as </span>
					<span>{ props.user.name }</span>
				</div>
			</Link>
			{ props.showNotifications && (
				<button
					className={ notifsClasses.filter( Boolean ).join( ' ' ) }
					type="button"
					onClick={ () => setNotifs( numNotifs + 1 ) }
				>
					<BellIcon
						size={ 18 }
					/>
					{ numNotifs > 0 && (
						<span className="Sidebar-User__notification-count">{ numNotifs >= 100 ? '99+' : numNotifs }</span>
					) }
				</button>
			) }
		</div>
	);
}

const mapStateToProps = ( state: AppState ) => {
	return {
		user: state.currentUser.user,
	};
};

export default connect( mapStateToProps )( User );
