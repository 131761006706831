import { FormEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { updateCurrentUser } from '../../actions';
import { UserResponse } from '../../api';
import { AppState } from '../../reducers';

interface Props {
	user: undefined | UserResponse;
}

function Settings( props: Props ) {
	const [ email, setEmail ] = useState( props.user?.email );
	const [ name, setName ] = useState( props.user?.name );
	const [ optInToPersonalDataCollection, setOptInToPersonalDataCollection ] = useState( props.user?.has_opted_in_to_personal_data_collection );
	const [ saved, setSaved ] = useState( false );

	useEffect( () => {
		setOptInToPersonalDataCollection( props.user?.has_opted_in_to_personal_data_collection );
		setEmail( props.user?.email );
		setName( props.user?.name );
	}, [ props.user ] );

	const dispatch = useDispatch();

	const onSubmit = async ( event: FormEvent<HTMLFormElement> ) => {
		event.preventDefault();
		await dispatch( updateCurrentUser( {
			email,
			name,
			has_opted_in_to_personal_data_collection: optInToPersonalDataCollection,
		} ) );

		setSaved( true );
	};

	return (
		<div className="bg-white text-altis-800">
			<div className="py-12 pb-10">
				<div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
					<form className="space-y-8 divide-y divide-gray-200" onSubmit={ onSubmit }>
						<div className="space-y-8 divide-y divide-gray-200">
							<div className="pt-8">
								{ saved && <p>
									Settings saved.
								</p> }
								<div>
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										Profile
									</h3>
									<p className="mt-1 text-sm text-gray-500">
										Enter your organisation details here.
									</p>
								</div>
								<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
									<div className="sm:col-span-4">
										<label className="block text-sm font-medium text-gray-700" htmlFor="full-name">
											Full Name
										</label>
										<div className="mt-1">
											<input
												autoComplete="given-name"
												className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												id="full-name"
												name="full-name"
												type="text"
												value={ name }
												onChange={ e => setName( e.target.value ) }
											/>
										</div>
									</div>

									<div className="sm:col-span-4">
										<label className="block text-sm font-medium text-gray-700" htmlFor="email">
											Company E-mail Address
										</label>
										<div className="mt-1">
											<input
												autoComplete="email"
												className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												id="email"
												name="email"
												type="email"
												value={ email }
												onChange={ e => setEmail( e.target.value ) }
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="pt-8">
								<div>
									<h3 className="text-lg leading-6 font-medium text-gray-900">Privacy</h3>
								</div>
								<div className="mt-6">
									<fieldset>
										<legend className="sr-only">Privacy</legend>
										<div className="mt-4 space-y-4">
											<div className="relative flex items-start">
												<div className="flex items-center h-5">
													<input
														checked={ optInToPersonalDataCollection }
														className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
														id="comments"
														name="comments"
														type="checkbox"
														onChange={ e => setOptInToPersonalDataCollection( e.target.checked ) }
													/>
												</div>
												<div className="ml-3 text-sm">
													<label className="text-gray-500" htmlFor="comments">
														I consent to my personal data being associated with actions
														performed in the Altis Dashboard, to inform feature activation
														suggestions.
													</label>
												</div>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
						</div>

						<div className="pt-5">
							<div className="flex justify-end">
								<button
									className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									type="submit"
								>
									Save
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps( state: AppState ): Props {
	return {
		user: state.currentUser.user,
	};
}
export default connect( mapStateToProps )( Settings );
