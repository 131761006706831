import { findAndReplace } from 'mdast-util-find-and-replace';
import type { Plugin } from 'unified';

function replaceIssue( value: string, num: string, match: RegExpMatchArray ) {
	if ( ! match.input || typeof match.index === 'undefined' ) {
		return false;
	}

	if (
		/\w/.test( match.input.charAt( match.index - 1 ) ) ||
		/\w/.test( match.input.charAt( match.index + value.length ) )
	) {
		return false;
	}

	const url = `/support/ticket/${ num }/`;

	return {
		type: 'link',
		title: null,
		url,
		children: [
			{
				type: 'text',
				value,
			},
		],
	};
}

export const remarkTicketReferences: Plugin = () => tree => {
	findAndReplace(
		// @ts-ignore
		tree,
		[
			[ /#([1-9]\d*)/gi, replaceIssue ],
		],
		{
			ignore: [
				'link',
				'linkReference',
			],
		}
	);
};
