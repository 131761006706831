import React from 'react';

import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import { CliSessionResponse } from '../../types/rest-api';
import ActivityListItemLoading from '../ActivityListItemLoading';
import Pagination from '../Pagination';
import withPagination, { PaginationProps } from '../withPagination';

import SessionItem from './SessionItem';

import './Sessions.css';

interface Props {
	application: string,
}
type AllProps = Props & PaginationProps & {
	sessions: ApiResponse<CliSessionResponse[]>,
}
export function Sessions( props: AllProps ) {
	const pages = props.sessions.response ? parseInt( props.sessions.response.headers.get( 'X-WP-TotalPages' ) || '1', 10 ) : 0;
	return (
		<div className="ApplicationShell-Sessions">
			<h3>Session History</h3>
			{ props.sessions.error ? (
				<p>Could not load sessions. { props.sessions.error.message }</p>
			) : props.sessions.isLoading ? (
				<>
					<ActivityListItemLoading />
					<ActivityListItemLoading />
					<ActivityListItemLoading />
					<ActivityListItemLoading />
				</>
			) : ( props.sessions.data.length > 0 ) ? (
				<>
					<ul>
						{ props.sessions.data.map( session => (
							<SessionItem
								key={ session.id }
								application={ props.application }
								session={ session }
							/>
						) ) }
					</ul>
					<Pagination
						currentPage={ props.page }
						totalPages={ pages }
						onNewer={ () => props.setPage( props.page - 1 ) }
						onOlder={ () => props.setPage( props.page + 1 ) }
					/>
				</>
			) : (
				<p>No sessions found.</p>
			) }
		</div>
	);
}
export default withPagination( withApiData( ( props: Props & PaginationProps ) => ( {
	sessions: `/stack/applications/${ props.application }/cli/sessions?page=${ props.page }`,
} ) )( Sessions ) );
