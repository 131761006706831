import React, { Component } from 'react';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import { connect } from 'react-redux';

import { updateCurrentUser } from '../actions';
import { defaultAPI } from '../api';

import Avatar from './Avatar';
import ErrorBlock from './ErrorBlock';
import OutlineButton from './OutlineButton';
import SolidButton from './SolidButton';

import './EditProfileForm.css';

class EditProfileForm extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			user: { ...props.user },
			isSubmitting: false,
			error: null,
		};
	}
	onSubmit( e ) {
		e.preventDefault();
		this.setState( { isSubmitting: true } );
		this.props
			.dispatch( updateCurrentUser( this.state.user ) )
			.then( user => {
				this.props.onSubmit( user );
			} )
			.catch( error => {
				this.setState( { error } );
			} )
			.finally( () => {
				this.setState( { isSubmitting: false } );
			} );
	}
	render() {
		return (
			<div className="EditProfileForm">
				<header>
					<Avatar size={ 48 } user={ this.props.user } />
					<h2>Edit Profile</h2>
					<a
						className="edit-extended-profile"
						href={ `${defaultAPI.config.rest_url}../wp-admin/profile.php` }
						rel="noopener noreferrer"
						target="_blank"
					>
						Edit Extended Profile <ExternalLinkIcon size={ 12 } />
					</a>
				</header>
				<form onSubmit={ e => this.onSubmit( e ) }>
					{ this.state.error && <ErrorBlock message={ this.state.error.message } /> }
					<div>
						<label>Name</label>
						<input
							type="text"
							value={ this.state.user.name }
							onChange={ e =>
								this.setState( {
									user: {
										...this.state.user,
										name: e.target.value,
									},
								} ) }
						/>
					</div>
					<div>
						<label>Email Address</label>
						<input
							type="email"
							value={ this.state.user.email }
							onChange={ e =>
								this.setState( {
									user: {
										...this.state.user,
										email: e.target.value,
									},
								} ) }
						/>
					</div>
					<div>
						<label>
							<input
								checked={ this.state.user.has_opted_in_to_personal_data_collection }
								type="checkbox"
								onChange={ e =>
									this.setState( {
										user: {
											...this.state.user,
											has_opted_in_to_personal_data_collection: e.target.checked,
											has_responded_to_personal_data_collection: true,
										},
									} ) }
							/>
							{ ' ' }
							I consent to my personal data being associated with actions performed in the Altis Dashboard, to inform feature activation suggestions.
						</label>
					</div>
					<p className="submit">
						<SolidButton
							disabled={ this.state.isSubmitting }
							name={ this.state.isSubmitting ? 'Saving...' : 'Update' }
							type="submit"
						/>
						<OutlineButton
							name="Log out"
							onClick={ e => {
								e.preventDefault();
								this.props.onLogOut();
							} }
						/>
					</p>
					<p
						className="EditProfileForm__version"
					>
						Version { process.env.REACT_APP_GIT_SHA }
					</p>
				</form>
			</div>
		);
	}
}

function mapStateToProps( state ) {
	return {
		user: state.currentUser.user,
	};
}

export default connect( mapStateToProps )( EditProfileForm );
