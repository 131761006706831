import { DateTime } from 'luxon';
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';

import { ApplicationResponse } from '../../types/rest-api';
import ErrorBoundary from '../ErrorBoundary';
import RequestsGraph from '../InstanceMetrics/RequestsGraph';
import PageTitle from '../PageTitle';

import 'react-datepicker/dist/react-datepicker.css';
import './Metrics.css';
import { getProdEnv } from './util';

type InputProps = {
	value?: string,
	onClick?(): void,
}

const CustomInput = forwardRef<HTMLButtonElement, InputProps>(
	( { value, onClick }: InputProps, ref ) => (
		<button
			ref={ ref }
			className="Instance-Metrics__select"
			onClick={ onClick }
		>
			{ value }

			<ChevronDownIcon
				size={ 14 }
			/>
		</button>
	)
);

interface Props {
	applications: ApplicationResponse[],
	instance: any,
	type?: string,
	onChangeType( type: string ): void,
}

function Metrics( props: Props ) {
	const { applications } = props;
	const [ month, setMonth ] = useState( DateTime.utc().toJSDate() );

	const start = DateTime.fromJSDate( month, { zone: 'utc' } ).startOf( 'month' );
	const end = start.endOf( 'month' );

	if ( applications.length < 1 ) {
		return (
			<div className="Instance-Metrics">
				<h1>Metrics</h1>
				<p>No environments available to pull metrics for.</p>
			</div>
		);
	}

	const prod = getProdEnv( props.applications );

	return (
		<div className="Instance-Metrics">
			<PageTitle title="Metrics" />
			<h1>Metrics</h1>
			<p className="Instance-Metrics__selector box-border">
				{ 'Showing page requests for ' }
				<DatePicker
					customInput={ <CustomInput /> }
					dateFormat="LLLL yyyy"
					selected={ month }
					showMonthYearPicker
					onChange={ month => setMonth( month as Date ) }
				/>
			</p>

			<ErrorBoundary>
				{ props.applications.length < 1 ? (
					<RequestsGraph
						application={ null }
						period={ {
							start,
							end,
						} }
						showTitle
					/>
				) : ! prod ? (
					<p>No production application available. Metrics are only available on production environments.</p>
				) : (
					( ! props.type ) ? (
						<RequestsGraph
							application={ prod.id }
							period={ {
								start,
								end,
							} }
							showTitle
						/>
					) : (
						<p>Invalid graph type selected.</p>
					)
				) }
			</ErrorBoundary>
		</div>
	);
}

export default Metrics;
