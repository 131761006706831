import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { appIdsForRegion } from '../lib/utils';

export class ApplicationSelect extends Component {
	static propTypes = {
		selectedApplication: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		region: PropTypes.string.isRequired,
		applications: PropTypes.array.isRequired,
	};
	onChange( option ) {
		this.props.onChange( option ? option.value : null );
	}
	render() {
		return (
			<Select
				options={ this.props.applications.map( id => ( {
					value: id,
					label: id,
				} ) ) }
				require
				value={ {
					value: this.props.selectedApplication,
					label: this.props.selectedApplication,
				} }
				onChange={ v => this.onChange( v ) }
			/>
		);
	}
}

function mapStateToProps( state, props ) {
	return {
		applications: appIdsForRegion( state.applications, props.region ),
	};
}
export default connect( mapStateToProps )( ApplicationSelect );
