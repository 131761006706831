import PropTypes from 'prop-types';
import React from 'react';

import './RunningTasksList.css';
import RunningTask from './RunningTask';

export default function RunningTasksList( props ) {
	return (
		<div className="RunningTasksList">
			{ props.items.map( ( task, i ) => (
				<RunningTask
					key={ i }
					{ ...task }
					onRemoveTask={ () => props.onRemoveTask( task ) }
					onToggleTaskDetail={ () => props.onToggleTaskDetail( task ) }
				/>
			) ) }
		</div>
	);
}

RunningTasksList.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape( {
			isExpanded: PropTypes.bool,
			progress: PropTypes.number.isRequired,
			messages: PropTypes.array.isRequired,
		} )
	),
	onRemoveTask: PropTypes.func.isRequired,
	onToggleTaskDetail: PropTypes.func.isRequired,
};
