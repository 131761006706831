import React from 'react';

import './ElasticSearchClusterListItem.css';
import LiveLineChart from './LiveLineChart';
import ServerStatusIndicator from './ServerStatusIndicator';

export default function ElasticSearchClusterListItem( props ) {
	return (
		<div className="ElasticSearchClusterListItem">
			<ServerStatusIndicator status="ok" />
			<div className="server-name">
				<strong>{ props.id }</strong>
				<span className="type">{ props.size }</span>
			</div>
			{ props._links && <LiveLineChart endpoint={ props._links['https://hm-stack.hm/metric'][0].href } height={ 60 } /> }
		</div>
	);
}
