import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';

interface Props {
	disabled?: boolean,
	to?: string,
	onClick?() : void,
	name: string,
	color?: string,
	disabledColor?: string
	type?: 'button' | 'submit',
}

export default function SolidButton( props: Props ) {
	return (
		<Button
			disabled={ !! props.disabled }
			href={ props.to ? props.to : undefined }
			type={ props.type }
			variant="primary"
			onClick={ props.to ? undefined : props.onClick }
		>
			{ props.name }
		</Button>
	);
}

SolidButton.propTypes = {
	color: PropTypes.string.isRequired,
	disabledColor: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disable: PropTypes.bool,
	onClick: PropTypes.func,
	to: PropTypes.string,
	style: PropTypes.object,
};

SolidButton.defaultProps = {
	color: '#4667DE',
	disabledColor: 'rgba( 70, 103, 222, .3 )',
};
