import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PillButton from './PillButton';
import './FilterLogs.css';

export default class FilterLogs extends Component {
	static propTypes = {
		status: PropTypes.arrayOf( PropTypes.string ),
		onChangeFilter: PropTypes.func.isRequired,
		after: PropTypes.string,
		before: PropTypes.string,
	};
	constructor( props ) {
		super( props );
		this.state = {
			after: props.after,
			before: props.before,
			search: '',
		};
	}
	onToggleStatus( status ) {
		const filter = { ...this.props };
		const statuses = filter.status.slice();
		if ( statuses.indexOf( status ) > -1 ) {
			statuses.splice( statuses.indexOf( status ), 1 );
		} else {
			statuses.push( status );
		}
		filter.status = statuses;
		this.props.onChangeFilter( filter );
	}
	render() {
		return (
			<div className="FilterLogs">
				{ this.props.status && (
					<div className="filter-status">
						<strong>Filter:</strong>
						{ [ 'success', 'fail' ].map( status => (
							<PillButton
								key={ status }
								color={ this.props.status.indexOf( status ) > -1 ? '#4667DE' : '#DDD' }
								name={ status }
								onClick={ () => this.onToggleStatus( status ) }
							/>
						) ) }
					</div>
				) }
				<form
					onSubmit={ e => {
						e.preventDefault();
						this.props.onChangeFilter( {
							...this.props,
							...this.state,
						} );
					} }
				>
					<input
						className="search"
						placeholder="Search..."
						value={ this.state.search }
						onChange={ e => this.setState( { search: e.target.value } ) }
					/>
					{ this.props.after && (
						<div className="filter-after">
							<strong>Since:</strong>
							<input value={ this.state.after } onChange={ e => this.setState( { after: e.target.value } ) } />
						</div>
					) }
					{ this.props.before && (
						<div className="filter-after">
							<strong>Before:</strong>
							<input
								value={ this.state.before }
								onChange={ e =>
									this.setState( {
										before: e.target.value,
									} ) }
							/>
						</div>
					) }
					<input type="submit" value="Update" />
				</form>
			</div>
		);
	}
}
