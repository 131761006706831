import { Link } from 'react-router-dom';

export default function VerifyInstall() {
	return (
		<div className="AddSiteStep4 pb-10">
			<h2 className="text-2xl pb-5">Verify Installation</h2>
			<div className="">Your site has now been set up!</div>
			<Link
				className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				to="/accelerate/"
			>
				Go back to Dashboard 🎉
			</Link>
		</div>
	);
}
