import React from 'react';
import FaultIcon from 'react-feather/dist/icons/alert-octagon';
import WarningIcon from 'react-feather/dist/icons/alert-triangle';

import { PlainFormattedDateTime } from '../FormattedDateTime';
import IpAddress from '../IpAddress';
import PHPLogLoading from '../PHPLogLoading';
import RelativeLink from '../RelativeLink';
import SortableTable, { Columns } from '../SortableTable';
import WarningBlock from '../WarningBlock';

import './SummaryList.css';

interface TraceSummary {
	Id: string,
	Http: {
		HttpMethod: string,
		HttpStatus?: number,
		ClientIp?: string,
		HttpURL: string,
	},
	ResponseTime?: number,
	HasFault?: boolean,
	HasError?: boolean,
	Duration?: number,
}
type TraceSummaryDated = TraceSummary & {
	Date: number,
}

interface Props {
	isLoading?: boolean,
	traceSummaries: TraceSummary[],

	// todo: remove and clean up
	onSelectTrace?: any,
}

const COLUMNS: Columns<TraceSummaryDated> = {
	method: {
		title: 'Method',
		className: 'XRayOverview-SummaryList__col--method',
		sort: [
			i => i.Http.HttpMethod,
		],
	},
	status: {
		title: 'Status',
		className: 'XRayOverview-SummaryList__col--status',
		sort: [
			i => i.Http.HttpStatus || 500,
		],
	},
	date: {
		title: 'Date',
		className: 'XRayOverview-SummaryList__col--date',
		sort: [ 'Date' ],
	},
	'response-time': {
		title: 'Duration',
		className: 'XRayOverview-SummaryList__col--response-time',
		sort: [ 'ResponseTime' ],
	},
	url: {
		title: 'URL',
		className: 'XRayOverview-SummaryList__col--url',
		sort: [
			i => i.Http.HttpURL,
		],
	},
	'client-ip': {
		title: 'Client IP',
		className: 'XRayOverview-SummaryList__col--client-ip',
		sort: [
			i => i.Http.ClientIp || '0.0.0.0',
		],
	},
};

// The date of traces is not available in the summaries view. However,
// the second segment of the trace ID is actually a base 16 value of the date
// as unix timestamp in seconds (as discovered by reverse engineering the AWS console).
const getDate = ( summary: TraceSummary ) => parseInt( summary.Id.split( '-' )[1], 16 );
const withDates = ( summaries: TraceSummary[] ): TraceSummaryDated[] => summaries.map( summary => ( {
	...summary,
	Date: getDate( summary ),
} ) );

function SummaryList( props: Props ) {
	const summaries = withDates( props.traceSummaries );

	return (
		<div className="XRayOverview-SummaryList">
			{ summaries.length === 500 && (
				<WarningBlock message="More than 500 traces were found, therefore results are truncated. Reduce the timeframe or make your query more specific to see all results." />
			) }
			<SortableTable
				className="XRayOverview-SummaryList__table"
				columns={ COLUMNS }
				data={ summaries }
				defaultReverse
				defaultSort="date"
				isDense
				isFixed
			>
				{ item => (
					<tr
						key={ item.Id }
						className={ [
							'XRayOverView-SummaryList__summary',
							item.HasError && 'XRayOverView-SummaryList__summary--error',
							item.HasFault && 'XRayOverView-SummaryList__summary--fault',
						].filter( Boolean ).join( ' ' ) }
					>
						<td className="XRayOverview-SummaryList__col--method">{ item.Http.HttpMethod }</td>
						<td className="XRayOverview-SummaryList__col--status">
							{ item.Http.HttpStatus }{ ' ' }
							<span title="Trace has errors">
								{ item.HasError && ! item.HasFault && <WarningIcon color="#ff9a00" size={ 12 } /> }
								{ item.HasFault && <FaultIcon color="#d11602" size={ 12 } /> }
							</span>
						</td>
						<td className="XRayOverview-SummaryList__col--date">
							<PlainFormattedDateTime
								format="short-precise"
								value={ item.Date * 1000 }
							/>
						</td>
						<td className="XRayOverview-SummaryList__col--response-time">
							{ item.ResponseTime?.toFixed( 3 ) }
						</td>
						<td className="XRayOverview-SummaryList__col--url">
							{ /* @ts-ignore */ }
							<RelativeLink
								to={ `/trace/${item.Id}` }
							>
								{ item.Http.HttpURL }
							</RelativeLink>
						</td>
						<td className="XRayOverview-SummaryList__col--client-ip">
							<IpAddress ip={ item.Http.ClientIp } />
						</td>
					</tr>
				) }
			</SortableTable>
			{ props.isLoading ? (
				<PHPLogLoading />
			) : (
				<span className="XRayOverView-SummaryList__total">A total of { summaries.length } traces.</span>
			) }
		</div>
	);
}

export default SummaryList;
