import deepmerge from 'deepmerge';
import { Action } from 'redux';

import { Action as CreateBackupAction } from '../actions/createBackup';
import { Action as CreateDeployAction } from '../actions/createDeploy';
import { Action as CreatImportAction } from '../actions/createImport';
import { Action as DeleteFailedTaskAction } from '../actions/deleteFailedTask';
import { Action as DeleteRunningTaskAction } from '../actions/deleteRunningTask';
import { Action as FetchRunningTasksAction } from '../actions/fetchRunningTasks';
import { Action as FetchTaskStreamAction } from '../actions/fetchTaskStream';
import { Action as UpdateApplicationAction } from '../actions/updateApplication';
import { RunningTasksResponse } from '../types/rest-api';

export interface ToggleRunningTasksDropdownAction extends Action {
	type: 'TOGGLE_RUNNING_TASKS_DROPDOWN';
}

export interface ToggleRunningTasksDetailAction extends Action {
	type: 'TOGGLE_RUNNING_TASKS_DETAIL';
	payload: {
		task: Task;
	};
}

interface State {
	isShowingDropDown: boolean;
	byId: Record<string, Task>;
	runningTasks: string[];
	failedTasks: string[];
}

interface TaskPhases {
	[phaseName: string]: TaskPhase;
}

interface MergedTaskPhases {
	phases: TaskPhases;
}

function arrayUnique( arr: any[] ) {
	return arr.filter( ( value, index, self ) => {
		return self.indexOf( value ) === index;
	} );
}

function mergeAndSortPhases( old: TaskPhase[], arrayOfPhases: MergedTaskPhases[] ): TaskPhase[] {
	// Let's convert the array temporarily into a mergable object
	let tmpPhases = {} as TaskPhases;
	for ( let phase of old ) {
		tmpPhases[phase.phaseType] = phase;
	}

	const merged = deepmerge.all( [ { phases: tmpPhases }, ...arrayOfPhases ] ) as MergedTaskPhases;
	const sorted = Object.values( merged.phases ).sort( ( a, b ) => a.order! - b.order! );
	return sorted;
}

function calculateDurations( arr: TaskPhase[] ): TaskPhase[] {
	return arr.map( item => {
		if ( item.startTime && item.endTime ) {
			item.durationInSeconds = ( Date.parse( item.endTime ) - Date.parse( item.startTime ) ) / 1000;
		}
		return item;
	} );
}

function removeTaskFromList( tasksList: string[], task_id: string ) {
	const index = tasksList.indexOf( task_id );
	let newTaskList = Array.from( tasksList );
	newTaskList.splice( index, 1 );
	return newTaskList;
}

function mergeIntoTask( newTask: object ): Task {
	return {
		startDate: new Date(),
		progress: 0,
		title: '',
		details: '',
		messages: [],
		phases: [],
		isExpanded: false,
		applicationId: '',
		id: '',
		...newTask,
	};
}

export default function tasks(
	state: State = {
		isShowingDropDown: false,
		byId: {},
		runningTasks: [],
		failedTasks: [],
	},
	action:
		| CreateBackupAction
		| ToggleRunningTasksDropdownAction
		| ToggleRunningTasksDetailAction
		| CreateDeployAction
		| UpdateApplicationAction
		| CreatImportAction
		| FetchTaskStreamAction
		| FetchRunningTasksAction
		| DeleteRunningTaskAction
		| DeleteFailedTaskAction
) {
	switch ( action.type ) {
		case 'TOGGLE_RUNNING_TASKS_DROPDOWN':
			return {
				...state,
				isShowingDropDown: ! state.isShowingDropDown,
			};
		case 'TOGGLE_RUNNING_TASKS_DETAIL':
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.task.id]: {
						...state.byId[action.payload.task.id],
						isExpanded: ! state.byId[action.payload.task.id].isExpanded,
					},
				},
			};
		case 'DEPLOY_CREATING_TASK_STREAM': {
			const task = mergeIntoTask( {
				title: `Deploying to ${action.payload.application.id}`,
				id: action.payload.taskStream,
				applicationId: action.payload.application.id,
			} );
			return {
				...state,
				byId: {
					...state.byId,
					[task.id]: task,
				},
				runningTasks: [ ...state.runningTasks, task.id ],
			};
		}
		case 'APPLICATION_UPDATING_TASK_STREAM': {
			const task = mergeIntoTask( {
				title: `Updating ${action.payload.application.id} ${Object.keys( action.payload.fields ).join( ', ' )}`,
				id: action.payload.taskStream,
				applicationId: action.payload.application.id,
			} );
			return {
				...state,
				byId: {
					...state.byId,
					[task.id]: task,
				},
				runningTasks: [ ...state.runningTasks, task.id ],
			};
		}
		case 'IMPORT_UPLOADS_CREATING_TASK_STREAM': {
			const task = mergeIntoTask( {
				title: `Import uploads to ${action.payload.application.id}`,
				id: action.payload.taskStream,
				applicationId: action.payload.application.id,
			} );
			return {
				...state,
				byId: {
					...state.byId,
					[task.id]: task,
				},
				runningTasks: [ ...state.runningTasks, task.id ],
			};
		}
		case 'IMPORT_DATABASE_CREATING_TASK_STREAM': {
			const task = mergeIntoTask( {
				title: `Import database to ${action.payload.application.id}`,
				id: action.payload.taskStream,
				applicationId: action.payload.application.id,
			} );
			return {
				...state,
				byId: {
					...state.byId,
					[task.id]: task,
				},
				runningTasks: [ ...state.runningTasks, task.id ],
			};
		}
		case 'BACKUP_CREATING_TASK_STREAM': {
			const task = mergeIntoTask( {
				title: `Backing up ${action.payload.application.id}`,
				id: action.payload.taskStream,
				applicationId: action.payload.application.id,
			} );
			return {
				...state,
				byId: {
					...state.byId,
					[task.id]: task,
				},
				runningTasks: [ ...state.runningTasks, task.id ],
			};
		}
		case 'TASK_STREAM_CREATED': {
			// Skip it if the task already exists from an already specific task creted above.
			if ( state.byId[action.payload.id] ) {
				return state;
			}
			const task = mergeIntoTask( {
				id: action.payload.id,
				applicationId: action.payload.application.id,
			} );
			return {
				...state,
				byId: {
					...state.byId,
					[task.id]: {
						...task,
						...state.byId[task.id],
					},
				},
			};
		}
		case 'TASK_STREAM_LOGS_UPDATED':
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.id]: {
						...state.byId[action.payload.id],
						messages: state.byId[action.payload.id].messages.concat( action.payload.logs ),
					},
				},
			};
		case 'TASK_STREAM_PERCENT_UPDATED':
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.id]: {
						...state.byId[action.payload.id],
						progress: action.payload.percent,
					},
				},
			};
		case 'TASK_STREAM_PHASES_UPDATED':
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.id]: {
						...state.byId[action.payload.id],
						phases: calculateDurations( mergeAndSortPhases( state.byId[action.payload.id].phases, action.payload.data ) ),
					},
				},
			};
		case 'TASK_STREAM_COMPLETED':
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.id]: {
						...state.byId[action.payload.id],
						progress: 100,
					},
				},
				isShowingDropDown: state.isShowingDropDown && Object.values( state.byId ).length > 0,
				runningTasks: state.runningTasks.filter( id => id !== action.payload.id ),
			};
		case 'TASK_STREAM_FAILED':
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.id]: {
						...state.byId[action.payload.id],
						messages: state.byId[action.payload.id].messages.concat( action.payload.error ),
					},
				},
				isShowingDropDown: state.isShowingDropDown && Object.values( state.byId ).length > 0,
				runningTasks: state.runningTasks.filter( id => id !== action.payload.id ),
				failedTasks:
					state.runningTasks.indexOf( action.payload.id ) > -1
						? [ ...state.failedTasks, action.payload.id ]
						: state.failedTasks,
			};
		case 'RUNNING_TASKS_UPDATED': {
			const newTasks = action.tasks.reduce( ( carry: { [s: string]: any }, task ) => {
				carry[task.id] = mergeIntoTask( {
					title: task.description,
					startDate: new Date( task.date ),
					id: task.id,
					applicationId: task.application,
				} );
				return carry;
			}, {} );
			return {
				...state,
				byId: {
					...newTasks,
					...state.byId,
				},
				runningTasks: arrayUnique( [
					...state.runningTasks,
					...action.tasks.map( ( task: RunningTasksResponse ) => task.id ),
				] ),
			};
		}
		case 'REMOVING_FAILED_TASK': {
			// Remove the ID from the failedTasks list
			const failedTasks = removeTaskFromList( state.failedTasks, action.payload.task.id );
			return {
				...state,
				failedTasks,
			};
		}
		case 'REMOVING_TASK': {
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.task.id]: {
						...state.byId[action.payload.task.id],
						isRemoving: true,
					},
				},
			};
		}
		case 'REMOVE_RUNNING_TASK': {
			// Remove the ID from the runningTasks list
			const runningTasks = removeTaskFromList( state.runningTasks, action.payload.task.id );
			return {
				...state,
				runningTasks,
			};
		}
		case 'REMOVING_TASK_FAILED': {
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.task.id]: {
						...state.byId[action.payload.task.id],
						isRemoving: false,
					},
				},
			};
		}
		default:
			return state;
	}
}
