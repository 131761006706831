import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ActivityDashboardFilterForm.css';
import Select from 'react-select';

import { fetchActivity } from '../actions';
import { defaultAPI } from '../api';

class ActivityDashboardFilterForm extends Component {
	onUpdateSelectedApplication( value ) {
		if ( ! value ) {
			return this.props.onUpdateFilter( {} );
		}
		this.props.onUpdateFilter( { object: 'Application::' + value.value } );
	}
	render() {
		return (
			<form className="ActivityDashboardFilterForm">
				<label>Filter by site</label>
				<Select
					isClearable
					multi={ false }
					options={ Object.values( this.props.applications ).map( application => ( {
						value: application.id,
						label: application.id,
					} ) ) }
					value={
						this.props.filter.object && {
							value: this.props.filter.object.substr( 13 ),
							label: this.props.filter.object.substr( 13 ),
						}
					}
					onChange={ option => this.onUpdateSelectedApplication( option ) }
				/>
			</form>
		);
	}
}

export default connect(
	state => ( {
		applications: state.applications,
		filter: state.activity.filter,
	} ),
	dispatch => ( {
		onUpdateFilter( filter ) {
			dispatch( {
				type: 'UPDATE_ACTIVITY_FILTER',
				filter,
			} );
			dispatch( fetchActivity( filter, defaultAPI ) );
		},
	} )
)( ActivityDashboardFilterForm );
