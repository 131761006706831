import debounce from 'lodash/debounce';
import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { defaultAPI } from '../api';
import { AppState } from '../reducers';
import { LogResponse } from '../types/rest-api';

interface TaskStreamCreatedAction extends ReduxAction {
	type: 'TASK_STREAM_CREATED';
	payload: {
		application: Application;
		id: string;
	};
}

interface TaskStreamLogsUpdatedAction extends ReduxAction {
	type: 'TASK_STREAM_LOGS_UPDATED';
	payload: {
		id: string;
		application: Application;
		logs: LogResponse[];
	};
}

interface TaskStreamCompletedAction extends ReduxAction {
	type: 'TASK_STREAM_COMPLETED';
	payload: {
		application: Application;
		id: string;
	};
}

interface TaskStreamFailedAction extends ReduxAction {
	type: 'TASK_STREAM_FAILED';
	payload: {
		application: Application;
		id: string;
		error: any;
	};
}

interface TaskStreamPercentUpdatedAction extends ReduxAction {
	type: 'TASK_STREAM_PERCENT_UPDATED';
	payload: {
		application: Application;
		id: string;
		percent: number;
	};
}

interface TaskStreamPhasesUpdatedAction extends ReduxAction {
	type: 'TASK_STREAM_PHASES_UPDATED';
	payload: {
		id: string;
		application: Application;
		data: any[];
	};
}

export type Action =
	| TaskStreamCreatedAction
	| TaskStreamLogsUpdatedAction
	| TaskStreamCompletedAction
	| TaskStreamFailedAction
	| TaskStreamPercentUpdatedAction
	| TaskStreamPhasesUpdatedAction;

export default function fetchTaskStream(
	id: string,
	application: Application
): ThunkAction<Promise<any>, AppState, null, Action> {
	return dispatch => {
		return new Promise( ( resolve, reject ) => {
			dispatch( {
				type: 'TASK_STREAM_CREATED',
				payload: {
					id,
					application,
				},
			} );
			const stream = new EventSource(
				`${defaultAPI.url}/../stream-log?id=${application.id}&log=${id}&access_token=${
					defaultAPI.credentials &&
					defaultAPI.credentials.token &&
					defaultAPI.credentials.token.public}`
			);

			let logs: LogResponse[] = [];
			const dispatchLogs = debounce(
				() => {
					const logsToDispatch = [ ...logs ];
					logs = [];
					dispatch( {
						type: 'TASK_STREAM_LOGS_UPDATED',
						payload: {
							id,
							application,
							logs: logsToDispatch,
						},
					} );
				},
				500,
				{ maxWait: 3000 }
			);
			stream.addEventListener( 'log', ( message: any ) => {
				logs = logs.concat( JSON.parse( message.data ) );
				dispatchLogs();
			} );
			stream.addEventListener( 'complete', () => {
				stream.close();
				dispatch( {
					type: 'TASK_STREAM_COMPLETED',
					payload: {
						id,
						application,
					},
				} );
				resolve( {
					id,
					application,
				} );
			} );
			stream.addEventListener( 'fail', ( message: any ) => {
				stream.close();
				dispatch( {
					type: 'TASK_STREAM_FAILED',
					payload: {
						id,
						application,
						error: JSON.parse( message.data ),
					},
				} );
				reject( JSON.parse( message.data ) );
			} );
			stream.addEventListener(
				'percentComplete',
				debounce( ( message: any ) => {
					dispatch( {
						type: 'TASK_STREAM_PERCENT_UPDATED',
						payload: {
							id,
							application,
							percent: JSON.parse( message.data ),
						},
					} );
				}, 500 )
			);

			let phases: any[] = [];
			const dispatchPhases = debounce(
				() => {
					const phasesToDispatch = [ ...phases ];
					phases = [];
					dispatch( {
						type: 'TASK_STREAM_PHASES_UPDATED',
						payload: {
							id,
							application,
							data: phasesToDispatch,
						},
					} );
				},
				500,
				{ maxWait: 3000 }
			);

			stream.addEventListener( 'phaseStatus', ( message: any ) => {
				phases = phases.concat( JSON.parse( message.data ) );
				dispatchPhases();
			} );
		} );
	};
}
