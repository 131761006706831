import { Action as ReduxAction } from 'redux';

import { Action } from '../actions/fetchActivity';
import { AuditLogResponse } from '../types/rest-api';

interface State {
	byId: {
		[s: string]: AuditLogResponse,
	}
	filter: {
		[s: string]: string
	},
	isLoading: boolean,
}

interface UpatedActivityFilterAction extends ReduxAction {
	type: 'UPDATE_ACTIVITY_FILTER',
	filter: {
		[s: string]: string
	}
}

export default function activity(
	state: State = {
		byId: {},
		filter: {},
		isLoading: false,
	},
	action: Action | UpatedActivityFilterAction
) {
	switch ( action.type ) {
		case 'UPDATING_ACTIVITY':
			return {
				...state,
				isLoading: true,
			};
		case 'UPDATED_ACTIVITY':
			return {
				...state,
				isLoading: false,
				byId: {
					...action.activity.reduce( ( byId: any, item ) => {
						byId[item.id] = item;
						return byId;
					}, {} ),
				},
			};
		case 'UPDATE_ACTIVITY_FILTER':
			return {
				...state,
				filter: action.filter,
			};
		default:
			return state;
	}
}
