import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchRunningTasks } from '../actions';
import deleteFailedTask from '../actions/deleteFailedTask';
import deleteRunningTask from '../actions/deleteRunningTask';
import { defaultAPI } from '../api';
import RunningTasksList from '../components/RunningTasksList';
import { AppState } from '../reducers';

interface OwnProps {
	application: string,
}

interface StateProps {
	tasks: Task[],
	failedTasks: Task[],
}

interface DispatchProps {
	onRemoveFailedTask( task: Task ): void,
	onRemoveTask( task: Task ): void,
	onToggleTaskDetail( task: Task ): void,
	onUpdateRunningTasks(): void,
}

type Props = OwnProps & StateProps & DispatchProps;

function RunningTasksLoader( props: Props ) {
	const { onUpdateRunningTasks } = props;
	const [ timer, setTimer ] = useState<ReturnType<typeof setInterval>>();

	useEffect( () => {
		if ( ! timer ) {
			onUpdateRunningTasks();

			const intervalTimer = setInterval( () => {
				try {
					onUpdateRunningTasks();
				} catch ( err ) {
					// eslint-disable-next-line no-console
					console.warn( err );
				}
			}, 30000 );
			setTimer( intervalTimer );
		}
		return () => {
			if ( timer ) {
				clearInterval( timer );
			}
		};
	}, [ timer, onUpdateRunningTasks ] );

	return (
		<div>
			<RunningTasksList
				items={ props.tasks }
				onRemoveTask={ props.onRemoveTask }
				onToggleTaskDetail={ props.onToggleTaskDetail }
			/>

			{ props.failedTasks.length > 0 && (
				<div>
					<h2>Failed</h2>
					<RunningTasksList
						items={ props.failedTasks }
						onRemoveTask={ props.onRemoveFailedTask }
						onToggleTaskDetail={ props.onToggleTaskDetail }
					/>
				</div>
			) }
		</div>
	);
}

const mapStateToProps = ( state: AppState, props: OwnProps ) => {
	return {
		tasks: state.tasks.runningTasks.map( id => state.tasks.byId[id] ).filter( task => task.applicationId === props.application ),
		failedTasks: state.tasks.failedTasks.map( id => state.tasks.byId[id] ).filter( task => task.applicationId === props.application ),
	};
};

const mapDispatchToProps = ( dispatch: any ) => {
	return {
		onToggleTaskDetail( task: Task ) {
			dispatch( {
				type: 'TOGGLE_RUNNING_TASKS_DETAIL',
				payload: {
					task,
				},
			} );
		},
		onRemoveTask( task: Task ) {
			dispatch( deleteRunningTask( task ) );
		},
		onRemoveFailedTask( task: Task ) {
			dispatch( deleteFailedTask( task ) );
		},
		onUpdateRunningTasks() {
			dispatch( fetchRunningTasks( defaultAPI ) );
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)( RunningTasksLoader );
