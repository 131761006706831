import React, { useState } from 'react';

import useTooltip, { Tooltip } from './useTooltip';

interface Props {
	children: React.ReactNode,
	className?: string,
}

export default function Infotip( props: Props ) {
	const { setReferenceElement, TooltipProps } = useTooltip();
	const [ showing, setShowing ] = useState<boolean>( false );

	return (
		<>
			<svg
				ref={ setReferenceElement }
				className={ [
					'w-4 h-4 px-0.5 opacity-80 align-sub',
					props.className,
				].filter( Boolean ).join( ' ' ) }
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
				onMouseOut={ () => setShowing( false ) }
				onMouseOver={ () => setShowing( true ) }
			>
				<path
					clipRule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
					fillRule="evenodd"
				/>
			</svg>

			<Tooltip
				{ ...TooltipProps }
				className="pointer-events-none"
				visible={ showing }
			>
				{ props.children }
			</Tooltip>
		</>
	);
}
