import React from 'react';
import AlertTriangleIcon from 'react-feather/dist/icons/alert-triangle';
import InfoIcon from 'react-feather/dist/icons/info';
import OctagonIcon from 'react-feather/dist/icons/x-octagon';

import './PinnedNotices.css';

export interface Notice {
	message: string;
	level: 'major' | 'minor' | 'info';
}

const getIcon = ( level: Notice['level'] ) => {
	switch ( level ) {
		case 'major':
			return OctagonIcon;

		case 'minor':
			return AlertTriangleIcon;

		case 'info':
			return InfoIcon;
	}
};

type NoticeProps = {
	notice: Notice,
}

function NoticeComponent( props: NoticeProps ) {
	const Icon = getIcon( props.notice.level );

	return (
		<div
			className={ `PinnedNotices__notice PinnedNotices__notice--${ props.notice.level }` }
		>
			<Icon />
			<div
				className="PinnedNotices__notice-content"
				dangerouslySetInnerHTML={ { __html: props.notice.message } }
			/>
		</div>
	);
}

interface Props {
	notices: Notice[],
}

export default function PinnedNotices( props: Props ) {
	if ( ! props.notices.length ) {
		return null;
	}

	return (
		<>
			{ props.notices.map( ( notice, i ) => (
				<NoticeComponent
					key={ i }
					notice={ notice }
				/>
			) ) }
		</>
	);
}
