import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';

export default function ActivityListItemLoading( props ) {
	return (
		<ContainerDimensions>
			{ ( { width } ) => (
				<ContentLoader
					className="ActivityListItemLoading"
					height={ 40 }
					primaryColor="#f3f3f3"
					secondaryColor="#ecebeb"
					speed={ 2 }
					width={ width }
					{ ...props }
				>
					<circle cx="20" cy="20" r="20" />
					<rect height="18" rx="2" ry="2" width={ 200 } x="58" y="0" />
					<rect height="18" rx="2" ry="2" width={ width - 200 } x="58" y="23" />
				</ContentLoader>
			) }
		</ContainerDimensions>
	);
}
