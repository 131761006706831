import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';

export default function ServerListItemLoading( props ) {
	return (
		<div className="ServerListItemLoading">
			<div style={ { width: '100%' } }>
				<ContainerDimensions>
					{ ( { width } ) => (
						<ContentLoader
							height={ 55 }
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
							speed={ 2 }
							width={ width }
							{ ...props }
						>
							<circle cx="8" cy="19" r="8" />
							<rect height="33" rx="2" ry="2" width={ 166 } x="30" y="11" />
							<rect height="33" rx="2" ry="2" width={ 200 } x="230" y="11" />
							<rect height="33" rx="2" ry="2" width={ 153 } x="460" y="11" />
							<rect height="33" rx="2" ry="2" width={ 160 } x="643" y="11" />
						</ContentLoader>
					) }
				</ContainerDimensions>
			</div>
		</div>
	);
}
