import React from 'react';

import './CronLogItem.css';
import ExpandableText from './ExpandableText';
import FormattedRelativeTime from './FormattedRelativeTime';

export default function CronLogItem( props ) {
	return (
		<div className={ 'CronLogItem' }>
			<div className="time-ago" title={ new Date( props.date ).toISOString() }>
				<FormattedRelativeTime value={ props.date } /> / { props.duration.toFixed( 2 ) } seconds
			</div>
			<ul>
				<li>{ props.hook }</li>
				{ props.status && (
					<li>
						<strong>Status</strong> { props.status }
					</li>
				) }
				<li>
					<strong>Site</strong> { props.site_url }
				</li>
				{ typeof props.args !== 'undefined' && (
					<li>
						<strong>Args</strong>{ ' ' }
						<pre>
							<ExpandableText>{ JSON.stringify( props.args ) }</ExpandableText>
						</pre>
					</li>
				) }
				{ props.stdout && (
					<li>
						<strong>stdOut</strong>{ ' ' }
						<pre>
							<ExpandableText>{ props.stdout }</ExpandableText>
						</pre>
					</li>
				) }
				{ props.stderr && (
					<li>
						<strong>stdErr</strong>{ ' ' }
						<pre>
							<ExpandableText>{ props.stderr }</ExpandableText>
						</pre>
					</li>
				) }
			</ul>
		</div>
	);
}
