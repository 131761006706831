import { ChartBarIcon } from '@heroicons/react/solid';

import { AccelerateSiteResponse } from '../../../api';

import DashboardSitesMeta from './DashboardSitesMeta';
import SiteCard from './SiteCard';

interface Props {
	sites: AccelerateSiteResponse[],
}

export default function SitesList( props: Props ) {
	return (
		<div>
			<DashboardSitesMeta />
			{ props.sites.length === 0 ?
				<div className="max-w-7xl mx-auto px-4 h-[200px] sm:px-6 lg:px-8 ">
					<div className="rounded-[15px] bg-dash py-20 align-middle text-center text-gray-400">
						<ChartBarIcon className="h-10 w-10 text-gray-200" />
						<br />
						<h3>Add your first site</h3>
					</div>
				</div>
				:
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
						{ props.sites.map( site => <SiteCard key={ site.id } { ...site } /> ) }
					</ul>
				</div> }
		</div>
	);
}
