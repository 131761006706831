import memoize from 'lodash/memoize';

const BUILT_INS = [
	'require',
	'require_once',
	'include',
	'include_once',
];
const BUILT_INS_MATCH = new RegExp( '^(' + BUILT_INS.join( '|' ) + ')::' );

const WP_PATTERNS = [
	'_(_|e|x)$',
	'translate$',
	'load(_plugin)?_textdomain$',
	'wp$',
	'WP::',
	'(P|M)O::',
	'(POMO_|Translation_)',
	'wp_',
	'wpdb::',
	'do_action',
	'do_action_ref_array',
	'apply_filters',
	'(get|update|add|delete)_(option|metadata|post_meta|term_meta)',
];
const WP_MATCH = new RegExp( '^' + WP_PATTERNS.join( '|' ), 'i' );
const ALTIS_MATCH = /^Altis/i;

export const componentForFunction = memoize( name => {
	if ( BUILT_INS_MATCH.test( name ) ) {
		return 'php';
	}

	if ( WP_MATCH.test( name ) ) {
		return 'wordpress';
	}

	if ( ALTIS_MATCH.test( name ) ) {
		return 'altis';
	}

	return 'user';
} );

export const nameForComponent = ( component: ReturnType<typeof componentForFunction> ) => {
	switch ( component ) {
		case 'altis':
			return 'Altis';

		case 'php':
			return 'PHP';

		case 'user':
			return 'User';

		case 'wordpress':
			return 'WordPress';

		default: {
			// Ensure exhaustive switch.
			const _unused: never = component;
			return component;
		}
	}
};

export type ComponentName = ReturnType<typeof componentForFunction>;
