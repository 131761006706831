// From php_error_cb()
// https://github.com/php/php-src/blob/34b352d121051a1ba582916be48e2e36f8ddf0e7/main/main.c#L1277
export const ERROR_TYPES = {
	'E_ERROR': 'Fatal error',
	'E_RECOVERABLE_ERROR': 'Recoverable fatal error',
	'E_USER_WARNING': 'Warning',
	'E_PARSE': 'Parse error',
	'E_NOTICE': 'Notice',
	'E_STRICT': 'Strict Standards',
	'E_DEPRECATED': 'Deprecated',

	'__LOG': 'Log message',
	'__UNKNOWN': 'Unknown error',
};

export type PhpErrorTypeCode = keyof typeof ERROR_TYPES;
const ERROR_TYPE_CODES = ( Object.keys( ERROR_TYPES ) as PhpErrorTypeCode[] );

const getTypeCode = ( type: string ) : PhpErrorTypeCode => {
	return ERROR_TYPE_CODES.find( k => ERROR_TYPES[ k ] === type ) || '__UNKNOWN';
};

interface PhpError {
	type: PhpErrorTypeCode,
	message: string,
	file: string,
	line: number,
}

type LogError = {
	type: 'error',
	detail: PhpError,
}

type LogText = {
	type: 'log',
	detail: string,
}

type LogEntry = LogError | LogText;

// Errors are created in the format:
//   'PHP %s:  %s in %s on line %'
// They are then prefixed with the timestamp:
//   [d-M-Y H:i:s e] %s
//
// Except: core errors are instead in the format:
//   PHP %s: %s (%s)
//
// https://github.com/php/php-src/blob/34b352d121051a1ba582916be48e2e36f8ddf0e7/main/main.c#L1333
// https://github.com/php/php-src/blob/34b352d121051a1ba582916be48e2e36f8ddf0e7/main/main.c#L841

const TYPE_MATCH = Object.values( ERROR_TYPES ).join( '|' );
const ERROR_MATCH = `PHP (?<php_type>${ TYPE_MATCH }):  (?<php_message>.+) in (?<php_file>.+) on line (?<php_line>\\d+)`;
const TIMESTAMP_MATCH = '(?<ts_day>\\d{2})-(?<ts_month>\\w{3})-(?<ts_year>\\d{4}) (?<ts_hour>\\d{2}):(?<ts_min>\\d{2}):(?<ts_sec>\\d{2}) (?<ts_tz>[a-zA-Z/+\\-]+)';
const LINE_MATCH = `^\\[${ TIMESTAMP_MATCH }\\] ${ ERROR_MATCH }$`;
const LINE_MATCHER = new RegExp( LINE_MATCH, 's' );
const GENERIC_MATCHER = new RegExp( `^\\[${ TIMESTAMP_MATCH }] (?<message>.+)$`, 's' );

// export const FPM_ERROR_TYPES = {
// 	[ZLOG_DEBUG]   = "DEBUG",
// 	[ZLOG_NOTICE]  = "NOTICE",
// 	[ZLOG_WARNING] = "WARNING",
// 	[ZLOG_ERROR]   = "ERROR",
// 	[ZLOG_ALERT]   = "ALERT",
// };

// FPM errors are in the format
//    '%s [pool %s] %s'
//    1 = FPM_ERROR_TYPE (uppercase), 2 = pool name, 3 = message
// They are then prefixed with the timestamp:
//    [d-M-Y H:i:s e] %s
//
// https://cs.github.com/php/php-src/blob/34b352d121051a1ba582916be48e2e36f8ddf0e7/sapi/fpm/fpm/zlog.c?q=zlog#L166
// https://github.com/php/php-src/blob/72fec0bbf3f9f022e0ad78e8d5fd6d0403f8492f/sapi/fpm/fpm/fpm_env.c#L140
// https://github.com/php/php-src/blob/72fec0bbf3f9f022e0ad78e8d5fd6d0403f8492f/sapi/fpm/fpm/fpm_stdio.c#L200-L201
// https://github.com/php/php-src/blob/72fec0bbf3f9f022e0ad78e8d5fd6d0403f8492f/sapi/fpm/fpm/fpm_children.c#L160

export function parseLogLine( line: string ): LogEntry {
	const res = LINE_MATCHER.exec( line );
	if ( ! res ) {
		const stripper = GENERIC_MATCHER.exec( line );
		return {
			type: 'log',
			detail: ( stripper && stripper.groups?.message ) || line,
		};
	}

	return {
		type: 'error',
		detail: {
			type: getTypeCode( res.groups?.php_type || 'Unknown error' ),
			message: res.groups?.php_message || '',
			line: parseInt( res.groups?.php_line || '0', 10 ),
			file: res.groups?.php_file || '',
		},
	};
}
