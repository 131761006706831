import BarLoader from 'react-spinners/BarLoader';

export default function CreatingSite() {
	return (
		<div>
			<h2 className="text-2xl pb-5">Creating your website...</h2>
			<p>This process may <strong>take a few minutes</strong>, please wait till you're moved to the next screen.</p>
			<BarLoader className="mt-5" color='#5561E6' height="5px" width="200" />
		</div>
	);
}
