import React from 'react';

import DurationSelector from '../DurationSelector';
import SolidButton from '../SolidButton';

import { TimeFilter } from './util';

import './TimeSelector.css';

interface Props {
	selected: TimeFilter,
	onRefresh(): void,
	onSelect( filter: TimeFilter ): void,
}

export default class TimeSelector extends React.Component<Props> {
	render() {
		return (
			<form
				className="XRayOverview-TimeSelector"
				onSubmit={ e => e.preventDefault() }
			>
				<div className="XRayOverview-TimeSelector__primary">
					<DurationSelector
						label="Viewing responses from"
						selected={ this.props.selected }
						onSelect={ this.props.onSelect }
					/>
				</div>

				<div className="XRayOverview-TimeSelector__secondary">
					<SolidButton
						name="Refresh"
						onClick={ this.props.onRefresh }
					/>
				</div>
			</form>
		);
	}
}
