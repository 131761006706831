import sortBy from 'lodash/sortBy';
import React, { useEffect, useRef, useState } from 'react';
import DatabaseIcon from 'react-feather/dist/icons/database';
import GlobeIcon from 'react-feather/dist/icons/globe';

import { Document, Segment } from './types';

import './Timeline.css';

type SEGMENT_TYPE = 'db' | 'remote' | 'info';

const typeForSegment = ( segment: Segment ): SEGMENT_TYPE | null => {
	if ( segment.Document.sql ) {
		return 'db';
	}

	if ( segment.Document.parent_id && segment.Document.http ) {
		return 'remote';
	}

	return null;
};

const nameForSegment = ( segment: Segment ): string | null => {
	const type = typeForSegment( segment );
	switch ( type ) {
		case 'db':
			return ( segment.Document.sql && segment.Document.sql.sanitized_query ) || null;

		case 'remote':
			if ( ! segment.Document.http ) {
				return null;
			}

			return `${ segment.Document.http.request?.method } ${ segment.Document.http.request?.url }`;

		default:
			return null;
	}
};

type EntryProps = {
	children?: React.ReactNode,
	entryEnd: number | undefined,
	entryStart: number,
	name: string,
	requestEnd: number,
	requestStart: number,
	selected?: boolean,
	timelineWidth: number,
	type: SEGMENT_TYPE,
	onSelect?(): void,
};

function Entry( props: EntryProps ) {
	const { children, entryEnd, entryStart, name, requestEnd, requestStart, selected, timelineWidth, onSelect } = props;
	const totalTime = requestEnd - requestStart;
	const startPercent = ( entryStart - requestStart ) / totalTime;
	const endPercent = entryEnd ? ( ( entryEnd - requestStart ) / totalTime ) : 1;
	const durationMs = ( ( entryEnd || requestEnd ) - entryStart ) * 1000;

	const offset = startPercent * timelineWidth;
	const indicatorWidth = ( endPercent - startPercent ) * timelineWidth;
	const textWidth = 300;

	const flip = ( offset + indicatorWidth + textWidth ) > timelineWidth;
	const inside = indicatorWidth > textWidth;

	const classes = [
		'XRayTrace-Timeline__entry',
		`XRayTrace-Timeline__entry-type-${ props.type }`,
		( ! inside && flip ) && 'XRayTrace-Timeline__entry-flip',
		inside && 'XRayTrace-Timeline__entry-inside',
		onSelect && 'XRayTrace-Timeline__entry--selectable',
		selected && 'XRayTrace-Timeline__entry--selected',
	];

	return (
		<div
			className={ classes.filter( Boolean ).join( ' ' ) }
			onClick={ onSelect ? () => onSelect() : undefined }
		>
			<div
				className="XRayTrace-Timeline__entry-inner"
				style={ {
					left: offset,
					width: indicatorWidth < 3 ? 3 : indicatorWidth,
				} }
			>
				<div
					className="XRayTrace-Timeline__timer"
				/>
				<div
					className="XRayTrace-Timeline__detail"
					style={ {
						width: inside ? '100%' : textWidth,
					} }
				>
					<div
						className="XRayTrace-Timeline__entry-name"
					>
						{ name }
					</div>
					<div className="XRayTrace-Timeline__entry-time">
						{ durationMs < 1 ? (
							'<1ms'
						) : (
							durationMs.toFixed( 2 ) + 'ms'
						) }
					</div>
				</div>
			</div>

			{ children }
		</div>
	);
}

type TooltipProps = {
	requestStart: number,
	segment: Segment,
}

function Tooltip( props: TooltipProps ) {
	const { requestStart, segment } = props;
	const type = typeForSegment( segment );
	const durationMs = segment.Document.end_time ? ( segment.Document.end_time - segment.Document.start_time ) * 1000 : null;
	const duration = (
		<div className="XRayTrace-Timeline__tooltip-duration">
			<p>
				{ 'Duration: ' }
				{ durationMs ? (
					<>{ durationMs.toFixed( 2 ) } ms</>
				) : 'In progress' }
			</p>
			{ segment.Document.end_time && (
				<p>
					{ 'Start time: ' }
					{ ( ( segment.Document.end_time - requestStart ) * 1000 ).toFixed( 2 ) }ms
				</p>
			) }
		</div>
	);

	switch ( type ) {
		case 'db':
			return (
				<div
					className="XRayTrace-Timeline__tooltip"
					onClick={ e => e.stopPropagation() }
				>
					<p className="XRayTrace-Timeline__tooltip-header">
						<span>Database query</span>
						<DatabaseIcon
							size={ 16 }
						/>
					</p>
					<pre className="XRayTrace-Timeline__tooltip-title">
						{ ( segment.Document.sql && segment.Document.sql.sanitized_query ) || '' }
					</pre>
					{ duration }
					<div className="XRayTrace-Timeline__tooltip-details">
						<p>Server: <code>{ segment.Document.sql?.url }</code></p>
						{ segment.Document.fault && segment.Document.cause && segment.Document.cause && (
							typeof segment.Document.cause === 'string' ? (
								<p>Error: { segment.Document.cause }</p>
							) : (
								<p>Error: { segment.Document.cause?.exceptions![0].message }</p>
							)
						) }
					</div>
				</div>
			);

		case 'remote':
			if ( ! segment.Document.http ) {
				return null;
			}

			return (
				<div
					className="XRayTrace-Timeline__tooltip"
					onClick={ e => e.stopPropagation() }
				>
					<p className="XRayTrace-Timeline__tooltip-header">
						<span>Remote HTTP request</span>
						<GlobeIcon
							size={ 16 }
						/>
					</p>
					<pre className="XRayTrace-Timeline__tooltip-title">
						{ segment.Document.http.request?.method } { segment.Document.http.request?.url }
					</pre>
					{ duration }
					<div className="XRayTrace-Timeline__tooltip-details">
						{ segment.Document.fault && segment.Document.cause && segment.Document.cause && (
							typeof segment.Document.cause === 'string' ? (
								<p>Error: { segment.Document.cause }</p>
							) : (
								<p>Error: { segment.Document.cause?.exceptions![0].message }</p>
							)
						) }
						{ segment.Document.http.response && (
							<p>Response Code: { segment.Document.http.response.status }</p>
						) }
					</div>
				</div>
			);

		default:
			return null;
	}
}

type SegmentProps = {
	requestEnd: number,
	requestStart: number,
	segment: Segment,
	selected: boolean,
	timelineWidth: number,
	onSelect(): void,
};

function SegmentEntry( props: SegmentProps ) {
	const { requestEnd, requestStart, segment, selected, timelineWidth, onSelect } = props;
	const type = typeForSegment( segment );
	if ( ! type ) {
		return null;
	}

	// For now, ignore anything after end of request.
	if ( segment.Document.start_time > props.requestEnd ) {
		return null;
	}

	const name = nameForSegment( segment );
	return (
		<Entry
			entryEnd={ segment.Document.end_time }
			entryStart={ segment.Document.start_time }
			name={ name || '(unknown)' }
			requestEnd={ requestEnd }
			requestStart={ requestStart }
			selected={ selected }
			timelineWidth={ timelineWidth }
			type={ type }
			onSelect={ onSelect }
		>
			{ selected && (
				<Tooltip
					requestStart={ requestStart }
					segment={ segment }
				/>
			) }
		</Entry>
	);
}

type PropTypes = {
	main: Document;
	segments: Segment[];
};

type ShowTypes = {
	[ k: string ]: boolean,
};

export default function Timeline( props: PropTypes ) {
	const { main, segments } = props;

	const timelineRef = useRef<HTMLDivElement>( null );
	const [ search, setSearch ] = useState<string>( '' );
	const [ width, setWidth ] = useState<number>( 300 );
	useEffect( () => {
		if ( timelineRef.current ) {
			setWidth( timelineRef.current.clientWidth );
		}
	}, [] );

	const [ selected, setSelected ] = useState<string | null>( null );

	// Catch click-outside events, and deselect.
	const entriesRef = useRef<HTMLDivElement>( null );
	useEffect( () => {
		const handleClickOutside = ( ev: MouseEvent ) => {
			if ( entriesRef.current && ev.target && ! entriesRef.current.contains( ev.target as Node ) ) {
				setSelected( null );
			}
		};

		document.addEventListener( 'mousedown', handleClickOutside );
		return () => {
			document.removeEventListener( 'mousedown', handleClickOutside );
		};
	}, [ entriesRef, setSelected ] );

	const [ showTypes, setShowTypes ] = useState<ShowTypes>( {
		db: true,
		remote: true,
	} );

	const requestStart = Math.min( ...segments.map( s => s.Document.start_time ) );
	const requestEnd = Math.max( ...segments.map( s => s.Document.end_time ? s.Document.end_time : requestStart ) );

	const filtered = segments.filter( segment => {
		const type = typeForSegment( segment );
		if ( ! type || ! showTypes[ type ] ) {
			return false;
		}

		if ( search.length > 0 ) {
			const name = nameForSegment( segment );
			if ( ! name || name.indexOf( search ) === -1 ) {
				return false;
			}
		}

		return true;
	} );
	const sorted = sortBy( filtered, s => s.Document.start_time );

	return (
		<div
			ref={ timelineRef }
			className="XRayTrace-Timeline"
		>
			<header
				className="XRayTrace-Timeline__header"
			>
				<div />
				<div className="XRayTrace-Timeline__filters">
					<label
						className="XRayTrace-Timeline__search"
					>
						<span>Search Term:</span>
						<input
							placeholder="Search..."
							type="search"
							value={ search }
							onChange={ e => setSearch( e.target.value ) }
						/>
					</label>
					<label
						className="XRayTrace-Timeline__filter"
						title="Toggle database queries"
					>
						<input
							checked={ showTypes.db }
							type="checkbox"
							onChange={ e => setShowTypes( {
								...showTypes,
								db: e.target.checked,
							} ) }
						/>
						<DatabaseIcon
							size={ 16 }
						/>
					</label>
					<label
						className="XRayTrace-Timeline__filter"
						title="Toggle remote requests"
					>
						<input
							checked={ showTypes.remote }
							type="checkbox"
							onChange={ e => setShowTypes( {
								...showTypes,
								remote: e.target.checked,
							} ) }
						/>
						<GlobeIcon
							size={ 16 }
						/>
					</label>
				</div>
			</header>
			<div
				ref={ entriesRef }
				className="XRayTrace-Timeline__entries"
			>
				<Entry
					entryEnd={ main.end_time }
					entryStart={ main.start_time }
					name="Response time"
					requestEnd={ requestEnd }
					requestStart={ requestStart }
					timelineWidth={ width }
					type="info"
				/>
				{ sorted.map( query => (
					<SegmentEntry
						key={ query.Id }
						requestEnd={ requestEnd }
						requestStart={ requestStart }
						segment={ query }
						selected={ selected === query.Id }
						timelineWidth={ width }
						onSelect={ () => selected === query.Id ? setSelected( null ) : setSelected( query.Id ) }
					/>
				) ) }
				{ ( main.end_time && main.end_time < requestEnd ) && (
					<span
						className="XRayTrace-Timeline__request-end"
						style={ {
							left: ( ( main.end_time - requestStart ) / ( requestEnd - requestStart ) ) * width,
						} }
					/>
				) }
			</div>
		</div>
	);
}
