import React, { useState } from 'react';
import { connect } from 'react-redux';

import { withApiData } from '../../lib/with-api-data';
import { AppState } from '../../reducers';
import { ApiResponse } from '../../types/api';
import { InstanceAccessResponse, InstanceResponse } from '../../types/rest-api';
import ActivityListItemLoading from '../ActivityListItemLoading';
import ErrorBlock from '../ErrorBlock';
import PageTitle from '../PageTitle';

import InviteForm from './InviteForm';
import User from './User';
import { EmbeddedUser, WithEmbeddedUser } from './util';

import './index.css';

interface Props {
	instance?: InstanceResponse,
	instanceLoading: boolean,
	instanceError?: Error,
}

type AllProps = Props & {
	access?: ApiResponse<( InstanceAccessResponse & WithEmbeddedUser )[]>,
	currentUser: number | null,
	fetch: Window['fetch'],
	invalidateData(): void,
}

export function Users( props: AllProps ) {
	const [ error, setError ] = useState<string | null >( null );

	const onChangeRole = async ( user: EmbeddedUser, role: string ) => {
		if ( props.instanceLoading || ! props.instance ) {
			return;
		}

		setError( null );

		try {
			const res = await props.fetch(
				`/stack/instances/${ props.instance.id }/access/${ user.id }`,
				{
					method: 'PUT',
					body: JSON.stringify( {
						role,
					} ),
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			const data = await res.json();
			if ( ! res.ok ) {
				throw new Error( data.message );
			}

			props.invalidateData();
		} catch ( err ) {
			setError( ( err as Error ).message );
			throw err;
		}
	};
	const onRevokeAccess = async ( user: EmbeddedUser ) => {
		if ( props.instanceLoading || ! props.instance ) {
			return false;
		}

		setError( null );

		const confirmed = window.confirm(
			`You are about to revoke access for ${ user.name }. `
		);
		if ( ! confirmed ) {
			return false;
		}

		try {
			const res = await props.fetch(
				`/stack/instances/${ props.instance.id }/access/${ user.id }`,
				{
					method: 'DELETE',
				}
			);
			const data = await res.json();
			if ( ! res.ok ) {
				throw new Error( data.message );
			}

			// Finally, force reload the access data.
			props.invalidateData();
			return true;
		} catch ( err ) {
			setError( ( err as Error ).message );
			throw err;
		}
	};
	const onInvite = async ( email: string, role: InstanceAccessResponse['role'] ): Promise<'pending' | 'added'> => {
		if ( props.instanceLoading || ! props.instance ) {
			throw new Error( 'Instance not loaded' );
		}

		setError( null );

		let res;
		try {
			res = await props.fetch(
				`/stack/instances/${ props.instance.id }/access`,
				{
					method: 'POST',
					body: JSON.stringify( {
						email,
						role,
					} ),
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
		} catch ( err ) {
			throw new Error( 'An unknown error occurred' );
		}

		const data = await res.json();
		if ( ! res.ok ) {
			throw new Error( data.message );
		}

		if ( data.pending ) {
			return 'pending';
		}

		props.invalidateData();
		return 'added';
	};

	if ( ! props.instanceLoading && ! props.instance ) {
		// This should be caught higher up.
		return null;
	}

	return (
		<div className="InstanceUsers">
			<PageTitle title="Team" />

			<h1>Members</h1>
			{ ( ! props.access || props.access.isLoading || props.instanceLoading ) ? (
				<>
					<ActivityListItemLoading />
					<ActivityListItemLoading />
				</>
			) : props.access.error ? (
				<ErrorBlock
					message={ props.access.error.message }
				/>
			) : (
				<>
					{ props.access.data.length > 0 ? (
						<ul className="Instance-Users__users-list">
							{ props.access.data.map( item => (
								<User
									key={ item.user }
									access={ item }
									canEdit={ props.instance!.can.edit }
									isCurrentUser={ item.user === props.currentUser }
									onChangeRole={ role => onChangeRole( item._embedded.author[0], role ) }
									onRevokeAccess={ () => onRevokeAccess( item._embedded.author[0] ) }
								/>
							) ) }
						</ul>
					) : (
						<p>No team members yet! Add some below.</p>
					) }

					{ error && (
						<ErrorBlock
							message={ error }
							small
						/>
					) }

					{ props.instance!.can.edit ? (
						<>
							<h2>Invite New Members</h2>
							<InviteForm
								onSubmit={ onInvite }
							/>
						</>
					) : (
						<div className="InstanceUsers__no-permission">
							<h2>Invite New Members</h2>
							<p>You don't have permission to manage users.</p>
							<p>
								To add additional users, contact an member of
								your team with admin access, or contact support.
							</p>
						</div>
					) }
				</>
			) }
		</div>
	);
}

const mapPropsToData = ( props: Props ) => {
	if ( props.instanceLoading || ! props.instance ) {
		return {};
	}

	return {
		access: `/stack/instances/${ props.instance.id }/access?_embed`,
	};
};

const mapStateToProps = ( state: AppState | {} ) => {
	return {
		currentUser: 'currentUser' in state ? state.currentUser.user?.id : undefined,
	};
};

export default connect<AppState | {}, {}, Props>( mapStateToProps )( withApiData( mapPropsToData )( Users ) );
