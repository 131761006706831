import React from 'react';

import Avatar from '../Avatar';

import Status from './Status';

import './ChangeEvent.css';

const getFormatter = type => {
	switch ( type ) {
		case 'status':
			return value => <Status status={ value } />;

		case 'assignee':
			return value => value.name;

		default:
			return value => value;
	}
};

const SUPPORT_AUTHOR = {
	name: 'Support',
	avatar_urls: {
		'96': 'https://secure.gravatar.com/avatar/77b52774da3b3fe5a3ddafacf1ee578e?s=96&d=mm&r=g',
	},
};

export default function ChangeEvent( props ) {
	const format = getFormatter( props.field );
	const author = props.author || SUPPORT_AUTHOR;

	return (
		<div className="SupportTicketChangeEvent">
			{ author && (
				<Avatar size={ 24 } user={ author } />
			) }

			<span className="SupportTicketChangeEvent__author">
				{ author.name }
			</span>

			<span className="SupportTicketChangeEvent__description">
				{ props.from && props.to ? (
					<>
						{ ` changed the ${ props.field } from ` }
						{ format( props.from ) }
						{ ' to ' }
						{ format( props.to ) }
					</>
				) : props.to ? (
					<>
						{ ` set the ${ props.field } to ` }
						{ format( props.to ) }
					</>
				) : (
					<>
						{ ` cleared the ${ props.field } from ` }
						{ format( props.from ) }
					</>
				) }
			</span>
		</div>
	);
}
