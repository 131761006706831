import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';

import SolidButton from '../SolidButton';

import './UpdateForm.css';

export default class SupportTicketUpdateForm extends Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		isSubmitting: PropTypes.bool,
		status: PropTypes.string.isRequired,
		showUpdatePriority: PropTypes.bool,
	};

	constructor( props ) {
		super( props );
		this.state = {
			status: props.status,
			priority: props.priority,
			type: props.type,
		};
	}

	async onSubmit( e ) {
		e.preventDefault();
		this.props.onSubmit( {
			status: this.state.status,
			priority: this.state.priority,
			type: this.state.type,
		} );
	}

	onStatusChange( status ) {
		this.setState( { status: status.value } );
	}

	onTypeChange( type ) {
		this.setState( { type: type.value } );
	}

	onPriorityChange( priority ) {
		this.setState( { priority: priority.value } );
	}

	render() {
		if ( this.props.status === 'closed' ) {
			// Closed tickets cannot be updated.
			return null;
		}

		const statuses = {
			open: 'Open',
			pending: 'Pending',
			hold: 'On Hold',
			solved: 'Solved',
		};

		// Allow selecting New iff the status is currently new.
		if ( this.props.status === 'new' ) {
			statuses.new = 'New';
		}

		const types = {
			question: 'Question',
			incident: 'Incident',
			problem: 'Problem',
			task: 'Task',
		};

		const priorities = {
			low: 'Low',
			normal: 'Normal',
			high: 'High',
			urgent: 'Urgent (Site Down)',
		};

		return (
			<form className="SupportTicketUpdateForm" onSubmit={ e => this.onSubmit( e ) }>
				<label>
					Type
					<Select
						menuPlacement="auto"
						options={ Object.entries( types ).map( ( [ key, value ] ) => ( {
							value: key,
							label: value,
						} ) ) }
						value={ {
							value: this.state.type,
							label: types[this.state.type],
						} }
						onChange={ selected => this.onTypeChange( selected ) }
					/>
				</label>
				<label>
					Priority
					<Select
						menuPlacement="auto"
						options={ Object.entries( priorities ).map( ( [ key, value ] ) => ( {
							value: key,
							label: value,
						} ) ) }
						value={ {
							value: this.state.priority,
							label: priorities[this.state.priority],
						} }
						onChange={ selected => this.onPriorityChange( selected ) }
					/>
				</label>
				<label>
					Status
					<Select
						menuPlacement="auto"
						options={ Object.entries( statuses ).map( ( [ key, value ] ) => ( {
							value: key,
							label: value,
						} ) ) }
						value={ {
							value: this.state.status,
							label: statuses[this.state.status],
						} }
						onChange={ selected => this.onStatusChange( selected ) }
					/>
				</label>
				<SolidButton
					disabled={ this.props.isSubmitting }
					name={ this.props.isSubmitting ? 'Updating...' : 'Update' }
					type="submit"
				/>
			</form>
		);
	}
}
