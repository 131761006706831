import { Action } from 'redux';

import { Action as FetchApplicationsAction } from '../actions/fetchApplications';
import { Action as UpdateApplicationAction } from '../actions/updateApplication';
import { Action as UpdatedApplicationContactsAction } from '../actions/updateApplicationContacts';
import { ApplicationResponse } from '../api';

interface State {
	[s: string]: ApplicationResponse & Application;
}

interface UserLogOutAction extends Action {
	type: 'CURRENT_USER_LOGOUT';
}

type Actions = FetchApplicationsAction | UpdateApplicationAction | UserLogOutAction | UpdatedApplicationContactsAction;

export default function applications( state: State = {}, action: Actions ) {
	switch ( action.type ) {
		case 'APPLICATION_UPDATING_TASK_STREAM': {
			const applications = { ...state };
			const application = action.payload.application;
			applications[application.id].isUpdating = true;
			return applications;
		}
		case 'APPLICATION_UPDATE_FAILED': {
			const applications = { ...state };
			const application = action.payload.application;
			applications[application.id].isUpdating = false;
			return applications;
		}
		case 'APPLICATION_CONTACTS_UPDATED':
		case 'APPLICATION_UPDATED': {
			const applications = { ...state };
			const application = action.payload.application;
			if ( application['altis-instance'] ) {
				application.instance = application['altis-instance'];
			}
			applications[ application.id ] = application;
			return applications;
		}
		case 'UPDATED_APPLICATIONS': {
			const applications = { ...state };
			// Don't just replace all applications in the store with the updated applications,
			// remove all that belong to region that are no longer in the response.
			// for ( const id of Object.keys( applications ) ) {
			// 	if ( applications[id].region === action.region ) {
			// 		delete applications[id];
			// 	}
			// }
			action.applications.forEach( application => {
				// The application response data does not have the instance set, so take it from the request args.
				// TOdo: add this to the API response at some point.
				if ( action.args.instance ) {
					application.instance = action.args.instance;
				}
				if ( application['altis-instance'] ) {
					application.instance = application['altis-instance'];
				}
				applications[ application.id ] = application;
			} );
			return applications;
		}
		case 'CURRENT_USER_LOGOUT': {
			const applications = {};
			return applications;
		}
		default:
			return state;
	}
}
