import React, { useState } from 'react';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import GitBranchIcon from 'react-feather/dist/icons/git-branch';

import Button from '../Button';

import GitHubIcon from './github.svg';

import './Config.css';

const REPO_MATCHER = /^(?:git@|https?:\/\/)github.com(?:\/|:)([^/]+\/[^/]+)(?:\.git)$/i;

const getRepoName = repo => {
	const match = repo.match( REPO_MATCHER );
	return match.length ? match[1] : repo;
};

export default function Config( props ) {
	const { data } = props;

	const [ expanded, setExpanded ] = useState( false );
	const repo = getRepoName( data.url );
	const settingsUrl = props.settingsUrl || 'settings/deployment';

	return (
		<div className="ApplicationDeploys-Config">
			{ data.url ? (
				<div className="grid grid-cols-2 items-center">
					<div className="ApplicationDeploys-Config__summary">
						<h3>Connected Repository</h3>

						<p className="ApplicationDeploys-Config__source">
							<p className="ApplicationDeploys-config__source-repo">
								<img
									alt="GitHub Logo"
									className="ApplicationDeploys-Config__source-logo"
									src={ GitHubIcon }
								/>
								<a
									href={ `https://github.com/${ repo }` }
									rel="noopener noreferrer"
									target="_blank"
								>
									{ repo }
								</a>
							</p>
							<p className="ApplicationDeploys-config__source-branch">
								<GitBranchIcon
									className="ApplicationDeploys-Config__source-branch-icon"
									size={ 16 }
								/>
								<a
									href={ `https://github.com/${ repo }/tree/${ data.ref }` }
									rel="noopener noreferrer"
									target="_blank"
								>
									{ data.ref }
								</a>
							</p>
						</p>

						<p className="ApplicationDeploys-Config__autodeploy">
							Autodeploy is
							{ ' ' }
							<strong>
								{ data.is_autoupdating ? 'enabled' : 'disabled' }
							</strong>
						</p>
					</div>
					<div className="flex justify-end p-3">
						<Button
							href={ settingsUrl }
							variant="tertiary"
						>
							Edit settings
						</Button>
					</div>
				</div>
			) : (
				<div className="ApplicationDeploys-Config__summary">
					<h3>Connected Repository</h3>
					<p>Altis is automatically building all commits from:</p>
				</div>
			) }
			<div className="ApplicationDeploys-Config__footer">
				<button
					className="ApplicationDeploys-Config__expando"
					type="button"
					onClick={ () => setExpanded( ! expanded ) }
				>
					{ expanded ? <ChevronDownIcon size={ 15 } /> : <ChevronRightIcon size={ 15 } /> }

					Advanced actions
				</button>

				{ expanded && (
					<div className="ApplicationDeploys-Config__advanced-actions">
						{ props.children }
					</div>
				) }
			</div>
		</div>
	);
}
