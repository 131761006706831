import { AnyAction } from 'redux';

const defaulData = {
	'us-east-1': {
		applications: [],
		amis: [],
		fetchApplicationsError: null,
		isFetchingApplications: false,
		isFetchingAmis: false,
		id: 'us-east-1',
		name: 'US East 1',
		isMenuItemExpanded: true,
	},
	'eu-west-1': {
		applications: [],
		amis: [],
		fetchApplicationsError: null,
		isFetchingApplications: false,
		isFetchingAmis: false,
		id: 'eu-west-1',
		name: 'EU West 1',
		isMenuItemExpanded: true,
	},
	'eu-central-1': {
		applications: [],
		amis: [],
		fetchApplicationsError: null,
		isFetchingApplications: false,
		isFetchingAmis: false,
		id: 'eu-central-1',
		name: 'EU Central 1',
		isMenuItemExpanded: true,
	},
	'ap-southeast-1': {
		applications: [],
		amis: [],
		fetchApplicationsError: null,
		isFetchingApplications: false,
		isFetchingAmis: false,
		id: 'ap-southeast-1',
		name: 'AP Southeast 1',
		isMenuItemExpanded: true,
	},
	'ap-southeast-2': {
		applications: [],
		amis: [],
		fetchApplicationsError: null,
		isFetchingApplications: false,
		isFetchingAmis: false,
		id: 'ap-southeast-2',
		name: 'AP Southeast 2',
		isMenuItemExpanded: true,
	},
	// dev: {
	//   applications: [],
	//   fetchApplicationsError: null,
	//   isFetchingApplications: false,
	//   id: 'dev',
	//   name: 'Dev',
	//   isMenuItemExpanded: true,
	// },
};

interface State {
	[s: string]: any;
}

export default function regions( state: State = defaulData, action: AnyAction ) {
	switch ( action.type ) {
		case 'REGION_MENU_ITEM_TOGGLED': {
			let region = state[action.payload.region];
			return {
				...state,
				[action.payload.region]: {
					...region,
					isMenuItemExpanded: ! region.isMenuItemExpanded,
				},
			};
		}
		case 'WP_API_REDUX_FETCH_APPLICATIONS_UPDATING':
			return {
				...state,
				[action.payload.filter.region]: {
					...state[action.payload.filter.region],
					isFetchingApplications: true,
				},
			};
		case 'WP_API_REDUX_FETCH_APPLICATIONS_UPDATED':
			return {
				...state,
				[action.payload.filter.region]: {
					...state[action.payload.filter.region],
					isFetchingApplications: false,
					applications: [
						...state[action.payload.filter.region].applications,
						...action.payload.objects.map( ( a: any ) => a.id ),
					],
				},
			};
		case 'WP_API_REDUX_FETCH_AMIS_UPDATING':
			return {
				...state,
				[action.payload.filter.region]: {
					...state[action.payload.filter.region],
					isFetchingAmis: true,
				},
			};
		case 'WP_API_REDUX_FETCH_AMIS_UPDATED':
			return {
				...state,
				[action.payload.filter.region]: {
					...state[action.payload.filter.region],
					isFetchingAmis: false,
					amis: [
						...state[action.payload.filter.region].amis,
						...action.payload.objects.map( ( a: any ) => a.id ),
					],
				},
			};
		case 'WP_API_REDUX_FETCH_APPLICATIONS_ERRORED':
			return {
				...state,
				[action.payload.filter.region]: {
					...state[action.payload.filter.region],
					isFetchingApplications: false,
					fetchApplicationsError: action.payload.error,
				},
			};
		default:
			return state;
	}
}
