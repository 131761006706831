import PropTypes from 'prop-types';
import React from 'react';
import InfoOutlineIcon from 'react-feather/dist/icons/info';

import { withApiData } from '../lib/with-api-data';

import './ApplicationServersScaling.css';

export function ApplicationServersScaling( props ) {
	const { alarms } = props;
	if ( ! alarms.data || alarms.data.length <= 0 ) {
		return null;
	}

	// Filter out legacy events.
	const events = alarms.data.filter( alarm => alarm.type && alarm.type === 'scaling' );

	return (
		<div className="ApplicationServersScaling">
			{ events.map( event => (
				<p
					key={ event.id }
				>
					<InfoOutlineIcon
						size={ 16 }
					/>

					{ event.description }
				</p>
			) ) }
		</div>
	);
}

ApplicationServersScaling.propTypes = {
	application: PropTypes.string.isRequired,
};

const mapPropsToData = props => ( {
	alarms: `/stack/applications/${ props.application }/alarms?type=scaling`,
} );

export default withApiData( mapPropsToData )( ApplicationServersScaling );
