import { DocumentDuplicateIcon, KeyIcon } from '@heroicons/react/outline';
import { FormEvent, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { AccelerateSiteResponse } from '../../../../api';

import StepButton from './StepButton';

interface Props {
	onDone(): void,
	site: AccelerateSiteResponse,
}
export default function ActivatePlugin( props: Props ) {
	function onSubmit( event: FormEvent<HTMLFormElement> ) {
		event.preventDefault();
		props.onDone();
	}

	const [ copiedApiKey, setCopiedApiKey ] = useState( false );
	const [ copyApiKeyTextTimeout, setCopyApiKeyTextTimeout ] = useState<NodeJS.Timeout | undefined>( undefined );

	useEffect( () => {
		return () => {
			if ( copyApiKeyTextTimeout ) {
				clearTimeout( copyApiKeyTextTimeout );
			}
		};
	}, [] ); // eslint-disable-line
	const onCopyApiKey = () => {
		setCopiedApiKey( true );
		const timeout = setTimeout( () => {
			setCopiedApiKey( false );
		}, 5000 );
		setCopyApiKeyTextTimeout( timeout );
	};

	return (
		<form className="AddSiteStep3 pb-10" onSubmit={ onSubmit }>
			<h2 className="text-2xl pb-5">Activate the Altis Plugin by adding your API Key</h2>
			<div>
				<label className="block text-sm font-medium text-gray-700" htmlFor="email">
					Site-specific API Key
				</label>
				{ props.site.status === 'creating' &&
					<div className="mt-1 flex rounded-md shadow-sm">
						<p>Setting up site...</p>
					</div> }
				{ props.site.status === 'created' &&
					<>
						<div className="mt-1 flex rounded-md shadow-sm">
							<div className="relative flex items-stretch flex-grow focus-within:z-10">
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<KeyIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
								</div>
								<input
									className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
									readOnly
									value={ props.site.plugin_config }
								/>
							</div>
							<button
								className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
								type="button"
							>
								<DocumentDuplicateIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
								<CopyToClipboard text={ props.site.plugin_config } onCopy={ onCopyApiKey }>
									<span>{ ! copiedApiKey ? 'Copy API Key' : 'Copied' }</span>
								</CopyToClipboard>
							</button>
						</div>
						<StepButton text="Next" />
					</> }

				{ props.site.status === 'failed' &&
					<p>Sorry there was an error creating the site. Please contact us.</p> }
			</div>
		</form>
	);
}
