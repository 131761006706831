import React, { useState } from 'react';
import CheckIcon from 'react-feather/dist/icons/check';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronUpIcon from 'react-feather/dist/icons/chevron-up';

import { Duration } from '../DurationSelector';

interface Props {
	periods: number[],
	selected: number,
	onSelect( period: number ): void,
}

export default function PeriodSelector( props: Props ) {
	const [ showingDropdown, setShowing ] = useState<boolean>( false );

	const onChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		if ( ! e.target.checked ) {
			return;
		}

		setShowing( false );
		props.onSelect( parseInt( e.target.value, 10 ) );
	};

	return (
		<form
			className={ [
				'relative',
				'DurationSelector--flip',
			].filter( Boolean ).join( ' ' ) }
			// onSubmit={ this.onSubmit }
		>
			<button
				className="DurationSelector__selector"
				type="button"
				onClick={ () => setShowing( ! showingDropdown ) }
			>
				<Duration value={ props.selected } />

				{ showingDropdown ? (
					<ChevronUpIcon
						color="#4667DE"
						size={ 16 }
					/>
				) : (
					<ChevronDownIcon
						color="#4667DE"
						size={ 16 }
					/>
				) }
			</button>

			{ showingDropdown && (
				<ul
					// todo: replace rest with Tailwind classes
					className="absolute w-auto min-w-[200px] -right-[5px] bg-white shadow-sm z-50 DurationSelector__dropdown"
					role="menu"
				>
					{ props.periods.map( period => (
						<li
							aria-checked={ props.selected === period }
							className={ [
								'DurationSelector__option',
								props.selected === period && 'DurationSelector__option-selected',
							].filter( Boolean ).join( ' ' ) }
							role="menuitemcheckbox"
						>
							<label
								className={ [
									'block cursor-pointer px-2 pt-4 pb-8 hover:bg-[#DEEBFF]',
									props.selected === period && 'bg-[#2684FF] color-white',
								].filter( Boolean ).join( ' ' ) }
							>
								<input
									key={ period }
									checked={ props.selected === period }
									name="duration"
									type="radio"
									value={ period }
									onChange={ onChange }
								/>
								{ props.selected === period && (
									<CheckIcon
										className="DurationSelector__option-indicator"
										size={ 14 }
									/>
								) }
								<Duration value={ period } />
							</label>
						</li>
					) ) }
				</ul>
			) }
		</form>
	);
}
