import React, { useState } from 'react';
import AlertTriangleIcon from 'react-feather/dist/icons/alert-triangle';
import CheckIcon from 'react-feather/dist/icons/check';
import InfoIcon from 'react-feather/dist/icons/info';
import MessageSquareIcon from 'react-feather/dist/icons/message-square';
import RefreshIcon from 'react-feather/dist/icons/refresh-cw';
import OctagonIcon from 'react-feather/dist/icons/x-octagon';

import DurationSelector, { DurationFilter, ManualTimeFilter, TimeFilter } from '../DurationSelector';

import { Filter } from './util';

import './Header.css';

export interface TypeFilter {
	error?: boolean,
	warning?: boolean,
	notice?: boolean,
	log?: boolean,
	success?: boolean,
}

interface Props {
	after?: string,
	before?: string,
	search?: string,
	types?: TypeFilter,
	onChangeFilter( filter: Filter ): void,
	onRefresh?(): void,
	onToggleType?( types: TypeFilter ): void,
}

export default function Header( props: Props ) {
	const [ filter, setFilter ] = useState<Filter>( {
		after: props.after || '',
		before: props.before || '',
		search: props.search || '',
	} );

	const { types, onChangeFilter } = props;
	const onToggleType = ( type: keyof TypeFilter, enabled: boolean ) => {
		props.onToggleType!( {
			...types,
			[ type ]: enabled,
		} );
	};
	const onChangeDuration = ( timeFilter: TimeFilter ) => {
		let after: string, before: string;

		// todo: support duration natively in the API
		if ( ( timeFilter as DurationFilter ).duration ) {
			const dur = ( timeFilter as DurationFilter ).duration;
			after = dur > 3600 ? `${ dur / 3600 } hours ago` : `${ dur / 60 } mins ago`;
			before = 'now';
		} else {
			after = ( timeFilter as ManualTimeFilter ).after || '';
			before = ( timeFilter as ManualTimeFilter ).before || '';
		}
		setFilter( {
			...filter,
			before,
			after,
		} );
		onChangeFilter( {
			...filter,
			before,
			after,
		} );
	};
	const onTermChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const nextFilter = {
			...filter,
			search: e.target.value,
		};
		setFilter( nextFilter );
		props.onChangeFilter( nextFilter );
	};

	return (
		<form
			className="ApplicationLogs-Header"
			onSubmit={ e => {
				e.preventDefault();
				onChangeFilter( filter );
			} }
		>
			<div>
				<label
					className="ApplicationLogs-Header__search"
				>
					<span>Search Term:</span>
					<input
						placeholder="Search..."
						type="search"
						value={ filter.search }
						onChange={ onTermChange }
					/>
				</label>
				{ types?.error !== undefined && (
					<label
						className="ApplicationLogs-Header__filter ApplicationLogs-Header__filter--error"
						title="Toggle errors"
					>
						<input
							checked={ types.error }
							type="checkbox"
							onChange={ e => onToggleType( 'error', e.target.checked ) }
						/>
						<OctagonIcon
							size={ 16 }
						/>
					</label>
				) }
				{ types?.warning !== undefined && (
					<label
						className="ApplicationLogs-Header__filter ApplicationLogs-Header__filter--warning"
						title="Toggle warnings"
					>
						<input
							checked={ types.warning }
							type="checkbox"
							onChange={ e => onToggleType( 'warning', e.target.checked ) }
						/>
						<AlertTriangleIcon
							size={ 16 }
						/>
					</label>
				) }
				{ types?.notice !== undefined && (
					<label
						className="ApplicationLogs-Header__filter ApplicationLogs-Header__filter--notice"
						title="Toggle notices"
					>
						<input
							checked={ types.notice }
							type="checkbox"
							onChange={ e => onToggleType( 'notice', e.target.checked ) }
						/>
						<InfoIcon
							size={ 16 }
						/>
					</label>
				) }
				{ types?.log !== undefined && (
					<label
						className="ApplicationLogs-Header__filter ApplicationLogs-Header__filter--log"
						title="Toggle log messages"
					>
						<input
							checked={ types.log }
							type="checkbox"
							onChange={ e => onToggleType( 'log', e.target.checked ) }
						/>
						<MessageSquareIcon
							size={ 16 }
						/>
					</label>
				) }
				{ types?.success !== undefined && (
					<label
						className="ApplicationLogs-Header__filter ApplicationLogs-Header__filter--success"
						title="Toggle successes"
					>
						<input
							checked={ types.success }
							type="checkbox"
							onChange={ e => onToggleType( 'success', e.target.checked ) }
						/>
						<CheckIcon
							size={ 16 }
						/>
					</label>
				) }
			</div>
			<div className="ApplicationLogs-Header__time">
				<DurationSelector
					flip
					label="Viewing logs from"
					selected={ filter }
					onSelect={ onChangeDuration }
				/>
				{ props.onRefresh && (
					<button
						className="ApplicationLogs-Header__refresh"
						type="button"
						onClick={ props.onRefresh }
					>
						<RefreshIcon
							size={ 16 }
						/>
					</button>
				) }
			</div>
		</form>
	);
}
