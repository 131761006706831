import React from 'react';

import Button from './Button';

import './OutlineButton.css';

export default function OutlineButton( props ) {
	return (
		<Button
			disabled={ !! props.disabled }
			variant="tertiary"
			onClick={ props.onClick }
		>
			{ props.name }
		</Button>
	);
}

OutlineButton.defaultProps = {
	color: '#4667DE',
	disabledColor: 'rgba( 70, 103, 222, .3 )',
};
