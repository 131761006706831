import { FormEvent, useState } from 'react';

import ErrorBlock from '../../../ErrorBlock';

import StepButton from './StepButton';

interface Props {
	onSubmit( url: string ): Promise<any>,
}
export default function WebsiteURL( props: Props ) {
	const [ url, setUrl ] = useState( '' );
	const [ creatingSite, setCreatingSite ] = useState( false );
	const [ error, setError ] = useState( '' );

	async function onSubmit( event: FormEvent<HTMLFormElement> ) {
		event.preventDefault();
		setError( '' );
		setCreatingSite( true );

		try {
			await props.onSubmit( url );
		} catch ( e ) {
			setError( e.message );
		}

		setCreatingSite( false );
	}
	return (
		<form className="AddSiteStep1 pb-10" onSubmit={ onSubmit }>
			<h2 className="text-2xl pb-5">Enter your Website URL</h2>
			<label className="block text-sm font-medium text-gray-700" htmlFor="company-website">
				Company Website
			</label>
			<div className="mt-1 flex rounded-md shadow-sm mb-4">
				<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
					https://
				</span>
				<input
					className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
					id="company-website"
					name="company-website"
					placeholder="www.example.com"
					required
					type="text"
					value={ url }
					onChange={ e => setUrl( e.target.value.replace( /^https?:\/\/(.*?)\/*$/, '$1' ) ) }
				/>
			</div>

			{ error && (
				<ErrorBlock message={ error } />
			) }

			<StepButton
				isLoading={ creatingSite }
				loadingText="Creating site..."
				text="Next"
			/>
		</form>
	);
}
