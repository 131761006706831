import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';
import { SupportTicketResponse } from '../types/rest-api';

interface UpdatingSupportTicketsAction extends ReduxAction {
	type: 'UPDATING_SUPPORT_TICKETS';
}

interface UpdatedSupportTicketsAction extends ReduxAction {
	type: 'UPDATED_SUPPORT_TICKETS';
	supportTickets: SupportTicketResponse[];
	totalPages: number;
}

export type Action = UpdatingSupportTicketsAction | UpdatedSupportTicketsAction;

const defaultAvatarUrls = {
	'96': 'https://secure.gravatar.com/avatar/83888eb8aea456e4322577f96b4dbaab?s=96&d=mm&r=g',
};

type Response = SupportTicketResponse[] & {
	getResponse(): {
		headers: Headers,
	}
}

export default function fetchSupportTickets(
	args: {
		status?: string[];
		applications?: string[];
		page?: number;
	} = {},
	api: API
): ThunkAction<Promise<SupportTicketResponse[]>, AppState, null, Action> {
	return dispatch => {
		dispatch( { type: 'UPDATING_SUPPORT_TICKETS' } );
		return api.get( '/stack/support-tickets', args ).then( ( supportTickets: Response ) => {
			const tickets = supportTickets.map( ticket => {
				if ( ! ticket.author ) {
					ticket.author = {
						name: '',
						id: 0,
						avatar_urls: defaultAvatarUrls,
					};
				}

				if ( ticket.author.avatar_urls['96'] === null ) {
					ticket.author.avatar_urls = defaultAvatarUrls;
				}
				return ticket;
			} );
			dispatch( {
				type: 'UPDATED_SUPPORT_TICKETS',
				supportTickets: tickets,
				totalPages: parseInt( supportTickets.getResponse().headers.get( 'X-WP-TotalPages' ) || '1', 10 ),
			} );
			return supportTickets;
		} );
	};
}
