import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';
import { SupportTicketResponse } from '../types/rest-api';

interface SupportTicketEndpointParams {
	status: 'open' | 'pending' | 'solved' | 'new' | 'closed';
}

interface UpdatingSupportTicketAction extends ReduxAction {
	type: 'SUPPORT_TICKET_UPDATING';
	ticketId: string;
	data: SupportTicketEndpointParams;
}

interface UpdatedSupportTicketAction extends ReduxAction {
	type: 'SUPPORT_TICKET_UPDATED';
	supportTicket: SupportTicketResponse;
}

export type Action = UpdatingSupportTicketAction | UpdatedSupportTicketAction;

export default function updateSupportTicket(
	api: API,
	ticketId: string,
	data: SupportTicketEndpointParams
): ThunkAction<Promise<SupportTicketResponse>, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'SUPPORT_TICKET_UPDATING',
			ticketId,
			data,
		} );
		return api.post( `/stack/support-tickets/${ticketId}`, data ).then( ( ticket: SupportTicketResponse ) => {
			dispatch( {
				type: 'SUPPORT_TICKET_UPDATED',
				supportTicket: ticket,
			} );
			return ticket;
		} );
	};
}
