import memoize from 'lodash/memoize';
import { DateTime } from 'luxon';
import { useState } from 'react';

import useTooltip, { Tooltip } from './useTooltip';

type ParseableDateTime = Date | number | string;

export const getCanonicalDate = memoize( ( date: ParseableDateTime ): DateTime => {
	if ( typeof date === 'string' ) {
		return DateTime.fromISO( date );
	}

	if ( typeof date === 'number' ) {
		return DateTime.fromMillis( date );
	}

	if ( date instanceof Date ) {
		return DateTime.fromJSDate( date );
	}

	throw new Error( 'Invalid date provided' );
} );

interface Props {
	className?: string,
	format: 'short-date' | 'short' | 'short-precise' | 'long-date' | 'long' | 'long-precise';
	value: ParseableDateTime;
	withTooltip?: boolean;
}

export const formatDate = ( format: Props['format'], value: DateTime ) => {
	switch ( format ) {
		case 'short-date':
			return value.toFormat( 'yyyy-MM-dd' );

		case 'short':
			return value.toFormat( 'yyyy-MM-dd HH:mm' );

		case 'short-precise':
			return value.toFormat( 'yyyy-MM-dd HH:mm:ss' );

		case 'long-date':
			return value.toLocaleString( DateTime.DATE_FULL );

		case 'long':
			return value.toLocaleString( DateTime.DATETIME_FULL );

		case 'long-precise':
			return value.toLocaleString( DateTime.DATETIME_FULL_WITH_SECONDS );

		default: {
			// Check we're exhaustive.
			const _exhaustiveCheck: never = format;
			return value.toString();
		}
	}
};

/**
 * Plain (non-interactive) date-time element.
 *
 * This is a more performant version for use with thousands of instances
 * (e.g. in a list/table), using native title instead of a rich tooltip.
 */
export function PlainFormattedDateTime( props: Props ) {
	const value = getCanonicalDate( props.value );
	const full = value.toLocaleString( DateTime.DATETIME_FULL_WITH_SECONDS );

	return (
		<time
			className={ props.className }
			dateTime={ value.toISO() }
			title={ full }
		>
			{ formatDate( props.format, value ) }
		</time>
	);
}

/**
 * Date-time element with rich tooltip.
 *
 * Note: Avoid rendering many instances of this, as it may have performance
 * problems with thousands of copies of the tooltip.
 */
export default function FormattedDateTime( props: Props ) {
	const [ visible, setVisible ] = useState<boolean>( false );
	const value = getCanonicalDate( props.value );
	const { setReferenceElement, TooltipProps } = useTooltip();

	const full = value.toLocaleString( DateTime.DATETIME_FULL_WITH_SECONDS );

	return (
		<>
			<time
				ref={ setReferenceElement }
				className={ props.className }
				dateTime={ value.toISO() }
				onMouseOut={ () => setVisible( false ) }
				onMouseOver={ () => setVisible( true ) }
			>
				{ formatDate( props.format, value ) }
			</time>
			<Tooltip
				{ ...TooltipProps }
				className="pointer-events-none"
				visible={ visible }
			>
				{ full }
			</Tooltip>
		</>
	);
}
