import React from 'react';
import { Link } from 'react-router-dom';

import FormattedContent from '../FormattedContent';

import './Welcome.css';

export default function Welcome() {
	return (
		<div className="Instance-Welcome">
			<FormattedContent>
				<p>Welcome to the Altis Dashboard!</p>
				<p>You don't have any environments available yet. Contact your account manager to get started.</p>
				<p>
					To set up your first environment, you'll need to provide a
					GitHub repository and branch for the environment.
					{ ' ' }
					<a href="https://docs.altis-dxp.com/getting-started/">Consult
					the Getting Started documentation</a> for more about how to
					get started.
				</p>
				<p>
					Need any help?
					{ ' ' }
					<Link to="/support">Contact support</Link> at any time.
				</p>
			</FormattedContent>
		</div>
	);
}
