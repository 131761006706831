import partition from 'lodash/partition';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import Button from '../Button';
import InlineLoader from '../InlineLoader';
import useInterval from '../useInterval';

import AddDomain from './AddDomain';
import DomainRow from './DomainRow';

export interface Domain {
	name: string,
	application: string,
	status: 'active' | 'pending' | 'failed' | 'suspended',
	records?: {
		name: string,
		type: string,
		value: string,
	}[],
}

const REFRESH_TIME = 5000;

interface OwnProps {
	application: ApplicationResponse,
}

type MappedProps = {
	data: ApiResponse<Domain[]>,

	invalidateData(): void,
	post( url: string, data: any ): Promise<any>,
}

type Props = OwnProps & MappedProps;

export function Domains( props: Props ) {
	const [ domainData, setDomainData ] = useState<Domain[]>( [] );

	useInterval( () => {
		const missingRecords = domainData.filter( domain => {
			if ( domain.status !== 'pending' ) {
				return false;
			}

			return ( ! domain.records ) || domain.records.length === 0;
		} );

		if ( missingRecords.length === 0 ) {
			// Done.
			return;
		}

		// Still missing records, reload.
		props.invalidateData();
	}, REFRESH_TIME );

	useEffect( () => {
		if ( ! props.data.isLoading && props.data.data && props.data.data !== domainData ) {
			setDomainData( props.data.data );
		}
	}, [ props.data, domainData ] );

	if ( props.data.error ) {
		return null;
	}

	// todo: remove fallback after backend is updated
	const domains: Domain[] = domainData.length > 0 ? domainData : props.application.domains.map( domain => ( {
		id: domain,
		name: domain,
		application: props.application.id,
		status: 'active',
	} ) );

	const onCreate = async ( domains: string[] ) => {
		const data = {
			domains,
		};
		await props.post( `/stack/applications/${ props.application.id }/domains`, data );

		// Load our new data.
		props.invalidateData();
	};

	// Divide domains into three groups: internal (i.e. permanent), active, and inactive.
	const internal: Domain | null = props.application.internal_domain ? {
		application: props.application.id,
		name: props.application.internal_domain,
		status: 'active',
	} : null;
	const [ active, inactive ] = partition( domains, domain => domain.status === 'active' );

	return (
		<div>
			<header className="md:flex md:items-center md:justify-between">
				<div className="min-w-0 flex-1">
					<h2 className="!text-xl !leading-6 font-medium !m-0">Domains</h2>
					<p className="my-4 text-sm text-gray-500">
						Manage custom domains connected to your environment here.
					</p>
				</div>
				<div className="mt-4 flex gap-3 md:ml-4 md:mt-0">
					{ ( internal && props.application.has.custom_domains ) && (
						<AddDomain
							application={ props.application }
							domains={ domains }
							internalDomain={ internal.name }
							onCreate={ onCreate }
						/>
					) }

					<Button
						disabled={ props.data.isLoading }
						onClick={ props.invalidateData }
					>
						{ props.data.isLoading ? (
							<>
								<InlineLoader className="-ml-1 mr-3 h-5 w-5" />
								Loading…
							</>
						) : (
							'Refresh'
						) }
					</Button>
				</div>
			</header>

			{ ( ! props.application.has.custom_domains ) && (
				<p className="mb-4 text-sm text-gray-500">
					Need to remove or change a domain? <Link to="/support/new">Contact support.</Link> (Self-service coming soon!)
				</p>
			) }

			<ul className="my-2 space-y-2">
				{ /* First: inactive, since it needs actioning. */ }
				{ inactive.map( domain => (
					<DomainRow
						key={ domain.name }
						application={ props.application }
						domain={ domain }
					/>
				) ) }

				{ /* Next: active, since they're configurable. */ }
				{ active.map( domain => (
					<DomainRow
						key={ domain.name }
						application={ props.application }
						domain={ domain }
					/>
				) ) }

				{ /* Finally: internal, just for reference. */ }
				{ internal && (
					<DomainRow
						application={ props.application }
						domain={ internal }
					/>
				) }
			</ul>
		</div>
	);
}

const mapPropsToData = ( props: OwnProps ) => ( {
	data: `/stack/applications/${ props.application.id }/domains`,
} );

export default withApiData( mapPropsToData )( Domains );
