import PropTypes from 'prop-types';
import React from 'react';
import './OnCallCarouselSlide.css';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import { FormattedTime } from 'react-intl';

import Avatar from './Avatar';

export default function OnCallCarouselSlide( props ) {
	return (
		<div className="OnCallCarouselSlide">
			<Avatar size={ 64 } user={ props.user } />
			<div className="details">
				<strong>{ props.user.name }</strong>
				<span className="onCallUntil">
					on call until <FormattedTime timeZoneName="short" value={ props.endDate } />
				</span>
				{ ( props.onPrevious || props.onNext ) && (
					<div className="pagination">
						{ props.onPrevious && (
							<button
								className="OnCallCarouselSlide__previous"
								onClick={ props.onPrevious }
							>
								<ChevronLeft size={ 22 } /> Prev
							</button>
						) }
						{ props.onNext && (
							<button
								className="OnCallCarouselSlide__next"
								onClick={ props.onNext }
							>
								Next <ChevronRight size={ 22 } />
							</button>
						) }
					</div>
				) }
			</div>
		</div>
	);
}

OnCallCarouselSlide.propTypes = {
	user: PropTypes.object.isRequired,
	onNext: PropTypes.func,
	onPrevious: PropTypes.func,
	endDate: PropTypes.string.isRequired,
};
