import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../reducers';

interface RemovingFailedTaskAction extends ReduxAction {
	type: 'REMOVING_FAILED_TASK';
	payload: {
		task: Task;
	};
}

export type Action = RemovingFailedTaskAction;
export default function deleteRunningTask( task: Task ): ThunkAction<Promise<any>, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'REMOVING_FAILED_TASK',
			payload: {
				task,
			},
		} );
		return Promise.resolve( true );
	};
}
