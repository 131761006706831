import React from 'react';
import { components, Creatable } from 'react-select';
import { Props as SelectProps } from 'react-select/lib/Select';

import Avatar from '../Avatar';

import './FollowersSelector.css';

// Validates the follower's email address.
const validateNewFollower = ( input: string ) => (
	!! input.match( /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i )
);

const overrideComponents: SelectProps['components'] = {
	// Disable dropdown.
	DropdownIndicator: () => null,
	IndicatorSeparator: () => null,
	Menu: () => null,

	// Show avatars.
	MultiValueLabel: props => (
		<span className="SupportTicketFollowersSelector__follower">
			<Avatar
				user={ props.data.user || {} }
			/>
			<components.MultiValueLabel
				{ ...props }
			/>
		</span>
	),
};

const overrideStyles: SelectProps['styles'] = {
	// Only allow removing new followers.
	multiValueRemove: ( base, state ) => {
		return state.data.__isNew__ ? base : {
			...base,
			display: 'none',
		};
	},
};

type Item = {
	label: string,
	value: string,
}

interface Props {
	autoFocus?: boolean,
	className?: string,
	value: Item[],
	onChange( value: Item[] ): void,
}

type State = {
	value: string,
};

export default class FollowersSelector extends React.Component<Props> {
	state: State = {
		value: '',
	};

	onChangeFollowers: SelectProps['onChange'] = ( value, action ) => {
		switch ( action.action ) {
			case 'pop-value':
			case 'remove-value': {
				// Only allow removing new followers.
				if ( ! ( action as any ).removedValue!.__isNew__ ) {
					return;
				}
			}
			// Fallthrough.

			default:
				this.props.onChange( value as any );
		}
	};

	render() {
		return (
			<Creatable
				autoFocus={ this.props.autoFocus }
				className={ this.props.className }
				components={ overrideComponents }
				inputValue={ this.state.value }
				isClearable={ false }
				isMulti
				isValidNewOption={ validateNewFollower }
				placeholder="admin@example.com"
				styles={ overrideStyles }
				value={ this.props.value }
				onChange={ this.onChangeFollowers }
				onInputChange={ ( value, action ) => {
					if ( action.action !== 'input-blur' && action.action !== 'menu-close' ) {
						this.setState( { value } );
					}
					if ( action.action === 'input-blur' && validateNewFollower( this.state.value ) ) {
						// Blurred, and looks like a real email, commit it.
						this.props.onChange( [
							...this.props.value,
							{
								label: this.state.value,
								value: this.state.value,
								__isNew__: true,
							} as any,
						] );
						this.setState( {
							value: '',
						} );
					}
				} }
			/>
		);
	}
}
