import React, { useState } from 'react';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';

import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import { CliSessionResponse } from '../../types/rest-api';
import ActivityListItem from '../ActivityListItem';
import Log from '../Log';

import './SessionItem.css';

const strToLines = ( str: string ) => {
	if ( ! str ) {
		return [];
	}

	return str.split( '\r\n' ).map( line => ( {
		ansi: true,
		text: line,
	} ) );
};

export const SessionLog = ( props: { log: ApiResponse<{log: string}> } ) => {
	if ( props.log.error ) {
		return (
			<div className="ApplicationShell-SessionItem__log-error">
				<p>{ props.log.error.message }</p>
			</div>
		);
	}

	return (
		<Log
			ansi
			lines={ ! props.log.isLoading ? strToLines( props.log?.data?.log ? props.log.data.log : 'This log isn\'t ready to show yet, please try again later.' ) : null }
			loading={ props.log.isLoading }
		/>
	);
};
const ConnectedSessionLog = withApiData( ( props: { application: string, id: number } ) => ( {
	log: `/stack/applications/${ props.application }/cli/sessions/${ props.id }/log`,
} ) )( SessionLog );

interface Props {
	application: string,
	session: CliSessionResponse,
}

export default function SessionItem( props: Props ) {
	const [ expanded, setExpanded ] = useState<boolean>( false );

	const status = props.session.status === 'failed' ? 'Failed' : 'Completed';

	const classes = [
		'ApplicationShell-SessionItem',
		expanded && 'ApplicationShell-SessionItem--expanded',
		`ApplicationShell-SessionItem--${ props.session.status }`,
	];
	return (
		<div className={ classes.filter( Boolean ).join( ' ' ) }>
			<div className="ApplicationShell-SessionItem__header">
				<button
					className="ApplicationShell-SessionItem__expando"
					type="button"
					onClick={ () => setExpanded( ! expanded ) }
				>
					{ expanded ? <ChevronDownIcon /> : <ChevronRightIcon /> }
				</button>

				<ActivityListItem
					date={ props.session.date }
					description={ `Session #${ props.session.id } | ${ status }` }
					user={ props.session.user }
				/>
			</div>
			{ expanded && (
				<ConnectedSessionLog
					application={ props.application }
					id={ props.session.id }
				/>
			) }
		</div>
	);
}
