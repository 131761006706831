import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { UserResponse } from '../../../api';
import { AppState } from '../../../reducers';
import LogoSVG from '../assets/Altis-logo-dark.svg';
import AltisMark from '../assets/altis-mark.png';

function classNames( ...classes: string[] ) {
	return classes.filter( Boolean ).join( ' ' );
}

// CSS Classes for active and inactive links
const routeStyles = [
	{
		active: 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
		inactive:
			'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
	},
];

interface Props {
	user: undefined | UserResponse,
	onClickLogout(): void,
}

// Check if current link is active or not
export function Nav( props: Props ) {
	// All Links
	const altisRoutes: { name: string; path: string }[] = [];

	if ( props.user ) {
		altisRoutes.push( {
			name: 'Dashboard',
			path: '/accelerate/',
		} );
	} else {
		altisRoutes.push( {
			name: 'Login',
			path: '/accelerate/login/',
		} );
	}

	const location = useLocation();

	const getRouteStyles = function ( linkPath: string ) {
		if ( linkPath.replace( /\/$/, '' ) === location.pathname.replace( /\/$/, '' ) ) {
			return routeStyles[0].active;
		} else {
			return routeStyles[0].inactive;
		}
	};

	return (
		<Disclosure as="nav" className="bg-white shadow z-10 sticky top-0">
			{ ( { open } ) => (
				<>
					<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
						<div className="relative flex justify-between h-16">
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								{ /* Mobile menu button */ }
								<Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<span className="sr-only">Open main menu</span>
									{ open ? (
										<XIcon aria-hidden="true" className="block h-6 w-6" />
									) : (
										<MenuIcon aria-hidden="true" className="block h-6 w-6" />
									) }
								</Disclosure.Button>
							</div>
							<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
								<Link className="flex-shrink-0 flex items-center" to="/accelerate/">
									<img alt="Altis" className="block lg:hidden h-8 w-auto" src={ AltisMark } />
									<img alt="Altis" className="hidden lg:block h-8 w-auto" src={ LogoSVG } />
								</Link>

								{ altisRoutes.map( route => (
									<div key={ route.name } className="hidden sm:ml-6 sm:flex sm:space-x-8">
										<Link className={ getRouteStyles( route.path ) } to={ route.path }>
											{ route.name }
										</Link>
									</div>
								) ) }

								<div className="hidden sm:ml-6 sm:flex sm:space-x-8">
									<a className={ routeStyles[0].inactive } href="https://altis-accelerate.canny.io/" target="altis-accelerate-feedback">
										Feature Requests and Bugs
									</a>
								</div>
							</div>
							<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

								{ /* Profile dropdown */ }
								{ props.user &&
									<Menu as="div" className="ml-3 relative">
										<div>
											<Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
												<span className="sr-only">Open user menu</span>
												<img
													alt=""
													className="h-8 w-8 rounded-full"
													src={ props.user.avatar_urls['96'] }
												/>
											</Menu.Button>
										</div>
										<Transition
											as={ Fragment }
											enter="transition ease-out duration-200"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
												<Menu.Item>
													{ ( { active } ) => (
														<Link
															className={ classNames(
																active ? 'bg-gray-100' : '',
																'block px-4 py-2 text-sm text-gray-700'
															) }
															to="/accelerate/settings/"
														>
															Settings
														</Link>
													) }
												</Menu.Item>
												<Menu.Item>
													{ ( { active } ) => (
														<button
															className={ classNames(
																active ? 'bg-gray-100' : '',
																'block px-4 py-2 text-sm text-gray-700'
															) }
															onClick={ e => {
																e.preventDefault();
																props.onClickLogout();
															} }
														>
															Sign out
														</button>
													) }
												</Menu.Item>
											</Menu.Items>
										</Transition>
									</Menu> }
							</div>
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden">
						<div className="pt-2 pb-4 space-y-1">
							{ /* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */ }
							<Disclosure.Button
								as="a"
								className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
								href="/dashboard/"
							>
								Dashboard
							</Disclosure.Button>
							<Disclosure.Button
								as="a"
								className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
								href="/accelerate/settings/"
							>
								Settings
							</Disclosure.Button>
							<Disclosure.Button
								as="a"
								className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
								href="#"
								onClick={ ( e: React.MouseEvent<HTMLAnchorElement> ) => {
									e.preventDefault();
									props.onClickLogout();
								} }
							>
								Sign out
							</Disclosure.Button>
						</div>
					</Disclosure.Panel>
				</>
			) }
		</Disclosure>
	);
}

function mapStateToProps( state: AppState ) {
	return {
		user: state.currentUser.user,
	};
}

export default connect( mapStateToProps )( Nav );
