import PropTypes from 'prop-types';
import React from 'react';

import Log from './Log';

const formatLines = messages => messages.reduce( ( lines, item ) => {
	const matches = item.message.match( /^(err|out)::(.+)$/s );
	const className = [
		item.level,
		matches && matches[1] === 'err' && 'stderr',
		matches && matches[1] === 'out' && 'stdout',
	].filter( Boolean ).join( ' ' );

	const message = matches ? matches[2] : item.message;
	const text = message.trim().split( '\n' );
	return [
		...lines,
		...text.map( line => ( {
			text: line,
			className,
		} ) ),
	];
}, [] );

export default function TaskLog( props ) {
	const loading = ! props.task || ! props.task.messages.length;

	// Convert messages into lines, and split as needed.
	const lines = loading ? [] : formatLines( props.task.messages );

	return (
		<Log
			ansi={ props.ansi }
			className="TaskLog"
			follow
			lines={ lines }
			loading={ loading }
		/>
	);
}

TaskLog.defaultProps = {
	ansi: false,
};

TaskLog.propTypes = {
	ansi: PropTypes.bool,
	task: PropTypes.shape( {
		messages: PropTypes.arrayOf(
			PropTypes.shape( {
				message: PropTypes.string.isRequired,
			} )
		).isRequired,
	} ).isRequired,
};
