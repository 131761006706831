import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';

interface UpdatedOnCallAction extends ReduxAction {
	type: 'UPDATED_ON_CALL',
	onCall: any,
}

export type Action = UpdatedOnCallAction;

export default function fetchOnCall( api: API ) : ThunkAction<void, AppState, null, Action> {
	return async dispatch => {
		const onCall = await api.get( '/stack/on-call' );
		dispatch( {
			type: 'UPDATED_ON_CALL',
			onCall,
		} );
	};
}
