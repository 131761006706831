import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
	fetchSupportTicketsComments,
	fetchSupportTicket,
	createSupportTicketComment,
	updateSupportTicket,
	fetchApplications,
} from '../../actions';
import selectInstance from '../../actions/selectInstance';
import { defaultAPI as api } from '../../api';
import Avatar from '../Avatar';
import BigTitle from '../BigTitle';
import ErrorBlock from '../ErrorBlock';
import FormattedRelativeTime from '../FormattedRelativeTime';
import PageTitle from '../PageTitle';
import SolidButton from '../SolidButton';
import Type from '../Support/Type';

import Event from './Event';
import Followers from './Followers';
import InstanceName from './InstanceName';
import MessageLoading from './MessageLoading';
import Reply from './Reply';
import Status from './Status';
import UpdateForm from './UpdateForm';

import './index.css';

export class SupportTicket extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		applications: PropTypes.arrayOf( PropTypes.string ).isRequired,
		applicationObjects: PropTypes.arrayOf( PropTypes.object ).isRequired,
		status: PropTypes.string,
		title: PropTypes.string,
		content: PropTypes.string,
		url: PropTypes.string,
		author: PropTypes.shape( {
			name: PropTypes.string.isRequired,
			avatar_urls: PropTypes.shape( {
				'96': PropTypes.string,
			} ).isRequired,
		} ),
		priority: PropTypes.string,
		type: PropTypes.string,
		date: PropTypes.string,
		comments: PropTypes.shape( {
			byid: PropTypes.object,
			isLoading: PropTypes.bool,
		} ),
		showZendeskLink: PropTypes.bool,
		showUpdateForm: PropTypes.bool,
		isLoading: PropTypes.bool,
	};

	state = {
		isCreatingSupportComment: false,
		creatingSupportCommentError: null,
		isUpdatingSupportTicket: false,
		updatingSupportTicketError: null,
	};

	componentDidMount() {
		this.props.dispatch( fetchSupportTicketsComments( api, this.props.id ) );

		if ( ! this.props.title ) {
			this.props.dispatch( fetchSupportTicket( api, this.props.id ) );
			this.props.dispatch( fetchSupportTicket( api, this.props.id ) );
		}

		if ( this.props.applications && this.props.applications.length > 0 && this.props.applicationObjects.length === 0 ) {
			this.props.dispatch( fetchApplications( { include: this.props.applications[0] } ) );
		}

		const instance_id = this.getInstanceId();
		if ( instance_id && instance_id !== this.props.selectedInstance ) {
			this.props.dispatch( selectInstance( instance_id ) );
		}
	}

	componentDidUpdate( prevProps ) {
		if ( this.props.id !== prevProps.id ) {
			this.props.dispatch( fetchSupportTicket( api, this.props.id ) );
			this.props.dispatch( fetchSupportTicketsComments( api, this.props.id ) );
		}
		if ( this.props.applicationObjects.length > 0 && this.props.applicationObjects[0]['altis-instance'] !== this.props.selectedInstance ) {
			this.props.dispatch( selectInstance( this.props.applicationObjects[0]['altis-instance'] ) );
		}
	}

	getInstanceId() {
		if ( this.props.instance ) {
			return this.props.instance;
		}

		if ( this.props.applicationObjects.length > 0 && this.props.applicationObjects[0]['altis-instance'] ) {
			return this.props.applicationObjects[ 0 ][ 'altis-instance' ];
		}

		return null;
	}

	onSubmitComment( data ) {
		this.setState( {
			isCreatingSupportComment: true,
			creatingSupportCommentError: null,
		} );
		return this.props
			.dispatch( createSupportTicketComment( api, this.props.id, data ) )
			.then( result => {
				if ( data.solved || this.props.status === 'solved' ) {
					// Reload the ticket, as the status will have changed.
					this.props.dispatch( fetchSupportTicket( api, this.props.id ) );
					this.props.dispatch( fetchSupportTicketsComments( api, this.props.id ) );
				}

				return result;
			} )
			.catch( error => {
				this.setState( { creatingSupportCommentError: error } );
				return false;
			} )
			.finally( () => {
				this.setState( { isCreatingSupportComment: false } );
			} );
	}

	onUpdateTicket( data ) {
		this.setState( {
			isUpdatingSupportTicket: true,
			updatingSupportTicketError: null,
		} );
		this.props
			.dispatch( updateSupportTicket( api, this.props.id, data ) )
			.finally( () => {
				this.setState( { isUpdatingSupportTicket: false } );
			} )
			.catch( e => this.setState( { updatingSupportTicketError: e } ) );
	}

	render() {
		if ( this.props.isLoading ) {
			return 'Loading...';
		}

		const instance = this.getInstanceId();

		// See https://support.zendesk.com/hc/en-us/articles/232053008-Requesters-are-unable-to-mark-tickets-as-solved-in-the-Help-Center
		const canSolve = this.props.status !== 'solved' && this.props.type !== 'problem' && this.props.assignee;

		return (
			<div className="SupportTicket">
				<PageTitle
					title={ `#${ this.props.id }: ${ this.props.title }` }
				/>
				<div className="SupportTicket__back-link">
					<Link to="/support">&larr; Back to Support</Link>
				</div>

				<div className="SupportTicket__head">
					<div className="SupportTicket__title">
						<BigTitle name={ this.props.title } />

						<p className="SupportTicket__context">
							{ instance && (
								<span className="SupportTicket__instance">
									{ 'Instance: ' }
									<InstanceName
										id={ instance }
									/>
								</span>
							) }

							{ this.props.applications.length > 0 && (
								<span>
									{ this.props.applications.length > 1 ? 'Applications: ' : 'Application: ' }
									{ this.props.applications.map( applicationId => (
										<Link
											key={ applicationId }
											className="SupportTicket__application"
											to={ `/e/${applicationId}` }
										>
											{ applicationId }
										</Link>
									) ) }
								</span>
							) }
						</p>
					</div>
					<div className="SupportTicket__status">
						<Status status={ this.props.status } />
					</div>

					<div className="SupportTicket__details">
						<FormattedRelativeTime
							value={ this.props.date }
						/>

						{ this.props.assignee && (
							<span className="SupportTicket__assignee">
								<Avatar size={ 24 } user={ this.props.assignee } />
								<strong>{ this.props.assignee.name }</strong>
								{ ' is assigned' }
							</span>
						) }

						<Type
							type={ this.props.type }
						/>
						<span>Priority: { this.props.priority }</span>
					</div>
					<div className="SupportTicket__id">
						{ this.props.showZendeskLink ? (
							<a
								className="SupportTicket__zendesk-link"
								href={ this.props.url }
								rel="noopener noreferrer"
								target="_blank"
								title="View in Zendesk"
							>
								#{ this.props.id } <ExternalLinkIcon color="#30A671" size={ 16 } />
							</a>
						) : (
							`#${ this.props.id }`
						) }
					</div>
				</div>

				<Followers
					{ ...this.props }
				/>

				{ Object.values( ( this.props.comments && this.props.comments.byId ) || {} ).map( comment => (
					<Event
						key={ comment.id }
						data={ comment }
					/>
				) ) }
				{ ( ! this.props.comments || this.props.comments.isLoading ) && <MessageLoading /> }

				{ this.props.status !== 'closed' ? (
					<>
						<Reply
							author={ this.props.user }
							canReplyInternally={ this.props.showUpdateForm }
							canSolve={ canSolve }
							errorMessage={ this.state.creatingSupportCommentError ? this.state.creatingSupportCommentError.message : null }
							followers={ this.props.followers_users }
							isSubmitting={ this.state.isCreatingSupportComment }
							willReopen={ this.props.status === 'solved' }
							onSubmit={ content => this.onSubmitComment( content ) }
						/>
						{ this.state.updatingSupportTicketError && (
							<ErrorBlock message={ this.state.updatingSupportTicketError.message } />
						) }

						{ this.props.showUpdateForm && (
							<UpdateForm
								isSubmitting={ this.state.isUpdatingSupportTicket }
								priority={ this.props.priority }
								status={ this.props.status }
								type={ this.props.type }
								onSubmit={ data => this.onUpdateTicket( data ) }
							/>
						) }
					</>
				) : (
					<div className="SupportTicket__closed">
						<p>This ticket is closed, and cannot be updated.</p>

						<SolidButton
							name="Create new ticket"
							to="/support/new"
						/>
					</div>
				) }
			</div>
		);
	}
}

const mapStateToProps = ( state, props ) => {
	const ticket = state.supportTickets.byId[Number( props.match.params.ticket )];
	const appById = id => Object.values( state.applications ).find( app => app.id.toLowerCase() === id );

	return {
		...ticket,
		applicationObjects: ticket && ticket.applications ? ticket.applications.map( appById ).filter( Boolean ) : [],
		id: props.match.params.ticket,
		comments: state.supportTickets.replies[Number( props.match.params.ticket )],
		user: state.currentUser.user,
		showZendeskLink: state.currentUser.capabilities.manage_support_tickets,
		showUpdateForm: state.currentUser.capabilities.manage_support_tickets,
		isLoading: state.supportTickets.isLoading || ! state.supportTickets.byId[Number( props.match.params.ticket )],
		selectedInstance: state.instances.selected,
	};
};

export default connect( mapStateToProps )( SupportTicket );
