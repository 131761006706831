import { ChevronDownIcon, ChevronUpIcon, FilterIcon } from '@heroicons/react/solid';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import React, { useState } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';

import { ColorSpec, getColor } from '../../lib/graph';

import CustomTooltip from './Tooltip';

const UNKNOWN = 'Unknown';

interface TraceSummary {
	Id: string,
	Http: {
		HttpMethod: string,
		HttpStatus?: number,
		ClientIp?: string,
		HttpURL: string,
	},
	ResponseTime?: number,
	HasFault?: boolean,
	HasError?: boolean,
	Duration?: number,
}

type Grouped = {
	[ k: string ]: TraceSummary[],
}
const groupBys = {
	url: 'URL',
	statusCode: 'Status Code',
	method: 'HTTP Method',
	clientIp: 'Client IP',
};

const MAX_ITEMS = 20;

type GraphedItem = {
	name: string,
	value: number,
	average: number,
	percentage: number,
}

const getLimitedData = ( data: GraphedItem[] ) => {
	const sorted = sortBy( data, i => -i.value );
	const firstData = sorted.slice( 0, MAX_ITEMS );
	const restData = sorted.slice( MAX_ITEMS );
	return restData.length > 0 ? [
		...firstData,
		{
			name: 'Other',
			value: restData.length,
			average: sumBy( restData, i => i.average * i.value ) / restData.length,
			percentage: restData.length / sorted.length,
		},
	] : firstData;
};

function StatsTooltip( props: TooltipProps<number, string> ) {
	const { active, payload } = props;
	if ( ! active || ! payload || ! payload[0] ) {
		return null;
	}
	console.log( props );

	const item: GraphedItem & { color: ColorSpec } = payload[0].payload;
	return (
		<CustomTooltip>
			<div className="flex items-center justify-between space-x-8">
				<div className="flex items-center space-x-2">
					<svg
						className={ `shrink-0 ${ item.color.fill } border-white h-3 w-3 rounded-full border-2 shadow` }
						fill="currentColor"
						viewBox="0 0 8 8"
					>
						<circle cx="4" cy="4" r="4"></circle>
					</svg>
					<p className="text-elem font-medium tabular-nums text-right whitespace-nowrap text-gray-700">
						{ `${ item.value } (${ ( item.percentage * 100 ).toFixed( 1 ) }%)` }
					</p>
				</div>
				<p className="text-elem text-right whitespace-nowrap text-gray-500 font-normal max-w-[400px] overflow-hidden text-ellipsis">
					{ item.name }
				</p>
			</div>
		</CustomTooltip>
	);
}

type ColumnName = 'name' | 'average' | 'percentage';

interface Props {
	isLoading: boolean,
	traceSummaries: TraceSummary[],
	onSelectFilter( filter: { field: string, value: string } ) : void,
}

export default function TraceStats( props: Props ) {
	const [ groupBy, setGroupBy ] = useState<string>( 'method' );
	const [ orderBy, setOrderBy ] = useState<ColumnName>( 'percentage' );
	const [ order, setOrder ] = useState<'ASC' | 'DESC'>( 'DESC' );

	const summariesGroupedByKey = props.traceSummaries.reduce( ( groups: Grouped, traceSummary ) => {
		let key = '';
		switch ( groupBy ) {
			case 'url':
				key = traceSummary.Http.HttpURL;
				break;
			case 'statusCode':
				key = `${ traceSummary.Http.HttpStatus || UNKNOWN }`;
				break;
			case 'method':
				key = traceSummary.Http.HttpMethod;
				break;
			case 'clientIp':
				key = traceSummary.Http.ClientIp || UNKNOWN;
				break;
			default:
				return groups;
		}
		if ( ! groups[key] ) {
			groups[key] = [];
		}

		groups[key].push( traceSummary );
		return groups;
	}, {} );

	const data = Object.entries( summariesGroupedByKey ).map( ( [ key, items ] ) => ( {
		name: key,
		value: items.length,
		average: sumBy( items, i => i.ResponseTime || 0 ) / items.length,
		percentage: items.length / props.traceSummaries.length,
	} ) );
	const sorted = order === 'DESC' ? reverse( sortBy( data, orderBy ) ) : sortBy( data, orderBy );

	const headers = {
		name: {
			label: groupBys[ groupBy as keyof typeof groupBys ],
			className: 'w-[70%] text-left',
		},
		average: {
			label: 'Avg Response Time',
			className: 'w-[15%] text-right',
		},
		percentage: {
			label: '% of Traces',
			className: 'w-[15%] text-right',
		},
	};

	const graphable = getLimitedData( data ).map( ( item, idx ) => ( {
		...item,
		color: getColor( idx ),
	} ), {} );

	const onChangeOrder = ( column: ColumnName ) => {
		if ( orderBy === column ) {
			setOrder( order === 'ASC' ? 'DESC' : 'ASC' );
		} else {
			setOrderBy( column );
		}
	};
	const onFilter = ( value: string ) => {
		let key = '';
		switch ( groupBy ) {
			case 'url':
			case 'method':
				key = `http.${ groupBy }`;
				break;
			case 'statusCode':
				key = 'http.status';
				break;
			case 'clientIp':
				key = 'http.clientip';
				break;
			default:
				return;
		}
		props.onSelectFilter( {
			field: key,
			value,
		} );
	};

	return (
		<div className="grid grid-cols-4">
			<div className="col-span-1">
				{ props.isLoading ? (
					<ContainerDimensions>
						{ ( { width } ) => (
							<ContentLoader
								className=""
								height={ 300 }
								// @ts-ignore
								primaryColor="#f3f3f3"
								secondaryColor="#ecebeb"
								speed={ 2 }
								width={ width }
							>
								<circle
									cx={ width / 2 }
									cy={ 300 / 2 }
									r={ 80 }
								/>
							</ContentLoader>
						) }
					</ContainerDimensions>
				) : (
					<ResponsiveContainer
						className="relative z-10"
						height={ 300 }
						width="100%"
					>
						<PieChart>
							<Pie
								cx="50%"
								cy="50%"
								data={ graphable }
								dataKey="value"
								endAngle={ -270 }
								fill="#8884d8"
								isAnimationActive={ false }
								nameKey="name"
								outerRadius={ 80 }
								startAngle={ 90 }
							>
								{ graphable.map( item => (
									<Cell
										className={ item.color.fill }
										fill={ item.name }
									/>
								) ) }
							</Pie>
							<Tooltip
								allowEscapeViewBox={ {
									x: true,
									y: true,
								} }
								content={ <StatsTooltip /> }
							/>
						</PieChart>
					</ResponsiveContainer>
				) }
			</div>
			<div className="col-span-3">
				<label className="block pb-2">
					Group by{ ' ' }
					<select
						className="mt-2 inline-block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						value={ groupBy }
						onChange={ e => setGroupBy( e.target.value ) }
					>
						{ Object.entries( groupBys ).map( ( [ key, value ] ) => (
							<option key={ key } value={ key }>
								{ value }
							</option>
						) ) }
					</select>
				</label>
				<div className="max-h-[300px] overflow-y-auto relative">
					<table className="w-full table-fixed">
						<thead className="sticky top-0 bg-white shadow">
							<tr>
								{ Object.keys( headers ).map( key => (
									<th
										className={ `group whitespace-nowrap px-2 py-3.5 text-xs font-semibold text-gray-900 ${ headers[ key as ColumnName ].className }` }
									>
										{ headers[ key as ColumnName ].label }

										<button
											className={ [
												'ml-2 flex-none rounded',
												key === orderBy ? 'bg-gray-100 text-gray-900 group-hover:bg-gray-200' : 'invisible text-gray-400 group-hover:visible group-focus:visible',
											].join( ' ' ) }
											onClick={ () => onChangeOrder( key as ColumnName ) }
										>
											{ order === 'ASC' ? (
												<ChevronUpIcon
													aria-hidden="true"
													className="h-5 w-5"
												/>
											) : (
												<ChevronDownIcon
													aria-hidden="true"
													className="h-5 w-5"
												/>
											) }
										</button>
									</th>
								) ) }
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white">
							{ props.isLoading && (
								<tr>
									<td
										className="group flex whitespace-nowrap px-2 py-2 text-xs font-medium text-gray-900"
										colSpan={ 3 }
									>
										<ContainerDimensions>
											{ ( { width } ) => (
												<ContentLoader
													className=""
													height={ 16 }
													// @ts-ignore
													primaryColor="#f3f3f3"
													secondaryColor="#ecebeb"
													speed={ 2 }
													width={ width }
												>
													<rect
														height="16"
														rx="2"
														ry="2"
														width={ 120 }
														x="0"
														y="0"
													/>
												</ContentLoader>
											) }
										</ContainerDimensions>
									</td>
								</tr>
							) }
							{ sorted.map( item => (
								<tr
									key={ item.name }
								>
									<td className="group flex whitespace-nowrap px-2 py-2 text-xs font-medium text-gray-900">
										<span className="inline-block overflow-x-hidden text-ellipsis">
											{ item.name }
										</span>
										{ item.name !== UNKNOWN && (
											<button
												className={ [
													'ml-2 flex-none rounded',
													'invisible text-gray-400 group-hover:visible group-focus:visible',
												].join( ' ' ) }
												title="Filter by this value"
												onClick={ () => onFilter( item.name ) }
											>
												<FilterIcon
													aria-hidden="true"
													className="h-3 w-3"
												/>
											</button>
										) }
									</td>
									<td className="text-right tabular-nums whitespace-nowrap px-2 py-2 text-xs text-gray-500">
										{
											item.average.toFixed( 2 )
										}s
									</td>
									<td className="w-[25%] text-right tabular-nums whitespace-nowrap px-2 py-2 text-xs text-gray-500">
										{ ( item.percentage * 100 ).toFixed( 1 ) }%
									</td>
								</tr>
							) ) }
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
