import React, { useState } from 'react';

import { ApplicationResponse } from '../api';
import { withApiData } from '../lib/with-api-data';

import ErrorBlock from './ErrorBlock';
import OutlineButton from './OutlineButton';

interface Props {
	application: ApplicationResponse,
	fetch: Window['fetch'],
	showClearBuildCache: boolean,
	onClear?(): Promise<void>,
}

export function ApplicationBuildCacheButton( props: Props ) {
	const [ clearing, setClearing ] = useState<boolean>( false );
	const [ error, setError ] = useState<Error | null>( null );

	const onClearBuildCache = async () => {
		const onClear = props.onClear || ( () => (
			props.fetch( `/stack/applications/${ props.application.id }/deploys/build-cache`, {
				method: 'DELETE',
			} )
		) );

		setClearing( true );
		try {
			await onClear();
			setClearing( false );
		} catch ( err ) {
			setError( err );
		} finally {
			setClearing( false );
		}
	};

	if ( ! props.showClearBuildCache ) {
		return null;
	}

	return (
		<>
			{ error && (
				<ErrorBlock
					message={ error.message }
				/>
			) }
			<OutlineButton
				disabled={ clearing }
				name={ clearing ? 'Clearing build cache...' : 'Clear build cache' }
				onClick={ () => onClearBuildCache() }
			/>
		</>
	);
}

export default withApiData( () => ( {} ) )( ApplicationBuildCacheButton );
