import { Switch } from '@headlessui/react';
import React, { useState } from 'react';
import AlertTriangleIcon from 'react-feather/dist/icons/alert-triangle';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateApplicationSettings } from '../../actions';
import { ApplicationResponse } from '../../api';
import Button from '../Button';
import SettingsGroup from '../SettingsGroup';
import useId from '../useId';

interface Props {
	application: ApplicationResponse,
}

export default function Deployment( props: Props ) {
	const dispatch = useDispatch();

	const deployment = props.application['git-deployment'];
	// todo: move to redux again
	const [ isUpdating, setIsUpdating ] = useState<boolean>( false );
	const [ branch, setBranch ] = useState<string>( deployment.ref );
	const [ autodeploy, setAutodeploy ] = useState<boolean>( deployment.is_autoupdating );
	const canChangeAutodeploy = ! isUpdating && props.application['environment-type'] !== 'production';
	const needsSave = branch !== deployment.ref || autodeploy !== deployment.is_autoupdating;

	const onUpdate = async () => {
		setIsUpdating( true );
		const data = {
			'git-deployment': {
				ref: branch,
				is_autoupdating: autodeploy,
			},
		};
		await dispatch( updateApplicationSettings( props.application.id, data ) );
		setIsUpdating( false );
	};

	const id = useId();
	const repoId = id + '-repo';
	const branchId = id + '-branch';

	return (
		<div className="space-y-8">
			<SettingsGroup
				description={
					<>
						Altis will build commits automatically from this repository.

						{ ' ' }

						<a
							href="https://docs.altis-dxp.com/getting-started/deploy/"
							rel="noopener noreferrer"
						>
							Learn more <ExternalLinkIcon className="w-4 -mt-1" />
						</a>
					</>
				}
				footer={ (
					<p
						className="text-sm text-gray-500"
						id={ repoId + '-desc' }
					>
						Need to change your repository? <Link to="/support/new/task">Contact support</Link>. (Self-service coming soon!)
					</p>
				) }
				title="Connected Repository"
			>
				<div>
					<label
						className="block text-sm font-medium text-gray-700"
						htmlFor={ repoId }
					>
						Repository
					</label>
					<div className="mt-1">
						<input
							aria-labelledby={ repoId + '-desc' }
							className="block w-full rounded-md disabled:bg-gray-50 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
							disabled
							id={ repoId }
							type="text"
							value={ props.application['git-deployment'].url }
						/>
					</div>
				</div>
			</SettingsGroup>
			<SettingsGroup
				description={
					<>
						Every commit to this branch will create a new build.
					</>
				}
				footer={ (
					<div className="grid grid-cols-2 items-center">
						<div>
							{ branch !== deployment.ref && (
								<span className="text-sm text-yellow-700">
									<AlertTriangleIcon
										className="mr-2"
										size={ 16 }
									/>
									Updating your branch will clear your build cache.
								</span>
							) }
						</div>
						<div className="text-right">
							<Button
								disabled={ ! needsSave || isUpdating }
								variant="primary"
								onClick={ onUpdate }
							>
								{ isUpdating ? 'Updating…' : 'Update' }
							</Button>
						</div>
					</div>
				) }
				title="Deployment Branch"
			>
				<div className="my-3">
					<label
						className="block text-sm font-medium text-gray-700"
						htmlFor={ branchId }
					>
						Branch Name
					</label>
					<div className="mt-1">
						<input
							aria-labelledby={ branchId + '-desc' }
							className="block w-full rounded-md disabled:bg-gray-50 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
							disabled={ isUpdating }
							id={ branchId }
							type="text"
							value={ branch }
							onChange={ e => setBranch( e.target.value ) }
						/>
					</div>
				</div>

				<Switch.Group as="div" className="flex items-center justify-between my-3">
					<span className="flex flex-grow flex-col">
						<Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
							Deploy builds automatically
						</Switch.Label>
						<Switch.Description as="span" className="text-sm text-gray-500">
							{ props.application['environment-type'] === 'production' ? (
								<>
									<AlertTriangleIcon
										className="mr-2 text-red-700"
										size={ 14 }
									/>
									Autodeployment cannot be enabled on production environments.
								</>
							) : (
								<>
									As soon as a build is completed, Altis will deploy it for you.
								</>
							) }
						</Switch.Description>
					</span>
					<Switch
						checked={ autodeploy }
						className={ [
							autodeploy ? 'bg-blue-600' : 'bg-gray-200',
							'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
							canChangeAutodeploy ? '' : 'cursor-not-allowed opacity-30',
						].filter( Boolean ).join( ' ' ) }
						disabled={ ! canChangeAutodeploy }
						onChange={ checked => setAutodeploy( checked ) }
					>
						<span className="sr-only">Use setting</span>
						<span
							aria-hidden="true"
							className={ [
								autodeploy ? 'translate-x-5' : 'translate-x-0',
								'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
							].filter( Boolean ).join( ' ' ) }
						/>
					</Switch>
				</Switch.Group>
			</SettingsGroup>
		</div>
	);
}
