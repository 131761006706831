import React, { useState } from 'react';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';

import FlameGraph from '../Flamegraph';

import { Trace } from './types';
import { getXhprofFromTrace, orderByWeight, segmentToItem } from './util';

import './FlamegraphPage.css';

type Mode = 'chronological' | 'weight';

interface Props {
	trace: Trace,
}

export default function FlamegraphPage( props: Props ) {
	const [ mode, setMode ] = useState< Mode >( 'chronological' );
	const xhprof = getXhprofFromTrace( props.trace );
	if ( ! xhprof ) {
		return (
			<p>No xhprof trace found.</p>
		);
	}

	const data = segmentToItem( xhprof );

	// Force-set root to more readable name.
	data.name = 'Page load';

	const sortedData = mode === 'weight' ? orderByWeight( [ data ] )[0] : data;

	return (
		<div className="XRayTrace-FlamegraphPage">
			<header className="XRayTrace-FlamegraphPage__header">
				<div>
					<a
						href="https://docs.altis-dxp.com/cloud/dashboard/x-ray/#understanding-an-xray-trace"
						rel="noopener noreferrer"
						target="_blank"
					>
						{ ' View Help ' }
						<ExternalLinkIcon size={ 12 } />
					</a>
				</div>
				<form
					className="XRayTrace-FlamegraphPage__sort"
					onSubmit={ e => e.preventDefault() }
				>
					<div className="XRayTrace-FlamegraphPage__toggle">
						<label
							className="XRayTrace-FlamegraphPage__filter"
							title="Order chronologically"
						>
							<input
								checked={ mode === 'chronological' }
								name="mode"
								type="radio"
								onChange={ () => setMode( 'chronological' ) }
							/>
							<span className="XRayTrace-FlamegraphPage__filter-label">
								Chronological
							</span>
						</label>
						<label
							className="XRayTrace-FlamegraphPage__filter"
							title="Order by slowest"
						>
							<input
								checked={ mode === 'weight' }
								name="mode"
								type="radio"
								onChange={ () => setMode( 'weight' ) }
							/>
							<span className="XRayTrace-FlamegraphPage__filter-label">
								Slowest
							</span>
						</label>
					</div>
				</form>
			</header>

			<div
				className="XRayTrace-FlamegraphPage__axis-label"
			>
				<span className="XRayTrace-FlamegraphPage__axis-label-text">
					{ mode === 'chronological' ? 'Start' : 'Slowest' }
				</span>
				<span>➤</span>
				<span>➤</span>
				<span>➤</span>
				<span className="XRayTrace-FlamegraphPage__axis-label-text">
					{ mode === 'chronological' ? 'End' : 'Fastest' }
				</span>
			</div>

			<FlameGraph
				// Force focussed nodes to reset when toggling.
				key={ mode }
				data={ sortedData }
			/>
		</div>
	);
}
