import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import fetchAccelerateSites from '../../actions/fetchAccelerateSites';
import { AccelerateSiteResponse, UserResponse } from '../../api';
import { AppState } from '../../reducers';

import AddSite from './components/AddSite';
import SitesList from './components/SitesList';

interface Props {
	user: undefined | UserResponse,
	loadingUser: boolean,
	sites: AccelerateSiteResponse[],
}

function Dashboard( props: Props ) {
	if ( props.loadingUser ) {
		return null;
	}
	if ( ! props.user ) {
		return <Redirect to="/accelerate/login/" />;
	}

	const dispatch = useDispatch(); // eslint-disable-line react-hooks/rules-of-hooks
	useEffect( () => { // eslint-disable-line react-hooks/rules-of-hooks
		dispatch( fetchAccelerateSites() );
	}, [] ); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="bg-white text-altis-800">
			<div className="py-12 pb-10">
				<div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
					<div>
						<h2 className="my-4 text-4xl font-extrabold">Welcome { props.user?.name },</h2>
						<p>You now have access to the <strong>Altis Beta</strong> solution. We are continously releasing features, <a className="text-altis-400" href="https://www.linkedin.com/company/altis-dxp" rel="noreferrer" target="_blank">follow us</a> on LinkedIn for sneak peeks and other news.</p>
					</div>
				</div>
			</div>
			<div className="py-12 pb-10 bg-gray-50 shadow-inner">
				<Switch>
					<Route component={ AddSite } path="/accelerate/add" />
					<Route path="/accelerate" render={ () => <SitesList sites={ props.sites } /> } />
				</Switch>
			</div>
		</div>
	);
}

function mapStateToProps( state: AppState ) : Props {
	return {
		user: state.currentUser.user,
		sites: Object.values( state.accelerateSites ),
		loadingUser: state.currentUser.loading,
	};
}
export default connect( mapStateToProps )( Dashboard );
