import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// From react-router v6
/**
 * Creates a URLSearchParams object using the given initializer.
 *
 * This is identical to `new URLSearchParams(init)` except it also
 * supports arrays as values in the object form of the initializer
 * instead of just strings. This is convenient when you need multiple
 * values for a given key, but don't want to use an array initializer.
 *
 * For example, instead of:
 *
 *   let searchParams = new URLSearchParams([
 *     ['sort', 'name'],
 *     ['sort', 'price']
 *   ]);
 *
 * you can do:
 *
 *   let searchParams = createSearchParams({
 *     sort: ['name', 'price']
 *   });
 */
function createSearchParams(
	init: URLSearchParamsInit = ''
): URLSearchParams {
	return new URLSearchParams(
		typeof init === 'string' ||
		Array.isArray( init ) ||
		init instanceof URLSearchParams
			? init
			: Object.keys( init ).reduce( ( memo, key ) => {
				let value = init[ key ];
				return memo.concat(
					Array.isArray( value ) ? value.map( v => [ key, v ] ) : [ [ key, value ] ]
				);
			}, [] as ParamKeyValuePair[] )
	);
}
export function getSearchParamsForLocation(
	locationSearch: string,
	defaultSearchParams: URLSearchParams | null
) {
	let searchParams = createSearchParams( locationSearch );

	if ( defaultSearchParams ) {
		for ( let key of defaultSearchParams.keys() ) {
			if ( ! searchParams.has( key ) ) {
				defaultSearchParams.getAll( key ).forEach( value => {
					searchParams.append( key, value );
				} );
			}
		}
	}

	return searchParams;
}

// Types from react-router v6
// https://reactrouter.com/en/main/hooks/use-search-params
type ParamKeyValuePair = [ string, string ];

type URLSearchParamsInit =
	| string
	| ParamKeyValuePair[]
	| Record<string, string | string[]>
	| URLSearchParams;

interface NavigateOptions {
	replace?: boolean;
	state?: any;
	preventScrollReset?: boolean;
}

type SetURLSearchParams = (
  nextInit?:
    | URLSearchParamsInit
    | ( ( prev: URLSearchParams ) => URLSearchParamsInit ),
	navigateOpts?: NavigateOptions
) => void;

interface ReadOnlyURLSearchParams extends URLSearchParams {
	append: never;
	set: never;
	delete: never;
	sort: never;
}

export default function useSearchParams( defaultInit?: URLSearchParamsInit ) : [ URLSearchParams, SetURLSearchParams ] {
	const history = useHistory();
	const location = useLocation();

	let defaultSearchParamsRef = useRef( createSearchParams( defaultInit ) );
	let hasSetSearchParamsRef = useRef( false );

	const searchParams = useMemo(
		() =>
			// Only merge in the defaults if we haven't yet called setSearchParams.
			// Once we call that we want those to take precedence, otherwise you can't
			// remove a param with setSearchParams({}) if it has an initial value
			getSearchParamsForLocation(
				location.search,
				hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current
			) as ReadOnlyURLSearchParams,
		[ location.search ]
	);
	const setParams = useCallback<SetURLSearchParams>(
		( nextInit, navigateOptions ) => {
			const nextInitObj = typeof nextInit === 'function' ? nextInit( searchParams ) : nextInit;
			const newSearchParams = createSearchParams( nextInitObj );
			hasSetSearchParamsRef.current = true;

			if ( navigateOptions?.replace  ) {
				history.replace( {
					search: newSearchParams.toString(),
				}, navigateOptions?.state );
			} else {
				history.push( {
					search: newSearchParams.toString(),
				}, navigateOptions?.state );
			}
		},
		[ history, searchParams ]
	);

	return [
		searchParams,
		setParams,
	];
}
