import React, { useState } from 'react';

export type PaginationProps = {
	page: number,
	setPage( page: number ): void,
}

export default function withPagination<T>( Component: React.ComponentType<T> ) {
	return ( props: T ) => {
		const [ page, setPage ] = useState<number>( 1 );

		return (
			<Component
				{ ...props }
				page={ page }
				setPage={ setPage }
			/>
		);
	};
}
