import PropTypes from 'prop-types';
import React from 'react';

import './PHPLogItem.css';
import FormattedRelativeTime from './FormattedRelativeTime';
import PillButton from './PillButton';

export default function PHPLogItem( props ) {
	return (
		<div className={ 'PHPLogItem ' + props.level }>
			{ props.application && props.application.architecture === 'terraform-ecs-application' && (
				<span className="date">
					<div className="time-ago">
						<FormattedRelativeTime value={ props.date } />
					</div>
				</span>
			) }
			<span className="message">
				{ props.level && <span className="level">{ props.level }</span> }
				{ props.message }
				{ props.file && (
					<span className="source">
						{ ' ' }
						in{ ' ' }
						<a href={ `${props.sourceBaseUrl}${props.file}#L${props.line}` } rel="noopener noreferrer" target="_blank">
							{ props.file }:{ props.line }
						</a>
					</span>
				) }
			</span>

			{ props.onOpenIssue && <PillButton color="#DDD" name="Open Issue" onClick={ props.onOpenIssue } /> }
			{ props.onOpenTicket && <PillButton color="#DDD" name="Open Ticket" onClick={ props.onOpenTicket } /> }
		</div>
	);
}

PHPLogItem.propTypes = {
	level: PropTypes.string,
	message: PropTypes.string.isRequired,
	sourceBaseUrl: PropTypes.string,
	file: PropTypes.string,
	line: PropTypes.number,
	onOpenIssue: PropTypes.func,
	onOpenTicket: PropTypes.func,
};
