import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { fetchInstancesOnce } from '../actions/fetchInstances';
import { InstanceResponse } from '../api';
import { AppState } from '../reducers';

type OwnProps = Select<InstanceResponse>['props'] & {
	isDisabled: boolean,
	value?: string | null,
	onSelect( value: string | null ): void,
}
type StateProps = {
	instances: InstanceResponse[],
	instancesLoading: boolean,
}
type DispatchProps = {
	onLoad(): void,
}
type AllProps = OwnProps & StateProps & DispatchProps;

export function SelectInstance( props: AllProps ) {
	const { instances, instancesLoading, onLoad, onSelect, value, ...otherProps } = props;
	const selected = value ? instances.find( i => i.id === value ) : null;

	useEffect( () => {
		onLoad();
	}, [ onLoad ] );

	return (
		<div className="SelectInstance">
			<Select<InstanceResponse>
				getOptionLabel={ instance => instance.name }
				getOptionValue={ instance => instance.id }
				isLoading={ instancesLoading }
				options={ instances }
				placeholder="Select Instance..."
				value={ selected }
				onChange={ instance => {
					if ( instance ) {
						onSelect( ( instance as InstanceResponse ).id );
					} else {
						onSelect( null );
					}
				} }
				{ ...otherProps }
			/>
		</div>
	);
}

const mapStateToProps = ( state: AppState ): StateProps => ( {
	instances: state.instances.archives.all ? Object.values( state.instances.data ) : [],
	instancesLoading: state.instances.archives.all?.loading || false,
} );

const mapDispatchToProps = ( dispatch: any ): DispatchProps => ( {
	onLoad: () => dispatch( fetchInstancesOnce() ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( SelectInstance );
