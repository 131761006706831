import React, { useState } from 'react';

import { InstanceAccessResponse } from '../../types/rest-api';
import ErrorBlock from '../ErrorBlock';
import SolidButton from '../SolidButton';

import RoleSelect from './RoleSelect';

import './InviteForm.css';

type InviteProps = {
	onSubmit( email: string, role: InstanceAccessResponse['role'] ): Promise<'pending' | 'added'>,
}

export default function InviteForm( props: InviteProps ) {
	const [ email, setEmail ] = useState<string>( '' );
	const [ role, setRole ] = useState<InstanceAccessResponse['role'] | null>( null );
	const [ isSaving, setSaving ] = useState<boolean>( false );
	const [ error, setError ] = useState<string | null>( null );
	const [ success, setSuccess ] = useState<'pending' | 'added' | null>( null );

	const onSubmit = async ( e: React.FormEvent ) => {
		e.preventDefault();

		if ( ! role ) {
			return;
		}

		setError( null );
		setSaving( true );
		try {
			const res = await props.onSubmit( email, role );
			if ( res === 'pending' ) {
				setSuccess( res );
				setSaving( false );
			}
		} catch ( err ) {
			if ( ( err as Error ).message ) {
				setError( ( err as Error ).message );
			} else {
				setError( 'Unknown error occurred. Please try again.' );
			}
			setSaving( false );
		}
	};
	if ( success === 'pending' ) {
		return (
			<div>
				<p>Invitation sent. A confirmation link must be clicked to accept your invitation.</p>
				<SolidButton
					name="Invite another user"
					onClick={ () => {
						setEmail( '' );
						setRole( null );
						setSaving( false );
						setError( null );
						setSuccess( null );
					} }
				/>
			</div>
		);
	}

	return (
		<form
			className="InstanceUsers-InviteForm"
			onSubmit={ onSubmit }
		>
			<label className="InstanceUsers-InviteForm__input">
				<span>Email Address</span>
				<input
					placeholder="joe@humanmade.com"
					type="email"
					value={ email }
					onChange={ e => setEmail( e.target.value ) }
				/>
			</label>

			<label className="InstanceUsers-InviteForm__input">
				<span>Role</span>
				<RoleSelect
					isDisabled={ isSaving }
					isLoading={ isSaving }
					value={ role }
					onChange={ setRole }
				/>
			</label>

			{ error && (
				<ErrorBlock
					message={ error }
					small
				/>
			) }

			<SolidButton
				disabled={ isSaving || email.length < 3 || ! role }
				name="Invite"
				type="submit"
			/>
		</form>
	);
}
