/**
 * FormattedContent is a drop-in replacement for the legacy version of React Intl's <FormattedContent />
 */
import React from 'react';

import './FormattedContent.css';

interface Props {
	 html?: string,
	 children?: React.ReactNode,
}

export default function FormattedContent( props: Props ) {
	return props.html ? (
		<div
			className="FormattedContent"
			dangerouslySetInnerHTML={ { __html: props.html } }
		></div>
	) : (
		<div
			className="FormattedContent"
		>
			{ props.children }
		</div>
	);
}
