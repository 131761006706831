import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { AccelerateSiteResponse } from '../../../api';

export default function SiteCard( props: AccelerateSiteResponse )  {
	const {
		name,
		status,
		icon_url,
		url,
		plugin_config,
	} = props;

	const [ copiedApiKey, setCopiedApiKey ] = useState( false );
	const [ copyApiKeyTextTimeout, setCopyApiKeyTextTimeout ] = useState<NodeJS.Timeout | undefined>( undefined );
	const [ fallbackImage, setFallbackImage ] = useState<string | null>( null );

	const onCopyApiKey = () => {
		if ( copyApiKeyTextTimeout ) {
			clearTimeout( copyApiKeyTextTimeout );
		}
		setCopiedApiKey( true );
		const timeout = setTimeout( () => {
			setCopiedApiKey( false );
		}, 5000 );
		setCopyApiKeyTextTimeout( timeout );
	};

	return (
		/* todo: delete site? */
		<li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
			<div className="w-full flex items-center justify-between p-6 space-x-6">
				<div className="flex-1 truncate">
					<div className="flex items-center space-x-3">
						<h3 className="text-gray-900 text-sm font-medium truncate">{ name }</h3>
						<span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full
						">
							{ status }
						</span>
					</div>
					<p className="mt-1 text-gray-500 text-xs truncate">{ url }</p>
				</div>
				<img
					alt=""
					className="w-10 h-10 bg-gray-100 rounded-md flex-shrink-0"
					src={ fallbackImage || icon_url }
					onError={ () => {
						// If browser cannot display image with 404 response, shim faviconkit's behaviour inline.
						const firstLetter = url.replace( /^(https?:\/\/)?(www\.)?(.).*/, '$3' );
						const svg = `<?xml version="1.0" ?>
							<svg width="256" height="256" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
								<g>
									<path stroke="#000000" id="svg_1" d="m5,5l246,0l0,246l-246,0l0,-246z"
										stroke-opacity="0" stroke-linecap="null" stroke-linejoin="null"
										stroke-dasharray="null" stroke-width="0" fill="#e5e5e5"/>
									<text stroke="#000000" transform="matrix(
										8.667392220340947,0,0,8.667392220340947,-2136.7461406433986,
										-2308.843247870543)" xml:space="preserve" text-anchor="middle"
										font-family="Sans-serif" font-size="24" id="svg_2" y="289.438427"
										x="261.31262" stroke-opacity="0" stroke-linecap="null"
										stroke-linejoin="null" stroke-dasharray="null" stroke-width="0"
										fill="#ffffff">${ firstLetter.toUpperCase() }</text>
								</g>
							</svg>`;
						setFallbackImage( `data:image/svg+xml;base64,${ btoa( svg ) }` );
					} }
				/>
			</div>
			<footer className="w-full flex items-center space-x-6 px-6 py-4">
				<a
					className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-xs rounded-md text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					href={ `${ url }/wp-admin/` }
				>
					Go to Dashboard
				</a>
				<CopyToClipboard text={ plugin_config } onCopy={ onCopyApiKey }>
					<button
						className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-xs rounded-md text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						<span>{ ! copiedApiKey ? 'Copy API Key' : 'Copied!' }</span>
						<svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" strokeWidth={ 1.5 } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</button>
				</CopyToClipboard>
			</footer>
		</li>
	);
}
