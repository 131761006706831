import React, { Component } from 'react';

import ErrorBlock from './ErrorBlock';

interface Props {
	children: React.ReactNode;
}

export default class ErrorBoundary extends Component<Props> {
	state = {
		hasError: false,
		errorMessage: '',
	};

	static getDerivedStateFromError( error: Error ) {
		return {
			hasError: true,
			errorMessage: error.message,
		};
	}

	render() {
		if ( this.state.hasError ) {
			return (
				<ErrorBlock
					message={ `An error has occurred. ${ this.state.errorMessage } . Please reload.` }
				/>
			);
		}

		return this.props.children;
	}
}
