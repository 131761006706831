import React from 'react';
import LockIcon from 'react-feather/dist/icons/lock';
import { useSelector } from 'react-redux';

import { AppState } from '../reducers';

import './InternalBlock.css';

export function Title( props: { children: React.ReactNode } ) {
	return (
		<h3
			className="InternalBlock__title"
		>
			<span className="InternalBlock__title-text">{ props.children }</span>

			<LockIcon size={ 14 } />
		</h3>
	);
}

interface Props {
	children: React.ReactNode,
	className?: string,
}

function InternalBlock( props: Props ) {
	const canAccessInternal = useSelector( ( state: AppState ) => !! state.currentUser.capabilities.edit_applications );

	if ( ! canAccessInternal ) {
		return null;
	}

	return (
		<div
			className={ [
				'InternalBlock',
				props.className,
			].filter( Boolean ).join( ' ' ) }
		>
			{ props.children }
		</div>
	);
}
InternalBlock.Title = Title;

export default InternalBlock;
