import { DateTime } from 'luxon';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedNumber } from 'react-intl';

import ErrorBlock from '../ErrorBlock';
import Loader from '../Loader';
import SolidButton from '../SolidButton';

import { AvailableMetricMap } from './util';
import withMetricData, { MetricComponentProps } from './withMetricData';

const DURATION_THRESHOLD = 48 * 60 * 60;
const DURATION_THRESHOLD_MS = DURATION_THRESHOLD * 1000;
export const formatDateAsTime = ( date: number ) => DateTime.fromMillis( date ).toLocaleString( DateTime.TIME_SIMPLE );
export const formatDate = ( date: number ) => DateTime.fromMillis( date ).toLocaleString( {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
} );

type Datum = {
	date: number,
	[ k: string ]: any,
};

interface Props extends MetricComponentProps {
	metrics: AvailableMetricMap,
}

function Table( props: Props ) {
	const [ copied, setCopied ] = useState<boolean>( false );
	if ( props.metricData.isLoading ) {
		return (
			<Loader />
		);
	}
	if ( props.metricData.error ) {
		return (
			<ErrorBlock
				message={ props.metricData.error.message }
			/>
		);
	}

	const parsedData: Datum[] = props.metricData.data.map( item => ( {
		date: DateTime.fromISO( item.date ).toMillis(),
		...item.values,
	} ) );
	const min = parsedData.reduce<number | null>( ( min, item ) => ( ! min || item.date < min ) ? item.date : min, null );
	const max = parsedData.reduce<number | null>( ( max, item ) => ( ! max || item.date > max ) ? item.date : max, null );

	if ( ! min || ! max ) {
		return (
			<p>No data!</p>
		);
	}

	const columns = Object.keys( props.metrics );

	// Format as CSV for copying.
	const csvHeader = 'Date,' + columns.map( col => `"${ props.metrics[ col ].title }"` ).join( ',' );
	const csv = parsedData.reduce( ( csv: string, item ) => {
		const colData = columns.map( col => '' + item[ col ] );
		return `${ csv }\n${ DateTime.fromMillis( item.date ).toISO() },${ colData.join( ',' ) }`;
	}, csvHeader );

	const duration = max - min;
	return (
		<div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg divide-y divide-gray-300 max-h-full w-full overflow-hidden grid grid-rows-[1fr_max-content]">
			<div className="h-full w-full overflow-y-auto">
				<table className="min-w-full divide-y divide-gray-300">
					<thead>
						<th
							className="sticky top-0 whitespace-nowrap bg-gray-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
						>
							Time
						</th>
						{ columns.map( col => (
							<th
								key={ col }
								className="sticky top-0 whitespace-nowrap bg-gray-50 px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
							>
								{ props.metrics[ col ].title }
							</th>
						) ) }
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{ parsedData.map( item => (
							<tr
								key={ item.date }
							>
								<th
									className="whitespace-nowrap text-left pl-4 pr-2 py-2 text-sm font-medium text-gray-900 sm:pl-6"
									scope="row"
								>
									{ duration > DURATION_THRESHOLD_MS ? formatDate( item.date ) : formatDateAsTime( item.date ) }
								</th>
								{ columns.map( col => (
									<td
										key={ col }
										className="whitespace-nowrap px-2 py-2 text-sm text-gray-900"
									>
										{ typeof item[ col ] !== 'undefined' && (
											<FormattedNumber
												value={ item[ col ] }
											/>
										) }
									</td>
								) ) }
							</tr>
						) ) }
					</tbody>
				</table>
			</div>
			<p className="text-right bg-gray-50 shrink-0 py-2 pr-2 sm:pr-6">
				<CopyToClipboard
					text={ csv }
					onCopy={ () => setCopied( true ) }
				>
					<SolidButton
						name={ copied ? 'Copied!' : 'Copy as CSV' }
					/>
				</CopyToClipboard>
			</p>
		</div>
	);
}

export default withMetricData( Table );
