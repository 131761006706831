import round from 'lodash/round';

export type StatEntry = {
	time: number,
	time_end: number,
	requests: number,
	errors: number,
	error_rate: number,
	faults: number,
	fault_rate: number,
	latency: number,
	db_latency: number,
	db_replica_latency: number,
	cache_latency: number,
	apdex: {
		value: number,
		satisfied: number,
		tolerating: number,
		frustrated: number,
	},
}

export type DurationFilter = {
	duration: number,
}
export type ManualTimeFilter = {
	before?: string,
	after?: string,
}
export type TimeFilter = DurationFilter | ManualTimeFilter;

/**
 * Convert a TimeFilter to query string.
 *
 * @param {TimeFilter} filter The filter to apply.
 */
export const queryForTimeFilter = ( filter: TimeFilter ) => {
	if ( 'duration' in filter ) {
		return {
			before: 'now',
			after: filter.duration + ' seconds ago',
		};
	} else {
		return {
			before: filter.before || '',
			after: filter.after || '',
		};
	}
};

export const formatDate = ( timestamp: number ) => {
	const date = new Date( timestamp * 1000 );
	return date.toLocaleTimeString( [], {
		hour: '2-digit',
		minute: '2-digit',
	} );
};

/**
 * Format latency in milliseconds.
 *
 * Displays 2 decimal figures over 1s, 2 significant figures below 10ms,
 * otherwise rounds to the nearest millisecond.
 *
 * @param latency
 */
export const formatLatency = ( latency: number ) => {
	if ( latency > 1 ) {
		return round( latency, 2 ) + 's';
	}

	if ( latency < 0.01 && latency > 0.001 ) {
		return ( latency * 1000 ).toPrecision( 2 ) + 'ms';
	}

	return Math.round( latency * 1000 ) + 'ms';
};

/**
 * Format percentage from raw number.
 *
 * @param percentage
 * @param precision
 */
export const formatPercentage = ( percentage: number, precision: number = 0 ) => {
	return round( percentage * 100, precision ) + '%';
};

/* Apdex quality: https://www.apdex.org/index.php/2010/08/apdex-g-section-5-reporting/ */
export const APDEX_GROUPS = {
	excellent: {
		name: 'Excellent',
		min: 0.94,
		max: 1,
		color: 'green',
		opacity: 0.15,
	},
	good: {
		name: 'Good',
		min: 0.85,
		max: 0.94,
		color: 'lightgreen',
		opacity: 0.15,
	},
	fair: {
		name: 'Fair',
		min: 0.70,
		max: 0.85,
		color: 'yellow',
		opacity: 0.15,
	},
	poor: {
		name: 'Poor',
		min: 0.50,
		max: 0.70,
		color: 'orange',
		opacity: 0.2,
	},
	unacceptable: {
		name: 'Unacceptable',
		min: 0,
		max: 0.50,
		color: 'red',
		opacity: 0.15,
	},
};

/**
 * Round upwards (ceil) a number to the next significant figure.
 *
 * e.g. 0.0051 => 0.006, 0.12 => 0.2, 123 => 200
 *
 * @param num
 */
export const toNextSignificant = ( num: number ) => {
	let val = parseFloat( num.toPrecision( 1 ) );

	if ( num > val ) {
		val = 0 + val + 10 ** Math.floor( Math.log10( num ) );
	}

	return val;
};
