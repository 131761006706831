import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { defaultAPI as api } from '../api';
import { AppState } from '../reducers';
import { ApplicationResponse } from '../types/rest-api';

interface UpdatingApplicationsAction extends ReduxAction {
	type: 'UPDATING_APPLICATIONS';
	api: API;
	args: APIArgs;
}

interface UpdatedApplicationsAction extends ReduxAction {
	type: 'UPDATED_APPLICATIONS';
	api: API;
	applications: ApplicationResponse[];
	args: APIArgs;
}

interface APIArgs {
	instance?: string;
	_embed_include?: string[];
	include?: string;
}

export type Action = UpdatingApplicationsAction | UpdatedApplicationsAction;

export default function fetchApplications( args: APIArgs = {} ): ThunkAction<Promise<ApplicationResponse[]>, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'UPDATING_APPLICATIONS',
			api,
			args,
		} );
		args._embed_include = [ 'https://hm-stack.hm/alarm' ];
		if ( args.include ) {
			// API does not support include param current so we only support "include" of one, and
			// send directly to the single app endpoint.
			return api
				.get( `/stack/applications/${ args.include }`, args )
				.then( ( application: ApplicationResponse ) => {
					dispatch( {
						type: 'UPDATED_APPLICATIONS',
						applications: [ application ],
						api,
						args,
					} );
					return [ application ];
				} );
		} else {
			return api
				.get( '/stack/applications', args )
				.then( ( applications: ApplicationResponse[] ) => {
					dispatch( {
						type: 'UPDATED_APPLICATIONS',
						applications,
						api,
						args,
					} );
					return applications;
				} );
		}

	};
}
