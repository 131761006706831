import React, { Component } from 'react';

import { WithApiData } from '../lib/with-api-data';

import ErrorBlock from './ErrorBlock';
import FilterLogs from './FilterLogs';
import './PHPLog.css';
import NoItemsFoundBlock from './NoItemsFoundBlock';
import LogItem from './PHPLogItem';
import LogLoading from './PHPLogLoading';

export default class RegionLogsLogGroup extends Component {
	state = {
		after: '24 hours ago',
		before: 'now',
		search: '',
	};

	componentWillUnmount() {
		if ( this.apiData ) {
			this.apiData.invalidateData();
		}
	}
	componentDidUpdate( prevProps ) {
		if ( this.props.region !== prevProps.region && this.apiData ) {
			this.apiData.invalidateData();
		}
	}
	onOpenGitHubIssueForLogItem( logItem ) {
		const lineAndFile = logItem.file && ` in ${logItem.file}:${logItem.line}`;
		const body = encodeURIComponent( '```\n' + logItem.message + lineAndFile + '\n```' );
		window.open( `https://github.com/humanmade/vantage-backend/issues/new?title=PHP Error found in logs&body=${body}` );
	}
	onChangeFilter( filter ) {
		this.apiData.invalidateData();
		this.setState( { ...filter } );
	}
	render() {
		const openIssue = this.props.openIssues === 'true';
		return (
			<div className="PHPLog">
				<FilterLogs { ...this.state } onChangeFilter={ filter => this.onChangeFilter( filter ) } />
				<WithApiData
					ref={ apiData => ( this.apiData = apiData ) }
					data={ {
						logs: `/stack/region/${ this.props.region }/logs/${this.props.name}?after=${this.state.after}&before=${this.state.before}&search=${this.state.search}`,
					} }
					render={ props => {
						if ( props.logs.error ) {
							return <ErrorBlock message={ props.logs.error.message } />;
						}

						if ( ! props.logs.data ) {
							return <LogLoading />;
						}
						return props.logs.data.length === 0 ? (
							<NoItemsFoundBlock message="No log items found. Try expanding the date range!" />
						) : (
							props.logs.data
								.slice()
								.sort( ( a, b ) => ( a.date > b.date ? -1 : 1 ) )
								.map( log =>
									openIssue ? (
										<LogItem
											key={ log.id }
											date={ new Date( log.date ) }
											sourceBaseUrl={ 'https://github.com/humanmade/vantage-backend/blob/master' }
											onOpenIssue={ () => this.onOpenGitHubIssueForLogItem( log ) }
											{ ...log }
										/>
									) : (
										<LogItem key={ log.id } date={ new Date( log.date ) } { ...log } />
									)
								)
						);
					} }
					{ ...this.props }
				/>
			</div>
		);
	}
}
