import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './IpAddressInformation.css';

export default class IpAddressInformation extends Component {
	static propTypes = {
		ipAddress: PropTypes.string.isRequired,
	};
	static ipAddressCache = {};
	componentDidMount() {
		this.load();
	}
	componentDidUpdate() {
		this.load();
	}
	load() {
		const ipAddress = this.props.ipAddress;
		if ( IpAddressInformation.ipAddressCache[ipAddress] ) {
			return;
		}
		fetch( `https://ipinfo.io/${this.props.ipAddress}?token=c963dba9bfce12`, {
			headers: { Accept: 'application/json' },
		} )
			.then( r => r.json() )
			.then( data => ( IpAddressInformation.ipAddressCache[ipAddress] = data ) )
			.then( () => this.setState( {} ) );
	}
	render() {
		const ipAddressInfo = IpAddressInformation.ipAddressCache[this.props.ipAddress];
		if ( ! ipAddressInfo ) {
			return null;
		}
		return (
			<div className="IpAddressInformation">
				<div className="org">{ ipAddressInfo.org }</div>
				<span>{ [ ipAddressInfo.city, ipAddressInfo.region, ipAddressInfo.country ].filter( Boolean ).join( ', ' ) }</span>
			</div>
		);
	}
}
