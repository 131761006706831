import React from 'react';
import withSideEffect from 'react-side-effect';

interface Props {
	children?: React.ReactNode,
	title: string,
}

const PageTitle = ( props: Props ) => <>{ props.children }</>;

export default withSideEffect(
	( propsList: Props[] ) => {
		return propsList.reduce( ( title, props ) => `${ props.title } | ${ title }`, 'Altis Dashboard' );
	},
	title => document.title = title
)( PageTitle );
