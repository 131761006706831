import React from 'react';
import { connect } from 'react-redux';

import { fetchTaskStream } from '../../actions';
import TaskLog from '../TaskLog';

import BuildSteps from './BuildSteps';
import Deploy from './Deploy';

import './Details.css';

export class Details extends React.Component {
	componentDidMount = () => {
		if ( ! this.props.task && this.props.build.task_id ) {
			this.props.onLoadTaskStream( this.props.build.task_id );
		}
	};

	render() {
		const { build, current, deploys, deploying, task } = this.props;

		let latest = deploys && deploys[0];

		const inProgress = deploying && deploying.creating && {
			user: this.props.currentUser.user,
			date: ( new Date() ).toISOString(),
			status: 'deploying',
			id: deploying.stream && deploying.stream.split( ':' )[1],
		};

		const loading = ! task || ! task.messages.length;
		return (
			<div className="UnifiedDeployListItem-Details">
				<h3>Build</h3>
				{ build.phases ? (
					<BuildSteps
						loading={ loading }
						messages={ task && task.messages }
						phases={ build.phases }
					/>
				) : (
					<TaskLog task={ task } />
				) }

				<h3>Deploys</h3>
				{ inProgress && (
					<Deploy
						application={ this.props.application }
						data={ inProgress }
						isCurrent={ false }
						isLatest={ false }
					/>
				) }
				{ deploys.length > 0 ? (
					deploys.map( deploy => (
						<Deploy
							key={ deploy.id }
							application={ this.props.application }
							data={ deploy }
							isCurrent={ current && current.id === deploy.id }
							isLatest={ deploy === latest }
						/>
					) )
				) : (
					<p>This build has not been deployed.</p>
				) }
			</div>
		);
	}
}

const mapStateToProps = ( state, props ) => {
	return {
		currentUser: state.currentUser,
		deploying: state.applicationDeploys[ props.application.id ],
		task: state.tasks.byId[ props.build.task_id ],
	};
};

const mapDispatchToProps = ( dispatch, props ) => {
	return {
		onLoadTaskStream: stream => dispatch( fetchTaskStream( stream, props.application ) ),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)( Details );
