import React, { useState } from 'react';

import useTooltip, { Tooltip } from './useTooltip';

type AnnotationProps = {
	children: React.ReactNode,
	className?: string,
	title: React.ReactNode
};

export default function Annotation( props: AnnotationProps ) {
	const { setReferenceElement, TooltipProps } = useTooltip();
	const [ showing, setShowing ] = useState<boolean>( false );

	return (
		<>
			<span
				ref={ setReferenceElement }
				className={ `underline decoration-dotted ${ props.className || '' }` }
				onMouseOut={ () => setShowing( false ) }
				onMouseOver={ () => setShowing( true ) }
			>
				{ props.children }
			</span>

			<Tooltip
				{ ...TooltipProps }
				className="pointer-events-none"
				visible={ showing }
			>
				{ props.title }
			</Tooltip>
		</>
	);
}
