import { UserResponse } from '../../types/rest-api';

export const ROLE_OPTIONS = {
	user: 'User',
	developer: 'Developer',
	admin: 'Admin',
};
export type RoleOptionValue = keyof typeof ROLE_OPTIONS;

export const ROLE_DESCRIPTIONS = {
	user: 'Read-only access to environment data.',
	developer: 'Read and write access to all environments.',
	admin: 'Full access to all environments, and write access to instance information and member management.',
};

export type EmbeddedUser = Pick<UserResponse, 'id' | 'name' | 'avatar_urls'> & {
	email?: string,
};
export type WithEmbeddedUser = {
	_embedded: {
		author: EmbeddedUser[],
	},
}
