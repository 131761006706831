import { sha256 } from 'js-sha256';
import { isEmpty, isError, filter, sortBy } from 'lodash';
import React, { useEffect } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import fetchInstances from '../actions/fetchInstances';
import { getColor } from '../lib/graph';
import { AppState } from '../reducers';
import { InstanceResponse } from '../types/rest-api';

import ErrorBlock from './ErrorBlock';
import InternalBlock from './InternalBlock';
import PinnedNotices, { Notice } from './PinnedNotices';
import Sidebar from './Sidebar';

const getInitials = ( name: string ) => name.split( ' ', 2 ).map( name => name.substring( 0, 1 ) ).join( '' ).toUpperCase();
const getInstanceColor = ( id: string ) => getColor( sha256.array( id )[ 0 ] );

const InstanceItem = ( props: { instance: InstanceResponse | Error } ) => {
	const { instance } = props;
	if ( isError( instance ) ) {
		return null;
	}

	return (
		<li className="overflow-hidden rounded-xl border border-gray-200">
			<Link
				className="flex items-center gap-x-4 bg-gray-50 p-6 hover:bg-gray-100"
				to={ `/i/${ instance.id }` }
			>
				<div
					className={ [
						getInstanceColor( instance.id ).bg,
						'flex-none flex h-12 w-12 rounded-lg text-white items-center justify-center',
					].join( ' ' ) }
				>
					{ getInitials( instance.name ) }
				</div>
				<p className="grow text-sm font-medium leading-6 text-gray-900">{ instance.name }</p>
				<ChevronRightIcon
					className="shrink-0"
					size={ 32 }
				/>
			</Link>
		</li>
	);
};

function Header() {
	const user = useSelector( ( state: AppState ) => state.currentUser.user );
	const hour = ( new Date() ).getHours();
	const greeting = ( hour < 4 || hour > 18 ) ? 'evening' : ( hour < 12 ) ? 'morning' : 'afternoon';

	return (
		<div className="bg-white mb-4">
			<div className="py-6 md:flex md:items-center md:justify-between border-b border-gray-200">
				<div className="min-w-0 flex-1">
					<div className="flex items-center">
						<img
							alt=""
							className="hidden h-16 w-16 rounded-full sm:block"
							src={ user?.avatar_urls[96] }
						/>
						<div>
							<div className="flex items-center">
								<img
									alt=""
									className="h-16 w-16 rounded-full sm:hidden"
									src={ user?.avatar_urls[96] }
								/>
								<h1 className="!m-0 !ml-3 !text-2xl !font-bold !leading-7 text-gray-900 sm:truncate sm:leading-9">
									Good { greeting }, { user?.first_name || user?.name || '' }
								</h1>
							</div>
							<dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
								<dd className="flex items-center text-sm font-medium text-gray-500 sm:mr-6">
									Select an instance to get started.
								</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

interface Props {
	onLogout(): void,
}

type StateProps = {
	error?: Error | null,
	globalNotices?: Notice[],
	instances?: {
		[ k: string ]: InstanceResponse | Error,
	},
	loading: boolean,
}

type DispatchProps = {
	onLoad(): Promise<any>,
}

export function Home( props: Props & StateProps & DispatchProps ) {
	const { error, instances, loading, onLoad } = props;

	useEffect( () => {
		if ( ! instances && ! loading && ! error ) {
			onLoad();
		}
	}, [ instances, loading, error, onLoad ] );

	const sorted = sortBy( filter( instances, i => ! isError( i ) ), i => i.name.toLowerCase() );

	return (
		<div className="InstanceContainer horizontal">
			<Sidebar />

			<div className="page-outer">
				{ props.globalNotices && (
					<PinnedNotices
						notices={ props.globalNotices || [] }
					/>
				) }

				<div className="page">
					<Header />

					{ ( ! instances || loading ) ? (
						<div>
							<ContainerDimensions>
								{ ( { width } ) => (
									<ContentLoader
										className=""
										height={ 98 }
										// @ts-ignore
										primaryColor="#f3f3f3"
										secondaryColor="#ecebeb"
										speed={ 2 }
										width={ width }
										{ ...props }
									>
										<rect
											height="98"
											rx="12"
											ry="12"
											width={ ( width - 64 ) / 3 }
											x="0"
											y="0"
										/>
										<rect
											height="98"
											rx="12"
											ry="12"
											width={ ( width - 64 ) / 3 }
											x={ ( ( width - 64 ) / 3 ) + 32 }
											y="0"
										/>
									</ContentLoader>
								) }
							</ContainerDimensions>
						</div>
					) : error ? (
						<ErrorBlock
							message={ error.message }
						/>
					) : isEmpty( instances ) ? (
						<div
							className="relative block w-full box-border rounded-lg border-2 border-dashed border-gray-300 p-12 text-center"
						>
							<p className="mt-2 block text-sm font-semibold text-gray-900">You don't have access to any instances. Contact your account manager.</p>
							<p className="mt-1 text-sm text-gray-500">Contact your account manager.</p>
						</div>
					) : (
						<ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
							{ sorted.map( instance => (
								<InstanceItem
									key={ instance.name }
									instance={ instance }
								/>
							) ) }
						</ul>
					) }

					<InternalBlock className="Home__internal !mt-4">
						<InternalBlock.Title>Internal Links</InternalBlock.Title>
						<ul className="list-disc px-4">
							<li>
								<Link to="/overview">Environment Overview</Link>
							</li>
							<li>
								<Link to="/eu-west-1/logs">Vantage Logs</Link>
							</li>
						</ul>
					</InternalBlock>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = ( state: AppState ): StateProps => ( {
	error: state.instances.archives.all?.error,
	globalNotices: state.currentUser.user?.notices,
	instances: state.instances.archives.all ? state.instances.data : undefined,
	loading: state.instances.archives.all?.loading || false,
} );

const mapDispatchToProps = ( dispatch: any ): DispatchProps => ( {
	onLoad: () => dispatch( fetchInstances() ),
} );

export default connect<{}, {}, Props, AppState>( mapStateToProps, mapDispatchToProps )( Home );
