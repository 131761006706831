import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';
import { RunningTasksResponse } from '../types/rest-api';

import { fetchTaskStream } from './';

interface RunningTasksUpdatedAction extends ReduxAction {
	type: 'RUNNING_TASKS_UPDATED';
	tasks: RunningTasksResponse[];
}

export type Action = RunningTasksUpdatedAction;

export default function fetchRunningTasks(
	api: API
): ThunkAction<Promise<RunningTasksResponse[]>, AppState, null, Action> {
	return ( dispatch, getStore ) =>
		api.get( '/stack/running-tasks' ).then( ( tasks: RunningTasksResponse[] ) => {
			const applications = getStore().applications;
			const existingTasks = getStore().tasks.byId;
			if ( tasks.length === 0 ) {
				return [];
			}
			dispatch( {
				type: 'RUNNING_TASKS_UPDATED',
				tasks,
			} );
			tasks.forEach( task => {
				if ( existingTasks[ task.id ] ) {
					return;
				}
				if ( ! applications[task.application] ) {
					return;
				}
				dispatch( fetchTaskStream( task.id, applications[task.application] ) )
					.catch( err => console.warn( err ) );
			} );
			return tasks;
		} ).catch( err => {
			console.warn( err );
			return [];
		} );
}
