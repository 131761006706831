import React from 'react';

import Button from './Button';
import ErrorBlock from './ErrorBlock';
import { Layout } from './Login';

interface Props {
	authError: string | null,
	onClickLogin(): void,
	onRetryLogin(): void,
}

export default function LoginOAuth( props: Props ) {
	return (
		<Layout>
			{ props.authError && (
				<div className="mt-4">
					<ErrorBlock
						message={ (
							<>
								<p>Unable to log you in. Please try again.</p>
								<p className="text-sm">{ props.authError }</p>
							</>
						) }
					/>
				</div>
			) }

			<h2 className="mt-8 text-2xl leading-9 tracking-tight text-gray-900">
				Sign in to the Altis Dashboard
			</h2>
			<p className="mt-6 text-sm leading-6 text-gray-500">
				Welcome to the Altis Dashboard. We're glad you're here.
			</p>

			<div className="mt-2 flex justify-between gap-2">
				<Button
					className="flex-1 justify-center"
					variant="primary"
					onClick={ props.onClickLogin }
				>
					Log in
				</Button>

				{ props.authError && (
					<Button
						className="flex-1 justify-center"
						variant="secondary"
						onClick={ props.onRetryLogin }
					>
						Try again
					</Button>
				) }
			</div>

			{ props.authError && (
				<details className="mt-4 text-sm">
					<summary>Error details</summary>

					<pre className="mt-2 text-xs whitespace-pre-wrap">
						{ props.authError }{ props.authError }{ props.authError }{ props.authError }
					</pre>
				</details>
			) }
		</Layout>
	);
}
