import React from 'react';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import { connect } from 'react-redux';

import { fetchTaskStream } from '../../actions';
import Avatar from '../Avatar';
import FormattedRelativeTime from '../FormattedRelativeTime';

import DeploySteps from './DeploySteps';

import './Deploy.css';

const LOG_REL = 'https://hm-stack.hm/log';
const getStreamId = props => {
	if ( props.data._links && props.data._links[ LOG_REL ] && props.data._links[ LOG_REL ][0] ) {
		return props.data._links[ LOG_REL ][0].href;
	}
	return `${ props.application.id }/deploys:${ props.data.id }`;
};

export class Deploy extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			expanded: props.isInProgress || ( ! props.isDeploying && props.isLatest ),
		};
	}

	componentDidMount() {
		this.maybeLoadStream();
	}

	componentDidUpdate() {
		this.maybeLoadStream();
	}

	static getDerivedStateFromProps( props, state ) {
		if ( ! state.expanded && props.isInProgress ) {
			return {
				expanded: true,
			};
		}

		return null;
	}

	maybeLoadStream() {
		if ( this.props.taskStream || ! this.state.expanded ) {
			return;
		}

		this.props.onLoadTaskStream( getStreamId( this.props ) );
	}

	onToggleExpanded = () => {
		this.setState( {
			expanded: ! this.state.expanded,
		} );
	};

	render() {
		const { data, isCurrent, taskStream } = this.props;

		return (
			<div className="UnifiedDeployListItem-Deploy">
				<div className="UnifiedDeployListItem-Deploy__summary">
					<button
						className="UnifiedDeployListItem-Deploy__expando"
						type="button"
						onClick={ this.onToggleExpanded }
					>
						{ this.state.expanded ? <ChevronDownIcon /> : <ChevronRightIcon /> }
					</button>

					<Avatar size={ 24 } user={ data.user } />

					<span className="UnifiedDeployListItem-Deploy__byline">
						<span className="date" title={ new Date( data.date ).toISOString() }>
							<FormattedRelativeTime value={ data.date } />
						</span>
						{ data.user && (
							<>
								{ ' by ' }
								<span className="ActivityListItem__username">{ data.user.name }</span>
							</>
						) }
					</span>

					<span className="UnifiedDeployListItem-Deploy__status">
						{ data.status === 'deploying' ? (
							<strong>In progress…</strong>
						) : isCurrent ? (
							<strong>Current</strong>
						) : (
							data.status !== 'active' ? data.status : null
						) }
					</span>
				</div>

				{ this.state.expanded && (
					<DeploySteps
						messages={ taskStream && taskStream.messages }
						phases={ taskStream && taskStream.phases }
					/>
				) }
			</div>
		);
	}
}

const mapStateToProps = ( state, props ) => {
	const stream = getStreamId( props );
	return {
		isDeploying: state.applicationDeploys[ props.application.id ] && state.applicationDeploys[ props.application.id ].creating,
		isInProgress: state.applicationDeploys[ props.application.id ] && state.applicationDeploys[ props.application.id ].stream === stream,
		taskStream: state.tasks.byId[ stream ],
	};
};

const mapDispatchToProps = ( dispatch, props ) => {
	return {
		onLoadTaskStream: stream => dispatch( fetchTaskStream( stream, props.application ) ),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)( Deploy );

