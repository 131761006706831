import startsWith from 'lodash/startsWith';
import uniqBy from 'lodash/uniqBy';
import React from 'react';

import FormattedRelativeTime from '../FormattedRelativeTime';
import SortableTable from '../SortableTable';

import { Alarm as AlarmType, ExtendedAppResponse } from './index';

import './Alarms.css';

type AlarmItem = {
	alarm: AlarmType;
	app: ExtendedAppResponse;
	short: string;
};

interface Props {
	applications: {
		[ id: string ]: ExtendedAppResponse;
	};
}

// Strip app from ID.
const shortId = ( alarmId: string, appId: string ) => {
	if ( ! startsWith( alarmId, appId + '-' ) ) {
		return alarmId;
	}
	return alarmId.substring( appId.length + 1 );
};

const Alarm = ( { alarm, app, short }: AlarmItem ) => {
	return (
		<tr key={ alarm.id }>
			<td>{ app.id }</td>
			<td>
				<abbr title={ alarm.id }>
					{ short }
				</abbr>
			</td>
			<td>
				<FormattedRelativeTime
					value={ alarm.date }
				/>
			</td>
			<td className="Alarms-Overview__desc">
				<abbr title={ alarm.description }>
					{ alarm.description }
				</abbr>
			</td>
		</tr>
	);
};

const formatAlarms = ( apps: ExtendedAppResponse[] ): AlarmItem[] => {
	const alarms = apps.reduce( ( alarms: AlarmItem[], app ) => {
		if ( ! app._embedded || ! app._embedded['https://hm-stack.hm/alarm'] ) {
			return alarms;
		}

		const appAlarms = app._embedded['https://hm-stack.hm/alarm'][0];
		if ( appAlarms.length <= 0 ) {
			return alarms;
		}

		return [
			...alarms,
			...appAlarms.map( alarm => ( {
				alarm,
				app,
				short: shortId( alarm.id, app.id ),
			} ) ),
		];
	}, [] );

	// Remove any duplicates.
	return uniqBy( alarms, 'alarm.id' );
};

const COLUMNS = {
	app: {
		sort: [ 'app.id' ],
		title: 'App',
	},
	alarm: {
		sort: [ 'short' ],
		title: 'Alarm',
	},
	date: {
		sort: [ 'alarm.date' ],
		title: 'Date',
	},
	description: {
		sort: [ 'alarm.description' ],
		title: 'Description',
	},
};

export default function Alarms( props: Props ) {
	const alarms = formatAlarms( Object.values( props.applications ) );
	return (
		<SortableTable
			columns={ COLUMNS }
			data={ alarms }
			defaultSort="app"
		>
			{ data => (
				<Alarm
					key={ data.alarm.id }
					alarm={ data.alarm }
					app={ data.app }
					short={ data.short }
				/>
			) }
		</SortableTable>
	);
}
