import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';

export default function SupportTicketMessageLoading( props ) {
	return (
		<ContainerDimensions>
			{ ( { width } ) => (
				<ContentLoader
					className="SupportTicketMessageLoading"
					height={ 140 }
					primaryColor="#f3f3f3"
					secondaryColor="#ecebeb"
					speed={ 2 }
					width={ width }
					{ ...props }
				>
					<circle cx="20" cy="20" r="20" />
					<rect height="140" rx="2" ry="2" width={ width - 50 } x="50" y="0" />
				</ContentLoader>
			) }
		</ContainerDimensions>
	);
}
