import { Action as ReduxAction } from 'redux';

export interface Action extends ReduxAction {
	type: 'SELECT_INSTANCE',
	id: string,
}

export default function selectInstance( id: string ): Action {
	return {
		type: 'SELECT_INSTANCE',
		id,
	};
}
