import React from 'react';
import { NavLink } from 'react-router-dom';

import './Link.css';

interface Props {
	activeIncludesChildren?: boolean,
	children: React.ReactNode,
	to: string,
}

export default function SidebarLink( props: Props ) {
	const isRelative = props.to.substr( 0, 1 ) === '/';

	return (
		<div className="Sidebar-Link">
			{ isRelative ? (
				<NavLink
					activeClassName="Sidebar-Link__link-active"
					className="Sidebar-Link__link"
					exact={ ! props.activeIncludesChildren }
					to={ props.to }
				>
					{ props.children }
				</NavLink>
			) : (
				<a
					className="Sidebar-Link__link"
					href={ props.to }
					// eslint-disable-next-line react/jsx-no-target-blank
					rel="noreferrer" target="_blank"
				>
					{ props.children }
				</a>
			) }
		</div>
	);
}
