import PropTypes from 'prop-types';
import React from 'react';

import ServerListItemLoading from './ServerListItemLoading';
import ServerStatusIndicator from './ServerStatusIndicator';

import './DatabaseServersList.css';

function Item( props ) {
	const classes = [
		'DatabaseServersList__item',
		props.isCluster ? 'DatabaseServersList__item--cluster' : 'DatabaseServersList__item--instance',
	];
	return (
		<div className={ classes.filter( Boolean ).join( ' ' ) }>
			<ServerStatusIndicator status={ props.status } />

			<div className="DatabaseServersList__item-details">
				<span className="DatabaseServersList__item-name">{ props.name }</span>
				<span className="DatabaseServersList__item-type">{ props.type }</span>
			</div>
		</div>
	);
}

export default function DatabaseServersList( { items, isLoading } ) {
	if ( isLoading ) {
		return (
			<div className="DatabaseServersList">
				<ServerListItemLoading />
			</div>
		);
	}

	if ( ! items.length ) {
		return null;
	}

	const server = items[0];
	const status = server.status === 'available' ? 'ok' : 'error';
	const isCluster = server.details && server.details.servers.length > 0;

	return (
		<div className="DatabaseServersList">
			<Item
				isCluster
				name={ server.id }
				status={ status }
				type={ isCluster ? `cluster (${ server.size })` : server.size }
			/>

			{ server.details && server.details.servers.map( server => (
				<Item
					key={ server.id }
					name={ server.id }
					status={ status }
					type={ server.role }
				/>
			) ) }
		</div>
	);
}

DatabaseServersList.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape( {
			id: PropTypes.string.isRequired,
			status: PropTypes.string,
			size: PropTypes.string,
		} )
	),
};
