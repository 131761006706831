import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';

export default function ActivityListItemLoading( props ) {
	return (
		<div style={ { width: '100%' } }>
			<ContainerDimensions>
				{ ( { width } ) => (
					<ContentLoader
						className="ActivityListItemLoading"
						height={ 350 }
						primaryColor="#f3f3f3"
						secondaryColor="#ecebeb"
						speed={ 2 }
						width={ width }
						{ ...props }
					>
						<rect height={ 30 } rx="2" ry="2" width={ 360 } x="0" y="0" />
						<rect height="18" rx="2" ry="2" width={ width - 200 } x="0" y="50" />
						<rect height="40" rx="2" ry="2" width={ width } x="0" y="108" />
						<rect height="240" rx="2" ry="2" width={ width } x="0" y="198" />
					</ContentLoader>
				) }
			</ContainerDimensions>
		</div>
	);
}
