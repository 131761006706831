import React from 'react';
import { Link, withRouter } from 'react-router-dom';

export default withRouter( function ( props ) {
	let path = props.location.pathname;
	if ( path[path.length - 1] === '/' ) {
		path = path.substring( -1 );
	}
	return <Link to={ path + props.to }>{ props.children }</Link>;
} );
