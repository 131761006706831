import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import FormattedRelativeTime from './FormattedRelativeTime';
import Pill from './Pill';
import SupportTicketStatus from './SupportTicket/Status';

import './SupportListItem.css';

export default function SupportListItem( props ) {
	return (
		<Link className="SupportListItem" to={ `/support/ticket/${props.id}/` }>
			<div className="SupportListItem__status">
				<SupportTicketStatus status={ props.status } />
			</div>

			<span className="SupportListItem__main">
				<span className="SupportListItem__title">
					{ props.title }
					{ props.priority === 'urgent' && (
						<span className="priority">
							<Pill color="red">Urgent</Pill>
						</span>
					) }
				</span>
				<span className="SupportListItem__details">
					{ props.assignee && (
						`Assigned to ${ props.assignee.name }`
					) }
				</span>
			</span>

			<div className="SupportListItem__application">
				{ props.applications.join( ', ' ) }
			</div>

			<FormattedRelativeTime
				className="date"
				value={ props.date }
			/>
		</Link>
	);
}

SupportListItem.propTypes = {
	id: PropTypes.string.isRequired,
	applications: PropTypes.arrayOf( PropTypes.string ),
	status: PropTypes.oneOf( [ 'open', 'pending', 'new', 'hold', 'solved', 'closed' ] ),
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	author: PropTypes.shape( {
		name: PropTypes.string.isRequired,
		avatar_urls: PropTypes.shape( {
			'96': PropTypes.string,
		} ).isRequired,
	} ).isRequired,
	assignee: PropTypes.shape( {
		name: PropTypes.string.isRequired,
		avatar_urls: PropTypes.shape( {
			'96': PropTypes.string,
		} ).isRequired,
	} ),
	priority: PropTypes.oneOf( [ '-', 'low', 'normal', 'high', 'urgent' ] ),
	date: PropTypes.string.isRequired,
};
