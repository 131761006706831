import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { fetchTaskStream } from '../../actions';
import { ApplicationResponse, NodejsResponse } from '../../api';
import { collectBuilds, Build, Deploy } from '../../lib/builds';
import { withApiData } from '../../lib/with-api-data';
import { AppState } from '../../reducers';
import { ApiResponse } from '../../types/api';
import ApplicationBuildCacheButton from '../ApplicationBuildCacheButton';
import ApplicationBuildRebuildButton from '../ApplicationBuildRebuildButton';
import Config from '../ApplicationDeploys/Config';
import ErrorBlock from '../ErrorBlock';
import OutlineButton from '../OutlineButton';
import PageTitle from '../PageTitle';
import UnifiedDeployList from '../UnifiedDeployList';

interface OwnProps extends RouteComponentProps {
	application: ApplicationResponse,
}

type ApiProps<T> = T & {
	fetch: Window['fetch'],
	refreshData(): void,
	invalidateDataForUrl( url: string ): void,
};

interface StateProps {
	deployTasks: any,
	buildInProgress: AppState['applicationBuilds'][0],
}

interface ConnectedProps {
	builds: ApiResponse<Build[]>,
	deploys: ApiResponse<Deploy[]>,
	dispatch: Dispatch | ( () => Promise<void> ),
	settings: ApiResponse<NodejsResponse>,
}

type Props = OwnProps & ApiProps<StateProps> & ConnectedProps;

function ApplicationDeploys( props: Props ) {
	const [ deploying, setDeploying ] = useState<string | null>( null );
	const dispatch = useDispatch();

	const onRefresh = () => {
		props.invalidateDataForUrl( `/stack/applications/${ props.application.id }/nodejs/builds` );
		props.invalidateDataForUrl( `/stack/applications/${ props.application.id }/nodejs/deploys` );
	};
	const onRefreshDeploys = () => {
		props.invalidateDataForUrl( `/stack/applications/${ props.application.id }/nodejs/deploys` );
	};

	const onDeploy = async ( build: string ) => {
		setDeploying( build );
		const res = await props.fetch( `/stack/applications/${ props.application.id }/nodejs/deploys`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify( {
				build: build,
				stream: true,
			} ),
		} );

		// Trigger the task stream immediately, to avoid a race later.
		const data = await res.json();
		dispatch( fetchTaskStream( data.log, props.application ) );

		// Refresh deploy data to get our new deploy.
		onRefreshDeploys();
	};

	const onClearCache = async () => {
		await props.fetch( `/stack/applications/${ props.application.id }/nodejs/builds/cache`, {
			method: 'DELETE',
		} );
	};

	// Find any existing deployments in progress.
	useEffect( () => {
		if ( ! props.deploys.data ) {
			return;
		}
		const beingDeployed = props.deploys.data?.find( ( deploy: any ) => {
			return deploy.status === 'pending';
		} );
		if ( beingDeployed ) {
			setDeploying( beingDeployed.build );
		} else {
			setDeploying( null );
		}
	}, [ props.deploys, setDeploying ] );

	// Merge in any deploy tasks into the deploys list
	let deploys = props.deploys.data ? props.deploys.data.slice() : [];
	if ( props.application && deploys ) {
		deploys = deploys.map( ( deploy: any ) => ( {
			...deploy,
			deployTask: props.deployTasks[`${props.application.id}/nodejs-deploys:${deploy.id}`],
		} ) );
	}

	// Current (i.e. active) deploy is just the latest successful one.
	const currentDeploy = deploys ? deploys[0] : null;

	const builds = collectBuilds(
		props.builds.data || [],
		props.deploys.data || []
	);

	return (
		<div className="ApplicationDeploys">
			<PageTitle title="Release" />

			{ props.settings.isLoading ? (
				<p>Loading...</p>
			) : props.settings.error ? (
				<ErrorBlock message={ props.settings.error.message } />
			) : (
				<Config
					data={ props.settings.data?.['git-deployment'] }
					settingsUrl={ `/i/${ props.application.instance }/e/${ props.application.id }/settings/environment` }
				>
					<ApplicationBuildCacheButton
						application={ props.application }
						showClearBuildCache={ props.application.can.develop }
						onClear={ onClearCache }
					/>

					<ApplicationBuildRebuildButton
						application={ props.application }
					/>
				</Config>
			) }
			<header className="ApplicationDeploys__header">
				<h2>Latest Builds</h2>
				<OutlineButton
					name="Refresh"
					onClick={ onRefresh }
				/>
			</header>

			{ props.deploys.error && ! props.deploys.isLoading ? (
				<ErrorBlock message={ props.deploys.error.message } />
			) : (
				<UnifiedDeployList
					application={ props.application }
					canDeploy={ props.application.can.develop }
					// @ts-ignore
					current={ currentDeploy ? currentDeploy : null }
					deploying={ deploying }
					inProgress={ props.buildInProgress || null }
					isDeploying={ deploying !== null }
					isLoading={ props.deploys.isLoading }
					// @ts-ignore
					items={ builds || [] }
					renderBuildDescription={ ( description: string, build: Build ) => (
						// @ts-ignore
						build.nodejs_version ? (
							// @ts-ignore
							`${ description } | Node v${ build.nodejs_version }`
						) : description
					) }
					onDeploy={ onDeploy }
				/>
			) }
		</div>
	);
}

const ApplicationDeploysWithApiData = withApiData( ( props: any ) => {
	return {
		builds: `/stack/applications/${props.match.params.application}/nodejs/builds`,
		deploys: `/stack/applications/${props.match.params.application}/nodejs/deploys`,
		settings: `/stack/applications/${props.match.params.application}/nodejs`,
	};
} )( ApplicationDeploys );

const mapStateToProps = ( state: AppState, props: OwnProps ): StateProps => ( {
	// @todo check if used?
	deployTasks: state.tasks.byId,

	// @todo change to nodejs builds/deploys
	buildInProgress: state.applicationBuilds[ props.application.id ],
} );

const mapDispatchToProps = ( dispatch: any ) => {
	return {
		onToggleDeployDetails: function ( application: any, deploy: any ) {
			if ( ! deploy.deployTask ) {
				dispatch( fetchTaskStream( `${application.id}/deploys:${deploy.id}`, application ) );
			}
			dispatch( {
				type: 'TOGGLE_DEPLOY_DETAILS',
				payload: {
					id: deploy.id,
				},
			} );
		},
		dispatch( ...args: any[] ) {
			return dispatch( ...args );
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)( ApplicationDeploysWithApiData );
