import React from 'react';

export enum Step {
	registration,
	verification,
	switchover,
}
const steps = [
	{
		id: Step.registration,
		name: 'Registration',
		description: 'Select your domains',
	},
	{
		id: Step.verification,
		name: 'Verification',
		description: 'Add verification records',
	},
	{
		id: Step.switchover,
		name: 'Switch-over',
		description: 'Switch live traffic to Altis',
	},
];

function classNames( ...classes: ( string | boolean )[] ) {
	return classes.filter( Boolean ).join( ' ' );
}

type StepsProps  = {
	current: Step,
}

export default function DomainSteps( props: StepsProps ) {
	const { current } = props;

	return (
		<div className="lg:border-b lg:border-t lg:border-gray-200">
			<nav aria-label="Progress">
				<ol
					className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
				>
					{ steps.map( ( step, stepIdx ) => (
						<li
							key={ step.id }
							className="relative overflow-hidden lg:flex-1"
						>
							<div
								className={ classNames(
									stepIdx === 0 && 'rounded-t-md border-b-0',
									stepIdx === steps.length - 1 && 'rounded-b-md border-t-0',
									'overflow-hidden border border-gray-200 lg:border-0'
								) }
							>
								<div
									aria-current={ current === step.id ? 'step' : undefined }
									className="group"
								>
									{ current === step.id && (
										<span
											aria-hidden="true"
											className="absolute left-0 top-0 h-full w-1 bg-indigo-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
										/>
									) }
									<span
										className={ classNames(
											stepIdx !== 0 && 'lg:pl-7',
											'flex items-start px-4 py-3 text-sm font-medium'
										) }
									>
										<span className="flex-shrink-0">
											{ current > step.id ? (
												<span className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
													<svg
														aria-hidden="true"
														className="h-6 w-6 text-white"
														fill="currentColor"
														viewBox="0 0 24 24"
													>
														<path
															clip-rule="evenodd"
															d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
															fill-rule="evenodd"
														/>
													</svg>
												</span>
											) : current === step.id ? (
												<span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600">
													<span className="text-indigo-600">{ step.id + 1 }</span>
												</span>
											) : (
												<span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
													<span className="text-gray-500">{ step.id + 1 }</span>
												</span>
											) }
										</span>
										<span className="ml-4 mt-0.5 flex min-w-0 flex-col">
											<span
												className={ classNames(
													current === step.id && 'text-blue-600',
													'text-sm font-medium'
												) }
											>
												{ step.name }
											</span>
											<span className="text-xs font-light text-gray-500">{ step.description }</span>
										</span>
									</span>
								</div>

								{ /* Separator */ }
								{ stepIdx !== 0 ? (
									<div
										aria-hidden="true"
										className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
									>
										<svg
											className="h-full w-full text-gray-300"
											fill="none"
											preserveAspectRatio="none"
											viewBox="0 0 12 82"
										>
											<path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
										</svg>
									</div>
								) : null }
							</div>
						</li>
					) ) }
				</ol>
			</nav>
		</div>
	);
}
