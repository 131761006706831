import { Switch } from '@headlessui/react';
import React from 'react';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import LockIcon from 'react-feather/dist/icons/lock';
import { Link } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import { ReactComponent as LogoSVG } from '../logo-small.svg';
import SettingsGroup from '../SettingsGroup';
import useId from '../useId';

interface Props {
	application: ApplicationResponse,
}

// Maps version to supported-ness.
const PHP_VERSIONS: { [ k: string ]: boolean } = {
	'7.4': false,
	'8.0': true,
	'8.1': true,
	'8.2': true,
	'8.3': true,
};

export default function Environment( props: Props ) {
	const id = useId();

	const version = parseInt( props.application['altis-version']?.split( '. ' )[0] || '0', 10 );
	const hasAfterburnerStatus = Object.hasOwn( props.application.has, 'afterburner' );
	const hasAfterburner = !! props.application.has.afterburner;
	const canChangeAfterburner = false;
	const isCompatibleWithAfterburner = version >= 16;

	const hasElasticsearchStatus = Object.hasOwn( props.application.has, 'elasticsearch' );
	const hasElasticsearch = !! props.application.has.elasticsearch;
	const hasNodejs = !! props.application.has.nodejs;

	return (
		<div className="space-y-8">
			<SettingsGroup
				description={ (
					<>
						The application server runs your codebase in PHP.

						{ ' ' }

						<a
							href="https://docs.altis-dxp.com/cloud/architecture/"
							rel="noopener noreferrer"
						>
							Learn more <ExternalLinkIcon className="w-4 -mt-1" />
						</a>
					</>
				) }
				footer={ (
					<p className="text-sm text-gray-500">
						Need to change your settings? <Link to="/support/new/task">Contact support</Link>. (Self-service coming soon!)
					</p>
				) }
				title="Application"
			>
				<div className="my-3">
					<label
						className="block text-sm font-medium text-gray-700"
						htmlFor={ `${ id }-php` }
					>
						PHP Version
					</label>
					<div className="mt-1">
						<select
							className="block w-full rounded-md disabled:bg-gray-50 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
							disabled
							id={ `${ id }-php` }
							value={ props.application['php-version'] }
						>
							{ Object.keys( PHP_VERSIONS ).map( ver => (
								<option
									key={ ver }
									value={ ver }
								>
									{ ver }
									{ ( ! PHP_VERSIONS[ ver ] ) && (
										' (Unsupported)'
									) }
								</option>
							) ) }
						</select>
					</div>
				</div>
				{ hasAfterburnerStatus && (
					<Switch.Group as="div" className="flex items-center justify-between my-3">
						<span className="flex flex-grow flex-col">
							<Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
								Enable Afterburner 🚀
							</Switch.Label>
							{ isCompatibleWithAfterburner ? (
								<Switch.Description as="span" className="text-sm text-gray-500">
									Switch on Afterburner to get blazing-fast performance on your site.

									{ ' ' }

									<a
										href="https://docs.altis-dxp.com/cloud/afterburner/"
										rel="noopener noreferrer"
									>
										Learn more <ExternalLinkIcon className="w-4 -mt-1" />
									</a>
								</Switch.Description>
							) : (
								<Switch.Description as="span" className="text-sm text-gray-500">
									Upgrade to v16 to unlock blazing-fast performance with Afterburner.

									{ ' ' }

									<a
										href="https://docs.altis-dxp.com/cloud/afterburner/"
										rel="noopener noreferrer"
									>
										Learn more <ExternalLinkIcon className="w-4 -mt-1" />
									</a>
								</Switch.Description>
							) }
						</span>
						<Switch
							checked={ hasAfterburner }
							className={ [
								hasAfterburner ? 'bg-blue-600' : 'bg-gray-200',
								'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
								canChangeAfterburner ? '' : 'cursor-not-allowed opacity-30',
							].filter( Boolean ).join( ' ' ) }
							disabled
						>
							<span className="sr-only">Use setting</span>
							<span
								aria-hidden="true"
								className={ [
									hasAfterburner ? 'translate-x-5' : 'translate-x-0',
									'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
								].filter( Boolean ).join( ' ' ) }
							/>
						</Switch>
					</Switch.Group>
				) }
				<div className="my-3">
					<span className="block text-sm font-medium text-gray-700">
						Altis Version
						<LogoSVG
							aria-hidden="true"
							className="ml-1.5 h-3 w-3 flex-shrink-0 text-gray-400"
						/>
					</span>
					<div className="mt-1 text-sm text-gray-500">
						v{ version }

						{ ' ' }

						<span className="text-gray-400">
							(Upgrade to new versions of Altis via Composer.)
						</span>
					</div>
				</div>
			</SettingsGroup>

			{ hasNodejs && (
				<SettingsGroup
					footer={ hasNodejs ? null : (
						<p className="text-sm text-gray-500">
							Want to upgrade and experience Node.js? <Link to={ `/i/${ props.application['altis-instance'] }/settings/subscription` }>Contact your account manager</Link>.
						</p>
					) }
					title="Node.js"
				>
					<Switch.Group as="div" className="flex items-center justify-between my-3">
						<span className="flex flex-grow flex-col">
							<Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
								Enable Node.js
							</Switch.Label>
							<Switch.Description as="span" className="text-sm text-gray-500">
								Build headless applications with Node.js, right alongside your Altis site.

								{ ' ' }

								<a
									href="https://docs.altis-dxp.com/cloud/nodejs/"
									rel="noopener noreferrer"
								>
									Learn more <ExternalLinkIcon className="w-4 -mt-1" />
								</a>
							</Switch.Description>
						</span>
						<Switch
							checked={ hasNodejs }
							className={ [
								hasNodejs ? 'bg-blue-600' : 'bg-gray-200',
								'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
								'cursor-not-allowed opacity-30',
							].filter( Boolean ).join( ' ' ) }
							disabled
						>
							<span className="sr-only">Use setting</span>
							<span
								aria-hidden="true"
								className={ [
									hasNodejs ? 'translate-x-5' : 'translate-x-0',
									'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
								].filter( Boolean ).join( ' ' ) }
							/>
						</Switch>
					</Switch.Group>
				</SettingsGroup>
			) }

			{ hasElasticsearchStatus && (
				<SettingsGroup
					footer={ hasElasticsearch ? null : (
						<p className="text-sm text-gray-500">
							Want to upgrade and experience Altis Search? <Link to={ `/i/${ props.application['altis-instance'] }/settings/subscription` }>Contact your account manager</Link>.
						</p>
					) }
					title="Search"
				>
					<Switch.Group as="div" className="flex items-center justify-between my-3">
						<span className="flex flex-grow flex-col">
							<Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
								Enable Altis Search
							</Switch.Label>
							<Switch.Description as="span" className="text-sm text-gray-500">
								Get Altis Search to take content discovery up to the next level.

								{ ' ' }

								<a
									href="https://docs.altis-dxp.com/search/"
									rel="noopener noreferrer"
								>
									Learn more <ExternalLinkIcon className="w-4 -mt-1" />
								</a>
							</Switch.Description>
						</span>
						<Switch
							checked={ hasElasticsearch }
							className={ [
								hasElasticsearch ? 'bg-blue-600' : 'bg-gray-200',
								'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
								'cursor-not-allowed opacity-30',
							].filter( Boolean ).join( ' ' ) }
							disabled
						>
							<span className="sr-only">Use setting</span>
							<span
								aria-hidden="true"
								className={ [
									hasElasticsearch ? 'translate-x-5' : 'translate-x-0',
									'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
								].filter( Boolean ).join( ' ' ) }
							/>
						</Switch>
					</Switch.Group>
					{ hasElasticsearch && props.application['elasticsearch-version'] && (
						<div className="my-3">
							<span className="block text-sm font-medium text-gray-700">
								Version
							</span>
							<div className="mt-1 text-sm text-gray-500">
								Elasticsearch { props.application['elasticsearch-version'] }
							</div>
						</div>
					) }
				</SettingsGroup>
			) }

			<SettingsGroup
				description="Altis manages your platform and infrastructure on your behalf."
				title="Platform"
			>
				<div className="my-3">
					<dl className="divide-y divide-gray-100">
						<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Infrastructure Version</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ props.application.version }</dd>
						</div>
						<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">AMI</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ props.application.ami }</dd>
						</div>
						<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Environment Type</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ props.application['environment-type'] }</dd>
						</div>
						<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Region</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ props.application.region }</dd>
						</div>
						{ props.application._internal && props.application._internal.tfc && (
							<>
								<div className="bg-yellow-100 -mx-4 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										<LockIcon size={ 14 } />
										{ ' ' }
										Cloud Provider
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ props.application._internal.tfc.provider }</dd>
								</div>
								<div className="bg-yellow-100 -mx-4 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										<LockIcon size={ 14 } />
										{ ' ' }
										Workspace ID
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ props.application._internal.tfc.id }</dd>
								</div>
							</>
						) }
					</dl>
				</div>
			</SettingsGroup>
		</div>
	);
}
