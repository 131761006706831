import type { ListIteratee } from 'lodash';
import sortBy from 'lodash/sortBy';
import React, { useState, ReactElement } from 'react';

import './SortableTable.css';

export interface Columns<T> {
	[ key: string ]: {
		title: string;
		sort?: ListIteratee<T>[];
		className?: string;
	}
}

interface Props<T> {
	children: ( item: T ) => ReactElement;
	className?: string;
	columns: Columns<T>;
	data: T[];
	defaultSort: string;
	defaultReverse?: boolean;
	isDense?: boolean,
	isFixed?: boolean,
	isLined?: boolean,
}

export default function SortableTable<T>( props: Props<T> ) {
	const { children, columns, data, defaultSort } = props;

	const [ sort, setSort ] = useState( defaultSort );
	const [ reverse, setReverse ] = useState( props.defaultReverse || false );
	const sortKeys = ( columns[ sort ] && columns[ sort ].sort ) || [ sort ];
	const sorted = sortBy( data, [ ...sortKeys, defaultSort ] );
	if ( reverse ) {
		sorted.reverse();
	}

	return (
		<table
			className={
				[
					'SortableTable',
					props.isDense && 'SortableTable--dense',
					props.isFixed && 'SortableTable--fixed',
					props.isLined && 'SortableTable--lined',
					props.className,
				].filter( Boolean ).join( ' ' )
			}
		>
			<thead>
				<tr>
					{ Object.keys( columns ).map( key => (
						<th
							key={ key }
							className={ columns[ key ].className }
						>
							<button
								type="button"
								onClick={ () => key === sort ? setReverse( ! reverse ) : setSort( key ) }
							>
								{ columns[ key ].title }
								<span>{ sort === key ? ( reverse ? '▲' : '▼' ) : '' }</span>
							</button>
						</th>
					) ) }
				</tr>
			</thead>
			<tbody>
				{ sorted.map( item => (
					children( item as T )
				) ) }
			</tbody>
		</table>
	);
}
