import React from 'react';

import { ApiResponse } from '../../types/api';
import ApplicationLogsWarning from '../ApplicationLogsWarning';
import NoItemsFoundBlock from '../NoItemsFoundBlock';
import SortableTable, { Columns } from '../SortableTable';

import Timestamp from './Timestamp';
import { BasicLogEntry } from './util';

import './BasicTable.css';

const COLUMNS: Columns<BasicLogEntry> = {
	date: {
		title: 'Date',
		className: 'ApplicationLogs-BasicTable__date',
	},
	message: {
		title: 'Message',
		className: 'ApplicationLogs-BasicTable__message',
	},
};

interface LogTableProps {
	data: BasicLogEntry[],
}

export default function BasicTable( props: LogTableProps ) {
	if ( ! props.data.length ) {
		return (
			<NoItemsFoundBlock
				message="🌴 Observe a beautiful, blank, empty paradise, free of distraction, worries of the world, and log entries. (Zero results found.)"
			/>
		);
	}

	return (
		<SortableTable<BasicLogEntry>
			className="ApplicationLogs-Database__table"
			columns={ COLUMNS }
			data={ props.data }
			defaultReverse
			defaultSort="date"
			isDense
			isFixed
		>
			{ item => (
				<tr
					key={ item.id }
				>
					<td className="ApplicationLogs-BasicTable__date">
						<Timestamp
							date={ new Date( item.date ) }
						/>
					</td>
					<td className="ApplicationLogs-BasicTable__message">
						{ item.message }
					</td>
				</tr>
			) }
		</SortableTable>
	);
}

interface WarningProps {
	data: ApiResponse<BasicLogEntry[]>,
}

export function BasicTableWithWarning( props: WarningProps ) {
	return (
		<ApplicationLogsWarning
			logs={ props.data }
			renderLogs={ ( data: BasicLogEntry[] ) => (
				<BasicTable
					data={ data }
				/>
			) }
		/>
	);
}
