import { Action as ReduxAction } from 'redux';

import { Action as UpdateCurrentUserAction } from '../actions/updateCurrentUser';
import { UserResponse } from '../types/rest-api';

const defaultCapabilities = {
	read_tasks: false,
	read_regions: false,
	read_other_tasks: false,
	read_applications: false,
	read_other_applications: false,
	read_amis: false,
	create_amis: false,
	edit_applications: false,
	edit_tasks: false,
	edit_other_applications: false,
	edit_lifecycle_hook: false,
	create_messages: false,
	manage_support_tickets: false,
	ssh_applications_sandbox: false,
	read_user_ssh_public_key: false,
	administrator: false,
	read_activity: false,
};

type Capabilities = typeof defaultCapabilities;

interface State {
	user?: UserResponse;
	capabilities: Capabilities;
	loading: boolean;
}

interface CurrentUserLoadingAction extends ReduxAction {
	type: 'CURRENT_USER_LOADING';
}

interface CurrentUserLogoutAction extends ReduxAction {
	type: 'CURRENT_USER_LOGOUT';
}

export default function currentUser(
	state: State = {
		user: undefined,
		capabilities: defaultCapabilities,
		loading: false,
	},
	action: UpdateCurrentUserAction | CurrentUserLoadingAction | CurrentUserLogoutAction
) : State {
	switch ( action.type ) {
		case 'CURRENT_USER_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'CURRENT_USER_UPDATED': {
			return {
				...state,
				user: action.payload.user,
				capabilities: action.payload.user.capabilities as Capabilities,
				loading: false,
			};
		}
		case 'CURRENT_USER_LOGOUT': {
			return {
				...state,
				user: undefined,
				capabilities: defaultCapabilities,
			};
		}
		default:
			return state;
	}
}
