import { DateTime } from 'luxon';
import qs from 'qs';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import {
	Bar,
	Cell,
	Tooltip,
	TooltipProps,
} from 'recharts';

import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';

import Graph, { Metric } from './Graph';
import Loader from './Loader';
import { formatViews } from './util';

const CustomTooltip = ( { active, payload, label }: TooltipProps<number, string> ) => {
	if ( ! active || ! payload || ! payload[0]?.payload ) {
		return null;
	}

	const item = payload[0].payload as Metric;

	return (
		<div className="InstanceMetrics-Graph__tooltip">
			<p className="InstanceMetrics-Graph__tooltip-value">
				<FormattedNumber value={ item.value } /> page requests
			</p>
			<p className="InstanceMetrics-Graph__tooltip-date">
				{ DateTime.fromMillis( label ).toLocaleString( {
					month: 'long',
					day: 'numeric',
					year: 'numeric',
				} ) }
			</p>
		</div>
	);
};

interface Props {
	metrics?: ApiResponse<Metric[]>,
	period: {
		start: DateTime,
		end: DateTime,
	},
	showTitle?: boolean,
}

export function RequestsGraph( props: Props ) {
	const { metrics } = props;
	if ( ! metrics || metrics.isLoading ) {
		return (
			<Loader />
		);
	}
	if ( metrics.isLoading || metrics.error ) {
		return null;
	}

	const today = DateTime.utc().startOf( 'day' );
	const isIncomplete = ( date: number ) => DateTime.fromMillis( date, { zone: 'utc' } ) >= today;
	const parsedData = metrics.data.map( item => ( {
		...item,
		parsedDate: DateTime.fromISO( item.date ).toMillis(),
	} ) ).filter( item => item.value !== null );
	const total = metrics.data.reduce( ( total, item ) => total + item.value, 0 );

	return (
		<div className="TrafficGraph">
			{ props.showTitle && (
				<p className="InstanceMetrics-Graph__total">
					<span className="InstanceMetrics-Graph__total-label">Total:</span>
					{ ' ' }
					<span className="InstanceMetrics-Graph__total-value">
						<FormattedNumber value={ total } />
					</span>
				</p>
			) }
			<Graph
				formatY={ formatViews }
				metrics={ props.metrics }
				period={ props.period }
			>
				<Tooltip
					content={ CustomTooltip }
					position={ {
						// @ts-ignore
						x: 'auto',
						y: 370,
					} }
				/>

				<Bar
					dataKey="value"
					fill="#152A4E"
					stackId="views"
				>
					{ parsedData.map( ( entry, index ) => (
						<Cell
							key={ `cell-${index}` }
							cursor="pointer"
							fill={ isIncomplete( entry.parsedDate ) ? '#afe9be' : '#5cd179' }
							stroke={ isIncomplete( entry.parsedDate ) ? '#5cd179' : undefined }
							strokeDasharray="4"
						/>
					) ) }
				</Bar>
			</Graph>
		</div>
	);
}

type GraphableProps = {
	application: string,
	period: {
		start: DateTime,
		end: DateTime,
	},
	showTitle?: boolean,
}

export default withApiData( ( props: GraphableProps ) => {
	if ( ! props.application ) {
		return {};
	}

	const params = {
		from: props.period.start.toISO(),
		to: props.period.end.toISO(),
		period: 60 * 60 * 24,
	};
	return {
		metrics: `/stack/applications/${ props.application }/metrics/page-requests?${ qs.stringify( params ) }`,
	};
} )( RequestsGraph );
