import React from 'react';
import BaseModal from 'react-modal';

import './Modal.css';
import OutlineButton from './OutlineButton';

interface Props {
	children: React.ReactNode,
	className?: string,
	closeText?: string,
	isOpen: boolean,
	title?: string,
	wrapClassName?: string,
	onClose(): void,
}

export default function Modal( props: Props ) {
	return (
		<BaseModal
			className={ `Modal ${ props.className || '' }` }
			isOpen={ props.isOpen }
			overlayClassName="Modal__overlay"
			shouldCloseOnOverlayClick
			onRequestClose={ props.onClose }
		>
			{ props.title && (
				<header className="Modal__header">
					<h2>{ props.title }</h2>

					<OutlineButton
						name={ props.closeText || 'Close' }
						onClick={ props.onClose }
					/>
				</header>
			) }
			<div className={ `Modal__wrap ${ props.wrapClassName || '' }` }>
				{ props.children }
			</div>
		</BaseModal>
	);
}
