import PropTypes from 'prop-types';
import React from 'react';

import ActivityListItemLoading from './ActivityListItemLoading';
import UnifiedDeployListItem, { deployShape } from './UnifiedDeployListItem';
import InProgressBuild from './UnifiedDeployListItem/InProgressBuild';

export default function UnifiedDeployList( props ) {
	const { application, canDeploy, canRedeploy, current, deploying, inProgress, items, isLoading, renderBuildDescription } = props;

	if ( isLoading ) {
		return (
			<div className="DeployList">
				<ActivityListItemLoading />
				<ActivityListItemLoading />
				<ActivityListItemLoading />
				<ActivityListItemLoading />
				<ActivityListItemLoading />
			</div>
		);
	}

	// Find the current build, so that we can pin it to the top.
	const currentBuild = items.find( item => item.deploys.find( deploy => deploy.id === current.id ) );

	// If we're deploying, pin that build instead.
	let pinnedBuild = currentBuild;
	if ( deploying ) {
		pinnedBuild = items.find( item => item.id === deploying );
	}

	return (
		<div className="DeployList">
			{ /* Pin the current build to the top */ }
			{ pinnedBuild && items[0] !== pinnedBuild && (
				<UnifiedDeployListItem
					application={ application }
					build={ pinnedBuild.build }
					canDeploy={ canDeploy }
					canRedeploy={ canRedeploy }
					current={ current }
					currentBuild={ currentBuild ? currentBuild.build : null }
					deploying={ deploying && pinnedBuild.build.id === deploying }
					deploys={ pinnedBuild.deploys }
					isDeploying={ props.isDeploying }
					pinned
					renderBuildDescription={ renderBuildDescription }
					onDeploy={ () => props.onDeploy( pinnedBuild.build.id ) }
				/>
			) }

			{ /* In progress builds */ }
			{ inProgress && inProgress.creating && (
				<InProgressBuild
					application={ application }
					stream={ inProgress.stream }
				/>
			) }

			{ items.map( item => (
				<UnifiedDeployListItem
					key={ item.id }
					application={ application }
					build={ item.build }
					canDeploy={ canDeploy }
					canRedeploy={ canRedeploy }
					current={ current }
					currentBuild={ currentBuild ? currentBuild.build : null }
					deploying={ deploying && item.id === deploying }
					deploys={ item.deploys }
					isDeploying={ props.isDeploying }
					renderBuildDescription={ renderBuildDescription }
					onDeploy={ () => props.onDeploy( item.build.id ) }
				/>
			) ) }
		</div>
	);
}

UnifiedDeployList.propTypes = {
	application: PropTypes.object.isRequired,
	canDeploy: PropTypes.bool.isRequired,
	canRedeploy: PropTypes.bool,
	current: deployShape,
	deploying: PropTypes.string,
	inProgress: PropTypes.shape( {
		creating: PropTypes.bool,
		error: PropTypes.any,
		stream: PropTypes.string,
	} ),
	items: PropTypes.arrayOf(
		PropTypes.shape( {
			id: PropTypes.string.isRequired,
			build: PropTypes.object,
			deploys: PropTypes.arrayOf( deployShape ),
		} )
	).isRequired,
	isLoading: PropTypes.bool,
	renderBuildDescription: PropTypes.func,
};
