import React from 'react';

import './Avatar.css';

const defaultAvatarUrls = {
	'24': 'https://secure.gravatar.com/avatar/83888eb8aea456e4322577f96b4dbaab?s=24&d=mm&r=g',
	'48': 'https://secure.gravatar.com/avatar/83888eb8aea456e4322577f96b4dbaab?s=48&d=mm&r=g',
	'96': 'https://secure.gravatar.com/avatar/83888eb8aea456e4322577f96b4dbaab?s=96&d=mm&r=g',
};

// Find the first non-null URL of this size or larger.
const findBestMatch = ( urls, reqSize ) => {
	const key = Object.keys( urls ).find( size => Number( size ) >= reqSize && urls[ size ] );
	return key && urls[ key ];
};

export default function Avatar( props ) {
	const avatarUrls = props.user.avatar_urls || {};

	const url = findBestMatch( avatarUrls, props.size ) || findBestMatch( defaultAvatarUrls, props.size );

	return (
		<div className="Avatar" style={ {
			width: props.size,
			height: props.size,
			minWidth: props.size,
			minHeight: props.size,
			overflow: 'hidden',
		} }>
			<img
				alt={ props.user.name }
				height={ props.size }
				src={ url }
				style={ { borderRadius: props.size / 2 } }
				title={ props.user.name }
				width={ props.size }
			/>
			<div className="shadow" style={ { borderRadius: props.size / 2 } } />
		</div>
	);
}

Avatar.defaultProps = {
	size: 20,
};
