import PropTypes from 'prop-types';
import React from 'react';
import IconLoop from 'react-feather/dist/icons/refresh-ccw';

import Spin from './Spin';

export default function ActivityIndicator( props ) {
	return (
		<div
			className="ActivityIndicator"
			style={ {
				width: `${props.size}px`,
				height: `${props.size}px`,
				padding: 2,
			} }
		>
			<Spin>
				<IconLoop size={ props.size - 4 } />
			</Spin>
		</div>
	);
}

ActivityIndicator.propTypes = {
	size: PropTypes.number.isRequired,
};
