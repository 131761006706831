import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';

import { regionDetails } from '../../lib/regions';
import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import { InstanceResponse, InstanceSubscriptionResponse, NotificationDestinationsResponse } from '../../types/rest-api';
import Annotation from '../Annotation';
import InternalBlock from '../InternalBlock';
import PageTitle from '../PageTitle';
import SettingsGroup from '../SettingsGroup';
import SettingsPanel, { Page } from '../SettingsPanel';

import NotificationDestinations from './NotificationDestinations';

interface Props {
	instance: InstanceResponse,
	instanceLoading: boolean,
}

const formatViews = ( views: number ) => {
	if ( views >= 1e6 ) {
		return (
			<>
				<FormattedNumber
					value={ Math.floor( views / 1e6 * 10 ) / 10 }
				/>
				{ ' million' }
			</>
		);
	}

	return (
		<FormattedNumber
			value={ views }
		/>
	);
};

const Row = ( props: { children: React.ReactNode } ) => (
	<div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">{ props.children }</div>
);

const RowTitle = ( props: { children: React.ReactNode } ) => (
	<dt className="text-sm font-medium text-gray-500">{ props.children }</dt>
);

const RowValue = ( props: { children: React.ReactNode } ) => (
	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{ props.children }</dd>
);

type ConnectedProps = {
	subscription: ApiResponse<InstanceSubscriptionResponse>,
	notificationDestinations: ApiResponse<NotificationDestinationsResponse>,
};

type AllProps = Props & ConnectedProps;

const Subscription = ( props: AllProps ) => {
	if ( props.subscription.isLoading ) {
		return (
			<SettingsGroup title="Subscription">
				<p>Loading…</p>
			</SettingsGroup>
		);
	}

	if ( props.subscription.error ) {
		return (
			<SettingsGroup title="Subscription">
				<p>An error occurred while loading your subscription information.</p>
				<p>Contact your account manager for more information.</p>
			</SettingsGroup>
		);
	}

	// if ( props.subscription.data )
	const region = regionDetails[ props.subscription.data.configuration.region as keyof typeof regionDetails ];

	return (
		<>
			<SettingsGroup
				title="Subscription"
			>
				<dl className="sm:divide-y sm:divide-gray-200">
					<Row>
						<RowTitle>Region</RowTitle>
						<RowValue>
							{ region.isGreen ? (
								<Annotation title={ `This region (${ props.subscription.data.configuration.region }) is powered by 100% renewable energy.` }>
									{ region.title }
									{ ' 🌱' }
								</Annotation>
							) : (
								<Annotation title={ props.subscription.data.configuration.region }>
									{ region.title }
								</Annotation>
							) }
						</RowValue>
					</Row>
					{ props.subscription.data.tiers.product !== 'none' && (
						<Row>
							<RowTitle>Plan</RowTitle>
							<RowValue>
								{ props.subscription.data.display_tiers?.product || props.subscription.data.tiers.product }
							</RowValue>
						</Row>
					) }
					<Row>
						<RowTitle>Environment Tier</RowTitle>
						<RowValue>
							{ props.subscription.data.display_tiers?.environment || props.subscription.data.tiers.environment }
						</RowValue>
					</Row>
					<Row>
						<RowTitle>Support Tier</RowTitle>
						<RowValue>
							{ props.subscription.data.display_tiers?.support || props.subscription.data.tiers.support }
						</RowValue>
					</Row>
					{ props.subscription.data.entitlements.views && (
						<Row>
							<RowTitle>Page views</RowTitle>
							<RowValue>
								<strong>
									{ formatViews( props.subscription.data.entitlements.views.value ) }
									{ ' ' }
									{ props.subscription.data.entitlements.views.period === 'yearly' ? 'per year' : 'per month' }
								</strong>
								{ props.subscription.data.entitlements.views.period === 'yearly' && (
									<p>
										(Maximum { formatViews( props.subscription.data.entitlements.views.value * 0.4 ) } in one month.)
									</p>
								) }
							</RowValue>
						</Row>
					) }
				</dl>
				<InternalBlock>
					<InternalBlock.Title>CRM Details</InternalBlock.Title>
					<dl className="sm:divide-y sm:divide-gray-200">
						<Row>
							<RowTitle>CRM ID</RowTitle>
							<RowValue>
								<a href={ props.subscription.data.crm_url }>
									{ props.subscription.data.crm_id }
								</a>
							</RowValue>
						</Row>
						<Row>
							<RowTitle>Product Tier ID</RowTitle>
							<RowValue>
								<code>{ props.subscription.data.tiers.product }</code>
							</RowValue>
						</Row>
						<Row>
							<RowTitle>Environment Tier ID</RowTitle>
							<RowValue>
								<code>{ props.subscription.data.tiers.environment }</code>
							</RowValue>
						</Row>
						<Row>
							<RowTitle>Support Tier ID</RowTitle>
							<RowValue>
								<code>{ props.subscription.data.tiers.support }</code>
							</RowValue>
						</Row>
					</dl>
				</InternalBlock>
			</SettingsGroup>
			{ props.subscription.data?.account_manager && (
				<SettingsGroup
					title="Account"
				>
					<p className="text-sm text-gray-500">
						Your account manager is <strong>{ props.subscription.data.account_manager.name }</strong>. Contact { props.subscription.data.account_manager.email } for any questions about your account or subscription.
					</p>
					<p className="mt-2 text-sm text-gray-500">
						<Link to="/support/new">
							Contact support
						</Link>
						{ ' ' }
						for any questions about the product.
					</p>
				</SettingsGroup>
			) }
		</>
	);
};

function Settings( props: AllProps ) {
	// const isCustomMaintenance = subscription.tiers.environment === 'Legacy' || subscription.tiers.environment === 'Enterprise';
	const isCustomMaintenance = true;

	const baseUrl = `/i/${ props.instance.id }/settings`;
	const pages: Page[] = [
		{
			title: 'Subscription',
			path: `${ baseUrl }/subscription`,
			component: () => (
				<Subscription
					{ ...props }
				/>
			),
		},
		{
			title: 'Maintenance',
			path: `${ baseUrl }/maintenance`,
			component: () => (
				<SettingsGroup
					footer="(Self-service coming soon!)"
					title="Maintenance"
				>
					{ isCustomMaintenance ? (
						<p>Contact your account manager for information about your maintenance windows.</p>
					) : (
						<>
							<p>Maintenance notifications will be sent to all members of your team.</p>
							<p>Your maintenance window is <strong>2-4am BST (UTC+0)</strong>.</p>
						</>
					) }
				</SettingsGroup>
			),
		},
		{
			title: 'Notifications',
			path: `${ baseUrl }/notifications`,
			component: () => (
				<NotificationDestinations
					instance={ props.instance }
				/>
			),
		},
	];

	return (
		<div className="Instance-Settings">
			<PageTitle title="Settings" />

			<h1>Settings</h1>
			<SettingsPanel
				defaultPath={ pages[0].path }
				pages={ pages }
			/>
		</div>
	);
}

const mapPropsToData = ( props: Props ) => ( {
	subscription: `/stack/instances/${ props.instance.id }/subscription`,
	notificationDestinations: `/stack/instances/${ props.instance.id }/notifications/destinations`,
} );

export default withApiData( mapPropsToData )( Settings );
