import React from 'react';

import { UserResponse } from '../../api';
import Avatar from '../Avatar';
import FormattedDateTime from '../FormattedDateTime';

import './Author.css';

interface Props {
	author?: UserResponse,
	date: string | null,
}

export default function Author( props: Props ) {
	return (
		<section className="Blog-Author">
			<Avatar
				size={ 30 }
				user={ props.author }
			/>
			<span className="Blog-Author__name">
				{ props.author?.name }
			</span>
			{ props.date && (
				<span title={ new Date( props.date ).toISOString() }>
					<FormattedDateTime
						format="long-date"
						value={ props.date }
					/>
				</span>
			) }
		</section>
	);
}
