import React from 'react';

import { ApplicationResponse } from '../../api';

import connectBasicTable from './connectBasicTable';
import Header from './Header';
import { Filter, TimeProps } from './util';

type ConnectedLogsProps = Filter & {
	application: ApplicationResponse,
	rootUrl: string
};
const ConnectedLogs = connectBasicTable(
	( props: ConnectedLogsProps ) => `/stack/applications/${ props.application.id }/logs/db-slowquery?after=${ props.after }&before=${ props.before }&search=${ props.search }`
);

type OwnProps = TimeProps & {
	application: ApplicationResponse,
	onTimeUpdate( t: any ): void,
};

type AllProps = OwnProps;

export default class DatabaseSlow extends React.Component<AllProps, Filter> {
	apiData: typeof ConnectedLogs['prototype'] | null = null;

	constructor( props: AllProps ) {
		super( props );
		this.state = {
			after: this.props.after || '4 hours ago',
			before: this.props.before || 'now',
			search: '',
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}

	onChangeFilter = ( filter: Filter ) => {
		this.apiData?.onInvalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	};

	render() {
		return (
			<div className="ApplicationLogs-DatabaseSlow">
				<Header
					after={ this.state.after }
					before={ this.state.before }
					search={ this.state.search }
					onChangeFilter={ this.onChangeFilter }
				/>

				<ConnectedLogs
					ref={ ref => this.apiData = ref }
					after={ this.state.after }
					application={ this.props.application }
					before={ this.state.before }
					search={ this.state.search }
				/>
			</div>
		);
	}
}
