import { Action as CreateAccelerateSiteAction } from '../actions/createAccelerateSite';
import { Action as FetchAccelerateSitesAction } from '../actions/fetchAccelerateSites';
import { AccelerateSiteResponse } from '../api';

interface State {
	[s: string]: AccelerateSiteResponse;
}

type Actions = FetchAccelerateSitesAction | CreateAccelerateSiteAction;

export default function accelerateSites( state: State = {}, action: Actions ) {
	switch ( action.type ) {
		case 'UPDATED_ACCELERATE_SITES': {
			const sites = { ...state };
			action.sites.forEach( site => {
				sites[ site.id ] = site;
			} );
			return sites;
		}
		case 'ACCELERATE_SITE_CREATED': {
			const sites = { ...state };
			sites[ action.payload.site.id ] = action.payload.site;
			return sites;
		}
		default:
			return state;
	}
}
