import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import { cookieAuthApi, defaultAPI } from '../../../api';
import ErrorBlock from '../../ErrorBlock';

interface Props {
	onLogin?: ( response: any ) => void,
}

export default function Signup( props: Props ) {
	const [ email, setEmail ] = useState( '' );
	const [ name, setName ] = useState( '' );
	const [ password, setPassword ] = useState( '' );
	const [ repeatPassword, setRepeatPassword ] = useState( '' );
	const [ errorMessage, setErrorMessage ] = useState( '' );

	const handleSubmit = async ( event: FormEvent<HTMLFormElement> ) => {
		event.preventDefault();
		setErrorMessage( '' );

		let session: { nonce: string }|null = null;

		try {

			if ( password !== repeatPassword ) {
				throw new Error( 'Password and Confirm Password do not match.' );
			}

			const data: { email: string, password: string, auth_nonce?: string, name: string } = {
				email,
				password,
				auth_nonce: window.VantageInitData?.auth_nonce,
				name,
			};

			// make sure nonce is not set on the API when sending requests.
			( defaultAPI as cookieAuthApi ).nonce = undefined;
			await defaultAPI.post( '/stack/users', data );

			const loginData: { username: string, password: string, auth_nonce?: string, '2fa'?: { provider: string, value: string }, remember: true } = {
				username: email,
				password: password,
				auth_nonce: window.VantageInitData?.auth_nonce,
				remember: true,
			};

			// make sure nonce is not set on the API when sending requests.
			( defaultAPI as cookieAuthApi ).nonce = undefined;

			session = await defaultAPI.post( '/sessions/v0/sessions', loginData );

			if ( props.onLogin && session ) {
				window.analytics.track( 'Registration completed' );
				props.onLogin( session );
			}

		} catch ( e: any ) {
			if ( e.code === 'rest_cookie_invalid_nonce' ) {
				// Auth nonce is invalid.
				setErrorMessage( 'Login nonce is invalid, it may have expired. Please reload the page and try again.' );
				return;
			}
			// @ts-ignore
			setErrorMessage( e.message );
		}
	};

	return (
		<>
			<form className="space-y-6" onSubmit={ handleSubmit }>
				<div>
					<label className="block text-sm font-medium text-gray-700" htmlFor="text">
						Name
					</label>
					<div className="mt-1">
						<input
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-altis-400 focus:border-altis-400 sm:text-sm"
							id="name"
							name="name"
							placeholder="Elizabeth Shaw"
							required
							type="text"
							onChange={ e => setName( e.target.value ) }
						/>
					</div>
				</div>

				<div>
					<label className="block text-sm font-medium text-gray-700" htmlFor="email">
						Company Email
					</label>
					<div className="mt-1">
						<input
							autoComplete="email"
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-altis-400 focus:border-altis-400 sm:text-sm"
							id="email"
							name="email"
							placeholder="shaw@weyland.corp"
							required
							type="email"
							onChange={ e => setEmail( e.target.value ) }
						/>
					</div>
				</div>

				<div>
					<label className="block text-sm font-medium text-gray-700" htmlFor="password">
						Password
					</label>
					<div className="mt-1">
						<input
							autoComplete="current-password"
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-altis-400 focus:border-altis-400 sm:text-sm"
							id="password"
							name="password"
							required
							type="password"
							onChange={ e => setPassword( e.target.value ) }
						/>
					</div>
				</div>

				<div>
					<label className="block text-sm font-medium text-gray-700" htmlFor="password">
						Confirm Password
					</label>
					<div className="mt-1">
						<input
							autoComplete="current-password"
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-altis-400 focus:border-altis-400 sm:text-sm"
							id="confirmpassword"
							name="confirmpassword"
							required
							type="password"
							onChange={ e => setRepeatPassword( e.target.value ) }
						/>
					</div>
				</div>

				{ errorMessage &&
					<div>
						<ErrorBlock message={ <span dangerouslySetInnerHTML={ { __html: errorMessage } }></span> } />
					</div> }

				<div>
					<button
						className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-altis-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						type="submit"
					>
						Register
					</button>
				</div>
			</form>

			<div className="mt-6">
				<div className="relative">
					<div className="relative flex justify-center text-sm">
						<span className="px-2 bg-white text-gray-500">
							<Link
								className="font-regular text-gray-500 underline decoration-gray-300 underline-offset-2 hover:text-gray-600"
								target="_parent"
								to="/accelerate/login/"
							>
								Already registered? Login here
							</Link>
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
