import React from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { UserResponse } from '../../api';
import { AppState } from '../../reducers';

import Signup from './components/Signup';

interface StateProps {
	user: undefined | UserResponse,
}

function SignupEmbed( props: StateProps ) {
	if ( props.user ) {
		return (
			<div className="h-full items-center flex flex-wrap flex-col justify-center">
				<p className="text-center mb-4">You are already signed up.</p>
				<Link
					className="w-1/5 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-altis-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					target="_parent"
					to="/accelerate/"
				>
					Go to Dashboard
				</Link>
			</div>
		);
	}

	const onLogin = () => {
		window.parent.postMessage( {
			type: 'accelerate-signup-complete',
			url: `https://${window.location.hostname }${ generatePath( '/accelerate/dashboard/' ) }`,
		}, '*' );
	};

	return (
		<div className="p-1">
			<Signup onLogin={ onLogin } />
		</div>
	);
}

function mapStateToProps( state: AppState ) : StateProps {
	return {
		user: state.currentUser.user,
	};
}
export default connect( mapStateToProps )( SignupEmbed );
