import ceil from 'lodash/ceil';
import React from 'react';
import {
	CartesianGrid,
	LineChart,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import CustomTooltip from './Tooltip';
import { formatDate, formatPercentage, StatEntry } from './util';

function ErrorsTooltip( props: any ) {
	const { active, payload } = props;
	if ( ! active || ! payload ) {
		return null;
	}

	const item: StatEntry = payload[0].payload;
	return (
		<CustomTooltip>
			<ul>
				<li>Error rate: { formatPercentage( item.error_rate ) }</li>
				<li>Fault rate: { formatPercentage( item.fault_rate ) }</li>
				<li>Errors per minute: { item.errors || 0 }</li>
				<li>Faults per minute: { item.faults || 0 }</li>
				<li>Requests per minute: { item.requests || 0 }</li>
			</ul>
		</CustomTooltip>
	);
}

interface Props {
	data: StatEntry[],
	isLoading: boolean,
}

export default function RequestsChart( props: Props ) {
	if ( props.isLoading ) {
		return (
			<div
				className="animated-background"
				style={ {
					height: 200,
					width: '100%',
				} }
			/>
		);
	}

	return (
		<ResponsiveContainer
			className="XRayOverview-Statistics__errors"
			height={ 200 }
			width="100%"
		>
			<LineChart
				data={ props.data }
				syncId="XRayOverview-Statistics"
			>
				<CartesianGrid
					stroke="#aaa"
					strokeDasharray="3 3"
					strokeWidth="1"
					vertical={ false }
				/>
				<XAxis
					axisLine={ false }
					className="text-gray-400 text-sm"
					dataKey="time"
					tick={ {
						fill: 'currentColor',
						transform: 'translate(0, 6)',
					} }
					tickFormatter={ formatDate }
				/>
				<YAxis
					axisLine={ false }
					className="text-gray-400 text-sm"
					domain={ [ 0, ( dataMax: number ) => ceil( Math.max( dataMax, 0.1 ), 1 ) ] }
					tick={ {
						fill: 'currentColor',
						transform: 'translate(-3, 0)',
					} }
					tickFormatter={ i => formatPercentage( i, 2 ) }
					tickLine={ false }
				/>
				<Tooltip
					content={ <ErrorsTooltip /> }
					isAnimationActive={ false }
					position={ {
						y: 0,
					} }
				/>
				<Line
					dataKey="error_rate"
					dot={ false }
					stroke="#8884d8"
					strokeWidth={ 2 }
					type="linear"
				/>
				<Line
					dataKey="fault_rate"
					dot={ false }
					stroke="#68737d"
					strokeWidth={ 2 }
					type="linear"
				/>
			</LineChart>
		</ResponsiveContainer>
	);
}
