import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Modal from './Modal';
import SolidButton from './SolidButton';

import './BackupApplicationModal.css';

export default class BackupApplicationModal extends Component {
	static propTypes = {
		application: PropTypes.string.isRequired,
		onBackup: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	};
	constructor( props ) {
		super( props );
		this.state = {
			selectedApplication: null,
			database: false,
			uploads: false,
			path: '',
		};
	}
	onSubmit() {
		this.props.onBackup( {
			application: this.state.selectedApplication,
			database: this.state.database,
			uploads: this.state.uploads,
			uploads_path: this.state.path,
		} );
	}
	render() {
		return (
			<Modal
				className="BackupApplicationModal"
				closeText="Cancel"
				isOpen
				title={ `Create backup from ${ this.props.application }` }
				onClose={ this.props.onCancel }
			>
				<form onSubmit={ () => this.onSubmit() }>
					<div>
						{ this.state.uploads && (
							<div>
								<label>Uploads Path (optional)</label>
								<input
									className="input"
									type="text"
									value={ this.state.path }
									onChange={ e => this.setState( { path: e.target.value } ) }
								/>
								<small className="path">Only backup a subset of uploads. e.g. /2015/06/</small>
							</div>
						) }
					</div>
					<p>
						<label>
							Uploads:{ ' ' }
							<input
								checked={ this.state.uploads }
								type="checkbox"
								onChange={ e => this.setState( { uploads: e.target.checked } ) }
							/>
						</label>
						<label>
							Database:{ ' ' }
							<input
								checked={ this.state.database }
								type="checkbox"
								onChange={ e =>
									this.setState( {
										database: e.target.checked,
									} ) }
							/>
						</label>
					</p>
					<p className="submit">
						<SolidButton
							disabled={ ! this.state.uploads && ! this.state.database }
							name="Create Backup"
							type="submit"
						/>
					</p>
				</form>
			</Modal>
		);
	}
}
