import sumBy from 'lodash/sumBy';
import { DateTime } from 'luxon';
import qs from 'qs';
import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedNumber } from 'react-intl';

import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import Infotip from '../Infotip';
import RequestsGraph from '../InstanceMetrics/RequestsGraph';
import useTooltip, { Tooltip } from '../useTooltip';

type MetricItem = {
	date: string,
	values: {
		[ k: string ]: number,
	},
}
type ViewMetricItem = {
	date: string,
	value: number | null,
}

function calculateTotals( response: ApiResponse<MetricItem[]> ) {
	if ( response.isLoading || response.error || ! response.data ) {
		return {
			bandwidth: null,
			requests: null,
		};
	}

	return {
		bandwidth: sumBy( response.data, i => i.values['cdn/bandwidth'] ),
		requests: sumBy( response.data, i => i.values['cdn/requests'] ),
	};
}

function humanFileSize( size: number ) {
	let i = Math.floor( Math.log( size ) / Math.log( 1024 ) );
	return ( size / Math.pow( 1024, i ) ).toFixed( 2 ) + ' ' + [ 'B', 'kB', 'MB', 'GB', 'TB' ][i];
}

const percentageMonth = () => {
	const now = DateTime.utc();
	const monthStart = now.startOf( 'month' ).toMillis();
	const monthEnd = now.endOf( 'month' ).toMillis();
	return ( now.toMillis() - monthStart ) / ( monthEnd - monthStart );
};

function Card( props: { children: React.ReactNode } ) {
	return (
		<div className="overflow-hidden rounded-lg bg-white box-border px-4 py-5 border border-gray-200 sm:p-6">
			{ props.children }
		</div>
	);
}

function CardTitle( props: { children: React.ReactNode } ) {
	return (
		<h5
			className="truncate text-sm font-medium text-gray-500"
		>
			{ props.children }
		</h5>
	);
}

function TextLoader( props: { height: number } ) {
	return (
		<ContentLoader
			height={ props.height }
			// @ts-ignore
			primaryColor="#f3f3f3"
			secondaryColor="#ecebeb"
			speed={ 2 }
			width={ props.height * 3 }
		>
			<rect
				height={ props.height }
				rx="2"
				ry="2"
				width={ props.height * 3 }
				x="0"
				y="0"
			/>
		</ContentLoader>
	);
}

type CompareProps = {
	expected: number,
	title: React.ReactNode,
	value: number,
}

function CompareValue( props: CompareProps ) {
	const [ showing, setShowing ] = useState( false );
	const { setReferenceElement, TooltipProps } = useTooltip();
	const compared = 1 - ( props.expected ) / props.value;
	const baseClass = 'w-max flex-shrink-0 inline-flex justify-center items-center cursor-default rounded-full px-2.5 py-0.5 text-sm';

	const commonProps = {
		ref: setReferenceElement,
		onMouseOut: () => setShowing( false ),
		onMouseOver: () => setShowing( true ),
	};

	let className, iconPath;

	if ( compared > 0.01 ) {
		className = `${ baseClass } bg-emerald-100 text-emerald-700`;
		iconPath = 'M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25';
	} else if ( compared < -0.01 ) {
		className = `${ baseClass } bg-rose-100 text-rose-700`;
		iconPath = 'M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25';
	} else {
		className =`${ baseClass } bg-blue-100 text-blue-700`;
		iconPath = 'M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3';
	}

	return (
		<div
			className={ className }
			{ ...commonProps }
		>
			<span className="inline-flex justify-center items-center">
				<svg
					className="shrink-0 -ml-1 mr-1.5 w-4"
					fill="none"
					stroke="currentColor"
					strokeWidth={ 1.5 }
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d={ iconPath }
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>

				{ Math.abs( compared * 100 ).toFixed( 0 ) }%
			</span>
			<Tooltip
				{ ...TooltipProps }
				className="pointer-events-none"
				visible={ showing }
			>
				{ props.title }
			</Tooltip>
		</div>
	);
}

interface OwnProps {
	application: string,
}

interface Props {
	thisMonth: ApiResponse<ViewMetricItem[]>,
	lastMonth: ApiResponse<ViewMetricItem[]>,
	thisMonthData: ApiResponse<MetricItem[]>,
	lastMonthData: ApiResponse<MetricItem[]>,
}

export function Loader() {
	const LoaderCard = ( props: { title: string } ) => (
		<Card>
			<div className="flex w-full flex-row justify-between items-start">
				<CardTitle>{ props.title }</CardTitle>
			</div>
			<div className="mt-1 text-3xl font-semibold text-gray-800">
				<TextLoader
					height={ 36 }
				/>
			</div>
			<div className="mt-4 text-sm text-gray-400">
				<TextLoader
					height={ 20 }
				/>
			</div>
		</Card>
	);
	return (
		<div className="ApplicationStatPageRequests">
			<div className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-3">
				<LoaderCard title="Page views" />
				<LoaderCard title="CDN requests" />
				<LoaderCard title="Bandwidth" />
			</div>
		</div>
	);
}

function OverviewStats( props: OwnProps & Props ) {
	const views = props.thisMonth.data || [];
	// Pad this month with empty data
	if ( views.length ) {
		const lastDate = new Date( views[views.length - 1].date );
		let nextDay = new Date( lastDate );
		nextDay.setDate( nextDay.getDate() + 1 );
		while ( nextDay.getMonth() === lastDate.getMonth() ) {
			views.push( {
				date: nextDay.toISOString(),
				value: null,
			} );
			nextDay.setDate( nextDay.getDate() + 1 );
		}
	}
	const totalViews = sumBy( views, i => i.value || 0 );
	const lastMonthViews = sumBy( props.lastMonth.data || [], i => i.value || 0 );

	const lastMonth = calculateTotals( props.lastMonthData );
	const thisMonth = calculateTotals( props.thisMonthData );

	// Calculate our expected values.
	const pctComplete = percentageMonth();
	const expectedViews = lastMonthViews * pctComplete;
	const expectedBandwidth = lastMonth.bandwidth !== null ? lastMonth.bandwidth * pctComplete : null;
	const expectedRequests = lastMonth.requests !== null ? lastMonth.requests * pctComplete : null;

	const month = new Date();
	const start = DateTime.fromJSDate( month, { zone: 'utc' } ).startOf( 'month' );
	const end = start.endOf( 'month' );

	return (
		<div className="ApplicationStatPageRequests">
			<div className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-3">
				<Card>
					<div className="flex w-full flex-row justify-between items-start">
						<CardTitle>Page views</CardTitle>
						{ expectedViews > 0 && totalViews > 0 && (
							<CompareValue
								expected={ expectedViews }
								title={ (
									<>
										{ 'Compared to expected ' }
										<FormattedNumber
											value={ Math.round( expectedViews ) }
										/>
										{ ' based on last month\'s average' }
									</>
								) }
								value={ totalViews }
							/>
						) }
					</div>
					<div className="flex flex-full justify-start items-baseline space-x-3">
						<span className="mt-1 text-3xl font-semibold text-gray-700">
							{ props.thisMonth.data ? (
								<FormattedNumber
									value={ totalViews }
								/>
							) : (
								<TextLoader
									height={ 36 }
								/>
							) }
						</span>
						<span className="text-sm text-gray-400">
							estimated

							<Infotip

							>
								Estimation based on firewall traffic. Final numbers available in monthly reports.
							</Infotip>
						</span>
					</div>
					<div className="mt-4 text-sm text-gray-400">
						{ props.lastMonth.data ? (
							<>
								<FormattedNumber
									value={ sumBy( props.lastMonth.data, i => i.value || 0 ) }
								/>
								{ ' last month' }
							</>
						) : (
							<TextLoader
								height={ 20 }
							/>
						) }
					</div>
				</Card>
				<Card>
					<div className="flex w-full flex-row justify-between items-start">
						<CardTitle>
							CDN requests

							<Infotip>
								Total HTTP requests (including page views) to your production environment.
							</Infotip>
						</CardTitle>
						{ expectedRequests !== null && thisMonth.requests !== null && (
							<CompareValue
								expected={ expectedRequests }
								title={ (
									<>
										{ 'Compared to expected ' }
										<FormattedNumber
											value={ Math.round( expectedRequests ) }
										/>
										{ ' based on last month\'s average' }
									</>
								) }
								value={ thisMonth.requests }
							/>
						) }
					</div>
					<div className="mt-1 text-3xl font-semibold text-gray-800">
						{ thisMonth.requests !== null ? (
							<FormattedNumber
								value={ thisMonth.requests }
							/>
						) : (
							<TextLoader
								height={ 36 }
							/>
						) }
					</div>
					<div className="mt-4 text-sm text-gray-400">
						{ lastMonth.requests !== null ? (
							<>
								<FormattedNumber
									value={ lastMonth.requests }
								/>
								{ ' last month' }
							</>
						) : (
							<TextLoader
								height={ 20 }
							/>
						) }
					</div>
				</Card>
				<Card>
					<div className="flex w-full flex-row justify-between items-start">
						<CardTitle>
							Bandwidth

							<Infotip>
								Total data transferred to your production environment.
							</Infotip>
						</CardTitle>
						{ thisMonth.bandwidth !== null && expectedBandwidth !== null && (
							<CompareValue
								expected={ expectedBandwidth }
								title={ `Compared to expected ${ humanFileSize( expectedBandwidth ) } based on last month's average` }
								value={ thisMonth.bandwidth }
							/>
						) }
					</div>
					<div className="mt-1 text-3xl font-semibold text-gray-800">
						{ thisMonth.bandwidth !== null ? (
							humanFileSize( thisMonth.bandwidth )
						) : (
							<TextLoader
								height={ 36 }
							/>
						) }
					</div>
					<div className="mt-4 text-sm text-gray-400">
						{ ( lastMonth.bandwidth !== null ) ? (
							<>
								{ humanFileSize( lastMonth.bandwidth ) }
								{ ' last month' }
							</>
						) : (
							<TextLoader
								height={ 20 }
							/>
						) }
					</div>
				</Card>
			</div>

			<div className="my-4">
				<RequestsGraph
					application={ props.application }
					period={ {
						start,
						end,
					} }
				/>
			</div>
		</div>
	);
}

const mapPropsToData = ( props: OwnProps ) => ( {
	thisMonth: `/stack/applications/${props.application}/metrics/page-requests?${qs.stringify( {
		from: 'first day of this month today',
		to: 'tomorrow',
		period: 60 * 60 * 24,
	} )}`,
	lastMonth: `/stack/applications/${props.application}/metrics/page-requests?${qs.stringify( {
		from: 'first day of last month today',
		to: 'first day of this month today',
		period: 60 * 60 * 24,
	} )}`,
	thisMonthData: `/stack/applications/${props.application}/metrics?${qs.stringify( {
		from: 'first day of this month today',
		to: 'tomorrow',
		period: 60 * 60 * 24,
		metrics: [
			'cdn/bandwidth',
			'cdn/requests',
		],
	} )}`,
	lastMonthData: `/stack/applications/${props.application}/metrics?${qs.stringify( {
		from: 'first day of last month today',
		to: 'first day of this month today',
		period: 60 * 60 * 24,
		metrics: [
			'cdn/bandwidth',
			'cdn/requests',
		],
	} )}`,
} );

export default withApiData( mapPropsToData )( OverviewStats );
