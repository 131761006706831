import AWSSSMSession from '@humanmade/ssm';
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { Terminal, ITheme } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

import Loader from '../Loader';

import Toolbar from './Toolbar';

import '../../../node_modules/xterm/css/xterm.css';
import './Console.css';

// https://qwtel.com/posts/software/the-monospaced-system-ui-css-font-stack/
const fontStack = `
	ui-monospace,
	Menlo, Monaco,
	"Cascadia Mono", "Segoe UI Mono",
	"Roboto Mono",
	"Oxygen Mono",
	"Ubuntu Monospace",
	"Source Code Pro",
	"Fira Mono",
	"Droid Sans Mono",
	"Courier New", monospace
`;

const vantageTheme: ITheme = {
	foreground: '#d6d6d6',
	background: '#222222',
	black: '#222',
	brightBlack: '#686868',
	red: '#FF3B30',
	brightRed: '#FF3B30',
	green: '#4CD964',
	brightGreen: '#4CD964',
	yellow: '#FFCC00',
	brightYellow: '#FFCC00',
	blue: '#0095FF',
	brightBlue: '#0095FF',
	magenta: '#FF2D55',
	brightMagenta: '#FF2D55',
	cyan: '#5AC8FA',
	brightCyan: '#5AC8FA',
	white: '#fff',
	brightWhite: '#fff',
};

export interface Connection {
	id: string,
	stream_url: string,
	token: string,
}

interface Props {
	application: string;
	connection?: Connection;
	standalone?: boolean;
}

interface State {
	connected: boolean,
	maximized: boolean,
	popped: boolean,
	paused: boolean,
}

// Keep the socket alive by pinging every 10s.
const IDLE_INTERVAL = 30_000;

const INITIAL_SIZE_WAIT = 500;

export class ShellSessionConsole extends Component<Props, State> {
	awsSSMSession?: AWSSSMSession;
	fitAddon: FitAddon;
	idleTimerHandle?: number;
	root: HTMLDivElement | null = null;
	terminal: Terminal;

	state: State = {
		connected: false,
		maximized: false,
		popped: false,
		paused: false,
	};

	constructor( props: Props ) {
		super( props );
		this.terminal = new Terminal();

		// Configure terminal.
		this.terminal.setOption( 'fontFamily', fontStack );
		this.terminal.setOption( 'fontSize', 12 );
		this.terminal.setOption( 'lineHeight', 1.1 );
		this.terminal.setOption( 'theme', vantageTheme );
		this.terminal.setOption( 'cursorBlink', true );

		this.fitAddon = new FitAddon();
	}

	componentDidMount() {
		this.idleTimerHandle = window.setInterval( this.idleTimer, IDLE_INTERVAL );
		if ( ! this.root ) {
			return;
		}

		this.terminal.open( this.root );
		this.terminal.loadAddon( this.fitAddon );
		this.fitAddon.fit();
		this.terminal.onData( ev => {
			if ( ! this.awsSSMSession ) {
				return;
			}
			if ( this.state.paused ) {
				console.warn( 'SSM Session is paused, dropping data.' );
				return;
			}
			this.awsSSMSession.write( ev );
		} );
		window.addEventListener( 'resize', this.onResize );

		if ( this.props.connection ) {
			// Already have a connection! Connect straight away.
			this.connect( this.props.connection );
		}
	}

	componentWillUnmount() {
		window.removeEventListener( 'resize', this.onResize );

		this.terminal.dispose();

		if ( this.awsSSMSession ) {
			this.awsSSMSession.close();
			this.awsSSMSession = undefined;
		}

		if ( this.idleTimerHandle ) {
			window.clearInterval( this.idleTimerHandle );
		}
	}

	componentDidUpdate( prevProps: Props, prevState: State ) {
		if ( ! prevProps.connection && this.props.connection ) {
			this.connect( this.props.connection );
		} else if ( prevProps.connection !== this.props.connection && this.props.connection ) {
			// ????
			this.connect( this.props.connection );
		}

		if ( prevState.maximized !== this.state.maximized ) {
			this.onResize();
		}
	}

	onResize = () => {
		this.fitAddon.fit();
		if ( ! this.awsSSMSession ) {
			return;
		}
		this.awsSSMSession.setSize( this.terminal.cols, this.terminal.rows );
	};

	connect( conn: Connection ) {
		this.awsSSMSession = new AWSSSMSession( conn.stream_url, conn.id, conn.token );
		this.awsSSMSession.on( 'connect', () => {
			this.setState( { connected: true } );

			window.setTimeout( () => {
				if ( ! this.awsSSMSession ) {
					return;
				}
				this.awsSSMSession.setSize( this.terminal.cols, this.terminal.rows );
			}, INITIAL_SIZE_WAIT );
		} );
		this.awsSSMSession.on( 'disconnect', ( reason: string ) => {
			this.terminal.write( '\r\n' );
			const message = reason ? `${ reason }. Disconnected.` : 'Disconnected.';
			this.terminal.write( message );

			// Disable further input.
			this.terminal.setOption( 'disableStdin', true );

			// Hide cursor.
			this.terminal.setOption( 'cursorBlink', false );
			this.terminal.setOption( 'cursorStyle', 'underline' );
			this.terminal.setOption( 'theme', {
				...vantageTheme,
				cursor: 'transparent',
			} );

			this.setState( { connected: false } );
		} );
		this.awsSSMSession.on( 'output', ( data: string ) => {
			this.terminal.write( data );
		} );
		this.awsSSMSession.on( 'pause', () => {
			this.setState( { paused: true } );
		} );
		this.awsSSMSession.on( 'resume', () => {
			this.setState( { paused: false } );
		} );
	}

	idleTimer = () => {
		if ( ! this.awsSSMSession ) {
			return;
		}

		this.awsSSMSession.ping();
	};

	render() {
		const classes = [
			'Console',
			this.state.maximized && 'Console--maximized',
			this.state.popped && 'Console--popped',
			this.props.standalone && 'Console--standalone',
		];

		return (
			<div
				className={ classes.filter( Boolean ).join( ' ' ) }
			>
				<Prompt
					message="Navigating will close your shell session. Are you sure?"
					when={ this.props.connection && this.state.connected }
				/>

				<Toolbar
					application={ this.props.application }
					maximized={ this.state.maximized }
					onToggleMaximized={ () => this.setState( { maximized: ! this.state.maximized } ) }
				/>

				<div
					ref={ r => this.root = r }
					className="Console__terminal"
				/>
				{ this.state.popped && (
					<p className="Console__popped-status">Terminal is open in other window.</p>
				) }
				{ ( ! this.props.connection || this.state.paused ) && (
					<div className="Console__loader">
						<Loader />
					</div>
				) }
			</div>
		);
	}
}

export default ShellSessionConsole;
