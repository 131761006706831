import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import useTooltip, { Tooltip } from './useTooltip';

type ValueProps = {
	className?: string,
	value: string,
}

export default function CopiableValue( props: ValueProps ) {
	const { setReferenceElement, TooltipProps } = useTooltip();
	const [ didCopy, setDidCopy ] = useState<boolean>( false );
	const [ showing, setShowing ] = useState<boolean>( false );

	return (
		<>
			<CopyToClipboard
				text={ props.value }
				onCopy={ () => setDidCopy( true ) }
			>
				<code
					ref={ setReferenceElement }
					className={ `${ props.className } ${ showing ? 'bg-blue-50' : '' }` }
					onMouseOut={ () => setShowing( false ) }
					onMouseOver={ () => setShowing( true ) }
				>
					{ props.value }
				</code>
			</CopyToClipboard>
			<Tooltip
				{ ...TooltipProps }
				className="pointer-events-none"
				visible={ showing }
			>
				{ didCopy ? 'Copied!' : 'Click to copy' }
			</Tooltip>
		</>
	);
}
