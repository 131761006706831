import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API, defaultAPI } from '../api';
import { AppState } from '../reducers';
import { AuditLogResponse } from '../types/rest-api';

interface UpdatedActivityAction extends ReduxAction {
	type: 'UPDATED_ACTIVITY';
	activity: AuditLogResponse[];
}

interface UpdatingActivityAction extends ReduxAction {
	type: 'UPDATING_ACTIVITY';
}

export type Action = UpdatedActivityAction | UpdatingActivityAction;

export default function fetchActivity( args = {}, api: API = defaultAPI ): ThunkAction<Promise<any>, AppState, null, Action> {
	return async dispatch => {
		dispatch( {
			type: 'UPDATING_ACTIVITY',
		} );
		try {
			const activity: AuditLogResponse[] = await api.get( '/audit-log/v1/items', args );
			dispatch( {
				type: 'UPDATED_ACTIVITY',
				activity,
			} );
		} catch ( err ) {
			// Unauthorized users can't view the audit log, just ignore it.
			dispatch( {
				type: 'UPDATED_ACTIVITY',
				activity: [],
			} );
		}
	};
}
