import React from 'react';
import { connect } from 'react-redux';

import Item from './index';

interface Props {
	application: any;
	currentUser: any;
	stream: string | null;
}

export function InProgressBuild( props: Props ) {
	// Temporary, should be returned from backend instead.
	const inProgressBuild = {
		date: null,
		hm_platform_version: '',
		id: '__in_progress',
		phases: [],
		source_version: null,
		status: 'in_progress',
		task_id: props.stream,
		user: props.currentUser.user,
		wordpress_version: null,
	};

	return (
		<Item
			application={ props.application }
			build={ inProgressBuild }
			canDeploy={ false }
			current={ false }
			currentBuild={ null }
			defaultOpen
			deploys={ [] }
			isDeploying={ false }
			onDeploy={ () => {} }
		/>
	);
}

// Temporary, should be returned from backend instead.
const mapStateToProps = ( state: any ) => ( {
	currentUser: state.currentUser,
} );

export default connect( mapStateToProps )( InProgressBuild );
