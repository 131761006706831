import React from 'react';
import CheckIcon from 'react-feather/dist/icons/check';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronUpIcon from 'react-feather/dist/icons/chevron-up';

import PillButton from './PillButton';

import './DurationSelector.css';

export type DurationFilter = {
	duration: number,
}
export type ManualTimeFilter = {
	before?: string,
	after?: string,
}
export type TimeFilter = DurationFilter | ManualTimeFilter;

export const Duration = ( { value }: { value: number } ) => {
	if ( value > 86400 ) {
		const days = value / 86400;
		return (
			<>
				{ days }
				{ ' ' }
				<span className="DurationSelector__unit">days</span>
			</>
		);
	}
	if ( value > 3600 ) {
		const hours = value / 3600;
		return (
			<>
				{ hours }
				{ ' ' }
				<span className="DurationSelector__unit">hrs</span>
			</>
		);
	}

	return (
		<>
			{ value / 60 }
			{ ' ' }
			<span className="DurationSelector__unit">min{ value !== 60 ? 's' : '' }</span>
		</>
	);
};

const DEFAULT_TIMES = [
	15 * 60,
	30 * 60,
	60 * 60,
	3 * 60 * 60,
	6 * 60 * 60,
	12 * 60 * 60,
	24 * 60 * 60,
];

interface Props {
	flip?: boolean,
	label?: string,
	selected: TimeFilter,
	times?: number[],
	onSelect( filter: TimeFilter ): void,
}

interface State {
	showingDropdown: boolean,
	before: string | null,
	after: string | null,
}

const isSelected = ( selected: TimeFilter, time: number ) => 'duration' in selected && selected.duration === time;

export default class DurationSelector extends React.Component<Props, State> {
	state: State = {
		showingDropdown: false,
		before: null,
		after: null,
	};

	onSelectDuration = ( event: React.ChangeEvent<HTMLInputElement> ) => {
		if ( ! event.target.checked ) {
			return;
		}

		const time = event.target.value;
		this.props.onSelect( {
			duration: parseInt( time, 10 ),
		} );
		this.setState( {
			showingDropdown: false,
		} );
	};

	getBeforeAfter() {
		const { selected } = this.props;

		// Use local values if set, otherwise fall back.
		const after = this.state.after !== null ? this.state.after : ( ( 'after' in selected ? selected.after : '' ) );
		const before = this.state.before !== null ? this.state.before : ( ( 'before' in selected ? selected.before : '' ) );
		return {
			before,
			after,
		};
	}

	onSubmit = ( event: React.FormEvent ) => {
		event.preventDefault();
		const { before, after } = this.getBeforeAfter();
		if ( ! before || ! after ) {
			return;
		}

		this.props.onSelect( {
			before,
			after,
		} );
		this.setState( {
			showingDropdown: false,
		} );
	};

	render() {
		const { flip, label, selected } = this.props;
		const times = this.props.times || DEFAULT_TIMES;
		const { before, after } = this.getBeforeAfter();

		return (
			<form
				className={ [
					'DurationSelector',
					flip && 'DurationSelector--flip',
				].filter( Boolean ).join( ' ' ) }
				onSubmit={ this.onSubmit }
			>
				{ label && (
					<label className="DurationSelector__selector-label">{ label }</label>
				) }
				<button
					className="DurationSelector__selector"
					type="button"
					onClick={ () => this.setState( { showingDropdown: ! this.state.showingDropdown } ) }
				>
					{ ( 'duration' in selected )  ? (
						<>
							<Duration value={ selected.duration } />
							{ ' ending ' }
							now
						</>
					) : (
						<>
							{ selected.after }
							{ ' \u2014 ' }
							{ selected.before }
						</>
					) }

					{ this.state.showingDropdown ? (
						<ChevronUpIcon
							color="#4667DE"
							size={ 16 }
						/>
					) : (
						<ChevronDownIcon
							color="#4667DE"
							size={ 16 }
						/>
					) }
				</button>

				{ this.state.showingDropdown && (
					<ul
						className="DurationSelector__dropdown"
						role="menu"
					>
						{ times.map( time => (
							<li
								aria-checked={ isSelected( selected, time ) }
								className={ [
									'DurationSelector__option',
									isSelected( selected, time ) && 'DurationSelector__option-selected',
								].filter( Boolean ).join( ' ' ) }
								role="menuitemcheckbox"
							>
								<label>
									<input
										key={ time }
										checked={ isSelected( selected, time ) }
										name="duration"
										type="radio"
										value={ time }
										onChange={ this.onSelectDuration }
									/>
									{ isSelected( selected, time ) && (
										<CheckIcon
											className="DurationSelector__option-indicator"
											size={ 14 }
										/>
									) }
									<Duration value={ time } />
								</label>
							</li>
						) ) }

						<li
							aria-checked={ ! ( 'duration' in selected ) }
							className="DurationSelector__manual"
							role="menuitemcheckbox"
						>
							<input
								checked={ ! ( 'duration' in selected ) }
								name="duration"
								readOnly
								type="radio"
								value={ '' }
							/>

							<span>Manual selection:</span>

							<div className="DurationSelector__span">
								<input
									placeholder="Show responses after"
									type="text"
									value={ after }
									onChange={ e => this.setState( { after: e.target.value } ) }
								/>
								<input
									placeholder="Show responses before"
									type="text"
									value={ before }
									onChange={ e => this.setState( { before: e.target.value } ) }
								/>

								<PillButton
									name="Apply"
									// Handled by submission event above.
									onClick={ () => {} }
								/>
							</div>
						</li>
					</ul>
				) }
			</form>
		);
	}
}
