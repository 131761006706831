import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import './useTooltip.css';

export interface TooltipProps {
	attributes: ReturnType<typeof usePopper>['attributes'],
	children: React.ReactNode,
	className?: string,
	setArrowElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
	setPopperElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
	styles: ReturnType<typeof usePopper>['styles'],
	update(): void,
	visible: boolean,
}

export function Tooltip( props: TooltipProps ) {
	const { children, update } = props;

	// Force positioning to update when children change.
	useEffect( () => {
		update();
	}, [ children, update ] );

	return (
		<div
			ref={ props.setPopperElement }
			className={ [
				'Tooltip__popper z-50 bg-navy-900 rounded transition-opacity text-white text-sm px-2 py-1',
				props.visible ? 'opacity-100' : 'opacity-0',
				props.className,
			].filter( Boolean ).join( ' ' ) }
			style={ props.styles.popper }
			{ ...props.attributes.popper }
		>
			{ children }

			<div
				ref={ props.setArrowElement }
				className="Tooltip__arrow"
				style={ props.styles.arrow }
			/>
		</div>
	);
}

export default function useTooltip() {
	const [ referenceElement, setReferenceElement ] = useState<Element | null>( null );
	const [ popperElement, setPopperElement ] = useState<HTMLDivElement | null>( null );
	const [ arrowElement, setArrowElement ] = useState<HTMLDivElement | null>( null );
	const { styles, attributes, update } = usePopper( referenceElement, popperElement, {
		modifiers: [
			{
				name: 'arrow',
				options: {
					element: arrowElement,
				},
			},
			{
				name: 'offset',
				options: {
					offset: [ 0, 8 ],
				},
			},
		],
	} );

	const TooltipProps: Omit<TooltipProps, 'children' | 'visible'> = {
		attributes,
		styles,
		update: update || ( () => {} ),
		setArrowElement,
		setPopperElement,
	};

	return {
		setReferenceElement,
		TooltipProps,
	};
}
