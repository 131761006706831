import { DateTime } from 'luxon';

export const formatDate = ( date: number ) => DateTime.fromMillis( date ).toLocaleString( {
	month: 'short',
	day: 'numeric',
} );

export const formatViews = ( views: number ) => {
	if ( views > 1e6 ) {
		return Math.round( views / 1e5 ) / 10 + 'M';
	}
	if ( views > 1e3 ) {
		return Math.round( views / 1e3 ) + 'K';
	}
	return `${ views }`;
};
