import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchActivity } from '../../actions';
import { AppState } from '../../reducers';
import { AuditLogResponse } from '../../types/rest-api';
import ActivityDashboardFilterForm from '../ActivityDashboardFilterForm';
import ActivityList from '../ActivityList';

interface Props {
	activity: {
		[s: string]: AuditLogResponse,
	},
	isLoading: boolean,
	onLoad(): void,
}

function Activity( props: Props ) {
	const { isLoading, onLoad } = props;
	const activity = Object.values( props.activity ).map( item => ( {
		user: {
			name: item.user_display_name,
			avatar_urls: {
				'96': item.user_avatar_url,
			},
		},
		date: item.date,
		description: item.description,
		details: item.event,
	} ) );
	const hasActivity = activity.length > 0;

	useEffect( () => {
		if ( ! isLoading && ! hasActivity ) {
			onLoad();
		}
	}, [ hasActivity, isLoading, onLoad ] );

	return (
		<div className="Overview-Activity">
			<ActivityDashboardFilterForm />
			<ActivityList
				isLoading={ props.isLoading }
				items={ activity }
			/>
		</div>
	);
}

const mapStateToProps = ( state: AppState ) => ( {
	activity: state.activity.byId,
	isLoading: state.activity.isLoading,
} );
const mapDispatchToProps = ( dispatch: any ) => ( {
	onLoad: () => dispatch( fetchActivity() ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( Activity );
