import { Action as FetchApplicationsAction } from '../actions/fetchApplications';
import { Action as FetchInstanceAction } from '../actions/fetchInstance';
import { Action as FetchInstancesAction } from '../actions/fetchInstances';
import { Action as SelectInstanceAction } from '../actions/selectInstance';
import { InstanceResponse } from '../types/rest-api';

interface State {
	selected: string | null,
	data: {
		[ id: string ]: InstanceResponse;
	},
	errors: {
		[ id: string ]: Error | undefined,
	},
	loading: {
		[ id: string ]: boolean,
	},
	loadingApplications: {
		[ id: string ]: boolean,
	},
	archives: {
		all?: {
			error: Error | null,
			ids: string[],
			loading: boolean,
		},
	},
}

const DEFAULT_STATE: State = {
	selected: null,
	data: {},
	errors: {},
	loading: {},
	loadingApplications: {},
	archives: {},
};

type Actions = FetchInstanceAction | FetchInstancesAction | SelectInstanceAction | FetchApplicationsAction;

export default function instances( state: State = DEFAULT_STATE, action: Actions ): State {
	switch ( action.type ) {
		case 'FETCH_INSTANCES_REQUEST':
			return {
				...state,
				archives: {
					...state.archives,
					all: {
						error: null,
						ids: [],
						loading: true,
					},
				},
			};

		case 'FETCH_INSTANCES': {
			const indexed = action.payload.reduce<State['data']>( ( acc, item ) => {
				return {
					...acc,
					[ item.id ]: item,
				};
			}, {} );

			return {
				...state,
				data: {
					...state.data,
					...indexed,
				},
				archives: {
					...state.archives,
					all: {
						error: null,
						ids: Object.keys( indexed ),
						loading: false,
					},
				},
			};
		}

		case 'FETCH_INSTANCES_ERROR':
			return {
				...state,
				archives: {
					...state.archives,
					all: {
						error: action.payload,
						ids: [],
						loading: false,
					},
				},
			};

		case 'FETCH_INSTANCE_REQUEST':
			return {
				...state,
				loading: {
					...state.loading,
					[ action.payload.id ]: true,
				},
				errors: {
					...state.errors,
					[ action.payload.id ]: undefined,
				},
			};

		case 'FETCH_INSTANCE':
			return {
				...state,
				loading: {
					...state.loading,
					[ action.payload.id ]: false,
				},
				data: {
					...state.data,
					[ action.payload.id ]: action.payload.data,
				},
				errors: {
					...state.errors,
					[ action.payload.id ]: undefined,
				},
			};

		case 'FETCH_INSTANCE_ERROR':
			return {
				...state,
				loading: {
					...state.loading,
					[ action.payload.id ]: true,
				},
				errors: {
					...state.errors,
					[ action.payload.id ]: action.payload.error,
				},
			};

		case 'SELECT_INSTANCE':
			return {
				...state,
				selected: action.id,
			};
		case 'UPDATING_APPLICATIONS': {
			if ( ! action.args.instance ) {
				return state;
			}
			return {
				...state,
				loadingApplications: {
					...state.loadingApplications,
					[action.args.instance ]: true,
				},
			};
		}
		case 'UPDATED_APPLICATIONS': {
			if ( ! action.args.instance ) {
				return state;
			}
			return {
				...state,
				loadingApplications: {
					...state.loadingApplications,
					[action.args.instance ]: false,
				},
			};
		}

		default:
			return state;
	}
}
