import { CloudDownloadIcon } from '@heroicons/react/outline';
import { FormEvent } from 'react';

import { AccelerateSiteResponse } from '../../../../api';

import StepButton from './StepButton';

interface Props {
	onDone(): void,
	site: AccelerateSiteResponse,
}

export default function InstallPlugin( props: Props ) {
	function onSubmit( event: FormEvent<HTMLFormElement> ) {
		event.preventDefault();
		props.onDone();
	}
	return (
		<form className="AddSiteStep2 pb-10" onSubmit={ onSubmit }>
			<h2 className="text-2xl pb-5">Install the Altis Plugin on your WordPress site</h2>
			<a
				className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				href={ props.site.install_plugin_url }
				rel="noreferrer" target="_blank"
			>
				<CloudDownloadIcon aria-hidden="true" className="-ml-1 mr-3 h-5 w-5" />
				Install Altis Plugin
			</a>
			<div className="text-sm text-gray-400 pt-2">
				This link will take you to <strong>your plugin dashboard</strong>.
			</div>
			<StepButton text="Next" />
		</form>
	);
}
