import React from 'react';
import AlertTriangleIcon from 'react-feather/dist/icons/alert-triangle';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import ClipboardIcon from 'react-feather/dist/icons/clipboard';
import HelpCircleIcon from 'react-feather/dist/icons/help-circle';
import SettingsIcon from 'react-feather/dist/icons/settings';
import { Link } from 'react-router-dom';

import BigTitle from '../BigTitle';
import PageTitle from '../PageTitle';

import './SelectType.css';

export const TYPES = [
	{
		icon: HelpCircleIcon,
		title: 'Ask a question',
		noun: 'Question',
		description: 'Get help with Altis.',
		type: 'question',
	},
	{
		icon: SettingsIcon,
		title: 'Request a change',
		noun: 'Change Request',
		description: 'Request changes to your Altis environments, PHP upgrades, new environments, or add new domains',
		type: 'task',
	},
	{
		icon: ClipboardIcon,
		title: 'Report an ongoing issue',
		noun: 'Ongoing Issue',
		description: 'Tell us about problems you\'re experiencing, including bugs.',
		type: 'problem',
	},
	{
		icon: AlertTriangleIcon,
		title: 'Report an incident',
		noun: 'Incident',
		description: 'Alert us to any unexpected interruptions to services, such as a site outage.',
		type: 'incident',
	},
];

export default function SelectType() {
	return (
		<div className="Support-SelectType">
			<PageTitle title="Create New Ticket" />
			<BigTitle name="What can we help you with?" />

			<ul className="Support-SelectType__options">
				{ TYPES.map( ( { icon: Icon, ...type } ) => (
					<li
						key={ type.title }
					>
						<Link
							className="Support-SelectType__option"
							to={ `/support/new/${ type.type }` }
							type="button"
						>
							<Icon
								className="Support-SelectType__option-icon"
								size={ 32 }
							/>

							<div className="Support-SelectType__option-main">
								<h3>{ type.title }</h3>
								<p>{ type.description }</p>
							</div>

							<ChevronRightIcon
								className="Support-SelectType__option-chevron"
								size={ 24 }
							/>
						</Link>
					</li>
				) ) }
			</ul>
		</div>
	);
}
