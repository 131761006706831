import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { defaultAPI as api } from '../api';
import { AppState } from '../reducers';
import { AccelerateSiteResponse } from '../types/rest-api';

interface UpdatingAccelerateSitesAction extends ReduxAction {
	type: 'UPDATING_ACCELERATE_SITES';
}

interface UpdatedAccelerateSitesAction extends ReduxAction {
	type: 'UPDATED_ACCELERATE_SITES';
	sites: AccelerateSiteResponse[];
}

interface UpdateAccelerateSitesFailedAction extends ReduxAction {
	type: 'UPDATE_ACCELERATE_SITES_FAILED';
	error: Error;
}

export type Action = UpdatingAccelerateSitesAction | UpdatedAccelerateSitesAction | UpdateAccelerateSitesFailedAction;

export default function fetchAccelerateSites(): ThunkAction<Promise<AccelerateSiteResponse[]>, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'UPDATING_ACCELERATE_SITES',
		} );

		return api
			.get( '/stack/accelerate/sites' )
			.then( ( sites: AccelerateSiteResponse[] ) => {
				dispatch( {
					type: 'UPDATED_ACCELERATE_SITES',
					sites,
				} );
				return sites;
			} )
			.catch( error => {
				dispatch( {
					type: 'UPDATE_ACCELERATE_SITES_FAILED',
					error,
				} );
				return [];
			} );

	};
}
