import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './WebServersListItem.css';
import CopyToClipboard from 'react-copy-to-clipboard';
import AnchorIcon from 'react-feather/dist/icons/anchor';
import CopyIcon from 'react-feather/dist/icons/copy';

import LiveLineChart from './LiveLineChart';
import ServerStatusIndicator from './ServerStatusIndicator';

class WebServersListItem extends Component {
	static propTypes = {
		ipAddress: PropTypes.string.isRequired,
		status: PropTypes.oneOf( [ 'ok', 'error' ] ),
		sshConnectionCommand: PropTypes.string,
		metricEndpoint: PropTypes.string,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		isPinned: PropTypes.bool.isRequired,
		onPin: PropTypes.func,
		onUnpin: PropTypes.func,
	};

	state = { copying: [] };

	onCopy( copiedText ) {
		const copying = [ ...this.state.copying ];
		copying.push( copiedText );
		this.setState( { copying } );
		setTimeout( () => {
			this.setState( {
				copying: this.state.copying.filter( el => this.state.copying.indexOf( el ) === -1 ),
			} );
		}, 1000 );
	}

	render() {
		const copyActions = [
			{
				name: 'ip-address',
				label: this.props.ipAddress,
				copyText: this.props.ipAddress,
				isCopying: this.state.copying.indexOf( 'ip-address' ) >= 0,
			},
			{
				name: 'ssh-connection',
				label: 'SSH Connection',
				copyText: this.props.sshConnectionCommand,
				isCopying: this.state.copying.indexOf( 'ssh-connection' ) >= 0,
			},
		];

		return (
			<div className="WebServersListItem">
				<div className="server-name">
					<ServerStatusIndicator status={ this.props.status } />
					<div>
						<span>{ this.props.name }</span>
						<span className="type">{ this.props.type }</span>
					</div>
				</div>
				{ this.props.metricEndpoint ? (
					<LiveLineChart endpoint={ this.props.metricEndpoint } height={ 60 } width={ 200 } />
				) : null }

				{ copyActions.map( action => {
					return (
						action.copyText && (
							<div key={ action.name } className={ `${action.name} web-server-copy-action` }>
								<span>{ action.label }</span>
								<div className={ 'copying-command ' + ( action.isCopying ? 'show' : '' ) }>Copied!</div>
								<CopyToClipboard text={ action.copyText } onCopy={ () => this.onCopy( action.name ) }>
									<button>
										<CopyIcon size={ 18 } />
									</button>
								</CopyToClipboard>
							</div>
						)
					);
				} ) }
				{ this.props.onPin &&
					( this.props.isPinned ? (
						<button onClick={ this.props.onUnpin }>
							<AnchorIcon color="#D24632" size={ 18 } />
						</button>
					) : (
						<button onClick={ this.props.onPin }>
							<AnchorIcon color="#f1f1f1" size={ 18 } />
						</button>
					) ) }
			</div>
		);
	}
}

export default WebServersListItem;
