import { combineReducers } from 'redux';

import accelerateSites from './accelerateSites';
import activity from './activity';
import applicationBuilds from './applicationBuilds';
import applicationDeploys from './applicationDeploys';
import applications from './applications';
import currentUser from './currentUser';
import instances from './instances';
import onCall from './onCall';
import preferences from './preferences';
import regions from './regions';
import regionsLoading from './regionsLoading';
import supportTickets from './supportTickets';
import tasks from './tasks';

const appState = combineReducers( {
	regions,
	tasks,
	applicationBuilds,
	applicationDeploys,
	currentUser,
	applications,
	instances,
	regionsLoading,
	activity,
	onCall,
	preferences,
	supportTickets,
	accelerateSites,
} );

export default appState;

export type AppState = ReturnType<typeof appState>;
