import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';

import fetchTaskStream from './fetchTaskStream';

interface ImportOptions {
	database: boolean;
	uploads: boolean;
	application: Application;
	path?: string,
}

interface ImportCreatingAction extends ReduxAction {
	type: 'IMPORT_CREATING';
	payload: {
		application: Application;
		importOptions: ImportOptions;
	};
}

interface ImportDatabaseCreatingTaskStreamAction extends ReduxAction {
	type: 'IMPORT_DATABASE_CREATING_TASK_STREAM';
	payload: {
		application: Application;
		taskStream: string;
	};
}

interface ImportUploadsCreatingTaskStreamAction extends ReduxAction {
	type: 'IMPORT_UPLOADS_CREATING_TASK_STREAM';
	payload: {
		application: Application;
		taskStream: string;
	};
}

interface ImportDatabaseCreatedAction extends ReduxAction {
	type: 'IMPORT_DATABASE_CREATED';
	payload: {
		application: Application;
	};
}

interface ImportUploadsCreatedAction extends ReduxAction {
	type: 'IMPORT_UPLOADS_CREATED';
	payload: {
		application: Application;
	};
}

interface ImportDatabaseFailedAction extends ReduxAction {
	type: 'IMPORT_DATABASE_FAILED';
	payload: {
		application: Application;
		error: Error;
	};
}

interface ImportUploadsFailedAction extends ReduxAction {
	type: 'IMPORT_UPLOADS_FAILED';
	payload: {
		application: Application;
		error: Error;
	};
}

export type Action =
	| ImportCreatingAction
	| ImportDatabaseCreatingTaskStreamAction
	| ImportDatabaseCreatedAction
	| ImportDatabaseFailedAction
	| ImportUploadsCreatingTaskStreamAction
	| ImportUploadsCreatedAction
	| ImportUploadsFailedAction;

export default function createImport(
	application: Application,
	importOptions: ImportOptions,
	api: API
): ThunkAction<Promise<any>, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'IMPORT_CREATING',
			payload: {
				application,
				importOptions,
			},
		} );

		const promises = [];

		window.analytics.track( 'import_started', {
			...importOptions,
			application: application.id,
			fromApplication: importOptions.application,
		} );

		if ( importOptions.database ) {
			const databaseImportPromise = api
				.post( `/stack/applications/${application.id}/import-database`, {
					stream: true,
					from_application: importOptions.application,
				} )
				.catch(
					( error: Error ): any => {
						// Hash that we should look up
						if ( error.message.indexOf( application.id ) === 0 ) {
							dispatch( {
								type: 'IMPORT_DATABASE_CREATING_TASK_STREAM',
								payload: {
									application,
									taskStream: error.message,
								},
							} );
							return dispatch( fetchTaskStream( error.message, application ) ).then( () => {
								dispatch( {
									type: 'IMPORT_DATABASE_CREATED',
									payload: {
										application,
									},
								} );
								if ( Notification ) {
									new Notification( 'Import Database Completed 🚀', {
										body: application.id,
									} );
								}
								return application;
							} );
						}
						dispatch( {
							type: 'IMPORT_DATABASE_FAILED',
							payload: {
								application,
								error,
							},
						} );
						if ( Notification ) {
							new Notification( 'Import Database Failed!', {
								body: application.id,
							} );
						}
					}
				);
			promises.push( databaseImportPromise );
		}

		if ( importOptions.uploads ) {
			const uploadsImportPromise = api
				.post( `/stack/applications/${application.id}/import-uploads`, {
					stream: true,
					from_application: importOptions.application,
					path: importOptions.path,
				} )
				.catch(
					( error: Error ): any => {
						// Hash that we should look up
						if ( error.message.indexOf( application.id ) === 0 ) {
							dispatch( {
								type: 'IMPORT_UPLOADS_CREATING_TASK_STREAM',
								payload: {
									application,
									taskStream: error.message,
								},
							} );
							return dispatch( fetchTaskStream( error.message, application ) ).then( () => {
								dispatch( {
									type: 'IMPORT_UPLOADS_CREATED',
									payload: {
										application,
									},
								} );
								if ( Notification ) {
									new Notification( 'Import Uploads Completed 🚀', {
										body: application.id,
									} );
								}
								return application;
							} );
						}
						dispatch( {
							type: 'IMPORT_UPLOADS_FAILED',
							payload: {
								application,
								error,
							},
						} );
						if ( Notification ) {
							new Notification( 'Import Uploads Failed!', {
								body: application.id,
							} );
						}
					}
				);
			promises.push( uploadsImportPromise );
		}
		return Promise.all( promises );
	};
}
