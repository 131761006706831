import React from 'react';
import MaximizeIcon from 'react-feather/dist/icons/maximize';
import MinimizeIcon from 'react-feather/dist/icons/minimize';

import './Toolbar.css';

interface Props {
	application: string,
	maximized?: boolean,
	onClose?(): void,
	onToggleMaximized(): void,
}

export default function Toolbar( props: Props ) {
	return (
		<div className="Console-Toolbar">
			<div>
				{ props.maximized && (
					<h1 className="Console-Toolbar__name">
						{ props.application }
					</h1>
				) }
			</div>
			<div>
				<button
					title={ props.maximized ? 'Minimize terminal' : 'Maximize terminal' }
					type="button"
					onClick={ props.onToggleMaximized }
				>
					{ props.maximized ? (
						<MinimizeIcon
							color="#d6d6d6"
							size={ 18 }
						/>
					) : (
						<MaximizeIcon
							color="#d6d6d6"
							size={ 18 }
						/>
					) }
				</button>
			</div>
		</div>
	);
}
