import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../reducers';

import ErrorBoundary from './ErrorBoundary';
import PinnedNotices from './PinnedNotices';
import Sidebar from './Sidebar';

interface Props {
	children: React.ReactNode,
}

export default function NonAppWrap( props: Props ) {
	const globalNotices = useSelector( ( state: AppState ) => state.currentUser.user?.notices );

	return (
		<div className="horizontal">
			<Sidebar />

			<div className="page-outer">
				{ globalNotices && (
					<PinnedNotices
						notices={ globalNotices || [] }
					/>
				) }

				<div className="page">
					<ErrorBoundary>
						{ props.children }
					</ErrorBoundary>
				</div>
			</div>
		</div>
	);
}
