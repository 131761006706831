import React from 'react';

import './TabBarButton.css';

export default function TabBarButton( props ) {
	const { isActive, name, onClick } = props;

	return (
		<button
			className={ isActive ? 'TabBarItem active' : 'TabBarItem' }
			type="button"
			onClick={ onClick }
		>
			<span className="name">{ name }</span>
		</button>
	);
}
