import React from 'react';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import GlobeIcon from 'react-feather/dist/icons/globe';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ApplicationResponse } from '../api';
import { regionDetails } from '../lib/regions';
import { AppState } from '../reducers';

import { ReactComponent as LogoSVG } from './logo-small.svg';

const getFriendlyName = ( app: ApplicationResponse ) => {
	switch ( app['environment-type'] ) {
		case 'production':
			return 'Production';

		case 'staging':
			return 'Staging';

		case 'development':
			return 'Development';

		case 'test':
			return 'Test';

		default:
			return app.id;
	}
};

type Section = {
	title: string,
	link: string,
}

interface Props {
	application: ApplicationResponse,
	sections?: Section[],
	title: React.ReactNode | string | false,
}

export default function ApplicationHeader( props: Props ) {
	const { application, title } = props;
	const instanceId = application['altis-instance'] || '';
	const instance = useSelector( ( state: AppState ) => state.instances.data[ instanceId ] );
	const instanceLoading = useSelector( ( state: AppState ) => state.instances.loading[ instanceId ] );
	const instanceError = useSelector( ( state: AppState ) => state.instances.errors[ instanceId ] );
	if ( ! instance || instanceLoading || instanceError ) {
		return null;
	}

	const friendly = getFriendlyName( application );
	const altisVersion = parseInt( application['altis-version']?.split( '. ' )[0] || '0', 10 );

	return (
		<div className="border-b border-gray-200 pb-5 mb-4 lg:flex lg:items-center lg:justify-between">
			<div className="min-w-0 flex-1">
				<nav
					aria-label="Breadcrumb"
					className="flex"
				>
					<ol className="flex items-center space-x-4">
						<li>
							<div className="flex">
								<Link
									className="text-sm font-medium !text-gray-500 !hover:text-gray-700"
									to={ `/i/${ instance.id }` }
								>
									{ instance.name }
								</Link>
							</div>
						</li>
						{ title !== false && (
							<li>
								<div className="flex items-center">
									<ChevronRightIcon
										aria-hidden="true"
										className="h-5 w-5 flex-shrink-0 text-gray-400"
									/>
									<Link
										className="ml-4 text-sm font-medium !text-gray-500 !hover:text-gray-700"
										to={ `/i/${ instance.id }/e/${ application.id }` }
									>
										{ friendly }
										{ friendly !== application.id && (
											` (${ application.id })`
										) }
									</Link>
								</div>
							</li>
						) }
						{ props.sections && props.sections.map( section => (
							<li>
								<div className="flex items-center">
									<ChevronRightIcon
										aria-hidden="true"
										className="h-5 w-5 flex-shrink-0 text-gray-400"
									/>
									<Link
										className="ml-4 text-sm font-medium !text-gray-500 !hover:text-gray-700"
										to={ section.link }
									>
										{ section.title }
									</Link>
								</div>
							</li>
						) ) }
					</ol>
				</nav>
				<h2 className="flex items-center mt-2 !text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
					{ title ? title : (
						<>
							{ friendly }
							{ friendly !== application.id && (
								` (${ application.id })`
							) }
						</>
					) }
				</h2>
				<div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
					<span className="mt-2 flex items-center text-sm text-gray-500">
						<LogoSVG
							aria-hidden="true"
							className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
						/>
						Altis v{ altisVersion }
						{ ' ' }
						<span className="ml-1.5 text-gray-400">
							(PHP { props.application['php-version'] })
						</span>
					</span>
					<span className="mt-2 flex items-center text-sm text-gray-500">
						<GlobeIcon
							aria-hidden="true"
							className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
						/>
						<span className="sr-only">Region:</span>
						<abbr title={ props.application.region }>
							{ regionDetails[ props.application.region as keyof typeof regionDetails ]?.title || props.application.region }
						</abbr>
					</span>
				</div>
			</div>
			<div className="mt-5 flex lg:mt-0 lg:ml-4">
				<span className="sm:ml-3">

					{ application && application.domains.length > 0 && (
						<a
							className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium !text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
							href={ `http://${application.domains[0]}` }
							rel="noopener noreferrer"
							target="_blank"
						>
							View Site

							<ExternalLinkIcon
								className="ml-1.5 flex-shrink-0"
								size={ 16 }
							/>
						</a>
					) }
				</span>
			</div>
		</div>
	);
}
