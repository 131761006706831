import React, { useState } from 'react';

import { NotificationDestinationsResponse } from '../../types/rest-api';
import ErrorBlock from '../ErrorBlock';
import SolidButton from '../SolidButton';

type NotificationDestination = NotificationDestinationsResponse['destinations'][0];

type NewNotificationDestinationProps = {
	onSubmit( destination: NotificationDestination ): Promise<boolean>,
}

type NotificationType = {
	id: NotificationDestination['notification_types'][0],
	title: string,
	description: string,
}

export const notificationTypes: NotificationType[] = [
	{
		id: 'deploy.start',
		title: 'Deployment Start',
		description: 'When a deployment is started by a user or autodeployment',
	},
	{
		id: 'deploy.end',
		title: 'Deployment End',
		description: 'When a deployment finishes successfully or with an error',
	},
];
const defaultNotificationDestination: NotificationDestination = {
	type: 'slack',
	slack_endpoint: '',
	notification_types: [],
};

export default function NewNotificationDestinationForm( props: NewNotificationDestinationProps ) {
	const [ destination, setDestination ] = useState<NotificationDestination>( { ...defaultNotificationDestination } );
	const [ isSaving, setSaving ] = useState<boolean>( false );
	const [ error, setError ] = useState<string | null>( null );

	const onSubmit = async ( e: React.FormEvent ) => {
		e.preventDefault();
		setSaving( true );
		setError( null );

		try {
			await props.onSubmit( destination );
			setDestination( { ...defaultNotificationDestination } );
		} catch ( e ) {
			setError( ( e as Error ).message );
		}

		setSaving( false );
	};

	const onChangeType = async ( e: React.ChangeEvent<HTMLSelectElement> ) => {
		let newDestination: NotificationDestination;

		switch ( e.target.value ) {
			case 'slack':
				newDestination = {
					type: 'slack',
					notification_types: destination.notification_types,
					slack_endpoint: '',
				};
				break;
			case 'email':
				newDestination = {
					type: 'email',
					notification_types: destination.notification_types,
					email: '',
				};
				break;
			default:
				return;
		}
		setDestination( newDestination );
	};

	const onChangeEmail = ( email: string ) => {
		setDestination( {
			...destination,
			email,
			type: 'email',
		} );
	};

	const onChangeSlackEndpoint = ( slack_endpoint: string ) => {
		setDestination( {
			...destination,
			slack_endpoint,
			type: 'slack',
		} );
	};

	const onChangeNotificationType = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		let newDestination = { ...destination };
		let notificationType = e.target.value as NotificationDestination['notification_types'][0];

		if ( e.target.checked ) {
			newDestination.notification_types.push( notificationType );
			setDestination( newDestination );
		} else {
			newDestination.notification_types.splice( newDestination.notification_types.indexOf( notificationType ), 1 );
			setDestination( newDestination );
		}
	};

	return (
		<form
			className="NewNotificationDestinationForm"
			onSubmit={ onSubmit }
		>
			<label className="my-4">
				<span className="block text-sm font-medium text-gray-700">Destination</span>
				<select
					className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
					onChange={ onChangeType }
				>
					<option selected={ destination.type === 'slack' } value="slack">Slack</option>
					<option selected={ destination.type === 'email' } value="email">Email</option>
				</select>
			</label>

			{ destination.type === 'slack' && (
				<>
					<p className="my-4 text-sm text-gray-500">
						Receive notifications to a Slack webhook, which will deliver messages to Slack rooms. Follow the <a href="https://api.slack.com/messaging/webhooks" rel="noopener noreferrer" target="_blank">Slack Incoming Webhooks</a> guide to create your webhook.
					</p>
					<label>
						<span className="block text-sm font-medium text-gray-700">Slack Incoming Webhook URL</span>
						<input
							className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
							placeholder="https://hooks.slack.com/services/..."
							required
							type="url"
							value={ destination.slack_endpoint }
							onChange={ e => onChangeSlackEndpoint( e.target.value ) }
						/>
					</label>
				</>
			) }

			{ destination.type === 'email' && (
				<>
					<p className="my-4 text-sm text-gray-500">
						Receive email notifications when events are triggered.
					</p>
					<label>
						<span>Email Address</span>
						<input
							className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
							placeholder="notify@example.com"
							required
							type="email"
							value={ destination.email }
							onChange={ e => onChangeEmail( e.target.value ) }
						/>
					</label>
				</>
			) }

			<div className="my-4">
				<span className="text-sm font-medium text-gray-700">Events</span>
				<ul className="my-2 space-y-5">
					{ notificationTypes.map( type => (
						<li
							key={ type.id }
							className="relative flex items-start"
						>
							<div>
								<input
									aria-labelledby={ `NewNotificationDestinationForm__type-desc-${ type.id }` }
									checked={ destination.notification_types?.indexOf( type.id ) > -1 }
									className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
									id={ `NewNotificationDestinationForm__type-${ type.id }` }
									type="checkbox"
									value={ type.id }
									onChange={ onChangeNotificationType }
								/>
							</div>
							<label
								className="ml-3 text-sm"
								htmlFor={ `NewNotificationDestinationForm__type-${ type.id }` }
							>
								<p
									className="font-medium text-gray-700"
								>
									{ type.title }
								</p>

								<p
									className="text-gray-500"
									id={ `NewNotificationDestinationForm__type-desc-${ type.id }` }
								>
									{ type.description }
								</p>
							</label>
						</li>
					) ) }
				</ul>
			</div>

			{ error && (
				<ErrorBlock
					message={ error }
					small
				/>
			) }

			<SolidButton
				disabled={ isSaving }
				name="Add"
				type="submit"
			/>
		</form>
	);
}
