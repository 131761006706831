import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router';

import { defaultAPI, cookieAuthApi } from '../../../api';
import ErrorBlock from '../../ErrorBlock';

interface Props {
	onLogin?: ( response: any ) => void,
}

export default function Login( props: Props ) {

	const [ mfaMethod, setMfaMethod ] = useState( '' );
	const [ mfaProviders, setMfaProviders ] = useState( [] );
	const [ email, setEmail ] = useState( '' );
	const [ password, setPassword ] = useState( '' );
	const [ token, setToken ] = useState( '' );
	const [ errorMessage, setErrorMessage ] = useState( '' );

	let session: { nonce: string }|null = null;

	let mfaLabels = {
		backup_codes: '2FA Backup Code',
		totp: '2FA Code',
		email: 'Email 2FA Code',
	};

	const history = useHistory();

	const handleSubmit = async ( event: FormEvent<HTMLFormElement> ) => {
		event.preventDefault();
		setErrorMessage( '' );
		try {
			const data: { username: string, password: string, auth_nonce?: string, '2fa'?: { provider: string, value: string }, remember: true } = {
				username: email,
				password: password,
				auth_nonce: window.VantageInitData?.auth_nonce,
				remember: true,
			};
			if ( mfaMethod ) {
				data['2fa'] = {
					provider: mfaMethod,
					value: token,
				};
			}
			// make sure nonce is not set on the API when sending requests.
			( defaultAPI as cookieAuthApi ).nonce = undefined;
			session = await defaultAPI.post( '/sessions/v0/sessions', data );
		} catch ( e ) {
			if ( e.errors['2fa_required'] ) {
				setMfaProviders( e.errors['2fa_required']['data']['2fa_providers'] );

				if ( mfaMethod === '' ) {
					setMfaMethod( e.errors['2fa_required']['data']['2fa_providers'][0] );
				}
			} else if ( e.code === 'rest_cookie_invalid_nonce' ) {
				// Auth nonce is invalid.
				setErrorMessage( 'Login nonce is invalid, it may have expired. Please reload the page and try again.' );
				return;
			}
			// Show the message from the first error that is not 2fa_required
			Object.entries( e.errors ).forEach( ( [ code, error ] ) => {
				if ( code === '2fa_required' ) {
					return;
				}
				// @ts-ignore
				setErrorMessage( error.message );
			} );
		}

		if ( props.onLogin && session ) {
			props.onLogin( session );
			window.analytics.track( 'Sign in' );
			history.push( '/accelerate/' );
		}

	};
	return (
		<div className="flex flex-col justify-center pt-10 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form className="space-y-6" onSubmit={ handleSubmit }>
						<div>
							<label className="block text-sm font-medium text-gray-700" htmlFor="email">
								Email address
							</label>
							<div className="mt-1">
								<input
									autoComplete="email"
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									id="email"
									name="email"
									required
									type="text"
									onChange={ e => setEmail( e.target.value ) }
								/>
							</div>
						</div>

						<div>
							<label className="block text-sm font-medium text-gray-700" htmlFor="password">
								Password
							</label>
							<div className="mt-1">
								<input
									autoComplete="current-password"
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									id="password"
									name="password"
									required
									type="password"
									onChange={ e => setPassword( e.target.value ) }
								/>
							</div>
						</div>

						{ mfaProviders.length >= 1 &&
							<>
								<div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
									{ mfaProviders.map( provider => (
										<div key={ provider } className="flex items-center">
											<input
												checked={ provider === mfaMethod }
												className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
												id={ `provider-${ provider }` }
												name="notification-method"
												type="radio"
												onChange={ e => {
													e.target.checked && setMfaMethod( provider );
												} }
											/>
											<label className="ml-3 block text-sm font-medium text-gray-700" htmlFor={ `provider-${ provider }` }>
												{ mfaLabels[ provider ] }
											</label>
										</div>
									) ) }
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700" htmlFor="token">
										Token
									</label>
									<div className="mt-1">
										<input
											autoComplete="one-time-code"
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											id="token"
											name="token"
											required
											type="text"
											onChange={ e => setToken( e.target.value ) }
										/>
									</div>
								</div>
							</> }

						<div className="flex items-center justify-between">
							<div className="text-sm">
								<a className="font-medium text-indigo-600 hover:text-indigo-500" href={ window.VantageInitData?.login_url + '?action=lostpassword&amp;redirect_to=/accelerate/login' }>
									Forgot your password?
								</a>
							</div>
						</div>

						{ errorMessage && <ErrorBlock message={ <span dangerouslySetInnerHTML={ { __html: errorMessage } }></span> } /> }

						<div>
							<button
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								type="submit"
							>
								Sign in
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
