import React, { useEffect, useState } from 'react';

import PillButton from './PillButton';

import './PersonalDataCollectionOptIn.css';

type Props = {
	onRespondToPersonalDataCollection: ( answer: boolean ) => null;
}

export default function PersonalDataCollectionOptIn( props: Props ) {
	const [ hasShown, setShown ] = useState( false );
	useEffect( () => {
		setShown( true );
	}, [] );

	const onRespondToPersonalDataCollection = ( answer: boolean ) => {
		setShown( false );
		setTimeout( () => props.onRespondToPersonalDataCollection( answer ), 500 );
	};

	return (
		<div className={ `PersonalDataCollectionOptIn ${ hasShown && 'PersonalDataCollectionOptIn__shown' }` }>
			<span>We track activity on Altis Dashboard to improve the customer experience and understand how you are using features to make recommendations and feature activation suggestions. Do you consent to sharing your personal information for these purposes?</span>
			<span>
				<PillButton
					name="Agree"
					onClick={ () => onRespondToPersonalDataCollection( true ) }
				/>
			</span>
			<span>
				<PillButton
					color="#666"
					name="Reject"
					onClick={ () => onRespondToPersonalDataCollection( false ) }
				/>
			</span>
		</div>
	);
}
