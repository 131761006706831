import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { fetchApplications } from '../actions';

export function SelectInstanceApplication( props ) {
	const { instances, applications, instancesLoading, ...otherProps } = props;
	const [ selectedInstance, setSelectedInstance ] = useState( null );
	const instanceApplications = Object.values( applications ).filter( application => application['altis-instance'] === selectedInstance );

	return (
		<div className="SelectInstanceApplication">
			<Select
				getOptionLabel={ instance => instance.name }
				getOptionValue={ instance => instance.id }
				isDisabled={ otherProps.isDisabled }
				isLoading={ instancesLoading }
				options={ instances }
				placeholder="Select Instance..."
				onChange={ instance => {
					setSelectedInstance( instance.id );
					props.onSelectInstance( instance.id );
				} }
			/>
			<Select
				getOptionLabel={ application => application.id }
				getOptionValue={ application => application.id }
				isDisabled={ ! selectedInstance }
				isLoading={ selectedInstance && instanceApplications.length === 0 }
				options={ instanceApplications }
				placeholder="Select Environment..."
				{ ...otherProps }
			/>
		</div>
	);
}

const mapStateToProps = state => ( {
	applications: state.applications,
	instances: state.instances.archives.all ? Object.values( state.instances.data ) : [],
	instancesLoading: state.instances.archives.all?.loading || false,
} );

const mapDispatchToProps = dispatch => ( {
	onSelectInstance( instance ) {
		dispatch( fetchApplications( { instance } ) );
	},
} );

export default connect( mapStateToProps, mapDispatchToProps )( SelectInstanceApplication );
