export interface TimeProps {
	after: string,
	before: string,
}

export interface Filter extends TimeProps {
	search: string,
}

export interface BasicLogEntry {
	date: string,
	id: string,
	message: string,
}

export interface PhpLogEntry extends BasicLogEntry {
	level: string,
}

export function getRepoUrl( repo: string ) {
	const foundRepo = repo.match( /git@github\.com:(.*?).git$/ );
	return foundRepo ? foundRepo[1] : undefined;
}
