import React from 'react';
import DeleteIcon from 'react-feather/dist/icons/x-circle';

import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import { InstanceResponse, NotificationDestinationsResponse } from '../../types/rest-api';
import SettingsGroup from '../SettingsGroup';

import NewNotificationDestinationForm, { notificationTypes } from './NewNotificationDestinationForm';

const destinationLabels = {
	email: 'Email',
	slack: 'Slack',
};

interface Props {
	instance: InstanceResponse,
	fetch: Window['fetch'],
	invalidateData(): void,
}

type NotificationDestinationType = NotificationDestinationsResponse['destinations'][0];

type ConnectedProps = {
	notificationDestinations: ApiResponse<NotificationDestinationsResponse>,
};

type AllProps = Props & ConnectedProps;

function NotificationDestinations( props: AllProps ) {
	const updateDestinations = async ( destinations: NotificationDestinationType[] ) : Promise<boolean> => {
		const res = await props.fetch( `/stack/instances/${ props.instance.id }/notifications/destinations`, {
			method: 'POST',
			body: JSON.stringify( {
				destinations,
			} ),
			headers: {
				'Content-Type': 'application/json',
			},
		} );
		const data = await res.json();
		if ( ! res.ok ) {
			throw new Error( data.message );
		}

		props.invalidateData();
		return true;
	};
	const onRemoveDestination = ( destination: NotificationDestinationType ) => {
		const destinations = [ ...props.notificationDestinations.data?.destinations || [] ];
		destinations.splice( destinations.indexOf( destination ), 1 );
		return updateDestinations( destinations );
	};

	const onAddDestination = async ( destination: NotificationDestinationType ) : Promise<boolean> => {
		const destinations = [ ...props.notificationDestinations.data?.destinations || [] ];
		destinations.push( destination );
		return updateDestinations( destinations );
	};

	return (
		<>
			<SettingsGroup
				description="Currently configured destinations for your events."
				title="Configured Destinations"
			>
				<ul className="divide-y divide-gray-200">
					{ props.notificationDestinations.data?.destinations.map( ( item, index ) => (
						<li
							key={ index }
							className="flex items-center justify-between py-4"
						>
							<div>
								<p className="truncate text-sm">
									<span className="font-medium">{ destinationLabels[ item.type ] || item.type }:</span>
									{ ' ' }
									<span className="text-gray-500">
										{ item.type === 'email' && (
											<span>{ item.email }</span>
										) }
										{ item.type === 'slack' && (
											<span>{ item.slack_endpoint }</span>
										) }
									</span>
								</p>
								<ul className="my-1 space-x-2">
									{ item.notification_types.map( type => (
										<li
											key={ type }
											className="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800"
										>
											{ notificationTypes.find( t => t.id === type )?.title || type }
										</li>
									) ) }
								</ul>
							</div>
							<div className="ml-2">
								<button
									className="InstanceUsers-User__delete"
									title="Remove notification destination"
									onClick={ () => onRemoveDestination( item ) }
								>
									<span className="sr-only">Remove notification destination</span>
									<DeleteIcon
										size={ 20 }
									/>
								</button>
							</div>
						</li>
					) ) }
				</ul>
			</SettingsGroup>

			<SettingsGroup title="Add Destination">
				<NewNotificationDestinationForm
					onSubmit={ onAddDestination }
				/>
			</SettingsGroup>
		</>
	);
}

const mapPropsToData = ( props: Props ) => ( {
	notificationDestinations: `/stack/instances/${ props.instance.id }/notifications/destinations`,
} );

export default withApiData( mapPropsToData )( NotificationDestinations );
