import React from 'react';
import AlertTriangleIcon from 'react-feather/dist/icons/alert-triangle';
import CheckIcon from 'react-feather/dist/icons/check';
import InfoIcon from 'react-feather/dist/icons/info';
import MessageSquareIcon from 'react-feather/dist/icons/message-square';
import OctagonIcon from 'react-feather/dist/icons/x-octagon';

import Pill from '../Pill';

const statusColorMap = {
	error: {
		background: '#d11602',
	},
	warning: {
		background: '#ff9a00',
	},

	notice: {
		background: '#1f73b7',
	},
	log: {
		background: '#000',
		color: '#fff',
	},
	success: {
		background: '#3FCF8E',
	},
	unknown: {
		background: '#68737d',
	},
};
const iconMap ={
	error: OctagonIcon,
	warning: AlertTriangleIcon,
	notice: InfoIcon,
	log: MessageSquareIcon,
	success: CheckIcon,
	unknown: MessageSquareIcon,
};

type LogLevel = keyof typeof statusColorMap;
type LogLevelProps = {
	level: LogLevel,
	text: string,
	title: string,
}
export default function Level( props: LogLevelProps ) {
	const Icon = iconMap[ props.level ] || iconMap['unknown'];
	return (
		<Pill
			style={ statusColorMap[ props.level as LogLevel ] || statusColorMap['unknown'] }
			title={ props.level }
		>
			<Icon size={ 12 } />{ ' ' }
			{ props.text }
		</Pill>
	);
}
