import React, { Component } from 'react';
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';

import { ApiResponse } from '../../types/api';
import ErrorBlock from '../ErrorBlock';
import FormattedDateTime from '../FormattedDateTime';
import PageTitle from '../PageTitle';
import PHPLogLoading from '../PHPLogLoading';
import TabBar from '../TabBar';
import TabBarItem from '../TabBarItem';

import Errors from './Errors';
import FlamegraphPage from './FlamegraphPage';
import Request from './Request';
import Timeline from './Timeline';
import { Trace } from './types';
import { getQuerySegments } from './util';

import './index.css';

type PropTypes = {
	trace: ApiResponse<Trace>;
}

class XRayTrace extends Component<RouteComponentProps & PropTypes> {
	state = {
		selectedTab: 'flamegraph',
	};

	render() {
		if ( this.props.trace.isLoading ) {
			return <PHPLogLoading />;
		}

		if ( this.props.trace.error ) {
			return <ErrorBlock message={ this.props.trace.error.message } />;
		}

		const traceData: Trace = this.props.trace.data;
		const main = this.props.trace.data.Segments[0].Document;
		const databaseQueries = getQuerySegments( this.props.trace.data.Segments );
		const remoteRequests = this.props.trace.data.Segments.filter(
			segment => segment.Document.parent_id && segment.Document.http
		);
		const errors = ( main.cause && typeof main.cause === 'object' && main.cause.exceptions ) || [];

		const tabs = [
			{
				path: '',
				name: 'Flamegraph',
			},
			{
				path: '/request',
				name: 'Request',
			},
			{
				path: '/timeline',
				name: 'Timeline',
			},
			{
				path: '/errors',
				name: `Errors (${ errors.length })`,
			},
		];

		return (
			<div className="XRayTrace">
				<PageTitle
					title={ `Trace ${ this.props.trace.data.Id }` }
				/>

				<ul className="header-block">
					<li>
						<span>Method</span>
						<span className="value">{ main.http && main.http.request && main.http.request.method }</span>
					</li>
					<li>
						<span>Response</span>
						<span className="value">{ main.http && main.http.response && main.http.response.status }</span>
					</li>
					<li>
						<span>Duration</span>
						<span className="value">{ this.props.trace.data.Duration }</span>
					</li>
					<li>
						<span>Trace ID</span>
						<span className="value">{ this.props.trace.data.Id }</span>
					</li>
					<li>
						<span>Request Time</span>
						<span className="value">
							<FormattedDateTime
								format="long-precise"
								value={ main.start_time * 1000 }
							/>
						</span>
					</li>
					<li>
						<span>Queries</span>
						<span className="value">
							{ databaseQueries.length }
						</span>
					</li>
					<li>
						<span>Remote Requests</span>
						<span className="value">
							{ remoteRequests.length }
						</span>
					</li>
				</ul>

				<code className="url">{ main.http && main.http.request && main.http.request.url }</code>

				{ main.in_progress && (
					<ErrorBlock
						message="Warning: Request is still in progress and data may be non-final. If the request has been running for a long time, this may indicate the request has crashed."
					/>
				) }

				<TabBar>
					{ tabs.map( tab => (
						<Route
							key={ tab.path }
							exact
							path={ this.props.match.path + tab.path }
						>
							{ ( { match } ) => (
								<TabBarItem
									isActive={ !! match }
									name={ tab.name }
									to={ this.props.match.url + tab.path }
								/>
							) }
						</Route>
					) ) }
				</TabBar>

				<Route
					exact
					path={ `${this.props.match.path}` }
					render={ () => (
						<FlamegraphPage
							trace={ traceData }
						/>
					) }
				/>
				<Route
					exact
					path={ `${this.props.match.path}/request` }
					render={ () => (
						<Request
							main={ main }
						/>
					) }
				/>
				<Route
					path={ `${this.props.match.path}/timeline` }
					render={ () => (
						<Timeline
							main={ main }
							segments={ this.props.trace.data?.Segments || [] }
						/>
					) }
				/>
				<Route
					path={ `${this.props.match.path}/errors` }
					render={ () => (
						<Errors
							errors={ errors }
						/>
					) }
				/>
			</div>
		);
	}
}

// Cannot for the life of me work out how to correct this `any`.
// Let me know if you work it out. -RM
export default withRouter<RouteComponentProps & PropTypes, any>( XRayTrace );
