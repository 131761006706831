import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';
import { SupportTicketResponse } from '../types/rest-api';

interface UpdatingSupportTicketAction extends ReduxAction {
	type: 'UPDATING_SUPPORT_TICKET';
}

interface UpdatedSupportTicketAction extends ReduxAction {
	type: 'UPDATED_SUPPORT_TICKET';
	supportTicket: SupportTicketResponse;
}

export type Action = UpdatingSupportTicketAction | UpdatedSupportTicketAction;

export default function fetchSupportTicket(
	api: API,
	ticketId: string
): ThunkAction<Promise<SupportTicketResponse>, AppState, null, Action> {
	return dispatch => {
		dispatch( { type: 'UPDATING_SUPPORT_TICKET' } );

		return api.get( `/stack/support-tickets/${ticketId}` ).then( ( ticket: SupportTicketResponse ) => {
			if ( ticket.author.avatar_urls['96'] === null ) {
				ticket.author.avatar_urls = {
					'96': 'https://secure.gravatar.com/avatar/83888eb8aea456e4322577f96b4dbaab?s=96&d=mm&r=g',
				};
			}
			dispatch( {
				type: 'UPDATED_SUPPORT_TICKET',
				supportTicket: ticket,
			} );
			return ticket;
		} );
	};
}
