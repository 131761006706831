import React from 'react';
import './PHPLogLoading.css';

export default function PHPLogLoading() {
	return (
		<div className="PHPLogLoading">
			{ [ 1, 2, 3, 4 ].map( log => (
				<div key={ log } className="item">
					<div className="animated-background" />
				</div>
			) ) }
		</div>
	);
}
