import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';

import fetchTaskStream from './fetchTaskStream';

interface BuildCreatingAction extends ReduxAction {
	type: 'BUILD_CREATING';
	payload: {
		application: Application;
	};
}

interface BuildCreatingTaskStreamAction extends ReduxAction {
	type: 'BUILD_CREATING_TASK_STREAM';
	payload: {
		application: Application;
		taskStream: string;
	};
}

interface BuildCreatedAction extends ReduxAction {
	type: 'BUILD_CREATED';
	payload: {
		application: Application;
	};
}

interface BuildFailedAction extends ReduxAction {
	type: 'BUILD_FAILED';
	payload: {
		application: Application;
		error: Error;
	};
}

export type Action = BuildCreatingAction | BuildCreatingTaskStreamAction | BuildCreatedAction | BuildFailedAction;

function buildFailed( error: string, application: Application, dispatch: Function ) {
	dispatch( {
		type: 'BUILD_FAILED',
		payload: {
			application,
			error,
		},
	} );
	if ( Notification ) {
		new Notification( 'Build Failed ❌', {
			body: application.id,
		} );
	}
}

export default function createBuild( application: Application, api: API ): ThunkAction<void, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'BUILD_CREATING',
			payload: {
				application,
			},
		} );
		const params: any = {
			force: true,
			stream: true,
		};
		return api
			.post( `/stack/applications/${application.id}/builds`, params )
			.then( response => {
				dispatch( {
					type: 'BUILD_CREATING_TASK_STREAM',
					payload: {
						application,
						taskStream: response.log,
					},
				} );
				return dispatch( fetchTaskStream( response.log, application ) )
					.then( () => {
						dispatch( {
							type: 'BUILD_CREATED',
							payload: {
								application,
							},
						} );
						if ( Notification ) {
							new Notification( 'Build Completed ✅', {
								body: application.id,
							} );
						}
						return application;
					} )
					.catch( err => buildFailed( err, application, dispatch ) );
			} )
			.catch( err => buildFailed( err, application, dispatch ) );
	};
}
