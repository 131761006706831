import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { sortLogItems } from '../lib/utils';
import { WithApiData } from '../lib/with-api-data';

import ApplicationLogsWarning from './ApplicationLogsWarning';
import CronLogItem from './CronLogItem';
import CronTasksRunning from './CronTasksRunning';
import FilterLogs from './FilterLogs';
import MetricGraph from './MetricGraph';

import './CronLogs.css';

class CronLogs extends Component {
	static propTypes = {
		application: PropTypes.string.isRequired,
	};
	constructor( props ) {
		super( props );
		this.state = {
			status: [ 'success', 'fail' ],
			after: this.props.after || '4 hours ago',
			before: this.props.before || 'now',
			search: '',
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}
	onChangeFilter( filter ) {
		this.apiData.invalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	}
	render() {
		return (
			<div className="CronLogs">
				<div className="metrics">
					<MetricGraph
						application={ this.props.application }
						dimensions={ { Application: this.props.application } }
						from="1 day ago"
						height={ 80 }
						label="Completed Last 24 Hours"
						name="Completed"
						namespace="Cavalcade"
						period={ 600 }
						statistic="Sum"
						unitLabel=" succeeded"
					/>
					<MetricGraph
						application={ this.props.application }
						dimensions={ { Application: this.props.application } }
						from="1 day ago"
						height={ 80 }
						label="Failed Last 24 Hours"
						name="Failed"
						namespace="Cavalcade"
						period={ 600 }
						statistic="Sum"
						unitLabel=" failed"
					/>
				</div>
				<CronTasksRunning application={ this.props.application } />
				<FilterLogs { ...this.state } onChangeFilter={ filter => this.onChangeFilter( filter ) } />
				<WithApiData
					ref={ apiData => ( this.apiData = apiData ) }
					data={ {
						logs: `/stack/applications/${this.props.application}/logs/cron?status=${this.state.status.join(
							','
						)}&after=${this.state.after}&before=${this.state.before}&search=${this.state.search}`,
						counts: `/api/applications/${this.props.application}/cron/counts`,
						runningTasks: `/api/applications/${this.props.application}/cron/running`,
					} }
					render={ props => {
						return (
							<ApplicationLogsWarning
								logs={ props.logs }
								renderLogs={ logs =>
									logs
										.slice()
										.sort( sortLogItems )
										.map( log => <CronLogItem key={ log.id } date={ new Date( log.date ) } { ...log } /> ) }
							/>
						);
					} }
					{ ...this.props }
				/>
			</div>
		);
	}
}

export default CronLogs;
