import PropTypes from 'prop-types';
import React from 'react';

import Pill from '../Pill';

const statusColorMap = {
	open: {
		background: '#c72a1c',
		description: 'We are working to resolve this ticket.',
	},
	pending: {
		background: '#1f73b7',
		description: 'This ticket is awaiting your reply.',
	},
	hold: {
		background: '#000',
		description: 'We are waiting on a third-party update.',
	},
	new: {
		background: '#ffb648',
		color: '#703b15',
		description: 'We have received your ticket and will get back to you soon.',
	},
	solved: {
		background: '#68737d',
		description: 'This ticket has been marked as solved.',
	},
	closed: {
		background: '#e9ebed',
		color: '#2b559e',
		description: 'This ticket is permanently closed and cannot be updated.',
	},
};

export default function SupportListItem( props ) {
	const mapped = statusColorMap[ props.status ];
	const { background, color, description } = mapped || {};

	return (
		<Pill
			color={ background }
			style={ { color } }
			title={ description }
		>
			{ props.status }
		</Pill>
	);
}

SupportListItem.propTypes = {
	status: PropTypes.oneOf( Object.keys( statusColorMap ) ),
};
