import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import React from 'react';
import ChevronDown from 'react-feather/dist/icons/chevron-down';
import ChevronUp from 'react-feather/dist/icons/chevron-up';
import { FormattedTime } from 'react-intl';

import OutlineButton from './OutlineButton';
import TaskLog from './TaskLog';

import './RunningTask.css';

export default function RunningTask( props ) {
	return (
		<div className="RunningTask">
			<div className="preview">
				<div className="description">
					<h5>{ props.title }</h5>
					<span className="initiatedAt" title={ props.startDate }>
						{ 'Initiated at ' }
						<FormattedTime
							timeZoneName="short"
							value={ props.startDate }
						/>
					</span>
				</div>
				<button className="expand-toggle" onClick={ props.onToggleTaskDetail }>
					{ props.isExpanded ? <ChevronUp color="#3FCF8E" /> : <ChevronDown color="#3FCF8E" /> }
				</button>
			</div>
			{ props.isExpanded ? (
				<div className="details">
					<div className="progress">
						<span>{ props.progress.toFixed( 2 ) }%</span>
						<Line percent={ props.progress } strokeColor="#3FCF8E" strokeWidth={ 2 } trailWidth={ 2 } />
					</div>
					<TaskLog task={ props } />
					<div className="button-row">
						<OutlineButton
							className="remove-task"
							color="#E2182C"
							disabled={ props.isRemoving }
							name={ props.isRemoving ? 'Removing...' : 'Remove Task' }
							onClick={ props.onRemoveTask }
						/>
					</div>
				</div>
			) : null }
		</div>
	);
}

RunningTask.propTypes = {
	isExpanded: PropTypes.bool,
	onToggleTaskDetail: PropTypes.func.isRequired,
	progress: PropTypes.number.isRequired,
	messages: PropTypes.arrayOf(
		PropTypes.shape( {
			message: PropTypes.string.isRequired,
		} )
	).isRequired,
	phases: PropTypes.arrayOf(
		PropTypes.shape( {
			phaseType: PropTypes.string.isRequired,
			phaseStatus: PropTypes.string.isRequired,
		} )
	),
};
