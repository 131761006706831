export interface Message {
	message: string;
}

// Reference:
// https://docs.aws.amazon.com/codebuild/latest/userguide/view-build-details.html#view-build-details-phases
// https://docs.aws.amazon.com/codebuild/latest/APIReference/API_BuildPhase.html
type CodeBuildStep =
	'SUBMITTED' |
	'QUEUED' |
	'PROVISIONING' |
	'DOWNLOAD_SOURCE' |
	'INSTALL' |
	'PRE_BUILD' |
	'BUILD' |
	'POST_BUILD' |
	'UPLOAD_ARTIFACTS' |
	'FINALIZING' |
	'COMPLETED';

export type CodeBuildStatus =
	'FAILED' |
	'FAULT' |
	'PENDING' |
	'IN_PROGRESS' |
	'QUEUED' |
	'STOPPED' |
	'SUCCEEDED' |
	'TIMED_OUT';

export const FAILED_STATUSES = [
	'FAILED',
	'FAULT',
	'STOPPED',
];

export type CodeBuildPhase = {
	contexts?: {
		message?: string;
		statusCode?: string;
	}[];
	startTime: string | null;
	endTime: string | null;
	durationInSeconds?: number;
	phaseStatus: CodeBuildStatus;
	phaseType: CodeBuildStep;
}

export type StepName = 'SETUP' | 'PREPARE' | 'BUILD' | 'CLEANUP';

export interface Step {
	messages: Message[];
	status: CodeBuildStatus | 'SKIPPED';
	contexts: {
		message?: string;
		statusCode?: string;
	}[];
	start: any;
	end: any;
	duration: number | null;
}

// Maps CodeBuildStep to StepName.
const PHASE_MAP: { [ k: string]: StepName } = {
	PROVISIONING: 'SETUP',
	DOWNLOAD_SOURCE: 'SETUP',
	INSTALL: 'SETUP',
	PRE_BUILD: 'PREPARE',
	BUILD: 'BUILD',
	POST_BUILD: 'CLEANUP',
	UPLOAD_ARTIFACTS: 'CLEANUP',
	FINALIZING: 'CLEANUP',
};
const FINAL_PHASE_STEPS = [
	'INSTALL',
	'PRE_BUILD',
	'BUILD',
	'POST_BUILD',
];

const ENTERING_PHASE = /^\[Container] (\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}(?:\.\d{6})?) (?:Phase is|Entering phase) (\w+)\n?$/;
const COMPLETE_PHASE = /^\[Container] (\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}(?:\.\d{6})?) Phase complete: ([A-Z_]+) State: ([A-Z_]+)\n?$/;
const PHASE_CONTEXT = /^\[Container].+?Phase context status code: ([A-Z_]*) Message: (.*)\n?$/;

export function groupBuildLogMessages( phases: CodeBuildPhase[], messages: Message[] ) {
	// Set up the main groups.
	const grouped: { [ k: string]: Step } = {};
	for ( const phase of Object.values( PHASE_MAP ) ) {
		if ( grouped[ phase ] ) {
			continue;
		}

		grouped[ phase ] = {
			messages: [],
			status: 'QUEUED',
			contexts: [],
			start: null,
			end: null,
			duration: null,
		};
	}

	// Then, combine messages.
	let step: StepName = 'SETUP';
	for ( const item of messages ) {
		const entering = item.message.match( ENTERING_PHASE );
		if ( entering ) {
			switch ( entering[2] ) {
				case 'PRE_BUILD':
					step = 'PREPARE';
					break;

				case 'BUILD':
					step = 'BUILD';
					break;

				case 'POST_BUILD':
					step = 'CLEANUP';
					break;
			}

			if ( grouped[ step ].status === 'QUEUED' ) {
				// We've started the next step, so it must be IN_PROGRESS.
				grouped[ step ].status = 'IN_PROGRESS';

				// (JS doesn't like the "2023/01/01 ..." format when
				// milliseconds are added, so converts to "2023-01-01 ...")
				grouped[ step ].start = entering[1].replace( /\//g, '-' );
			}
		} else if ( grouped[ step ].status === 'QUEUED' || grouped[ step ].status === 'IN_PROGRESS' ) {
			// When a build is in progress, we can't rely on the phase data
			// being complete, so we need to manually match as well.
			const stepComplete = item.message.match( COMPLETE_PHASE );
			if ( stepComplete && ( FINAL_PHASE_STEPS.indexOf( stepComplete[2] ) > -1 || stepComplete[3] !== 'SUCCEEDED' ) ) {
				// Ensure we work even if out-of-order.
				const completedStep = PHASE_MAP[ stepComplete[2] ];

				// Override the status, but only if it's the last step.
				grouped[ completedStep ].status = stepComplete[3] as CodeBuildStatus;

				// Set times as well.
				// (Mangle data, as per .start note above.)
				grouped[ completedStep ].end = stepComplete[1].replace( /\//g, '-' );

				const start = new Date( grouped[ completedStep ].start );
				const end = new Date( grouped[ completedStep ].end );
				grouped[ completedStep ].duration = end.getTime() - start.getTime();
			}
		} else {
			const phaseContext = item.message.match( PHASE_CONTEXT );
			if ( phaseContext ) {
				grouped[ step ].contexts.push( {
					statusCode: phaseContext[1],
					message: phaseContext[2],
				} );
			}
		}

		grouped[ step ].messages.push( item );
	}

	// For any other group which is missing data, work out what's up.
	let hasFailed = false;
	for ( const groupName of Object.keys( grouped ) ) {
		const group = grouped[ groupName ];

		// Override the status.
		if ( ! group.status || group.status === 'QUEUED' ) {
			group.status = hasFailed ? 'SKIPPED' : 'QUEUED';
		} else if ( FAILED_STATUSES.indexOf( group.status ) > -1 ) {
			hasFailed = true;
		}
	}

	return grouped;
}

export interface Build {
	id: string;
	source_version: string;
}

export interface Deploy {
	build: string;
	rev: string;
}

// Attach builds and deploys.
export const collectBuilds = ( builds: Build[], deploys: Deploy[] ) => {
	if ( ! builds ) {
		return null;
	}

	return builds.map( build => {
		// Find the corresponding deployments if we can.
		const matchingDeploys = deploys ? deploys.filter( deploy => {
			if ( deploy.build ) {
				return deploy.build === build.id;
			}
			return deploy.rev === build.source_version;
		} ) : [];

		return {
			id: build.id,
			build,
			deploys: matchingDeploys,
		};
	} );
};
