/* eslint-disable import/order */
import image1 from '../images/login/1.jpg';
import image2 from '../images/login/2.jpg';
import image3 from '../images/login/3.jpg';
import image4 from '../images/login/4.jpg';
import image5 from '../images/login/5.jpg';
import image6 from '../images/login/6.jpg';
import image7 from '../images/login/7.jpg';
import image8 from '../images/login/8.jpg';
import image9 from '../images/login/9.jpg';
import image10 from '../images/login/10.jpg';
import image11 from '../images/login/11.jpg';
import image12 from '../images/login/12.jpg';
import image13 from '../images/login/13.jpg';
import image14 from '../images/login/14.jpg';
import image15 from '../images/login/15.jpg';
import image16 from '../images/login/16.jpg';
import image17 from '../images/login/17.jpg';
import image18 from '../images/login/18.jpg';
import image19 from '../images/login/19.jpg';
import image20 from '../images/login/20.jpg';
import image21 from '../images/login/21.jpg';
import image22 from '../images/login/22.jpg';
import image23 from '../images/login/23.jpg';
import image24 from '../images/login/24.jpg';
import image25 from '../images/login/25.jpg';
import image26 from '../images/login/26.jpg';
import image27 from '../images/login/27.jpg';
import image28 from '../images/login/28.jpg';
import image29 from '../images/login/29.jpg';
import image30 from '../images/login/30.jpg';
import image31 from '../images/login/31.jpg';
import image32 from '../images/login/32.jpg';
import image33 from '../images/login/33.jpg';

export default [
	image1,
	image2,
	image3,
	image4,
	image5,
	image6,
	image7,
	image8,
	image9,
	image10,
	image11,
	image12,
	image13,
	image14,
	image15,
	image16,
	image17,
	image18,
	image19,
	image20,
	image21,
	image22,
	image23,
	image24,
	image25,
	image26,
	image27,
	image28,
	image29,
	image30,
	image31,
	image32,
	image33,
];
