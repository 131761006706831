import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { defaultAPI } from './api';
import App from './App';
import { Provider as APIProvider } from './lib/with-api-data';
import reducers from './reducers';

import './index.css';

const store = createStore(
	reducers,
	undefined,
	composeWithDevTools( applyMiddleware( thunk ) )
);

window.reduxStore = store;

const render = ( Component: React.ElementType ) => {
	return ReactDOM.render(
		<Provider store={ store }>
			<IntlProvider locale={ navigator.language }>
				{ window.VantageInitData ? (
					<BrowserRouter basename={ process.env.PUBLIC_URL }>
						<APIProvider fetch={ ( url: string, options: any ) => defaultAPI.fetch( url, options ) }>
							<Component />
						</APIProvider>
					</BrowserRouter>
				) : (
					<HashRouter>
						<APIProvider fetch={ ( url: string, options: any ) => defaultAPI.fetch( url, options ) }>
							<Component />
						</APIProvider>
					</HashRouter>
				) }
			</IntlProvider>
		</Provider>,
		document.getElementById( 'root' )
	);
};

const root = document.getElementById( 'root' );
if ( root ) {
	Modal.setAppElement( root );
}
render( App );

if ( module.hot ) {
	module.hot.accept( './App', () => {
		const NextApp = require( './App' ).default;
		render( NextApp );
	} );
}
