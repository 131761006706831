import React, { useEffect, useState } from 'react';

import { ApplicationResponse } from '../../api';
import Button from '../Button';
import InlineLoader from '../InlineLoader';
import Modal from '../Modal';
import useId from '../useId';

import DomainRow from './DomainRow';
import { Domain } from './Domains';
import DomainSteps, { Step } from './DomainSteps';

interface OptionProps {
	description: string,
	name: string,
	label: string,
	selected: boolean,
	value: string,
	onSelect(): void,
}

function Option( props: OptionProps ) {
	const id = useId();

	return (
		<div className="relative flex items-start">
			<div className="flex h-5 items-center">
				<input
					aria-describedby={ `${ id }-description` }
					checked={ props.selected }
					className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500"
					id={ id }
					name={ props.name }
					type="radio"
					value={ props.value }
					onChange={ props.onSelect }
				/>
			</div>
			<label
				className="ml-3 text-sm"
				htmlFor={ id }
			>
				<p className="font-medium text-gray-700">
					{ props.label }
				</p>
				<p className="text-gray-500">
					{ props.description }
				</p>
			</label>
		</div>
	);
}

type DomainType = 'base' | 'www' | 'wildcard';

enum Stage {
	entry,
	select,
	verify,
}

type VerifyProps = {
	application: ApplicationResponse,
	domains: Domain[],
	new: string,
}

function Verify( props: VerifyProps ) {
	const matching = props.domains.filter( domain => domain.name === props.new );

	return (
		<div className="my-5 h-[50vh] overflow-y-auto">
			<p className="mb-2">
				To verify that you own the domain, you'll need to add
				verification records. These records verify you control the
				domain, and allow us to provision SSL certificates on
				your behalf.
			</p>
			<p className="mb-2">
				These may take a few days to verify. Visit the Domains page
				any time to check the status.
			</p>

			{ matching.length < 1 && (
				<p className="block border bg-gray-50 border-gray-200 text-sm text-gray-500 rounded-lg shadow-sm px-4 py-5">
					<InlineLoader className="-ml-1 mr-3 h-5 w-5" />

					Loading…
				</p>
			) }

			{ matching.map( domain => (
				<DomainRow
					application={ props.application }
					defaultOpen
					domain={ domain }
					showSteps={ false }
				/>
			) ) }
		</div>
	);
}

interface OwnProps {
	application: ApplicationResponse,
	domains: Domain[],
	internalDomain: string,
	onCreate( domains: string[] ): Promise<void>,
}

interface ModalProps {
	onDone(): void,
}

function AddDomain( props: OwnProps & ModalProps ) {
	const [ stage, setStage ] = useState<Stage>( Stage.entry );
	const [ domain, setDomain ] = useState<string>( '' );
	const [ type, setType ] = useState<DomainType>( 'base' );
	const [ creating, setCreating ] = useState<boolean>( false );
	const [ isValid, setIsValid ] = useState<boolean>( false );
	const [ domainRef, setDomainRef ] = useState<HTMLInputElement | null>( null );
	const id = useId();
	const hostId = id + '-host';

	const hasText = domain.length >= 3;
	const alreadyAdded = !! props.domains.find( item => item.name === domain );

	useEffect( () => {
		if ( domainRef ) {
			setIsValid( domainRef?.validity.valid === true );
		}
	}, [ domain, domainRef ] );

	const onCreate = () => {
		let domains: string[];
		switch ( type ) {
			case 'base':
				domains = [
					domain,
				];
				break;

			case 'www':
				domains = [
					domain,
					`www.${ domain }`,
				];
				break;

			case 'wildcard':
				domains = [
					domain,
					`*.${ domain }`,
				];
				break;
		}

		setCreating( true );
		props.onCreate( domains ).then( () => {
			setStage( Stage.verify );
		} );
	};
	const onSave = ( e: React.FormEvent ) => {
		if ( e ) {
			e.preventDefault();
		}

		if ( ! isValid || alreadyAdded ) {
			return;
		}

		if ( stage !== Stage.select ) {
			setStage( stage + 1 );
		} else {
			onCreate();
		}
	};

	let saveLabel = stage === Stage.select ? 'Add domain' : 'Next…';
	if ( creating ) {
		saveLabel = 'Adding…';
	}

	const disabled = ! hasText || ! isValid || alreadyAdded || creating;

	const step = {
		[ Stage.entry ]: Step.registration,
		[ Stage.select ]: Step.registration,
		[ Stage.verify ]: Step.verification,
	}[ stage ];

	return (
		<form
			className="pr-1"
			onSubmit={ onSave }
		>
			<DomainSteps
				current={ step }
			/>
			{ stage === Stage.entry ? (
				<div className="my-5">
					<p className="mb-5">Select which domain to add. (Don't worry, no traffic will be switched yet.)</p>
					<label
						className="sr-only"
						htmlFor={ hostId }
					>
						<span className="sr-only">Hostname</span>
					</label>

					<div className="flex mt-1 rounded-md shadow-sm">
						<span
							className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
						>
							https://
						</span>
						<input
							ref={ setDomainRef }
							aria-describedby={ hostId + '-desc' }
							autoFocus
							className={ [
								'block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm',
								( hasText && ! isValid ) && 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500',
								( hasText && isValid ) && 'text-green-900 border-green-300 focus:ring-green-500 focus:border-green-500',
							].filter( Boolean ).join( ' ' ) }
							pattern="([a-z0-9\-]+\.)+[a-z0-9\-]+"
							placeholder="www.example.com"
							type="text"
							value={ domain }
							onChange={ e => setDomain( e.target.value ) }
						/>
					</div>

					{ hasText ? (
						alreadyAdded ? (
							<p className="mt-2 text-sm text-red-600">
								Whoops, you've already added that domain!
							</p>
						) : isValid ? (
							<p className="mt-2 text-sm text-green-600">
								✅ Ready to add!
							</p>
						) : (
							<p className="mt-2 text-sm text-red-600">
								Whoops, that domain isn't valid! Domains should
								only consist of lowercase letters, numbers,
								hyphens, and dots, like
								{ ' ' }
								<code className="text-xs">your-1st-domain.example.com</code>
							</p>
						)
					) : (
						<p
							className="mt-2 text-sm text-gray-500"
							id={ hostId + '-desc' }
						>
							Enter your top-level domain or subdomain to start.
						</p>
					) }
				</div>
			) : stage === Stage.select ? (
				<div className="my-5 space-y-5">
					<p>Select which variants of your domain to add.</p>
					<Option
						description="Just this domain."
						label={ domain }
						name="domaintype"
						selected={ type === 'base' }
						value="base"
						onSelect={ () => setType( 'base' ) }
					/>
					<Option
						description="This domain and the www variant."
						label={ `www.${ domain }, ${ domain }` }
						name="domaintype"
						selected={ type === 'www' }
						value="www"
						onSelect={ () => setType( 'www' ) }
					/>
					<Option
						description="This domain and all subdomains (including www)."
						label={ `*.${ domain }, ${ domain }` }
						name="domaintype"
						selected={ type === 'wildcard' }
						value="wildcard"
						onSelect={ () => setType( 'wildcard' ) }
					/>
				</div>
			) : (
				<Verify
					application={ props.application }
					domains={ props.domains }
					new={ domain }
				/>
			) }

			<footer className="flex align-middle py-3">
				<div className="flex-grow">
					{ ( stage > 0 && stage !== Stage.verify ) && (
						<Button
							type="button"
							variant="secondary"
							onClick={ () => setStage( stage - 1 ) }
						>
							Back
						</Button>
					) }
				</div>
				<div className="flex-shrink-0 flex self-end">
					{ stage !== Stage.verify ? (
						<Button
							disabled={ disabled }
							variant="primary"
							onClick={ onSave }
						>
							{ saveLabel }
						</Button>
					) : (
						<Button
							variant="primary"
							onClick={ props.onDone }
						>
							Close
						</Button>
					) }
				</div>
			</footer>
		</form>
	);
}

export default function AddDomainModal( props: OwnProps ) {
	const [ showingModal, setShowingModal ] = useState<boolean>( false );
	return (
		<>
			<Modal
				isOpen={ showingModal }
				title="Add a domain"
				onClose={ () => setShowingModal( false ) }
			>
				<AddDomain
					{ ...props }
					onDone={ () => setShowingModal( false ) }
				/>
			</Modal>
			<Button
				variant="primary"
				onClick={ () => setShowingModal( true ) }
			>
				<>Add new domain…</>
			</Button>
		</>
	);
}
