import PropTypes from 'prop-types';
import React from 'react';

import './BackupListItem.css';
import OutlineButton from './OutlineButton';

export default function BackupListItem( props ) {
	return (
		<div className="BackupListItem">
			<div className="backup-details">
				<strong>
					<a href={ props.url }>{ props.id }</a>
				</strong>
				<div className="backup-info">
					<span>{ Math.round( props.size / 1024 / 1014 ) } MB</span>
					{ props.uploads && <span className="pill">Uploads { props.uploads_path && `(${props.uploads_path})` }</span> }
					{ props.database && <span className="pill">Database</span> }
				</div>
			</div>
			{ props.onDownload && <OutlineButton name="Download" onClick={ props.onDownload } /> }
		</div>
	);
}

BackupListItem.propTypes = {
	url: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	size: PropTypes.number.isRequired,
	uploads: PropTypes.bool,
	database: PropTypes.bool,
	onDownload: PropTypes.func,
};
