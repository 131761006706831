import React from 'react';

import './Tooltip.css';

interface Props {
	children: React.ReactNode,
}

export default function Tooltip( props: Props ) {
	return (
		<div className="bg-white text-sm rounded-md border shadow-lg px-4 py-2">
			{ props.children }
		</div>
	);
}
