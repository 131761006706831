import React from 'react';

import { Exception } from './types';

import './Errors.css';

type PropTypes = {
	errors: Exception[];
};

export default function Errors( props: PropTypes ) {
	const { errors } = props;
	return (
		<div className="XRayTrace-Errors">
			{ errors.map( error => {
				return (
					<div className="XRayTrace-Errors__error">
						<div className="XRayTrace-Errors__message">
							<span className="XRayTrace-Errors__message-type">{ error.type }</span>
							<span className="XRayTrace-Errors__message-text">{ error.message }</span>
						</div>
						{ error.stack && (
							<div className="XRayTrace-Errors__source">
								{ error.stack.map( err => `${err.path}:${err.line}` ).join( ' => ' ) }
							</div>
						) }
					</div>
				);
			} ) }
		</div>
	);
}
