import React, { Component } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import { NavLink } from 'react-router-dom';
import RemarkBreaks from 'remark-breaks';
import RemarkGfm from 'remark-gfm';

import { SupportTicketCommentResponse } from '../../types/rest-api';
import FormattedContent from '../FormattedContent';

import MessageHeader from './MessageHeader';
import { remarkTicketReferences } from './util';

import './Message.css';

const customLink : Components['a'] = ( { node: _, children, ...props } ) => {
	const relativized = props.href?.replace( /^https?:\/\/dashboard.altis-dxp.com\/(next\/)?(#\/)?/, '/' );
	const isRelative = relativized?.substring( 0, 1 ) === '/';

	return isRelative ? (
		<NavLink
			to={ relativized }
		>
			{ children }
		</NavLink>
	) : (
		<a
			{ ...props }
			// eslint-disable-next-line react/jsx-no-target-blank
			target="_blank"
		>
			{ children }
		</a>
	);
};

const plugins: PluggableList = [
	RemarkBreaks,
	[
		RemarkGfm,
		{
			singleTilde: false,
		},
	],
	remarkTicketReferences,
];

type Props = {

} & SupportTicketCommentResponse

export default class SupportTicketMessage extends Component<Props> {
	render() {
		const classes = [
			'SupportTicketMessage',
			this.props.public ? 'public' : 'private',
			this.props.source ? `SupportTicketMessage--source-${ this.props.source }` : null,
		];

		return (
			<div className={ classes.filter( Boolean ).join( ' ' ) }>
				<MessageHeader
					{ ...this.props }
				/>

				<div className="SupportTicketMessage__content">
					<FormattedContent>
						{ this.props.source === 'email' ? (
							this.props.content.rendered
						) : (
							<ReactMarkdown
								components={ {
									a: customLink,
								} }
								remarkPlugins={ plugins }
							>
								{ this.props.content.rendered }
							</ReactMarkdown>
						) }
					</FormattedContent>
				</div>

				{ this.props.attachments && this.props.attachments.length > 0 && (
					<ul className="SupportTicketMessage__attachments">
						<p className="SupportTicketMessage__attachments-header">Attached files:</p>
						{ this.props.attachments.map( attachment => (
							<li key={ attachment.id }>
								<a href={ attachment.url } rel="noopener noreferrer" target="_blank">{ attachment.name }</a>
							</li>
						) ) }
					</ul>
				) }
			</div>
		);
	}
}
