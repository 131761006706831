import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';

import LiveLineChart from './LiveLineChart';
import MetricLine from './MetricLine';

import './MetricGraph.css';

class MetricGraph extends Component {
	static propTypes = {
		name: PropTypes.string.isRequired,
		height: PropTypes.number.isRequired,
		application: PropTypes.string.isRequired,
		dimensions: PropTypes.object,
		label: PropTypes.string.isRequired,
		from: PropTypes.string.isRequired,
		namespace: PropTypes.string.isRequired,
		statistic: PropTypes.string.isRequired,
		period: PropTypes.number.isRequired,
		unitLabel: PropTypes.string,
		timeMarker: PropTypes.object,
		onHoverTimeline: PropTypes.func,
	};
	render() {
		const endpoint = `/stack/metrics/${this.props.application}/metrics?${qs.stringify( {
			namespace: this.props.namespace,
			dimensions: this.props.dimensions,
			from: this.props.from,
			statistic: this.props.statistic,
			name: this.props.name,
			period: this.props.period,
		} )}`;
		return (
			<div className="MetricGraph" style={ { height: `${this.props.height}px` } }>
				<span className="title">{ this.props.label }</span>
				<LiveLineChart
					component={ MetricLine }
					domain={ this.props.domain }
					endpoint={ endpoint }
					fadeOut={ false }
					height={ this.props.height }
					nullIsZero={ false }
					timeMarker={ this.props.timeMarker }
					unit={ this.props.unitLabel }
					onHoverTimeline={ this.props.onHoverTimeline }
				/>
			</div>
		);
	}
}

export default MetricGraph;
