import { CheckCircleIcon } from '@heroicons/react/solid';

interface Props {
	currentStep: number,
}

const steps = [
	{ name: 'Add Webite URL' },
	{ name: 'Add Plugin' },
	{ name: 'Add API Key' },
	{ name: 'Verify Installation 🎉' },
];

export default function Nav( props: Props ) {
	return (
		<div className="px-2 sm:px-6 lg:px-8">
			<h2 className="text-2xl pb-10 text-gray-400">Steps</h2>
			<nav aria-label="Progress" className="flex">
				<ol className="space-y-6">
					{ steps.map( ( step, stepNumber ) => {
						stepNumber++;
						return (
							<li key={ step.name }>
								{ stepNumber < props.currentStep ? (
									<span className="group">
										<span className="flex items-start">
											<span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
												<CheckCircleIcon
													aria-hidden="true"
													className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
												/>
											</span>
											<span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
												{ step.name }
											</span>
										</span>
									</span>
								) : stepNumber === props.currentStep ? (
									<span aria-current="step" className="flex items-start">
										<span
											aria-hidden="true"
											className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
										>
											<span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
											<span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
										</span>
										<span className="ml-3 text-sm font-medium text-indigo-600">{ step.name }</span>
									</span>
								) : (
									<span className="group">
										<div className="flex items-start">
											<div
												aria-hidden="true"
												className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
											>
												<div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
											</div>
											<p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
												{ step.name }
											</p>
										</div>
									</span>
								) }
							</li>
						);
					} ) }
				</ol>
			</nav>
		</div>
	);
}
