import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { withApiData } from '../lib/with-api-data';
import { ApiResponse } from '../types/api';
import { ApplicationResponse } from '../types/rest-api';

import Sidebar from './Sidebar';

interface Props {
	application: ApiResponse<ApplicationResponse>,
}

type RouteProps = Pick<RouteComponentProps<{
	application: string,
	subpath?: string,
}>, 'match'>;

function ApplicationRedirecter( props: Props & RouteProps ) {
	if ( props.application.isLoading ) {
		return (
			<div className="horizontal">
				<Sidebar
					title="Loading…"
				/>
				<div className="page-outer">
					<div className="page">
						<p>Loading…</p>
					</div>
				</div>
			</div>
		);
	}

	if ( props.application.error ) {
		return (
			<div className="horizontal">
				<Sidebar />
				<div className="page-outer">
					<div className="page">
						<p>Could not load application.</p>
					</div>
				</div>
			</div>
		);
	}

	let path = `/i/${ props.application.data['altis-instance'] }/e/${ props.application.data.id }`;
	if ( props.match.params.subpath ) {
		path += '/' + props.match.params.subpath;
	}

	return (
		<Redirect
			to={ path }
		/>
	);
}

export default withApiData( ( props: RouteProps ) => ( {
	application: `/stack/applications/${ props.match.params.application }`,
} ) )( ApplicationRedirecter );
