import PropTypes from 'prop-types';
import React from 'react';
import MailIcon from 'react-feather/dist/icons/mail';

import Avatar from '../Avatar';
import FormattedRelativeTime from '../FormattedRelativeTime';
import Pill from '../Pill';
import PillButton from '../PillButton';

import './MessageHeader.css';

export default function SupportTicketMessageHeader( props ) {
	if ( ! props.author ) {
		return null;
	}

	return (
		<div className="SupportTicketMessageHeader">
			<Avatar size={ 40 } user={ props.author } />

			<div className="SupportTicketMessageHeader__details">
				<span>{ props.author.name }</span>

				{ props.source === 'email' && (
					<span
						className="SupportTicketMessageHeader__no-separator SupportTicketMessageHeader__source"
						title="Comment received via email"
					>
						<MailIcon
							size={ 14 }
						/>
					</span>
				) }

				{ props.author.staff && (
					<span className="SupportTicketMessageHeader__no-separator">
						<Pill>Altis Staff</Pill>
					</span>
				) }

				{ props.date && (
					<FormattedRelativeTime
						value={ props.date }
					/>
				) }

				{ props.onSetPublic ? (
					<span>
						<Pill style={ { padding: '4px 4px 2px 2px' } }>
							<label>
								<input
									checked={ ! props.public }
									type="checkbox"
									value="1"
									onChange={ e => props.onSetPublic( ! e.target.checked ) }
								/>{ ' ' }
								Internal Note
							</label>
						</Pill>
					</span>
				) : ! props.public && (
					<span>
						<Pill>Internal Note</Pill>
					</span>
				) }
			</div>

			{ props.onShowFollowers && (
				<div className="SupportTicketMessageHeader__show-ccs">
					<PillButton
						name="CCs"
						onClick={ props.onShowFollowers }
					/>
				</div>
			) }
		</div>
	);
}

SupportTicketMessageHeader.propTypes = {
	author: PropTypes.shape( {
		name: PropTypes.string.isRequired,
		avatar_urls: PropTypes.shape( {
			'96': PropTypes.string,
		} ).isRequired,
	} ).isRequired,
	date: PropTypes.string,
	public: PropTypes.bool,
	source: PropTypes.string,
	onSetPublic: PropTypes.func,
	onShowFollowers: PropTypes.func,
};
