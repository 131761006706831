import React from 'react';

import FormattedDateTime from '../FormattedDateTime';

interface Props {
	date: Date,
}

function Timestamp( props: Props ) {
	return (
		<FormattedDateTime
			className="ApplicationLogs-Timestamp"
			format="short-precise"
			value={ props.date }
		/>
	);
}

export default Timestamp;
