import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { defaultAPI } from '../api';
import { AppState } from '../reducers';
import { InstanceResponse } from '../types/rest-api';

interface FetchInstanceRequestAction extends ReduxAction {
	type: 'FETCH_INSTANCE_REQUEST';
	payload: {
		id: string;
	};
}

interface FetchInstanceAction extends ReduxAction {
	type: 'FETCH_INSTANCE';
	payload: {
		id: string;
		data: InstanceResponse;
	};
}

interface FetchInstanceErrorAction extends ReduxAction {
	type: 'FETCH_INSTANCE_ERROR',
	payload: {
		id: string;
		error: Error;
	};
}

export type Action = FetchInstanceRequestAction | FetchInstanceAction | FetchInstanceErrorAction;

export default function fetchInstance( id: string ): ThunkAction<Promise<InstanceResponse>, AppState, null, Action> {
	return async dispatch => {
		dispatch( {
			type: 'FETCH_INSTANCE_REQUEST',
			payload: {
				id,
			},
		} );

		try {
			const instance: InstanceResponse = await defaultAPI.get( `/stack/instances/${ id }` );

			dispatch( {
				type: 'FETCH_INSTANCE',
				payload: {
					id,
					data: instance,
				},
			} );
			return instance;
		} catch ( error ) {
			dispatch( {
				type: 'FETCH_INSTANCE_ERROR',
				payload: {
					id,
					error,
				},
			} );

			throw error;
		}
	};
}
