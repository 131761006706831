import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import DatabaseIcon from 'react-feather/dist/icons/database';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import GlobeIcon from 'react-feather/dist/icons/globe';
import MonitorIcon from 'react-feather/dist/icons/monitor';
import PackageIcon from 'react-feather/dist/icons/package';
import { Link } from 'react-router-dom';

import { ApplicationResponse, InstanceResponse } from '../../types/rest-api';
import InternalBlock from '../InternalBlock';

import OverviewStats, { Loader as OverviewLoader } from './OverviewStats';
import { getProdEnv, getStagingEnv } from './util';
import Welcome from './Welcome';

interface Props {
	applications: ApplicationResponse[],
	applicationsLoading: boolean,
	instance?: InstanceResponse,
	instanceLoading: boolean,
	instanceError?: Error,
}

type ActionProps = {
	children: React.ReactNode,
	icon?: React.ElementType,
	to: string,
}

const AppAction = ( props: ActionProps ) => (
	<li>
		<Link
			className="flex items-center justify-between group py-3 !text-gray-600 hover:!text-blue-600"
			to={ props.to }
		>
			<div className="flex items-center space-x-2">
				{ props.icon && (
					<props.icon
						className="h-4 w-4"
					/>
				) }

				<span>{ props.children }</span>
			</div>

			<ChevronRightIcon
				className="h-5 w-5 text-gray-600 translate-x-0 transition-transform group-hover:translate-x-1"
			/>
		</Link>
	</li>
);

type AppProps = {
	application: ApplicationResponse,
	baseUrl: string,
	name?: string,
}

const Application = ( props: AppProps ) => {
	const app = props.application;
	return (
		<li className="overflow-hidden rounded-xl border border-gray-200 flex flex-col justify-between">
			<div className="px-6 py-6 bg-gray-50">
				<h3 className="flex flex-wrap justify-between items-baseline">
					<Link
						className="group flex items-center"
						to={ props.baseUrl }
					>
						{ props.name || app.id }

						<ChevronRightIcon
							className="h-4 w-4 translate-x-0 transition-transform group-hover:translate-x-1"
						/>
					</Link>
					{ props.name && (
						<code
							className="text-xs text-gray-500"
						>
							{ app.id }
						</code>
					) }
				</h3>

				<ol className="flex flex-wrap items-baseline justify-between gap-y-2">
					{ app.domains.length > 0 && (
						<li>
							<a
								className="text-xs inline-flex items-center gap-x-1"
								href={ `http://${ app.domains[0] }` }
								rel="noopener noreferrer"
								target="_blank"
							>
								{ app.domains[0] }
								{ ' ' }
								<ExternalLinkIcon
									className="w-3 h-3 align-middle"
								/>
							</a>
						</li>
					) }
					{ app['altis-version'] && (
						<li
							className="text-xs text-gray-500"
						>
							Altis { app['altis-version'] }
						</li>
					) }
				</ol>
			</div>
			<ol className="-mt-2 divide-y divide-gray-100 px-6 py-2 text-sm leading-6">
				<AppAction
					icon={ PackageIcon }
					to={ `${ props.baseUrl }/deploys` }
				>
					Release
				</AppAction>
				<AppAction
					icon={ DatabaseIcon }
					to={ `${ props.baseUrl }/import-export` }
				>
					Data
				</AppAction>
				<AppAction
					icon={ MonitorIcon }
					to={ `${ props.baseUrl }/xray` }
				>
					X-Ray
				</AppAction>
			</ol>
		</li>
	);
};

function AppLoader() {
	return (
		<ContainerDimensions>
			{ ( { width } ) => (
				<ContentLoader
					className=""
					height={ 240 }
					// @ts-ignore
					primaryColor="#f3f3f3"
					secondaryColor="#ecebeb"
					speed={ 2 }
					width={ width }
				>
					<rect
						height="240"
						rx="12"
						ry="12"
						width={ ( width - 64 ) / 3 }
						x="0"
						y="0"
					/>
					<rect
						height="240"
						rx="12"
						ry="12"
						width={ ( width - 64 ) / 3 }
						x={ ( ( width - 64 ) / 3 ) + 32 }
						y="0"
					/>
				</ContentLoader>
			) }
		</ContainerDimensions>
	);
}

export default function Overview( props: Props ) {
	if ( props.instanceLoading ) {
		return (
			<div className="Instance-Overview">
				<h1>…</h1>
				<OverviewLoader />

				<h2>Environments</h2>
				<AppLoader />
			</div>
		);
	}
	if ( ! props.instance || props.instanceError ) {
		return null;
	}

	if ( ! props.applicationsLoading && props.applications.length < 1 ) {
		return (
			<div className="Instance-Overview">
				<h1>{ props.instance.name }</h1>
				<Welcome />
			</div>
		);
	}

	const production = getProdEnv( props.applications );
	const staging = getStagingEnv( props.applications );
	const other = props.applications.filter( app => (
		( ! production || app.id !== production.id ) && ( ! staging || app.id !== staging.id )
	) );
	const baseUrl = `/i/${ props.instance.id }`;

	return (
		<div className="Instance-Overview">
			<div className="md:flex md:items-center md:justify-between">
				<div className="min-w-0 flex-1">
					<h1 className="!m-0 !mt-2 !text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
						{ props.instance.name }
					</h1>
					<div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
						{ props.applications.length > 0 && (
							<span
								className="mt-2 flex items-center text-sm text-gray-500"
							>
								<GlobeIcon
									aria-hidden="true"
									className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								/>
								{ props.applications.length !== 1 ? (
									`${ props.applications.length } environments`
								) : (
									`${ props.applications.length } environment`
								) }
							</span>
						) }
					</div>
				</div>
			</div>
			{ props.instance.internal_note && (
				<InternalBlock
					className="mb-4"
				>
					<InternalBlock.Title>Internal Note</InternalBlock.Title>

					<p>{ props.instance.internal_note }</p>
				</InternalBlock>
			) }

			{ props.applicationsLoading && (
				<OverviewLoader />
			) }

			{ production && (
				<OverviewStats
					application={ production.id }
				/>
			) }

			<h2>Environments</h2>
			{ props.applicationsLoading ? (
				<AppLoader />
			) : (
				<ul className="my-4 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8 xl:grid-cols-3">
					{ production && (
						<Application
							application={ production }
							baseUrl={ `${ baseUrl }/e/${ production.id }` }
							name="Production"
						/>
					) }
					{ staging && (
						<Application
							application={ staging }
							baseUrl={ `${ baseUrl }/e/${ staging.id }` }
							name="Staging"
						/>
					) }
					{ other.map( app => (
						<Application
							key={ app.id }
							application={ app }
							baseUrl={ `${ baseUrl }/e/${ app.id }` }
						/>
					) ) }
				</ul>
			) }
		</div>
	);
}
