import React from 'react';
import { connect } from 'react-redux';

import followSupportTicket from '../../actions/followSupportTicket';
import unfollowSupportTicket from '../../actions/unfollowSupportTicket';
import { defaultAPI as api } from '../../api';
import PillButton from '../PillButton';

import './Followers.css';

export class Followers extends React.Component {
	state = { loading: false };
	onFollow = () => {
		this.setState( { loading: true } );
		this.props.onFollow()
			.then( () => {
				this.setState( { loading: false } );
			} );
	};

	onUnfollow = () => {
		this.setState( { loading: true } );
		this.props.onUnfollow()
			.then( () => {
				this.setState( { loading: false } );
			} );
	};

	getStatus() {
		const { assignee, followers, requester, user } = this.props;
		const isRequester = requester && requester.id === user.id;
		const isAssignee = assignee && assignee.id === user.id;
		const isFollowing = followers && followers.indexOf( user.id ) >= 0;

		switch ( true ) {
			case isRequester:
				return {
					reason: 'You are following this ticket because you reported it.',
					button: false,
				};

			case isAssignee:
				return {
					reason: 'You are following this ticket because you are assigned to it.',
					button: false,
				};

			case isFollowing:
				return {
					reason: 'You are following this ticket.',
					button: true,
					status: true,
				};

			default:
				return {
					reason: 'You are not following this ticket.',
					button: true,
					status: false,
				};
		}
	}

	render() {
		if ( ! this.props.user ) {
			return null;
		}

		// Is the backend up-to-date?
		// TODO: remove once vantage-backend is deployed.
		if ( ! this.props.followers ) {
			return null;
		}

		if ( this.state.loading ) {
			return (
				<p className="SupportTicket-Followers SupportTicket-Followers--loading">
					Updating…
				</p>
			);
		}

		const status = this.getStatus();
		const canUpdate = this.props.status !== 'closed';
		return (
			<p className="SupportTicket-Followers">
				<span className="SupportTicket-Followers__reason">
					{ status.reason }
				</span>

				{ canUpdate ? (
					status.button && (
						<PillButton
							name={ status.status ? 'Unfollow' : 'Follow' }
							onClick={ status.status ? this.onUnfollow : this.onFollow }
						/>
					)
				) : (
					<span>(You cannot update a closed ticket.)</span>
				) }
			</p>
		);
	}
}

const mapDispatchToProps = ( dispatch, props ) => {
	return {
		onFollow: () => dispatch( followSupportTicket( api, props.id ) ),
		onUnfollow: () => dispatch( unfollowSupportTicket( api, props.id ) ),
	};
};

export default connect( null, mapDispatchToProps )( Followers );
