import { PlusIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

export default function Example() {
	return (
		<div className="max-w-xl mx-auto mb-10 px-4 sm:px-6 lg:max-w-7xl lg:px-8 columns-2">
			<div className="left">
				<Link
					className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					to="/accelerate/add/"
				>
					<PlusIcon aria-hidden="true" className="h-4 w-4 mr-1" />
					Add Site
				</Link>
			</div>
			<div className="text-right">
				<span className="text-sm text-gray-400 align-bottom py-5">
					There are currently <strong>no limits</strong> during the beta phase.
				</span>
			</div>
		</div>
	);
}
