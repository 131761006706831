import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ImportApplicationModal.css';

import ApplicationSelect from './ApplicationSelect';
import Modal from './Modal';
import OutlineButton from './OutlineButton';
import SolidButton from './SolidButton';

export default class ImportApplicationModal extends Component {
	static propTypes = {
		application: PropTypes.object.isRequired,
		onImport: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	};
	constructor( props ) {
		super( props );
		this.state = {
			selectedApplication: null,
			database: false,
			uploads: false,
			path: '',
			isConfirming: false,
			confirmedId: '',
		};
	}

	onSubmitInitial = e => {
		e.preventDefault();
		this.setState( {
			isConfirming: true,
		} );
	};

	onSubmitConfirmed = e => {
		e.preventDefault();
		this.props.onImport( {
			application: this.state.selectedApplication,
			database: this.state.database,
			uploads: this.state.uploads,
			path: this.state.path,
		} );
	};

	renderForm() {
		return (
			<form
				className="ImportApplicationModal__form"
				onSubmit={ this.onSubmitInitial }
			>
				<div className="ImportApplicationModal__from">
					<div className="ImportApplicationModal__app">
						<label>Copy data from:</label>
						<ApplicationSelect
							region={ this.props.application.region }
							selectedApplication={ this.state.selectedApplication }
							onChange={ selectedApplication => this.setState( { selectedApplication } ) }
						/>
					</div>
					<div className="ImportApplicationModal__database">
						<label>
							<input
								checked={ this.state.database }
								type="checkbox"
								onChange={ e =>
									this.setState( {
										database: e.target.checked,
									} ) }
							/>
							<span>Database</span>
						</label>
					</div>
					<div className="ImportApplicationModal__uploads">
						<label>
							<input
								checked={ this.state.uploads }
								type="checkbox"
								onChange={ e => this.setState( { uploads: e.target.checked } ) }
							/>
							<span>Uploaded Assets</span>
						</label>
						{ this.state.uploads && (
							<div>
								<label>Subpath</label>
								<input
									className="input"
									type="text"
									value={ this.state.path }
									onChange={ e => this.setState( { path: e.target.value } ) }
								/>
								<p className="ImportApplicationModal__description">
									Select a subpath of your S3 bucket to import.
									By default, all items in this directory will be copied.
								</p>
								<p className="ImportApplicationModal__description">
									e.g. <code>/uploads/2015/06/</code>
								</p>
							</div>
						) }
					</div>
				</div>
				<div className="ImportApplicationModal__arrow">
					→
				</div>
				<div className="ImportApplicationModal__to">
					<p>Copying data to:</p>
					<p className="ImportApplicationModal__to-app">{ this.props.application.id }</p>
				</div>
				<p className="submit">
					<SolidButton
						disabled={ ! this.state.selectedApplication || ! ( this.state.database || this.state.uploads ) }
						name="Import..."
						type="submit"
					/>
					<OutlineButton name="Cancel" onClick={ this.props.onCancel } />
				</p>
			</form>
		);
	}

	renderConfirm() {
		const fromTo = (
			<>
				from <strong>{ this.state.selectedApplication }</strong> to <strong>{ this.props.application.id }</strong>
			</>
		);

		return (
			<form
				className="ImportApplicationModal__confirm"
				onSubmit={ this.onSubmitConfirmed }
			>
				<p>You are about to import { fromTo }</p>
				{ this.state.database && (
					<p><span aria-label="Checkmark" role="img">✅</span> Importing database { fromTo }</p>
				) }
				{ this.state.uploads && (
					<>
						<p><span aria-label="Checkmark" role="img">✅</span> Importing uploaded assets { fromTo }</p>
						{ this.state.path ? (
							<p className="ImportApplicationModal__confirm-assets">Subpath: <code>{ this.state.path }</code></p>
						) : (
							<p className="ImportApplicationModal__confirm-assets">Copying <strong>all data</strong>.</p>
						) }
					</>
				) }
				<p>
					This will copy data from { this.state.selectedApplication } to { this.props.application.id }.<br />
					<strong>Existing data on { this.props.application.id } will be overwritten.</strong>
				</p>
				<p>To confirm, type the name of the target application ({ this.props.application.id }):</p>
				<input
					className="input"
					type="text"
					value={ this.state.confirmedId }
					onChange={ e => this.setState( { confirmedId: e.target.value } ) }
				/>
				<p className="submit">
					<SolidButton
						disabled={ this.state.confirmedId !== this.props.application.id }
						name="Begin Import"
						type="submit"
					/>
					<OutlineButton name="Cancel" onClick={ this.props.onCancel } />
				</p>
			</form>
		);
	}

	render() {
		return (
			<Modal
				className="ImportApplicationModal"
				closeText="Cancel"
				isOpen
				title={ `Import data into ${ this.props.application.id }` }
				onClose={ this.props.onCancel }
			>
				<p>Select options below to import data from another environment into this environment.</p>
				<p>This will overwrite any data in { this.props.application.id }.</p>

				{ this.state.isConfirming ? (
					this.renderConfirm()
				) : (
					this.renderForm()
				) }
			</Modal>
		);
	}
}
