/**
 * FormattedRelativeTime is a drop-in replacement for the legacy version of React Intl's <FormattedRelativeTime />
 */
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { getCanonicalDate } from './FormattedDateTime';
import useTooltip, { Tooltip } from './useTooltip';

interface PlainProps {
	value: Date | string | number,
	now?: Date | string | number,
}

export function PlainFormattedRelativeTime( props: PlainProps ) {
	const currentDate = props.now ? getCanonicalDate( props.now ) : DateTime.now();

	const parsed = getCanonicalDate( props.value );
	if ( ! parsed.isValid ) {
		return (
			<>(invalid date)</>
		);
	}

	return (
		<>
			{ parsed.toRelative( { base: currentDate } ) }
		</>
	);
}

interface Props extends PlainProps {
	className?: string,
}

export default function FormattedRelativeTime( props: Props ) {
	const [ visible, setVisible ] = useState<boolean>( false );
	const { setReferenceElement, TooltipProps } = useTooltip();

	const currentDate = props.now ? getCanonicalDate( props.now ) : DateTime.now();

	const value = getCanonicalDate( props.value );
	if ( ! value.isValid ) {
		return (
			<>(invalid date)</>
		);
	}

	const full = value.toLocaleString( DateTime.DATETIME_FULL_WITH_SECONDS );
	return (
		<>
			<time
				ref={ setReferenceElement }
				className={ props.className }
				dateTime={ value.toISO() }
				onMouseOut={ () => setVisible( false ) }
				onMouseOver={ () => setVisible( true ) }
			>
				{ value.toRelative( { base: currentDate } ) }
			</time>
			<Tooltip
				{ ...TooltipProps }
				className="pointer-events-none"
				visible={ visible }
			>
				{ full }
			</Tooltip>
		</>
	);
}
