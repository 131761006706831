import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl';
import { ResponsiveContainer, LineChart, Line, XAxis, Tooltip } from 'recharts';

class MetricLine extends Component {
	static propTypes = {
		domain: PropTypes.object,
		compareData: PropTypes.array,
	};
	render() {
		// The line chart will use "number" for the x-axis,
		// so convert all the data values to timestamps.
		const data = this.props.data.map( ( datum, index ) => ( {
			...datum,
			time: new Date( datum.date ).getTime(),
			compare: this.props.compareData && this.props.compareData[ index ] && this.props.compareData[index].value,
		} ) );

		// Calculte the domain based off either the passed props
		// or the min / max of the data.
		let xDomain = null;
		if ( this.props.domain ) {
			xDomain = this.props.domain.x;
		} else if ( data.length > 0 ) {
			xDomain = [ data[0].time, data[ data.length - 1 ].time ];
		}

		return (
			<div>
				{ this.props.isLoading ? (
					<ContainerDimensions>
						{ ( { width } ) => (
							<ContentLoader
								height={ this.props.height }
								primaryColor="#f3f3f3"
								secondaryColor="#ecebeb"
								speed={ 2 }
								width={ width }
							>
								<rect height={ this.props.height } rx="2" ry="2" width={ width } x="0" y="0" />
							</ContentLoader>
						) }
					</ContainerDimensions>
				) : (
					<ResponsiveContainer height={ this.props.height }>
						<LineChart
							data={ data }
							margin={ {
								top: 0,
								right: 0,
								bottom: 0,
								left: 0,
							} }
						>
							<XAxis dataKey="time" domain={ xDomain } stroke="#EEE" tickCount={ 6 } tickFormatter={ () => '' } type="number" />
							<Tooltip
								content={ value => {
									if ( ! value.payload || ! value.payload[0] ) {
										return null;
									}
									const payload = value.payload.length === 1 ? value.payload[0] : value.payload[1];
									if ( payload.dataKey === 'compare' ) {
										return null;
									}
									return (
										<div
											style={ {
												background: 'white',
												padding: '5px',
											} }
										>
											<FormattedNumber value={ payload.value } /> { this.props.unit }
											<br />
											<span
												style={ {
													fontSize: '11.5px',
													color: '#666',
												} }
											>
												<FormattedDate value={ new Date( payload.payload.date ) } />
												&nbsp;
												<FormattedTime timeZoneName="short" value={ new Date( payload.payload.date ) } />
											</span>
										</div>
									);
								} }
							/>
							{ this.props.compareData && (
								<Line
									activeDot={ false }
									dataKey="compare"
									dot={ false }
									isAnimationActive={ false }
									stroke="#EEE"
									strokeWidth={ 2 }
									type="monotone"
								/>
							) }
							<Line
								dataKey="value"
								dot={ false }
								isAnimationActive={ false }
								stroke="#4667DE"
								strokeWidth={ 2 }
								type="monotone"
							/>
						</LineChart>
					</ResponsiveContainer>
				) }
			</div>
		);
	}
}

MetricLine.defaultProps = {
	unit: '%',
	data: [],
	compareData: [],
};
export default MetricLine;
