import React from 'react';
import CpuIcon from 'react-feather/dist/icons/cpu';
import GlobeIcon from 'react-feather/dist/icons/globe';
import PackageIcon from 'react-feather/dist/icons/package';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import SettingsPanel, { Page } from '../SettingsPanel';

import Deployment from './Deployment';
import Domains from './Domains';
import Environment from './Environment';

interface Props {
	application: ApplicationResponse,
}

export default function ApplicationSettings( props: Props & RouteComponentProps ) {
	const { application } = props;
	const root = props.match.url;

	const subpages: Page[] = [
		{
			title: 'Deployment',
			icon: PackageIcon,
			path: `${ root }/deployment`,
			component: () => (
				<Deployment
					application={ application }
				/>
			),
		},
		{
			title: 'Domains',
			icon: GlobeIcon,
			path: `${ root }/domains`,
			component: () => (
				<Domains
					application={ application }
				/>
			),
		},
		{
			title: 'Environment',
			icon: CpuIcon,
			path: `${ root }/environment`,
			component: () => (
				<Environment
					application={ application }
				/>
			),
		},
	];

	return (
		<SettingsPanel
			defaultPath={ `${ root }/deployment` }
			pages={ subpages }
		/>
	);
}
