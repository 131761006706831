import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { defaultAPI, parseResponse } from '../api';
import { AppState } from '../reducers';
import { ApplicationResponse } from '../types/rest-api';

interface ApplicationUpdatingTaskStreamAction extends ReduxAction {
	type: 'APPLICATION_UPDATING_TASK_STREAM';
	payload: {
		application: Application;
		taskStream: string;
		fields: {
			[s: string]: any;
		};
	};
}

interface ApplicationUpdatingAction extends ReduxAction {
	type: 'APPLICATION_UPDATING';
	payload: {
		application: Application;
		fields: { [s: string]: any };
	};
}

interface ApplicationUpdatedAction extends ReduxAction {
	type: 'APPLICATION_UPDATED';
	payload: {
		application: ApplicationResponse;
	};
}

interface ApplicationUpdateFailedAction extends ReduxAction {
	type: 'APPLICATION_UPDATE_FAILED';
	payload: {
		application: Application;
		error: Error;
	};
}

interface ApplicationSettings {
	'git-deployment': {
		ref: string,
		is_autoupdating: boolean,
	},
}

export type Action =
	| ApplicationUpdatingTaskStreamAction
	| ApplicationUpdatingAction
	| ApplicationUpdatedAction
	| ApplicationUpdateFailedAction;

export default function updateApplicationSettings(
	id: string,
	settings: Partial<ApplicationSettings>
): ThunkAction<Promise<ApplicationResponse>, AppState, null, Action> {
	return async dispatch => {
		// dispatch( {
		// 	type: 'APPLICATION_UPDATING',
		// 	payload: {
		// 		id,
		// 		settings,
		// 	},
		// } );

		window.analytics.track( 'application_updated', {
			id,
		} );

		const opts = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			mode: 'cors',
			body: JSON.stringify( settings ),
		};

		const application: ApplicationResponse = await defaultAPI.fetch( `/stack/applications/${ id }`, opts ).then( parseResponse );

		dispatch( {
			type: 'APPLICATION_UPDATED',
			payload: {
				application,
			},
		} );
		return application;
	};
}
