import qs from 'qs';
import React, { ComponentClass } from 'react';

import { ApplicationResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import { DurationFilter, ManualTimeFilter, TimeFilter } from '../DurationSelector';

import { AvailableMetricMap } from './util';

interface Props {
	application: ApplicationResponse,
	metrics: AvailableMetricMap,
	period?: number,
	time: TimeFilter,
}

export type MetricResponse = {
	date: string,
	values: {
		[ k: string ]: number,
	},
}[];

export interface MetricComponentProps {
	metricData: ApiResponse<MetricResponse>,
}

const mapPropsToData = ( props: Props ) => {
	const url = `/stack/applications/${ props.application.id }/metrics`;
	const params: { [ k: string ]: any } = {
		metrics: Object.keys( props.metrics ),
	};
	if ( ( props.time as DurationFilter ).duration ) {
		params.duration = ( props.time as DurationFilter ).duration;
	} else {
		params.from = ( props.time as ManualTimeFilter ).after;
		params.to = ( props.time as ManualTimeFilter ).before;
	}
	if ( props.period ) {
		params.period = props.period;
	}

	return {
		metricData: url + '?' + qs.stringify( params ),
	};
};

// Force the type to ensure correct props.
type WithMetricData = <CP extends MetricComponentProps, P extends Props>( component: React.ComponentType<CP> ) => ComponentClass<P & Omit<CP, 'metricData'>>;
export default withApiData( mapPropsToData ) as unknown as WithMetricData;
