import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
	children: React.ReactNode,
	className?: string,
	disabled?: boolean,
	href?: string,
	size?: 'xs' | 's' | 'm' | 'l' | 'xl',
	style?: React.CSSProperties,
	type?: 'button' | 'submit',
	variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive',
	onClick?: React.MouseEventHandler<HTMLButtonElement>,
}

const sizes = {
	xs: 'rounded px-2.5 py-1.5 text-xs',
	s: 'rounded-md px-3 py-2 text-sm leading-4',
	m: 'rounded-md px-4 py-2 text-sm',
	l: 'rounded-md px-4 py-2 text-base',
	xl: 'rounded-md px-6 py-3 text-base',
};

const variant = {
	primary: '!text-white bg-blue-600 border-transparent hover:bg-blue-700 focus:ring-blue-500',
	secondary: '!text-blue-700 bg-blue-100 border-transparent hover:bg-blue-200 focus:ring-blue-500',
	tertiary: '!text-gray-700 bg-white border-gray-300 hover:bg-gray-50 focus:ring-blue-500',
	destructive: '',
	link: '',
};

export default function Button( props: Props ) {
	const classes = [
		props.className,
		'inline-flex items-center font-medium shadow-sm border focus:outline-none focus:ring-2 focus:ring-offset-2',
		props.disabled && 'bg-opacity-30 hover:bg-opacity-30 cursor-not-allowed',
		sizes[ props.size || 'm' ],
		variant[ props.variant || 'secondary' ],
	];

	if ( props.href && props.href.indexOf( 'https:' ) !== 0 ) {
		return (
			<Link
				className={ classes.filter( Boolean ).join( ' ' ) }
				style={ props.style }
				to={ props.href }
				type={ props.type || 'button' }
			>
				{ props.children }
			</Link>
		);
	} else if ( props.href ) {
		return (
			<a
				className={ classes.filter( Boolean ).join( ' ' ) }
				href={ props.href }
				role={ props.type || 'button' }
				style={ props.style }
			>
				{ props.children }
			</a>
		);
	} else {
		return (
			<button
				className={ classes.filter( Boolean ).join( ' ' ) }
				disabled={ props.disabled }
				style={ props.style }
				type={ props.type || 'button' }
				onClick={ props.onClick }
			>
				{ props.children }
			</button>
		);
	}
}
