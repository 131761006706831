import React, { Component } from 'react';
import { withRouter, Route } from 'react-router';

import { WithApiData } from '../lib/with-api-data';

import XRayOverview from './XRayOverview';
import XRayTrace from './XRayTrace';

import './ApplicationXRay.css';

class ApplicationXRay extends Component {
	apiData = null;

	onSelectTrace = trace => {
		this.props.history.push( `xray/trace/${trace.Id}` );
	};

	render() {
		return (
			<div className="ApplicationXRay" style={ { flex: '1' } }>
				<Route
					exact
					path={ this.props.match.path }
					render={ () => (
						<XRayOverview
							application={ this.props.match.params.application }
							onSelectTrace={ this.onSelectTrace }
						/>
					) }
				/>
				<Route
					path={ `${this.props.match.path}/trace/:trace` }
					render={ props => (
						<WithApiData
							data={ {
								trace: `/stack/applications/${props.match.params.application}/xray/traces/${
									props.match.params.trace
								}`,
							} }
							render={ props => <XRayTrace { ...props } /> }
						/>
					) }
				/>
			</div>
		);
	}
}

export default withRouter( ApplicationXRay );
