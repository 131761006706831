import React from 'react';
import {
	CartesianGrid,
	LineChart,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import CustomTooltip from './Tooltip';
import { formatDate, StatEntry } from './util';

function RequestsTooltip( props: any ) {
	const { active, payload } = props;
	if ( ! active || ! payload || ! payload[0] ) {
		return null;
	}

	const item: StatEntry = payload[0].payload;
	return (
		<CustomTooltip>
			<p>Uncached requests</p>
			<p>{ formatDate( item.time ) }–{ formatDate( item.time_end ) }</p>
			<p>{ item.requests } requests/min</p>
		</CustomTooltip>
	);
}

interface Props {
	data: StatEntry[],
	isLoading: boolean,
}

export default function RequestsChart( props: Props ) {
	if ( props.isLoading ) {
		return (
			<div
				className="animated-background"
				style={ {
					height: 200,
					width: '100%',
				} }
			/>
		);
	}

	return (
		<ResponsiveContainer
			className="XRayOverview-Requests"
			height={ 200 }
			width="100%"
		>
			<LineChart
				data={ props.data }
				syncId="XRayOverview-Statistics"
			>
				<CartesianGrid
					stroke="#aaa"
					strokeDasharray="3 3"
					strokeWidth="1"
					vertical={ false }
				/>
				<XAxis
					axisLine={ false }
					className="text-gray-400 text-sm"
					dataKey="time"
					tick={ {
						fill: 'currentColor',
						transform: 'translate(0, 6)',
					} }
					tickFormatter={ formatDate }
				/>
				<YAxis
					axisLine={ false }
					className="text-gray-400 text-sm"
					dataKey="requests"
					domain={ [ 0, 'dataMax' ] }
					interval="preserveEnd"
					scale="linear"
					tick={ {
						fill: 'currentColor',
						transform: 'translate(-3, 0)',
					} }
					tickLine={ false }
				/>
				<Tooltip
					content={ <RequestsTooltip /> }
					isAnimationActive={ false }
					position={ {
						y: 0,
					} }
				/>
				<Line
					dataKey="requests"
					dot={ false }
					stroke="#8884d8"
					strokeWidth={ 2 }
					type="linear"
				/>
			</LineChart>
		</ResponsiveContainer>
	);
}
