import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { UserResponse } from '../../api';
import { AppState } from '../../reducers';

import LoginComponent from './components/Login';

interface StateProps {
	user: undefined | UserResponse,
}

interface Props {
	onLogin?: ( response: any ) => void,
}

function Login( props: Props & StateProps ) {
	if ( props.user ) {
		return <Redirect to="/accelerate/" />;
	}

	return (
		<div className="bg-gray-100 h-screen">
			<div>
				<LoginComponent onLogin={ props.onLogin } />
			</div>
		</div>
	);
}

function mapStateToProps( state: AppState ) : StateProps {
	return {
		user: state.currentUser.user,
	};
}
export default connect( mapStateToProps )( Login );
