import PropTypes from 'prop-types';
import React from 'react';

import { SupportTicketCommentResponse } from '../../types/rest-api';

import ChangeEvent from './ChangeEvent';
import Message from './Message';

type Props = {
	data: {
		from?: string, // from and to are not part of the documented REST API response.
		to?: string,
	} & SupportTicketCommentResponse
}

export default function Event( props: Props ) {
	const { data } = props;

	switch ( data.type ) {
		// For backwards compatibility:
		case undefined:
		case 'comment':
			return (
				<Message { ...data } />
			);

		case 'change':
			return (
				<ChangeEvent
					author={ data.author }
					field={ data.field }
					from={ data.from }
					to={ data.to }
				/>
			);

		default:
			return null;
	}
}

Event.propTypes = {
	data: PropTypes.shape( {
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	} ).isRequired,
};
