import React from 'react';

import { Step as StepData } from '../../lib/builds';
import {
	parseMessages,
	DeployPhase,
	DeployMessage,
	DeployStep,
} from '../../lib/deploys';
import { formatDuration } from '../../lib/utils';
import GroupedLog, { Group } from '../GroupedLog';

import QueueIndicator from './QueueIndicator';
import StatusIcon from './StatusIcon';

const getStepName = ( step: DeployStep ) => {
	switch ( step ) {
		case 'PROVISIONING':
			return 'Preparing deployment';

		case 'DEPLOY_SANDBOX':
			return 'Deploying sandbox container';

		case 'DEPLOY_CAVALCADE':
			return 'Deploying Cavalcade containers';

		case 'INSTALL_APP':
			return 'Creating new web containers';

		case 'HEALTHCHECK_APP':
			return 'Running healthchecks on web containers';

		case 'DEPLOY_APP':
			return 'Switching live traffic';

		default: {
			// Check we're exhaustive.
			const _exhaustiveCheck: never = step;
			return _exhaustiveCheck;
		}
	}
};

interface StepProps {
	name: DeployStep,
	data: StepData;
}

function Step( props: StepProps ) {
	const { data, name } = props;

	const duration = data.start !== undefined && (
		<span className="UnifiedDeployListItem-BuildSteps__step-duration">
			<abbr
				title={ `${ data.start } / ${ data.end || 'In progress…' }` }
			>
				{ data.duration !== null ? formatDuration( data.duration ) : '…' }
			</abbr>
		</span>
	);

	return (
		<Group
			icon={ <StatusIcon status={ data.status } /> }
			inProgress={ data.status === 'IN_PROGRESS' }
			messages={ data.messages.map( m => ( { text: m.message } ) ) }
			name={ getStepName( name ) }
			note={ duration }
		/>
	);
}

interface Props {
	loading: boolean;
	messages: DeployMessage[] | false;
	phases: DeployPhase[] | false;
}

export default function DeploySteps( props: Props ) {
	if ( props.loading ) {
		return (
			<GroupedLog
				loading
			/>
		);
	}

	if ( ! props.messages || ! props.phases ) {
		return null;
	}

	const provisioningStep = props.phases.find( phase => phase.phaseType === 'PROVISIONING' );
	if ( provisioningStep && provisioningStep.phaseStatus === 'QUEUED' ) {
		return (
			<QueueIndicator>
				Your deployment is queued.
			</QueueIndicator>
		);
	}

	const steps = parseMessages( props.phases, props.messages );
	return (
		<GroupedLog className="UnifiedDeployListItem-DeploySteps">
			{ Object.keys( steps ).map( key => (
				<Step
					key={ key }
					data={ steps[ key ] }
					name={ key as DeployStep }
				/>
			) ) }
		</GroupedLog>
	);
}
