import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { fetchApplications } from '../actions';
import { ApplicationResponse } from '../api';
import { AppState } from '../reducers';

type OwnProps = {
	instance: string | null,
	[ k: string ]: any,
}
type StateProps = {
	applications: ApplicationResponse[],
	loading: boolean,
}
type DispatchProps = {
	onSelectInstance( instance: string ): void,
};
type AllProps = OwnProps & StateProps;

export function SelectApplication( props: AllProps ) {
	const { applications, instance, loading, onSelectInstance, ...otherProps } = props;

	const [ didLoad, setDidLoad ] = useState<string | null>( null );

	useEffect( () => {
		if ( didLoad === instance ) {
			return;
		}

		if ( instance && ! loading && ! applications.length ) {
			onSelectInstance( instance );
			setDidLoad( instance );
		}
	}, [ applications, didLoad, instance, loading, onSelectInstance ] );

	return (
		<div className="SelectApplication">
			<Select<ApplicationResponse>
				getOptionLabel={ application => application.id }
				getOptionValue={ application => application.id }
				isDisabled={ ! instance }
				isLoading={ !! instance && loading }
				options={ applications }
				placeholder="Select Environment..."
				{ ...otherProps }
			/>
		</div>
	);
}

const mapStateToProps = ( state: AppState, props: OwnProps ) : StateProps => ( {
	applications: Object.values( state.applications ).filter( application => application['altis-instance'] === props.instance ),
	loading: props.instance ? state.instances.loadingApplications[ props.instance ] : false,
} );

const mapDispatchToProps = ( dispatch: any ) : DispatchProps => ( {
	onSelectInstance( instance ) {
		dispatch( fetchApplications( { instance } ) );
	},
} );

export default connect( mapStateToProps, mapDispatchToProps )( SelectApplication );
