import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';

import { FollowResponse } from './followSupportTicket';

interface UnfollowingSupportTicketAction extends ReduxAction {
	type: 'UNFOLLOWING_SUPPORT_TICKET';
}

interface UnfollowedSupportTicketAction extends ReduxAction {
	type: 'UNFOLLOW_SUPPORT_TICKET';
	ticketId: string;
	userId: number;
}

export type Action = UnfollowingSupportTicketAction | UnfollowedSupportTicketAction;

export default function unfollowSupportTicket(
	api: API,
	ticketId: string
): ThunkAction<Promise<FollowResponse>, AppState, null, Action> {
	return ( dispatch, getState ) => {
		const user = getState().currentUser.user;

		if ( ! user ) {
			return Promise.reject( new Error( 'Not logged in' ) );
		}

		dispatch( { type: 'UNFOLLOWING_SUPPORT_TICKET' } );

		window.analytics.track( 'support_ticket_unfollowed', {} );

		return api.del( `/stack/support-tickets/${ ticketId }/follow` ).then( ( resp: FollowResponse ) => {
			dispatch( {
				type: 'UNFOLLOW_SUPPORT_TICKET',
				ticketId,
				userId: user.id,
			} );

			return resp;
		} );
	};
}
