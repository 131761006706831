import React from 'react';
import { Link } from 'react-router-dom';

import { PostResponse, UserResponse } from '../../api';
import BigTitle from '../BigTitle';
import FormattedContent from '../FormattedContent';

import Author from './Author';

import './Content.css';

interface Props {
	isSingle?: boolean,
	postObject: PostResponse,
	showExcerpt?: boolean,
}

export default function Content( props: Props ) {
	const {
		postObject,
		showExcerpt,
	} = props;
	return (
		<article className={ `BlogPost-Content ${ showExcerpt ? 'BlogPost-Content--excerpt' : '' }` }>
			<Author
				author={ postObject._embedded?.author && postObject._embedded.author[0] as UserResponse }
				date={ postObject.date }
			/>

			<main className="BlogPost-Content__main">
				{ props.isSingle ? (
					<BigTitle
						name={ postObject.title.rendered }
					/>
				) : (
					<Link to={ `/blog/${ postObject.id }/${ postObject.slug }` }>
						<h2 className="BlogPost-Content__title">{ postObject.title.rendered }</h2>
					</Link>
				) }
				{ postObject.excerpt && postObject.content ? (
					<>

						<FormattedContent html={ showExcerpt ? postObject.excerpt.rendered : postObject.content.rendered } />

						{ props.showExcerpt && (
							<Link to={ `/blog/${ postObject.id }/${ postObject.slug }` }>
								Continue reading →
							</Link>
						) }
					</>
				) : (
					<p>(no content)</p>
				) }
			</main>
		</article>
	);
}
