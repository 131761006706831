import React, { Component } from 'react';
import HelpIcon from 'react-feather/dist/icons/help-circle';

import Modal from '../Modal';
import PillButton from '../PillButton';
import SolidButton from '../SolidButton';

import './FilterForm.css';

interface Props {
	initialFilter?: string,
	onRefresh(): void,
	onSubmit( filter: string ): void,
}

interface State {
	filter: string,
	showingHelpModal: boolean,
}

export default class XRayFilterForm extends Component<Props, State> {
	constructor( props: Props ) {
		super( props );

		this.state = {
			filter: props.initialFilter || '',
			showingHelpModal: false,
		};
	}

	componentDidUpdate( prevProps: Props ) {
		if ( prevProps.initialFilter !== this.props.initialFilter ) {
			this.setState( {
				filter: this.props.initialFilter || '',
			} );
		}
	}

	onSubmit = ( e: React.FormEvent ) => {
		e.preventDefault();
		if ( this.state.filter === this.props.initialFilter ) {
			this.props.onRefresh();
		} else {
			this.props.onSubmit( this.state.filter );
		}
	};

	clickHelper( filter: string ) {
		return ( e: React.FormEvent ) => {
			e.preventDefault();

			let nextFilter;
			if ( this.state.filter.trim().length > 0 ) {
				nextFilter = this.state.filter.trimRight() + ' AND ' + filter;
			} else {
				nextFilter = filter;
			}
			this.setState(
				{
					filter: nextFilter,
				},
				() => this.props.onSubmit( this.state.filter )
			);
		};
	}

	render() {
		return (
			<form
				className="XRayFilterForm"
				onSubmit={ this.onSubmit }
			>
				<div className="XRayFilterForm__input">
					<div className="filter-expression">
						<label>
							Filter Expression{ ' ' }
							<button type="button" onClick={ () => this.setState( { showingHelpModal: true } ) }>
								<HelpIcon color="#666" size={ 18 } />
							</button>
						</label>
						<input value={ this.state.filter } onChange={ e => this.setState( { filter: e.target.value } ) } />
					</div>

					<div className="self-end">
						<SolidButton
							name="Search"
							type="submit"
						/>
					</div>
				</div>
				<div className="XRayFilterForm__helpers">
					<span className="XRayFilterform__helpers-hint">Try:</span>
					<PillButton
						name="responsetime > 0.9"
						onClick={ this.clickHelper( 'responsetime > 0.9' ) }
					/>
					<PillButton
						name="http.status = 404"
						onClick={ this.clickHelper( 'http.status = 404' ) }
					/>
					<PillButton
						name={ 'http.url CONTAINS "wp-admin"' }
						onClick={ this.clickHelper( 'http.url CONTAINS "wp-admin"' ) }
					/>
				</div>

				<Modal
					className="prose"
					isOpen={ this.state.showingHelpModal }
					title="X-Ray Filter"
					onClose={ () => this.setState( { showingHelpModal: false } ) }
				>
					<p>The X-Ray view supports filter expressions, which can
						be used to narrow down the request log to the most
						relevant data.</p>

					<p>These filter expressions follow the <a href="https://docs.aws.amazon.com/xray/latest/devguide/xray-console-filters.html">AWS X-Ray filter expression syntax</a>. (Note that complex expressions are not supported.)</p>

					<h3>Filter expressions</h3>
					<p>Filter expressions consist of a keyword, an operator, and a value.</p>
					<p>Depending on the field type, different operators may be available. They are usually in the form:</p>
					<pre>keyword operator value</pre>
					<p>Expressions can be combined with the <code>AND</code> and <code>OR</code> keywords. For example:</p>
					<pre>responsetime &gt; 0.3 AND http.status = 200</pre>

					<h3>Boolean keywords</h3>
					<p>These special keywords can be used without an operator, or negated with the <code>!</code> operator.</p>
					<table className="XRayFilterForm__field-table">
						<thead>
							<tr>
								<th>Field</th>
								<th>Description</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><code>ok</code></td>
								<td>Response status code was 2XX Success.</td>
							</tr>
							<tr>
								<td><code>fault</code></td>
								<td>Response status code was 5XX Server Error.</td>
							</tr>
							<tr>
								<td><code>error</code></td>
								<td>Request has errors reported.</td>
							</tr>
							<tr>
								<td><code>partial</code></td>
								<td>Request has incomplete segments.</td>
							</tr>
						</tbody>
					</table>

					<h3>Available operators</h3>
					<p>The following operators can be used to compare values:</p>
					<ul>
						<li><code>=</code>, <code>!=</code> - Boolean, string, and number comparison operators</li>
						<li><code>&lt;</code>,<code>&lt;=</code>, <code>&gt;</code>,<code>&gt;=</code> - Number comparison operators</li>
						<li><code>CONTAINS</code> - String operator</li>
						<li><code>BEGINSWITH</code>, <code>ENDSWITH</code> - String operators</li>
					</ul>

					<h3>Number and string fields</h3>
					<p>These fields can be compared using the above operators.</p>
					<p>(Note that strings should be quoted.)</p>
					<table className="XRayFilterForm__field-table">
						<thead>
							<tr>
								<th>Field</th>
								<th>Type</th>
								<th>Description</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><code>responsetime</code></td>
								<td>Number</td>
								<td>Time that the server took to send a response.</td>
							</tr>
							<tr>
								<td><code>duration</code></td>
								<td>Number</td>
								<td>Total request duration including all downstream calls.</td>
							</tr>
							<tr>
								<td><code>http.status</code></td>
								<td>Number</td>
								<td>Response status code.</td>
							</tr>
							<tr>
								<td><code>http.url</code></td>
								<td>String</td>
								<td>Request URL.</td>
							</tr>
							<tr>
								<td><code>http.method</code></td>
								<td>String</td>
								<td>Request method.</td>
							</tr>
							<tr>
								<td><code>http.useragent</code></td>
								<td>String</td>
								<td>Request user agent string.</td>
							</tr>
							<tr>
								<td><code>http.clientip</code></td>
								<td>String</td>
								<td>Requestor's IP address.</td>
							</tr>
							<tr>
								<td><code>user</code></td>
								<td>String</td>
								<td>Value of user field on any segment in the trace.</td>
							</tr>
							<tr>
								<td><code>annotation.*key*</code></td>
								<td>String</td>
								<td>Value of annotation with field *key*.</td>
							</tr>
						</tbody>
					</table>

					<h3>Examples</h3>
					<p>Requests where response time was more than 5 seconds:</p>
					<pre>responsetime &gt; 5</pre>

					<p>Requests which gave a 404 response:</p>
					<pre>http.status = 404</pre>

					<p>Requests to the admin which caused an error:</p>
					<pre>http.url CONTAINS "wp-admin" AND error</pre>
				</Modal>
			</form>
		);
	}
}
