import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';

import fetchTaskStream from './fetchTaskStream';

interface BackupOptions {
	tables?: string[];
	uploads?: boolean;
	database?: boolean;
	uploads_path?: string;
}

interface BackupCreatingAction extends ReduxAction {
	type: 'BACKUP_CREATING';
	payload: {
		application: Application;
	};
}

interface BackupCreatingTaskStreamAction extends ReduxAction {
	type: 'BACKUP_CREATING_TASK_STREAM';
	payload: {
		application: Application;
		taskStream: string;
	};
}

interface BackupCreatedAction extends ReduxAction {
	type: 'BACKUP_CREATED';
	payload: {
		application: Application;
	};
}

interface BackupFailedAction extends ReduxAction {
	type: 'BACKUP_FAILED';
	payload: {
		application: Application;
		error: Error;
	};
}

export type Action = BackupCreatingAction | BackupCreatingTaskStreamAction | BackupCreatedAction | BackupFailedAction;

export default function createBackup(
	application: Application,
	backupOptions: BackupOptions,
	api: API
): ThunkAction<Promise<any>, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'BACKUP_CREATING',
			payload: {
				application,
			},
		} );
		window.analytics.track( 'backup_started', {
			application: application.id,
			...backupOptions,
		} );
		return api
			.post( `/stack/applications/${application.id}/backups`, {
				stream: true,
				...backupOptions,
			} )
			.catch(
				( error: Error ): any => {
					// Hash that we should look up
					if ( error.message.indexOf( application.id ) === 0 ) {
						dispatch( {
							type: 'BACKUP_CREATING_TASK_STREAM',
							payload: {
								application,
								taskStream: error.message,
							},
						} );
						return dispatch( fetchTaskStream( error.message, application ) ).then( () => {
							dispatch( {
								type: 'BACKUP_CREATED',
								payload: {
									application,
								},
							} );
							if ( Notification ) {
								new Notification( 'Backup Completed 🚀', {
									body: application.id,
								} );
							}
							return application;
						} );
					}
					dispatch( {
						type: 'BACKUP_FAILED',
						payload: {
							application,
							error,
						},
					} );
					if ( Notification ) {
						new Notification( 'Backup Failed!', {
							body: application.id,
						} );
					}
				}
			);
	};
}
