import { Action as ReduxAction } from 'redux';

import { Action as CreateSupportTicketCommentAction } from '../actions/createSupportTicketComment';
import { Action as FetchSupportTicketAction } from '../actions/fetchSupportTicket';
import { Action as FetchSupportTicketsAction } from '../actions/fetchSupportTickets';
import { Action as FetchSupportTicketCommentsAction } from '../actions/fetchSupportTicketsComments';
import { Action as FollowSupportTicketAction } from '../actions/followSupportTicket';
import { Action as UnfollowSupportTicketAction } from '../actions/unfollowSupportTicket';
import { Action as UpdateSupportTicketAction } from '../actions/updateSupportTicket';
import { SupportTicketResponse, SupportTicketCommentResponse } from '../types/rest-api';

interface Filter {
	status: Record<string, string>[];
	priority?: '-' | 'low' | 'normal' | 'high' | 'urgent';
	type?: '-' | 'question' | 'problem' | 'incident' | 'task';
	applications: string[];
}

interface State {
	byId: Record<string, SupportTicketResponse>;
	isLoading: boolean;
	totalPages: number;
	filter: Filter;
	replies: Record<
		string,
		{
			isLoading: boolean;
			byId: Record<string, SupportTicketCommentResponse>;
		}
	>;
}

interface UpdatedSupportTicketsFilterAction extends ReduxAction {
	type: 'UPDATED_SUPPORT_TICKETS_FILTER';
	filter: Filter;
}

export default function activity(
	state: State = {
		byId: {},
		isLoading: false,
		totalPages: 0,
		filter: {
			status: [
				{
					value: 'open',
					label: 'Open',
				},
				{
					value: 'new',
					label: 'New',
				},
				{
					value: 'pending',
					label: 'Pending',
				},
				{
					value: 'hold',
					label: 'On Hold',
				},
			],
			priority: undefined,
			type: undefined,
			applications: [],
		},
		replies: {},
	},
	action:
	| UpdateSupportTicketAction
	| FetchSupportTicketsAction
	| CreateSupportTicketCommentAction
	| FetchSupportTicketCommentsAction
	| UpdatedSupportTicketsFilterAction
	| FetchSupportTicketAction
	| FollowSupportTicketAction
	| UnfollowSupportTicketAction
) {
	switch ( action.type ) {
		case 'UPDATING_SUPPORT_TICKETS':
			return {
				...state,
				isLoading: true,
			};
		case 'UPDATED_SUPPORT_TICKETS': {
			const carry: Record<string, SupportTicketResponse> = {};

			return {
				...state,
				isLoading: false,
				byId: {
					...action.supportTickets.reduce( ( byId, item ) => {
						byId[item.id] = item;
						return byId;
					}, carry ),
				},
				totalPages: action.totalPages,
			};
		}

		case 'FOLLOW_SUPPORT_TICKET': {
			const ticket = state.byId[ action.ticketId ];
			return {
				...state,
				byId: {
					...state.byId,
					[ action.ticketId ]: {
						...ticket,
						followers: [
							...ticket.followers,
							action.userId,
						],
					},
				},
			};
		}

		case 'UNFOLLOW_SUPPORT_TICKET': {
			const ticket = state.byId[ action.ticketId ];
			return {
				...state,
				byId: {
					...state.byId,
					[ action.ticketId ]: {
						...ticket,
						followers: ticket.followers.filter( id => id !== action.userId ),
					},
				},
			};
		}

		case 'UPDATED_SUPPORT_TICKETS_FILTER': {
			return {
				...state,
				filter: action.filter,
			};
		}
		case 'UPDATING_SUPPORT_TICKET_COMMENTS':
			return {
				...state,
				replies: {
					...state.replies,
					[action.ticket]: {
						isLoading: true,
						byId: {},
					},
				},
			};
		case 'UPDATED_SUPPORT_TICKET_COMMENTS': {
			const carry: Record<string, SupportTicketCommentResponse> = {};
			return {
				...state,
				replies: {
					...state.replies,
					[action.ticket]: {
						isLoading: false,
						byId: {
							...action.comments.reduce( ( byId, item ) => {
								byId[item.id] = item;
								return byId;
							}, carry ),
						},
					},
				},
			};
		}
		case 'SUPPORT_TICKET_UPDATED':
		case 'UPDATED_SUPPORT_TICKET':
			return {
				...state,
				isLoading: false,
				byId: {
					...state.byId,
					[action.supportTicket.id]: action.supportTicket,
				},
			};
		case 'CREATED_SUPPORT_TICKET_COMMENT':
			return {
				...state,
				replies: {
					...state.replies,
					[action.ticketId]: {
						...state.replies[action.ticketId],
						byId: {
							...state.replies[action.ticketId].byId,
							[action.comment.id]: action.comment,
						},
					},
				},
			};
		default:
			return state;
	}
}
