import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import './Application.css';

import ApplicationImportExport from '../containers/ApplicationImportExport';

import ApplicationData from './ApplicationData';
import ApplicationDeploys from './ApplicationDeploys';
import ApplicationHeader from './ApplicationHeader';
import ApplicationLoading from './ApplicationLoading';
import ApplicationLogs from './ApplicationLogs';
import ApplicationMetrics from './ApplicationMetrics';
import ApplicationServers from './ApplicationServers';
import ApplicationSettings from './ApplicationSettings';
import ApplicationShell from './ApplicationShell';
import ApplicationXRay from './ApplicationXRay';
import ErrorBlock from './ErrorBlock';
import ErrorBoundary from './ErrorBoundary';
import Nodejs from './Nodejs';
import PageTitle from './PageTitle';

const canDevelop = ( user, application ) => {
	if ( ! user || user.loading ) {
		return false;
	}

	// Admins can always run CLI commands.
	if ( user.capabilities.edit_applications ) {
		return true;
	}

	// Are caps available to check?
	if ( application.can ) {
		return application.can.develop;
	}

	// Otherwise, check legacy meta keys.
	const rawUser = user.user;
	return rawUser.developable_applications && rawUser.developable_applications.indexOf( application.id ) >= 0;
};

function Application( props ) {
	const { application, currentUser, isLoading, error } = props;

	if ( error && ! isLoading ) {
		return <ErrorBlock message={ error.message } />;
	}
	if ( ! application ) {
		return <ApplicationLoading />;
	}

	const basePath = props.basePath || '/:region/:application';

	return (
		<div className="Application">
			{ application && (
				<PageTitle title={ application.id } />
			) }
			<ErrorBoundary>
				<Route
					exact
					path={ basePath }
					render={ props => (
						<>
							<ApplicationHeader
								application={ application }
								title={ false }
							/>
							<ApplicationMetrics
								application={ application }
								{ ...props }
							/>
						</>
					) }
				/>
				<Route path={ `${ basePath }/deploys` }
					render={ props => (
						<>
							<ApplicationHeader
								application={ application }
								title="Deploys"
							/>
							<ApplicationDeploys
								{ ...props }
								canDeploy={ canDevelop( currentUser, application ) }
							/>
						</>
					) }
				/>
				<Route
					path={ `${ basePath }/nodejs` }
					render={ () => (
						<Nodejs
							application={ application }
							basePath={ basePath }
							isLoading={ isLoading }
						/>
					) }
				/>
				<Route
					path={ `${ basePath }/servers` }
					render={ () => (
						<ApplicationServers
							application={ application }
						/>
					) }
				/>
				<Route
					path={ `${ basePath }/logs` }
					render={ props => (
						<>
							<ApplicationHeader
								application={ application }
								title="Logs"
							/>
							<ApplicationLogs
								application={ application }
								{ ...props }
							/>
						</>
					) }
				/>
				{ canDevelop( currentUser, application ) && (
					<Route
						path={ `${ basePath }/cli` }
						render={ props => (
							<>
								<ApplicationHeader
									application={ application }
									title="CLI"
								/>
								<ApplicationShell
									{ ...props }
									application={ application.id }
								/>
							</>
						) }
					/>
				) }
				<Route
					component={ ApplicationImportExport }
					path={ `${ basePath }/import-export` }
				/>
				<Route
					path={ `${ basePath }/xray` }
					render={ props => (
						<>
							<ApplicationHeader
								application={ application }
								title="X-Ray"
							/>
							<ApplicationXRay
								{ ...props }
							/>
						</>
					) }
				/>
				<Route
					exact
					path={ `${ basePath }/data` }
					render={ props => (
						<>
							<ApplicationHeader
								application={ application }
								title="Backups"
							/>
							<ApplicationData
								{ ...props }
							/>
						</>
					) }
				/>
				<Route
					path={ `${ basePath }/settings` }
					render={ props => (
						<>
							<ApplicationHeader
								application={ application }
								title="Settings"
							/>
							<ApplicationSettings
								application={ application }
								{ ...props }
							/>
						</>
					) }
				/>
			</ErrorBoundary>
		</div>
	);
}

function mapStateToProps( state, props ) {
	return {
		application: state.applications[ props.match.params.application ],
		error: null,
		isLoading: ! state.applications[ props.match.params.application ],
		currentUser: state.currentUser,
	};
}

export default connect( mapStateToProps )( Application );
