import React from 'react';

import { componentForFunction, nameForComponent } from './util';

import './Tooltip.css';

const MOUSE_OFFSET = 10;

interface TraceItem {
	name: string;
	value: number;
	children: TraceItem[];
	start?: number;
	end?: number;
}

type Mouse = {
	left: number;
	top: number;
};

interface TooltipProps {
	item: TraceItem | null;
	parentEl: HTMLElement | null;
	total?: number;
	visible: boolean;
}

type Dimensions = {
	height: number;
	width: number;
}

interface TooltipState {
	dims: Dimensions;
}

const getPosition = ( parent: HTMLElement, dims: Dimensions, mouse: Mouse ) => {
	const contain = parent.getBoundingClientRect();

	let tooltipLeft = mouse.left + MOUSE_OFFSET;

	// Is the tooltip overflowing the right edge?
	if ( ( tooltipLeft - contain.left + dims.width ) > contain.width ) {
		// Flip it horizontally.
		tooltipLeft -= dims.width;
	}

	// Is the tooltip overflowing the bottom edge?
	let tooltipTop = mouse.top + MOUSE_OFFSET;
	if ( ( tooltipTop - contain.top + dims.height ) > contain.height ) {
		// Flip it vertically.
		tooltipTop -= dims.height;
	}

	return {
		top: tooltipTop,
		left: tooltipLeft,
	};
};

export default class Tooltip extends React.Component<TooltipProps, TooltipState> {
	state = {
		dims: {
			height: 0,
			width: 0,
		},
	};

	ref?: HTMLDivElement;

	componentDidMount() {
		window.addEventListener( 'mousemove', this.onMouseMove );
	}

	componentWillUnmount() {
		window.removeEventListener( 'mousemove', this.onMouseMove );
	}

	onMouseMove = ( e: MouseEvent ) => {
		if ( ! this.ref || ! this.props.parentEl ) {
			return;
		}

		const mouse: Mouse = {
			left: e.clientX,
			top: e.clientY,
		};
		const pos = getPosition( this.props.parentEl, this.state.dims, mouse );
		this.ref.style.top = `${ pos.top }px`;
		this.ref.style.left = `${ pos.left }px`;
	};

	setRef = ( ref: HTMLDivElement | null ) => {
		if ( ! ref ) {
			return;
		}

		const bounding = ref.getBoundingClientRect();
		this.ref = ref;
		this.setState( {
			dims: {
				height: bounding.height,
				width: bounding.width,
			},
		} );
	};

	render() {
		if ( ! this.props.item ) {
			return null;
		}

		const { item, total } = this.props;

		const selfTime = item.value - item.children.reduce( ( total, child ) => total + child.value, 0 );
		const selfTimeMs = Math.round( selfTime * 1000 );
		const selfPercentage = Math.round( selfTime / item.value * 100 );

		const percentageOfTotal = total ? item.value / total : null;

		return (
			<div
				ref={ this.setRef }
				className="Flamegraph-Tooltip"
				style={ {
					opacity: this.props.visible ? 1 : 0,
				} }
			>
				<header>
					<p className="Flamegraph-Tooltip__name">
						{ item.name }
					</p>
					{ percentageOfTotal && (
						<span className="Flamegraph-Tooltip__of-total">
							{ Math.round( percentageOfTotal * 10000 ) / 100 }%
						</span>
					) }
				</header>

				<div className="Flamegraph-Tooltip__timing">
					<meter
						className="Flamegraph-Tooltip__timer"
						max="100"
						min="0"
						value={ selfPercentage }
					/>

					<span className="Flamegraph__Tooltip-title">Total</span>
					<span>{ Math.round( item.value * 1000 ) }ms</span>

					<span className="Flamegraph__Tooltip-title">Self</span>
					{ selfTimeMs === 0 ? (
						<span>-</span>
					) : (
						<span>{ selfTimeMs }ms</span>
					) }
				</div>

				<dl className="Flamegraph-Tooltip__details">
					<dt>Component:</dt>
					<dd>{ nameForComponent( componentForFunction( item.name ) ) }</dd>
					{ item.start && (
						<>
							<dt>Start Time:</dt>
							<dd>{ ( new Date( item.start * 1000 ) ).toISOString() }</dd>
						</>
					) }
					{ item.end && (
						<>
							<dt>End Time:</dt>
							<dd>{ ( new Date( item.end * 1000 ) ).toISOString() }</dd>
						</>
					) }
				</dl>
			</div>
		);
	}
}
