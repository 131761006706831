import { AnyAction } from 'redux';

interface State {
	[s: string]: {
		creating: boolean;
		stream: string | null;
		error: any;
	};
}

export default function applicationBuilds( state: State = {}, action: AnyAction ) {
	switch ( action.type ) {
		case 'BUILD_CREATING':
			return {
				...state,
				[action.payload.application.id]: {
					...state[action.payload.application.id],
					creating: true,
					stream: null,
					error: null,
				},
			};

		case 'BUILD_CREATING_TASK_STREAM':
			return {
				...state,
				[ action.payload.application.id ]: {
					...state[ action.payload.application.id ],
					stream: action.payload.taskStream,
				},
			};

		case 'BUILD_CREATED':
		case 'BUILD_FAILED':
			return {
				...state,
				[action.payload.application.id]: {
					...state[action.payload.application.id],
					creating: false,
					stream: null,
				},
			};
		default:
			return state;
	}
}
