import React, { useState } from 'react';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import HexagonIcon from 'react-feather/dist/icons/hexagon';
import ListIcon from 'react-feather/dist/icons/list';
import PackageIcon from 'react-feather/dist/icons/package';

import { ApplicationResponse } from '../../api';

import SidebarLink from './Link';

interface Props {
	application: ApplicationResponse,
	baseUrl: string,
}

export default function Nodejs( props: Props ) {
	const { baseUrl } = props;
	const [ isExpanded, setExpanded ] = useState<boolean>( false );

	const buttonClasses = [
		'w-full',
		'Sidebar-Link__link',
	];
	const submenuClasses = [
		'pl-4',
		! isExpanded && 'hidden',
	];
	return (
		<div className="Sidebar-Link relative">
			<button
				className={ buttonClasses.filter( Boolean ).join( ' ' ) }
				type="button"
				onClick={ () => setExpanded( ! isExpanded ) }
			>
				<span
					className="Sidebar-Link__disclosure absolute -ml-6"
				>
					{ isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon /> }
				</span>
				<HexagonIcon />

				Node.js
			</button>
			<div className={ submenuClasses.filter( Boolean ).join( ' ' ) }>
				<SidebarLink
					activeIncludesChildren
					to={ `${ baseUrl }/nodejs/deploys` }
				>
					<PackageIcon />

					Release
				</SidebarLink>
				<SidebarLink
					to={ `${ baseUrl }/logs?tab=nodejs` }
				>
					<ListIcon />

					Logs
				</SidebarLink>
			</div>
		</div>
	);
}
