import React from 'react';
import CheckIcon from 'react-feather/dist/icons/check';
import CircleIcon from 'react-feather/dist/icons/circle';
import SlashIcon from 'react-feather/dist/icons/slash';
import CancelIcon from 'react-feather/dist/icons/x';

import { Step } from '../../lib/builds';
import ActivityIndicator from '../ActivityIndicator';

import './StatusIcon.css';

interface Props {
	status: Step['status'];
}

export default function StatusIcon( props: Props ) {
	const baseClass = 'UnifiedDeployListItem-StatusIcon';
	const common = {
		size: 16,
	};
	switch ( props.status ) {
		case 'SUCCEEDED':
			return (
				<span
					className={ `${ baseClass } ${ baseClass }--success` }
					title="This step was successful."
				>
					<CheckIcon
						{ ...common }
					/>
				</span>
			);

		case 'IN_PROGRESS':
			return (
				<span
					className={ `${ baseClass } ${ baseClass }--in-progress` }
					title="This step is in progress."
				>
					<ActivityIndicator
						{ ...common }
					/>
				</span>
			);

		case 'FAILED':
		case 'FAULT':
		case 'TIMED_OUT':
			return (
				<span
					className={ `${ baseClass } ${ baseClass }--failed` }
					title="This step failed."
				>
					<CancelIcon
						{ ...common }
					/>
				</span>
			);

		case 'SKIPPED':
			return (
				<span
					className={ `${ baseClass } ${ baseClass }--skipped` }
					title="This step was skipped."
				>
					<SlashIcon
						{ ...common }
					/>
				</span>
			);

		case 'PENDING':
		case 'QUEUED':
		case 'STOPPED':
			return (
				<span
					className={ `${ baseClass } ${ baseClass }--waiting` }
					title="This step is waiting for prior steps to complete."
				>
					<CircleIcon
						{ ...common }
					/>
				</span>
			);

		default: {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const _exhaustiveCheck: never = props.status;
			return null;
		}
	}
}
