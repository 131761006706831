import { AnyAction } from 'redux';

interface State {
	[s: string]: {
		creating: boolean;
		error: any;
		build: string | null;
		stream: string | null;
	};
}

export default function applicationDeploys( state: State = {}, action: AnyAction ) {
	switch ( action.type ) {
		case 'WP_API_REDUX_FETCH_APPLICATIONS_UPDATED': {
			const newApps = action.payload.objects
				.map( ( object: any ) => object.id )
				.reduce( ( map: any, id: string ) => {
					map[id] = {
						creating: false,
						error: null,
						stream: null,
					};
					return map;
				}, {} );
			return {
				...state,
				...newApps,
			};
		}
		case 'DEPLOY_CREATING':
			return {
				...state,
				[action.payload.application.id]: {
					...state[action.payload.application.id],
					creating: true,
					error: null,
					build: action.payload.build || null,
					stream: null,
				},
			};
		case 'DEPLOY_CREATING_TASK_STREAM':
			return {
				...state,
				[action.payload.application.id]: {
					...state[action.payload.application.id],
					stream: action.payload.taskStream,
				},
			};
		case 'DEPLOY_CREATED':
		case 'DEPLOY_FAILED':
			return {
				...state,
				[action.payload.application.id]: {
					...state[action.payload.application.id],
					creating: false,
					build: null,
					stream: null,
				},
			};
		default:
			return state;
	}
}
