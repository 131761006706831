import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';

import fetchTaskStream from './fetchTaskStream';

interface DeployCreatingAction extends ReduxAction {
	type: 'DEPLOY_CREATING';
	payload: {
		application: Application;
		build: string | null;
	};
}

interface DeployCreatingTaskStreamAction extends ReduxAction {
	type: 'DEPLOY_CREATING_TASK_STREAM';
	payload: {
		application: Application;
		taskStream: string;
	};
}

interface DeployCreatedAction extends ReduxAction {
	type: 'DEPLOY_CREATED';
	payload: {
		application: Application;
	};
}

interface DeployFailedAction extends ReduxAction {
	type: 'DEPLOY_FAILED';
	payload: {
		application: Application;
		error: Error;
	};
}

export type Action = DeployCreatingAction | DeployCreatingTaskStreamAction | DeployCreatedAction | DeployFailedAction;

export default function createDeploy(
	application: Application,
	buildId: string | null,
	api: API
): ThunkAction<void, AppState, null, Action> {
	return dispatch => {
		dispatch( {
			type: 'DEPLOY_CREATING',
			payload: {
				application,
				build: buildId,
			},
		} );
		const params: any = {
			force: true,
			stream: true,
		};
		if ( buildId ) {
			params.build = buildId;
		}
		window.analytics.track( 'deploy_started', {
			application: application.id,
		} );
		return api.post( `/stack/applications/${application.id}/deploys`, params ).catch( error => {
			// Hash that we should look up
			if ( error.message.indexOf( application.id ) === 0 ) {
				dispatch( {
					type: 'DEPLOY_CREATING_TASK_STREAM',
					payload: {
						application,
						taskStream: error.message,
					},
				} );
				return dispatch( fetchTaskStream( error.message, application ) )
					.then( () => {
						dispatch( {
							type: 'DEPLOY_CREATED',
							payload: {
								application,
							},
						} );
						if ( Notification ) {
							new Notification( 'Deploy Completed 🚀', {
								body: application.id,
							} );
						}
						return application;
					} )
					.catch( error => {
						dispatch( {
							type: 'DEPLOY_FAILED',
							payload: {
								application,
								error,
							},
						} );
						if ( Notification ) {
							new Notification( 'Deploy Failed!', {
								body: application.id,
							} );
						}
					} );
			}
			dispatch( {
				type: 'DEPLOY_FAILED',
				payload: {
					application,
					error,
				},
			} );
			if ( Notification ) {
				new Notification( 'Deploy Failed!', {
					body: application.id,
				} );
			}
		} );
	};
}
