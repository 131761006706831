import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';

export default function flushObjectCacheServer(
	application: Application,
	api: API
): ThunkAction<void, AppState, null, Action> {
	return () => {
		window.analytics.track( 'object_cache_flushed', {
			application: application.id,
		} );
		return api.post( `/stack/applications/${application.id}/memcached-server/flush`, {} );
	};
}
