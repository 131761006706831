import qs from 'qs';
import React, { Component } from 'react';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { defaultAPI } from '../api';

import ActivityIndicator from './ActivityIndicator';
import BigTitle from './BigTitle';
import DropUpload from './DropUpload';
import ErrorBlock from './ErrorBlock';
import SuccessBlock from './NoItemsFoundBlock';
import PageTitle from './PageTitle';
import SelectApplication from './SelectApplication';
import SelectInstance from './SelectInstance';
import SolidButton from './SolidButton';
import FollowersSelector from './SupportTicket/FollowersSelector';

import './SupportNew.css';

const priorities = {
	low: 'Low',
	normal: 'Normal',
	high: 'High',
	urgent: 'Urgent (Site Down)',
};
const priorityItems = Object.keys( priorities ).map( value => ( {
	label: priorities[ value ],
	value,
} ) );

const TYPES = [
	'question',
	'incident',
	'problem',
	'task',
];

const defaultState = {
	applications: [],
	subject: '',
	description: '',
	followers: [],
	instance: null,
	isSending: false,
	error: null,
	response: null,
	priority: priorityItems[1],
	uploading: [],
	attachments: [],
};

class SupportNew extends Component {
	state = defaultState;

	constructor( props ) {
		super( props );

		if ( ! props.location || ! props.location.search ) {
			return;
		}

		const query = qs.parse( props.location.search, { ignoreQueryPrefix: true } );
		if ( query.instance ) {
			this.state.instance = query.instance;
		}

		if ( query.applications ) {
			this.state.applications = query.applications.map( id => ( { id } ) );
		}

		if ( query.subject ) {
			this.state.subject = query.subject;
		}

		if ( query.description ) {
			this.state.description = query.description;
		}
	}

	resetForm = ( args = {} ) => {
		this.setState( {
			...defaultState,
			...args,
		} );
	};

	onInputChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState( {
			[name]: value,
		} );
	};

	handleSelectChange = applications => {
		this.setState( {
			applications,
		} );
	};

	isSubmitDisabled = () => {
		let disabled = false;
		disabled = disabled || this.state.isSending;
		disabled = disabled || ( ! this.state.instance );
		disabled = disabled || this.state.subject.length === 0;
		disabled = disabled || this.state.description.length === 0;
		disabled = disabled || this.state.uploading.length > 0;
		return disabled;
	};

	async onSendMessage() {
		if ( ! this.state.instance ) {
			this.setState( {
				error: 'Instance is required.',
			} );
			return;
		}

		const instance = this.state.instance;
		const priority = this.state.priority.value;
		const type = this.props.match.params.type;

		const form = {
			...this.state,
			instance,
			priority,
			type,
			followers: this.state.followers.map( f => ( {
				action: 'put',
				email: f.value,
			} ) ),
			attachments: this.state.attachments.map( a => a.token ),
		};
		if ( this.state.applications.length > 0 ) {
			form.applications = this.state.applications.map( app => app.id );
		}

		this.setState( {
			isSending: true,
			error: null,
			response: null,
		} );

		try {
			const response = await defaultAPI.post( '/stack/support-tickets', form );

			this.props.history.push( `/support/ticket/${ response.id }/` );
		} catch ( error ) {
			this.setState( {
				isSending: false,
				error,
			} );

			return error;
		}
	}

	onUpload = files => {
		this.setState( s => ( {
			uploading: [
				...s.uploading,
				...files,
			],
		} ) );

		files.forEach( async file => {
			// Upload to the REST API.
			const options = {
				method: 'POST',
				body: new FormData(),
			};
			options.body.append( 'file', file );
			const resp = await defaultAPI.fetch( '/stack/support-tickets/upload', options );
			const data = await resp.json();

			// Remove from uploading, add to attachments.
			this.setState( s => ( {
				uploading: s.uploading.filter( f => f !== file ),
				attachments: [
					...s.attachments,
					data,
				],
			} ) );
		} );
	};

	render() {
		const type = this.props.match.params.type;
		if ( TYPES.indexOf( type ) === -1 ) {
			return (
				<div className="SupportNew">
					<BigTitle name="Create Support Ticket" />

					<p>The ticket type you have selected is invalid. <Link to="/support/new">Select a valid type.</Link></p>
				</div>
			);
		}

		return (
			<div className="SupportNew">
				<PageTitle title="Create Support Ticket" />
				<BigTitle name="Create Support Ticket" />
				<div className="form-field">
					<div>
						<h3>Instance</h3>
						<SelectInstance
							value={ this.state.instance }
							onSelect={ instance => this.setState( { instance } ) }
						/>
						<p className="field-description">
							The instance this request is concerning.
							Your ticket will be visible to all users with
							access to the instance.
						</p>
					</div>
				</div>

				<div className="form-field">
					<div>
						<h3>Environments</h3>
						<SelectApplication
							closeOnSelect={ false }
							instance={ this.state.instance }
							isDisabled={ ! this.state.instance }
							isMulti
							value={ this.state.applications }
							onChange={ selected => this.handleSelectChange( selected ) }
						/>
						<p className="field-description">
							If this is related to a specific environment, select it above.
						</p>
					</div>
				</div>

				{ type === 'incident' && (
					<div className="form-field">
						<label>
							<h3>Priority</h3>
							<Select
								options={ priorityItems }
								value={ this.state.priority }
								onChange={ priority => this.setState( { priority } ) }
							/>
						</label>
					</div>
				) }

				<div className="form-field">
					<label>
						<h3>Subject</h3>
						<input
							className="SupportNew__title"
							name="subject"
							type="text"
							value={ this.state.subject }
							onChange={ this.onInputChange }
						/>
						<p className="field-description">A brief summary of the question or issue.</p>
					</label>
				</div>
				<div
					className="form-field SupportNew__content"
				>
					<label>
						<h3>Description</h3>
						<DropUpload
							className="SupportNew__content-uploader"
							uploading={ this.state.uploading }
							onUpload={ this.onUpload }
						>
							<textarea
								name="description"
								value={ this.state.description }
								onChange={ this.onInputChange }
							/>

							<p className="field-description">
								<a
									href="https://commonmark.org/help/"
									rel="noopener noreferrer"
									target="_blank"
								>
									Format with Markdown

									<ExternalLinkIcon size={ 12 } />
								</a>
							</p>
						</DropUpload>

						{ this.state.attachments && this.state.attachments.length > 0 && (
							<ul className="SupportTicketMessage__attachments">
								<p className="SupportTicketMessage__attachments-header">Attached files:</p>
								{ this.state.attachments.map( attachment => (
									<li key={ attachment.token }>
										<a href={ attachment.url } rel="noopener noreferrer" target="_blank">
											{ attachment.filename }

											<ExternalLinkIcon size={ 12 } />
										</a>
									</li>
								) ) }
							</ul>
						) }
					</label>
				</div>
				<div
					className="form-field"
				>
					<label>
						<h3>CCs</h3>

						<FollowersSelector
							value={ this.state.followers }
							onChange={ followers => this.setState( { followers } ) }
						/>

						<p className="field-description">Additional emails to CC.</p>
					</label>
				</div>
				<div className="form-buttons">
					{ this.state.isSending && <ActivityIndicator size={ 30 } /> }
					<SolidButton disabled={ this.isSubmitDisabled() } name="Submit" onClick={ () => this.onSendMessage() } />
				</div>
				<div className="form-field">
					{ this.state.error && <ErrorBlock message={ 'Uh oh, an error occurred: ' + this.state.error.message } /> }
					{ this.state.response && (
						<SuccessBlock message="Form submitted successfully! We will respond as soon as possible. Thank you!" />
					) }
				</div>
			</div>
		);
	}
}

export default withRouter( SupportNew );
