import React from 'react';
import ChevronLeftIcon from 'react-feather/dist/icons/chevron-left';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';

import './Pagination.css';

interface Props {
	currentPage: number;
	totalPages: number;
	onNewer(): void;
	onOlder(): void;
}

export default function Pagination( props: Props ) {
	return (
		<div className="Pagination">
			<div className="Pagination__older">
				<button
					disabled={ props.currentPage >= props.totalPages }
					type="button"
					onClick={ props.onOlder }
				>
					<ChevronLeftIcon />
					Older
				</button>
			</div>
			<span>
				Page { props.currentPage } of { props.totalPages }
			</span>
			<div className="Pagination__newer">
				<button
					disabled={ props.currentPage <= 1 }
					type="button"
					onClick={ props.onNewer }
				>
					Newer
					<ChevronRightIcon />
				</button>
			</div>
		</div>
	);
}
