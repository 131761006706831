import React from 'react';
import { BulletList } from 'react-content-loader';
import { FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { withApiData } from '../lib/with-api-data';

import BigTitle from './BigTitle';
import RegionLogs from './RegionLogs';
import TabBar from './TabBar';
import TabBarItem from './TabBarItem';

import './Region.css';

function Region( props ) {
	const region = props.match.params.region;
	return (
		<div className="Region">
			<BigTitle name={ `Region ${props.match.params.region}` } />
			<TabBar>
				<TabBarItem
					key="Overview"
					isActive={ props.match.path === '/:region' }
					name="Overview"
					to={ `/${props.match.params.region}` }
				/>
				<TabBarItem
					key="amis"
					isActive={ props.match.path === '/:region/amis' }
					name="AMIs"
					to={ `/${props.match.params.region}/amis` }
				/>
				<TabBarItem
					key="logs"
					isActive={ props.match.path === '/:region/logs' }
					name="Logs"
					to={ `/${props.match.params.region}/logs` }
				/>
			</TabBar>
			<Route
				path="/:region/amis"
				render={ () =>
					props.amis.isLoading ? (
						<BulletList />
					) : (
						<table>
							<thead>
								<tr>
									<td>AMI ID</td>
									<td>Description</td>
									<td>Date</td>
									<td>Applications Count</td>
								</tr>
							</thead>
							<tbody>
								{ ( props.amis.data || [] )
									.sort( ( a, b ) => ( a.date >= b.date ? -1 : 1 ) )
									.map( ami => (
										<tr key={ ami.id }>
											<td>
												<code>{ ami.id }</code>
											</td>
											<td>{ ami.description }</td>
											<td>
												<FormattedDate value={ ami.date } />
											</td>
											<td>{ Object.values( props.applications ).filter( a => a.region === region && a.ami === ami.id ).length }</td>
										</tr>
									) ) }
							</tbody>
						</table>
					) }
			/>
			<Route component={ RegionLogs } path="/:region/logs" />
		</div>
	);
}

function mapStateToProps( state ) {
	return {
		applications: state.applications,
	};
}
export default connect( mapStateToProps )(
	withApiData( props => ( {
		amis: `/stack/region/${ props.match.params.region }/amis`,
	} ) )( Region )
);
