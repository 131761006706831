import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../reducers';

import PageTitle from './PageTitle';

import './Productboard.css';

interface Props {
	token: string,
}

function Productboard( props: Props ) {
	return (
		<div className="Productboard">
			<PageTitle title="Roadmap" />
			<iframe
				frameBorder="0"
				height="100%"
				src={ `https://portal.productboard.com/xeoe4s31e82z3m8kppcgjowj?token=${ props.token }` }
				title="Productboard Embed"
				width="100%"
			></iframe>
		</div>
	);
}

const mapStateToProps = ( state: AppState ) => ( {
	token: state.currentUser.user?.productboard_token || '',
} );

export default connect( mapStateToProps )( Productboard );
