import PropTypes from 'prop-types';
import React from 'react';

import './WebServersList.css';
import ServerListItemLoading from './ServerListItemLoading';
import WebServersListItem from './WebServersListItem';

export default function WebServersList( { isLoading, items, onPin, onUnpin, showSSHConnection } ) {
	return (
		<div className="WebServersList">
			{ isLoading ? (
				<ServerListItemLoading />
			) : (
				items.map( webServer => (
					<WebServersListItem
						key={ webServer.id }
						ipAddress={ webServer['ip-address'] }
						isPinned={ webServer['scale-in-protection'] || false }
						metricEndpoint={ webServer._links ? webServer._links['https://hm-stack.hm/metric'][0].href : undefined }
						name={ webServer.id }
						sshConnectionCommand={ showSSHConnection ? webServer['ssh-connection-string'] : null }
						status={ webServer.status === 'running' ? 'ok' : 'error' }
						type={ webServer.size }
						onPin={ onPin ? () => onPin( webServer ) : null }
						onUnpin={ onUnpin ? () => onUnpin( webServer ) : null }
					/>
				) )
			) }
		</div>
	);
}

WebServersList.propTypes = {
	isLoading: PropTypes.bool,
	onPin: PropTypes.func,
	onUnpin: PropTypes.func,
	showSSHConnection: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.shape( {
			id: PropTypes.string.isRequired,
			'ip-address': PropTypes.string.isRequired,
			isPinned: PropTypes.bool,
			status: PropTypes.string,
			size: PropTypes.string,
			'ssh-connection-string': PropTypes.string,
		} )
	).isRequired,
};
