import PropTypes from 'prop-types';
import React from 'react';

import { withApiData } from '../../lib/with-api-data';
import ActivityListItem from '../ActivityListItem';
import ErrorBlock from '../ErrorBlock';
import SolidButton from '../SolidButton';

import './CreateDeploy.css';

const CreateDeployEC2 = ( { deployment, children, currentDeploy, isDeploying, onDeploy } ) => {
	if ( deployment.isLoading ) {
		return (
			<div className="branch-deploy">
				<h3>Loading latest commit…</h3>
			</div>
		);
	}

	if ( ! deployment.data ) {
		return null;
	}

	if ( deployment.data.branch_details.error || ! deployment.data.branch_details.latest_commit ) {
		const error = deployment.data.branch_details.error;
		return (
			<ErrorBlock
				message={ error ? error.message : 'No latest comment found for branch details.' }
			/>
		);
	}

	return (
		<div className="branch-deploy">
			<h3>Latest Commit Ready to Deploy</h3>
			<div className="branch-details">
				<ActivityListItem
					date={ deployment.data.branch_details.latest_commit.date }
					description={ deployment.data.branch_details.latest_commit.description }
					user={ deployment.data.branch_details.latest_commit.user }
				/>
				<SolidButton
					disabled={ isDeploying }
					name={
						isDeploying
							? 'Deploying...'
							: currentDeploy &&
							deployment.data.branch_details.latest_commit.rev === currentDeploy.rev
								? 'Force Redeploy'
								: 'Deploy'
					}
					onClick={ onDeploy }
				/>
			</div>

			{ children }
		</div>
	);
};
const ConnectedCreateDeployEC2 = withApiData( props => {
	return {
		deployment: `/stack/applications/${ props.application.id }/deployment?include_details=true`,
	};
} )( CreateDeployEC2 );

export default function CreateDeploy( props ) {
	if ( props.application.architecture === 'terraform-ecs-application' ) {
		return null;
	}

	return (
		<ConnectedCreateDeployEC2
			{ ...props }
		/>
	);
}

CreateDeploy.propTypes = {
	application: PropTypes.object.isRequired,
	currentDeploy: PropTypes.object,
	isDeploying: PropTypes.bool,
	onDeploy: PropTypes.func.isRequired,
};
