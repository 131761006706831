import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { createBuild } from '../actions';
import { withApiData } from '../lib/with-api-data';

import ErrorBlock from './ErrorBlock';
import OutlineButton from './OutlineButton';

export class ApplicationBuildRebuildButton extends React.Component {
	static propTypes = {
		application: PropTypes.object,
	};

	onBuild = () => {
		const api = {
			post: this.props.post,
		};

		this.props.dispatch( createBuild( this.props.application, api ) )
			.then( this.props.refreshData );
	};

	render() {
		const { application, deployment } = this.props;
		const builds = this.props.builds.data || null;
		if ( ! application || ! builds || ! this.props.showBuild ) {
			return null;
		}

		if ( deployment.isLoading ) {
			return (
				<OutlineButton
					disabled
					name="Loading commits…"
					onClick={ () => {} }
				/>
			);
		}

		if ( deployment.data.branch_details.error || ! deployment.data.branch_details.latest_commit ) {
			const error = deployment.data.branch_details.error;
			return (
				<ErrorBlock
					message={ error ? error.message : 'No latest comment found for branch details.' }
				/>
			);
		}

		let latestCommitId = null;
		if ( builds ) {
			builds.sort( ( a, b ) => ( a.date > b.date ? -1 : 1 ) );
			latestCommitId = builds[0].source_version || builds[0].id;
		}

		let name = 'Build';
		if ( this.props.isBuilding ) {
			name = 'Building...';
		} else if ( builds && deployment.data && deployment.data.branch_details.latest_commit.rev === latestCommitId ) {
			name = 'Force Rebuild';
		}

		return (
			<OutlineButton
				disabled={ this.props.isBuilding }
				name={ name }
				onClick={ this.onBuild }
			/>
		);
	}
}

const mapStateToProps = ( state, props ) => ( {
	isBuilding:
		state.applicationBuilds[ props.application.id ] !== undefined
			? state.applicationBuilds[ props.application.id ].creating
			: false,
	showBuild: props.application.can.develop,
} );

export default compose(
	connect( mapStateToProps ),
	withApiData( props => ( {
		builds: `/stack/applications/${ props.application.id }/builds`,
		deployment: `/stack/applications/${ props.application.id }/deployment?include_details=true`,
	} ) )
)( ApplicationBuildRebuildButton );
