import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { fetchSupportTickets, fetchOnCall } from '../actions';
import { defaultAPI } from '../api';

import BigTitle from './BigTitle';
import OnCallCarousel from './OnCallCarousel';
import Pagination from './Pagination';
import SelectInstanceApplication from './SelectInstanceApplication';
import SolidButton from './SolidButton';
import SupportListItem from './SupportListItem';
import SupportListItemLoading from './SupportListItemLoading';
import TabBar from './TabBar';
import TabBarButton from './TabBarButton';

import './SupportList.css';

const priorityOptions = [
	{
		label: 'Low',
		value: 'low',
	},
	{
		label: 'Normal',
		value: 'normal',
	},
	{
		label: 'High',
		value: 'high',
	},
	{
		label: 'Urgent',
		value: 'urgent',
	},
];

const statusOptions = [
	{
		label: 'New',
		value: 'new',
	},
	{
		label: 'Open',
		value: 'open',
	},
	{
		label: 'Pending',
		value: 'pending',
	},
	{
		label: 'On Hold',
		value: 'hold',
	},
	{
		label: 'Solved',
		value: 'solved',
	},
	{
		label: 'Closed',
		value: 'closed',
	},
];
const openStatuses = [
	'open',
	'new',
	'pending',
	'hold',
];

class SupportList extends Component {
	state = {
		tab: 'open',
		pinnedOnly: true,
		page: 1,
	};

	componentDidMount() {
		this.props.dispatch( fetchSupportTickets( this.getSupportFilter(), defaultAPI ) );
		this.props.dispatch( fetchOnCall( defaultAPI ) );
	}

	componentDidUpdate( prevProps, prevState ) {
		if ( this.state.page !== prevState.page || this.state.tab !== prevState.tab || this.state.pinnedOnly !== prevState.pinnedOnly || this.props.filter !== prevProps.filter ) {
			this.props.dispatch( fetchSupportTickets( this.getSupportFilter(), defaultAPI ) );
		}
	}

	onChangeFilter( filterField, value ) {
		this.props.dispatch( {
			type: 'UPDATED_SUPPORT_TICKETS_FILTER',
			filter: {
				...this.props.filter,
				[filterField]: value,
			},
		} );

		// Reset page too.
		this.setState( {
			page: 1,
		} );
	}

	getSupportFilter() {
		const usePinned = this.props.pinnedApplications.length > 0 && this.state.pinnedOnly;
		switch ( this.state.tab ) {
			case 'open':
				return {
					status: openStatuses,
					priority: {},
					applications: usePinned ? this.props.pinnedApplications : [],
					page: this.state.page,
				};

			case 'all':
				return {
					status: statusOptions.map( status => status.value ),
					priority: {},
					applications: usePinned ? this.props.pinnedApplications : [],
					page: this.state.page,
				};

			case 'query':
			default: {
				const filter = {};
				if ( this.props.filter.status.length > 0 ) {
					filter.status = this.props.filter.status.map( status => status.value );
				}
				if ( this.props.filter.applications.length > 0 ) {
					filter.applications = this.props.filter.applications.map( app => app.id );
				}
				filter.priority = this.props.filter.priority;
				filter.page = this.state.page;
				return filter;
			}
		}
	}

	onOlder = () => {
		this.setState( {
			page: this.state.page + 1,
		} );
	};

	onNewer = () => {
		this.setState( {
			page: Math.max( this.state.page - 1, 1 ),
		} );
	};

	setActiveTab( tab ) {
		this.setState( {
			tab,
			page: 1,
		} );
	}

	render() {
		const fullFilter = this.getSupportFilter();

		const tickets = Object.values( this.props.tickets ).reverse().filter( ticket => {
			if ( fullFilter.priority && fullFilter.priority.value && fullFilter.priority.value !== ticket.priority ) {
				return false;
			}
			if ( fullFilter.status && fullFilter.status.length > 0 && fullFilter.status.indexOf( ticket.status ) === -1 ) {
				return false;
			}
			return true;
		} );

		const onCallUsers = this.props.onCallUsers.map( user => ( {
			user: user,
			endDate: user.end_date,
		} ) );

		return (
			<div className="SupportList">
				<div className="SupportList__title">
					<BigTitle name="Support" />
					{ this.props.onCallUsers.length > 0 && <OnCallCarousel users={ onCallUsers } /> }
				</div>

				<div className="SupportList__new">
					<SolidButton name="Create New Ticket" to="/support/new" />
				</div>

				<TabBar>
					<TabBarButton
						isActive={ this.state.tab === 'open' }
						name="Open Tickets"
						onClick={ () => this.setActiveTab( 'open' ) }
					/>

					<TabBarButton
						isActive={ this.state.tab === 'all' }
						name="All Tickets"
						onClick={ () => this.setActiveTab( 'all' ) }
					/>

					<TabBarButton
						isActive={ this.state.tab === 'query' }
						name="Query"
						onClick={ () => this.setActiveTab( 'query' ) }
					/>
				</TabBar>

				{ this.state.tab === 'query' ? (
					<form>
						<label className="SupportList__filter">
							<span className="SupportList__filter-name">Status:</span>
							<Select
								isClearable
								isMulti
								options={ statusOptions }
								placeholder="Status"
								value={ this.props.filter.status }
								onChange={ e => this.onChangeFilter( 'status', e ) }
							/>
						</label>
						<label className="SupportList__filter">
							<span className="SupportList__filter-name">Priority:</span>
							<Select
								isClearable
								options={ priorityOptions }
								placeholder="Priority"
								value={ this.props.filter.priority }
								onChange={ e => this.onChangeFilter( 'priority', e ) }
							/>
						</label>
						<label className="SupportList__filter">
							<span className="SupportList__filter-name">Applications:</span>
							<SelectInstanceApplication
								isClearable
								isMulti
								value={ this.props.filter.applications }
								onChange={ e => this.onChangeFilter( 'applications', e ) }
							/>
						</label>
					</form>
				) : this.props.pinnedApplications.length > 0 ? (
					<p
						className="SupportList__pin-note"
					>
						{ this.state.pinnedOnly ? (
							<>
								{ 'Showing tickets for starred applications only. ' }
								<button onClick={ () => this.setState( { pinnedOnly: false } ) }>View all.</button>
							</>
						) : (
							<>
								{ 'Showing tickets for all applications. ' }
								<button onClick={ () => this.setState( { pinnedOnly: true } ) }>View starred applications only.</button>
							</>
						) }
					</p>
				) : null }

				{ this.props.isLoading ? (
					<>
						<SupportListItemLoading
							titleModifier={ 1.0 }
						/>
						<SupportListItemLoading
							titleModifier={ 0.6 }
						/>
						<SupportListItemLoading
							titleModifier={ 0.8 }
						/>
					</>
				) : tickets.length > 0 ? (
					<div className="SupportList__items">
						{ tickets.map( supportItem => (
							<SupportListItem key={ supportItem.id } { ...supportItem } />
						) ) }
					</div>
				) : ! this.props.isLoading ? (
					<p>No results found.</p>
				) : null }

				{ ! this.props.isLoading && this.props.totalPages > 1 && (
					<Pagination
						currentPage={ fullFilter.page }
						totalPages={ this.props.totalPages }
						onNewer={ this.onNewer }
						onOlder={ this.onOlder }
					/>
				) }
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		filter: state.supportTickets.filter,
		isLoading: state.supportTickets.isLoading,
		tickets: state.supportTickets.byId,
		totalPages: state.supportTickets.totalPages,
		pinnedApplications: state.preferences.pinned,
		onCallUsers: state.onCall.users,
	};
};

export default connect( mapStateToProps )( SupportList );
