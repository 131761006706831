import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { defaultAPI } from '../api';
import { AppState } from '../reducers';
import { UserResponse } from '../types/rest-api';

interface CurrentUserUpdatedAction extends ReduxAction {
	type: 'CURRENT_USER_UPDATED';
	payload: {
		user: UserResponse;
	};
}

export type Action = CurrentUserUpdatedAction;

export default function updateCurrentUser( user: any ): ThunkAction<Promise<UserResponse>, AppState, null, Action> {
	return ( dispatch, getStore ) => {
		const currentUser = getStore().currentUser;
		if ( typeof currentUser.user === 'undefined' ) {
			throw new Error( 'User is not loggged in.' );
		}

		window.analytics.track( 'user_updated', {} );

		return defaultAPI.post( `/wp/v2/users/${currentUser.user.id}`, user ).then( ( user: UserResponse ) => {
			dispatch( {
				type: 'CURRENT_USER_UPDATED',
				payload: { user },
			} );
			return user;
		} );
	};
}
