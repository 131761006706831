import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './TabBarItem.css';

export default function TabBarItem( props ) {
	return (
		<Link className={ 'TabBarItem ' + ( props.isActive ? 'active' : '' ) } to={ props.to }>
			<span className="name">{ props.name }</span>
		</Link>
	);
}

TabBarItem.propTypes = {
	isActive: PropTypes.bool,
	name: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};
