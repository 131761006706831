import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';

import './PillButton.css';

export default function PillButton( props ) {
	return (
		<Button
			size="xs"
			type="submit"
			onClick={ props.onClick }
		>
			{ props.name }
		</Button>
	);
}

PillButton.defaultProps = {
	color: '#4667DE',
};

PillButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	color: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};
