import React from 'react';

import SolidButton from './SolidButton';
import useId from './useId';

interface Props {
	children?: React.ReactNode,
	className?: string,
	description?: React.ReactNode,
	footer?: React.ReactNode,
	title: React.ReactNode,
	saveDisabled?: boolean,
	saveLabel?: string,
	onSave?(): void,
}

export default function SettingsGroup( props: Props ) {
	const id = useId();
	return (
		<section
			aria-labelledby={ `${ id }-heading` }
			className={ `SettingsGroup border border-gray-200 sm:overflow-hidden sm:rounded-md ${ props.className || '' }` }
		>
			<div className="bg-white py-6 px-4 sm:p-6">
				<div className="mb-2">
					<h2
						className="!my-0 !mb-1 !text-lg font-medium !leading-6 text-gray-900"
						id={ `${ id }-heading` }
					>
						{ props.title }
					</h2>
					{ props.description && (
						<p className="text-sm text-gray-500">
							{ props.description }
						</p>
					) }
				</div>

				{ props.children }
			</div>
			{ ( props.footer || props.onSave ) && (
				<div className="flex align-middle bg-gray-50 px-4 py-3 sm:px-6">
					<div className="flex-grow">
						{ props.footer }
					</div>
					{ props.onSave && (
						<div className="flex-shrink-0 flex self-end">
							<SolidButton
								disabled={ props.saveDisabled }
								name={ props.saveLabel || 'Save' }
								onClick={ props.onSave }
							/>
						</div>
					) }
				</div>
			) }
		</section>
	);
}
