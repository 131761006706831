import React from 'react';

import { withApiData } from '../lib/with-api-data';

import './ObjectCacheServerList.css';
import ObjectCacheServerListItem from './ObjectCacheServerListItem';

export class ObjectCacheServerList extends React.Component {
	render() {
		if ( ! this.props.objectCacheServer.data ) {
			return '';
		}
		return (
			<div className="ObjectCacheServerList">
				{ [ this.props.objectCacheServer.data ].map( objectCacheServer => (
					<ObjectCacheServerListItem
						key={ objectCacheServer.id }
						metricEndpoint={
							objectCacheServer._links ? objectCacheServer._links['https://hm-stack.hm/metric'][0].href : undefined
						}
						name={ objectCacheServer.id }
						status={ objectCacheServer.status === 'available' ? 'ok' : 'error' }
						type={ objectCacheServer.size }
					/>
				) ) }
			</div>
		);
	}
}

export default withApiData( props => ( {
	objectCacheServer: `/stack/applications/${ props.application }/memcached-server`,
} ) )( ObjectCacheServerList );
