import PropTypes from 'prop-types';
import React, { Component } from 'react';

import OnCallCarouselSlide from './OnCallCarouselSlide';

export default class OnCallCarousel extends Component {
	static propTypes = {
		users: PropTypes.arrayOf( PropTypes.object ).isRequired,
	};

	constructor() {
		super();
		this.state = {
			currentPage: 0,
		};
	}

	onPrevious() {
		this.setState( {
			currentPage: this.state.currentPage - 1,
		} );
	}
	onNext() {
		this.setState( {
			currentPage: this.state.currentPage + 1,
		} );
	}
	render() {
		return (
			<div className="OnCallCarousel">
				<OnCallCarouselSlide
					endDate={ this.props.users[this.state.currentPage].endDate }
					user={ this.props.users[this.state.currentPage].user }
					onNext={ this.state.currentPage < this.props.users.length - 1 ? () => this.onNext() : null }
					onPrevious={ this.state.currentPage > 0 ? () => this.onPrevious() : null }
				/>
			</div>
		);
	}
}
