export type Region =
	'us-east-1' |
	'eu-west-1' |
	'eu-central-1' |
	'ap-southeast-1' |
	'ap-southeast-2' |
	'ap-northeast-1';

export const regionDetails = {
	'us-east-1': {
		title: 'US (East)',
		description: 'Best for teams in North America.',
		isGreen: true,
	},
	'eu-west-1': {
		title: 'Europe (Ireland)',
		description: 'Best for teams in western Europe.',
		isGreen: true,
	},
	'eu-central-1': {
		title: 'Europe (Frankfurt)',
		description: 'Best for teams in central or eatern Europe, western Asia, or Africa.',
		isGreen: true,
	},
	'ap-southeast-1': {
		title: 'Asia-Pacific (Singapore)',
		description: 'Best for teams in south-east Asia.',
		isGreen: false,
	},
	'ap-southeast-2': {
		title: 'Asia-Pacific (Sydney)',
		description: 'Best for teams in Australia and New Zealand.',
		isGreen: false,
	},
	'ap-northeast-1': {
		title: 'Asia-Pacific (Tokyo)',
		description: 'Best for teams in eastern Asia.',
		isGreen: false,
	},
};
