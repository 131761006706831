import React from 'react';
import DatabaseIcon from 'react-feather/dist/icons/database';
import GlobeIcon from 'react-feather/dist/icons/globe';
import ListIcon from 'react-feather/dist/icons/list';
import MonitorIcon from 'react-feather/dist/icons/monitor';
import PackageIcon from 'react-feather/dist/icons/package';
import ServerIcon from 'react-feather/dist/icons/server';
import SettingsIcon from 'react-feather/dist/icons/settings';
import TerminalIcon from 'react-feather/dist/icons/terminal';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { AppState } from '../../reducers';
import { ApplicationResponse } from '../../types/rest-api';

import SidebarLink from './Link';
import Nodejs from './Nodejs';

import './Application.css';

const canDevelop = ( user: AppState['currentUser'], application: ApplicationResponse ) => {
	if ( ! user || user.loading ) {
		return false;
	}

	// Admins can always run CLI commands.
	if ( user.capabilities.edit_applications ) {
		return true;
	}

	// Are caps available to check?
	if ( application.can ) {
		return application.can.develop;
	}

	// Otherwise, check legacy meta keys.
	const rawUser = user.user;
	// @ts-ignore
	return rawUser.developable_applications && rawUser.developable_applications.indexOf( application.id ) >= 0;
};

const canAdministrate = ( user: AppState['currentUser'], application: ApplicationResponse ) => {
	if ( ! user || user.loading ) {
		return false;
	}

	// Admins can always administrate.
	if ( user.capabilities.edit_applications ) {
		return true;
	}

	return application.can.administrate;
};

interface Props {
	application: ApplicationResponse,
	baseUrl: string,
}

export default function ApplicationSection( props: Props ) {
	const { application, baseUrl } = props;
	const currentUser = useSelector( ( state: AppState ) => state.currentUser );
	const isActive = !! useRouteMatch( baseUrl );
	const hasNode = application.has.nodejs;

	return (
		<>
			<SidebarLink
				activeIncludesChildren
				to={ baseUrl }
			>
				<GlobeIcon />

				{ application.id }
			</SidebarLink>

			{ isActive && (
				<div className="Sidebar-ApplicationSection__subitems">
					<SidebarLink
						activeIncludesChildren
						to={ `${ baseUrl }/deploys` }
					>
						<PackageIcon />

						Release
					</SidebarLink>

					{ hasNode && (
						<Nodejs
							application={ application }
							baseUrl={ baseUrl }
						/>
					) }

					<SidebarLink
						activeIncludesChildren
						to={ `${ baseUrl }/import-export` }
					>
						<DatabaseIcon />

						Data
					</SidebarLink>

					<SidebarLink
						activeIncludesChildren
						to={ `${ baseUrl }/servers` }
					>
						<ServerIcon />

						Servers
					</SidebarLink>

					<SidebarLink
						activeIncludesChildren
						to={ `${ baseUrl }/logs` }
					>
						<ListIcon />

						Logs
					</SidebarLink>

					{ /* @ts-ignore */ }
					{ currentUser && currentUser.capabilities.read_xray && (
						<SidebarLink
							activeIncludesChildren
							to={ `${ baseUrl }/xray` }
						>
							<MonitorIcon />

							XRay
						</SidebarLink>
					) }

					{ currentUser && canDevelop( currentUser, application ) && (
						<SidebarLink
							activeIncludesChildren
							to={ `${ baseUrl }/cli` }
						>
							<TerminalIcon />

							CLI
						</SidebarLink>
					) }

					{ currentUser && canAdministrate( currentUser, application ) && (
						<SidebarLink
							activeIncludesChildren
							to={ `${ baseUrl }/settings` }
						>
							<SettingsIcon />

							Settings
						</SidebarLink>
					) }
				</div>
			) }
		</>
	);
}
