import React, { Fragment } from 'react';

import './ActivityList.css';
import ActivityListItem from './ActivityListItem';
import ActivityListItemLoading from './ActivityListItemLoading';

const keyForItem = activity => `${ activity.date }-${ activity.description }`;

export default function ActivityList( { items, isLoading } ) {
	return (
		<div className="ActivityList">
			{ isLoading ? (
				<Fragment>
					<ActivityListItemLoading />
					<ActivityListItemLoading />
					<ActivityListItemLoading />
					<ActivityListItemLoading />
					<ActivityListItemLoading />
				</Fragment>
			) : (
				items.map( activity => (
					<ActivityListItem
						key={ keyForItem( activity ) }
						{ ...activity }
					/>
				) )
			) }
		</div>
	);
}
