import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import fetchInstance from '../actions/fetchInstance';
import selectInstance from '../actions/selectInstance';
import { AppState } from '../reducers';
import { ApplicationResponse, InstanceResponse } from '../types/rest-api';

import Application from './Application';
import ErrorBoundary from './ErrorBoundary';
import Metrics from './Instance/Metrics';
import Overview from './Instance/Overview';
import Reports from './Instance/Reports';
import Settings from './Instance/Settings';
import Users from './InstanceUsers';
import PageTitle from './PageTitle';
import PinnedNotices, { Notice } from './PinnedNotices';
import Sidebar from './Sidebar';

import './InstanceContainer.css';

interface Props {
	onLogout(): void,
}

type RouteProps = Pick<RouteComponentProps<{
	instance: string,
}>, 'match'>;

type StateProps = {
	applications: ApplicationResponse[],
	applicationsLoading: boolean,
	error?: Error,
	globalNotices?: Notice[],
	instance?: InstanceResponse,
	loading: boolean,
	selectedInstance: string | null,
}

type DispatchProps = {
	onLoad(): Promise<any>,
	onSelectInstance( id: string ): void,
}

type AllProps = Props & StateProps & RouteProps & DispatchProps;

export function InstanceContainer( props: AllProps ) {
	const { applications, applicationsLoading, error, instance, loading, selectedInstance, onLoad, onSelectInstance } = props;
	const baseUrl = props.match.url;
	const id = props.match.params.instance;

	useEffect( () => {
		if ( id !== selectedInstance ) {
			onSelectInstance( id );
		}

		if ( ! instance && ! loading ) {
			onLoad();
		}
	}, [ id, instance, loading, selectedInstance, onLoad, onSelectInstance ] );

	if ( loading && ! instance ) {
		return (
			<div className="InstanceContainer horizontal">
				<Sidebar />

				<div className="page-outer">
					<div className="page">
						<ContentLoader
							width="100%"
						>
							<rect
								height="31"
								width="70%"
								x="0"
								y="0"
							/>
							<rect
								height="25"
								width="100%"
								x="0"
								y="50"
							/>
							<rect
								height="25"
								width="80%"
								x="0"
								y="80"
							/>
						</ContentLoader>
					</div>
				</div>
			</div>
		);
	}

	if ( error || ! instance ) {
		return null;
	}

	return (
		<div className="InstanceContainer horizontal">
			{ instance && (
				<PageTitle
					title={ instance.name }
				/>
			) }
			<Sidebar />

			<div className="page-outer">
				{ props.globalNotices && (
					<PinnedNotices
						notices={ props.globalNotices || [] }
					/>
				) }

				<PinnedNotices
					notices={ instance.notices || [] }
				/>

				<div className="page">
					<ErrorBoundary>
						<Switch>
							<Route
								exact
								path={ baseUrl }
								render={ props => (
									<Overview
										applications={ applications }
										applicationsLoading={ applicationsLoading }
										instance={ instance }
										instanceError={ error }
										instanceLoading={ loading }
										{ ...props }
									/>
								) }
							/>
							<Route
								exact
								path={ `${ baseUrl }/metrics/:type?` }
								render={ ( props: RouteComponentProps<{ type?: string }> ) => (
									<Metrics
										applications={ applications }
										instance={ instance }
										type={ props.match.params.type }
										onChangeType={ type => props.history.push( `${ baseUrl }/metrics/${ type }`.replace( /\/$/, '' ) ) }
									/>
								) }
							/>
							<Route
								exact
								path={ `${ baseUrl }/users` }
								render={ props => (
									<Users
										instance={ instance }
										instanceLoading={ loading }
										{ ...props }
									/>
								) }
							/>
							<Route
								exact
								path={ `${ baseUrl }/reports` }
								render={ props => (
									<Reports
										instance={ instance }
										instanceLoading={ loading }
										{ ...props }
									/>
								) }
							/>
							<Route
								path={ `${ baseUrl }/settings` }
								render={ props => (
									<Settings
										instance={ instance }
										instanceLoading={ loading }
										{ ...props }
									/>
								) }
							/>
							<Route
								path={ `${ baseUrl }/e/:application/:tab*` }
								render={ props => (
									<Application
										basePath={ `${ baseUrl }/e/:application` }
										baseUrl={ `${ baseUrl }/e/${ props.match.params.application }` }
										{ ...props }
									/>
								) }
							/>
							<Route><p>404.</p></Route>
						</Switch>
					</ErrorBoundary>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = ( state: AppState, props: RouteProps ): StateProps => ( {
	error: state.instances.errors[ props.match.params.instance ],
	globalNotices: state.currentUser.user?.notices,
	instance: state.instances.data[ props.match.params.instance ],
	loading: state.instances.loading[ props.match.params.instance ],
	selectedInstance: state.instances.selected,
	applications: Object.values( state.applications ).filter( application => application.instance === props.match.params.instance ),
	applicationsLoading: state.instances.loadingApplications[ props.match.params.instance ],
} );

const mapDispatchToProps = ( dispatch: any, props: RouteProps ): DispatchProps => ( {
	onLoad: () => dispatch( fetchInstance( props.match.params.instance ) ),
	onSelectInstance: () => dispatch( selectInstance( props.match.params.instance ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( InstanceContainer );
