import PropTypes from 'prop-types';
import React from 'react';

import ErrorBlock from './ErrorBlock';
import OutlineButton from './OutlineButton';

export default function DeployLockActions( props ) {
	return (
		<>
			{ props.releaseDeployLocksError && <ErrorBlock message={ props.releaseDeployLocksError.message } /> }
			<OutlineButton
				disabled={ props.isReleasingDeployLocks }
				name={ props.isReleasingDeployLocks ? 'Releasing...' : 'Release deploy locks' }
				onClick={ props.onReleaseDeployLocks }
			/>
		</>
	);
}

DeployLockActions.propTypes = {
	releaseDeployLocksError: PropTypes.shape( {
		message: PropTypes.string.isRequired,
	} ),
	isReleasingDeployLocks: PropTypes.bool.isRequired,
	onReleaseDeployLocks: PropTypes.func.isRequired,
};
