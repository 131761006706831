import {
	CodeBuildStatus,
	FAILED_STATUSES,
	Message,
	Step,
} from './builds';

export type DeployStep =
	'PROVISIONING' |
	'DEPLOY_SANDBOX' |
	'DEPLOY_CAVALCADE' |
	'INSTALL_APP' |
	'HEALTHCHECK_APP' |
	'DEPLOY_APP';

const DEPLOY_PHASES: DeployStep[] = [
	'PROVISIONING',
	'DEPLOY_SANDBOX',
	'DEPLOY_CAVALCADE',
	'INSTALL_APP',
	'HEALTHCHECK_APP',
	'DEPLOY_APP',
];
const DEPLOY_PHASE_MAP: { [ k: string ]: DeployStep } = DEPLOY_PHASES.reduce( ( obj, k ) => ( {
	...obj,
	[ k ]: k,
} ), {} );

export type DeployPhase = {
	contexts?: {
		message?: string;
		statusCode?: string;
	}[];
	startTime: string | null;
	endTime: string | null;
	durationInSeconds?: number;
	phaseStatus: CodeBuildStatus;
	phaseType: DeployStep;
	order?: number;
}

export interface DeployMessage extends Message {
	context: {
		phaseType: DeployStep;
	};
	level: 'info' | 'debug';
	time: string;
}

/**
 * Parse phases into group objects.
 *
 */
function buildGroups( map: { [ k: string ]: string }, phases: DeployPhase[] ) {
	const grouped: { [ k: string ]: Step } = {};

	// Set up the main groups.
	let hasFailed = false;
	for ( const step of Object.keys( map ) ) {
		const mappedPhase = map[ step ];

		// Set the defaults first.
		if ( ! grouped[ mappedPhase ] ) {
			grouped[ mappedPhase ] = {
				messages: [],
				status: hasFailed ? 'SKIPPED' : 'QUEUED',
				contexts: [],
				start: null,
				end: null,
				duration: 0,
			};
		}

		// Grab a quick ref for mutating.
		const currentPhase = grouped[ mappedPhase ];

		// Do we have data for this phase?
		const phaseData = phases.find( phase => phase.phaseType === step );
		if ( ! phaseData ) {
			continue;
		}

		if ( ! currentPhase.start ) {
			currentPhase.start = phaseData.startTime;
		}
		if ( ! currentPhase.end ) {
			currentPhase.end = phaseData.endTime;
		}
		if ( phaseData.durationInSeconds ) {
			currentPhase.duration = ( currentPhase.duration || 0 ) + ( phaseData.durationInSeconds * 1000 );
		}

		if ( currentPhase.status === 'QUEUED' || currentPhase.status === 'SKIPPED' ) {
			// Always use an actual status if we have one.
			currentPhase.status = phaseData.phaseStatus || 'IN_PROGRESS';
		} else if ( currentPhase.status === 'SUCCEEDED' && phaseData.phaseStatus !== 'SUCCEEDED' ) {
			// Otherwise, use the first failed status.
			currentPhase.status = phaseData.phaseStatus;
		}

		// Add any non-empty contexts.
		if ( phaseData.contexts && phaseData.contexts.length > 0 ) {
			for ( const context of phaseData.contexts ) {
				if ( context.message || context.statusCode ) {
					currentPhase.contexts.push( context );
				}
			}
		}

		if ( FAILED_STATUSES.indexOf( currentPhase.status ) > -1 ) {
			hasFailed = true;
		}
	}

	return grouped;
}

export function parseMessages( phases: DeployPhase[], messages: DeployMessage[] ): { [ k: string ]: Step } {
	if ( ! phases.length ) {
		const didStart = messages.find( message => message.message.match( 'Starting deploy' ) );
		const didComplete = messages.find( message => message.message.match( 'status: COMPLETED' ) );
		const startTime = didStart ? new Date( didStart.time ).getTime() : null;
		const completeTime = didComplete ? new Date( didComplete.time ).getTime() : null;
		return {
			'DEPLOY_APP': {
				messages,
				status: didComplete ? 'SUCCEEDED' : ( didStart ? 'IN_PROGRESS' : 'QUEUED' ),
				contexts: [],
				start: startTime,
				end: completeTime,
				duration: ( completeTime && startTime ) ? ( completeTime - startTime ) : null,
			},
		};
	}

	// Set up the main groups.
	const grouped: { [ k: string ]: Step } = buildGroups( DEPLOY_PHASE_MAP, phases );

	// Then, add messages.
	let lastPhase: DeployStep | null = null;
	for ( const item of messages ) {
		let phase = item.context && item.context.phaseType;
		if ( ! phase && lastPhase ) {
			// If the server screws up, use the last phase we saw as a backup.
			phase = lastPhase;
		}
		if ( ! grouped[ phase ] ) {
			console.warn( `Missing phase ${ phase }` );
			continue;
		}

		lastPhase = phase;
		grouped[ phase ].messages.push( item );
	}

	return grouped;
}
