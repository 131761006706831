import React from 'react';
import {
	Area,
	AreaChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	TooltipProps,
	XAxis,
	YAxis,
} from 'recharts';

import CustomTooltip from './Tooltip';
import { formatDate, formatLatency, formatPercentage, StatEntry, toNextSignificant } from './util';

import './MainChart.css';

type LegendEntry = {
	color: string,
	inactive: boolean,
	dataKey: 'latency' | 'db_latency' | 'db_replica_latency' | 'cache_latency',
	value: 'latency' | 'db_latency' | 'db_replica_latency' | 'cache_latency',
}

type Label = {
	legend: string,
	isDark?: boolean,
	color: string,
}

const LABEL_MAP: { [ label: string ]: Label } = {
	latency: {
		legend: 'Response Time',
		isDark: true,
		color: '#8884d8',
	},
	db_latency: {
		legend: 'Database',
		color: '#fdcc39',
	},
	db_replica_latency: {
		legend: 'Database (Read Replica)',
		color: '#fae3a5',
	},
	cache_latency: {
		legend: 'Object Cache',
		isDark: true,
		color: '#68737d',
	},
};

const MainLegend = ( props: any ) => {
	const { payload, onClick } = props;

	return (
		<ul className="XRayOverview-MainChart__legend">
			{ payload.map( ( entry: LegendEntry ) => (
				<li
					key={ entry.dataKey }
				>
					<button
						style={ {
							borderColor: entry.color,
							backgroundColor: entry.inactive ? 'transparent' : entry.color,
							color: ( ! entry.inactive && LABEL_MAP[ entry.dataKey ].isDark ) ? '#fff' : undefined,
						} }
						type="button"
						onClick={ () => onClick( entry ) }
					>
						{ LABEL_MAP[ entry.dataKey ].legend }
					</button>
				</li>
			) ) }
		</ul>
	);
};

function MainTooltip( props: TooltipProps<number, string> ) {
	const { active, payload } = props;
	if ( ! active || ! payload ) {
		return null;
	}

	const item: StatEntry = payload[0].payload;
	return (
		<CustomTooltip>
			<div className="border-gray-200 -ml-4 -mr-4 pb-2 mb-2 border-b">
				<p className="text-elem text-gray-700 font-medium pl-4 pr-4">
					Average response times from { formatDate( item.time ) }–{ formatDate( item.time_end ) }
				</p>
			</div>
			<ul>
				{ payload.map( dataItem => (
					<li className="flex items-center justify-between space-x-8">
						<div className="flex items-center space-x-2">
							<svg
								// @ts-ignore
								className="shrink-0 border-white h-3 w-3 rounded-full border-2 shadow"
								fill={ LABEL_MAP[ dataItem.dataKey! ].color }
								viewBox="0 0 8 8"
							>
								<circle cx="4" cy="4" r="4"></circle>
							</svg>
							<p className="text-elem font-medium tabular-nums text-right whitespace-nowrap text-gray-700">
								{ formatLatency( dataItem.value! ) }
								{ ( item.latency && dataItem.dataKey !== 'latency' ) ? (
									` (${ formatPercentage( dataItem.value! / item.latency ) })`
								) : '' }
							</p>
						</div>
						<p className="text-elem text-right whitespace-nowrap text-gray-500 font-normal">
							{ dataItem.dataKey === 'latency' ? 'Total' : dataItem.name }
						</p>
					</li>
				) ) }
			</ul>
		</CustomTooltip>
	);
}

interface Props {
	data: StatEntry[];
	isLoading: boolean,
}

type LegendEntryProps = { dataKey?: any };

export default class MainChart extends React.Component<Props> {
	state = {
		latency: true,
		db_latency: true,
		db_replica_latency: true,
		cache_latency: true,
	};

	onClickLegend = ( o: LegendEntryProps ) => {
		const dataKey = o.dataKey as ( keyof typeof MainChart.prototype.state | undefined );
		if ( ! dataKey ) {
			return;
		}

		this.setState( {
			[ dataKey ]: ! this.state[ dataKey ],
		} );
	};

	render() {
		const { data, isLoading } = this.props;
		if ( isLoading ) {
			return (
				<div
					className="animated-background"
					style={ {
						height: 400,
						width: '100%',
					} }
				/>
			);
		}

		return (
			<ResponsiveContainer
				height={ 400 }
				width="100%"
			>
				<AreaChart
					data={ data }
					syncId="XRayOverview-Statistics"
				>
					<CartesianGrid
						stroke="#aaa"
						strokeDasharray="3 3"
						strokeOpacity="0.5"
						strokeWidth="1"
						vertical={ false }
					/>
					<XAxis
						className="text-gray-400 text-sm"
						dataKey="time"
						tick={ {
							fill: 'currentColor',
							transform: 'translate(0, 6)',
						} }
						tickFormatter={ formatDate }
					/>
					<YAxis
						axisLine={ false }
						className="text-gray-400 text-sm"
						domain={ [ 0, ( dataMax: number ) => Math.max( toNextSignificant( dataMax ), 0.05 ) ] }
						interval="preserveEnd"
						scale="linear"
						tick={ {
							fill: 'currentColor',
							transform: 'translate(-3, 0)',
						} }
						tickFormatter={ formatLatency }
						tickLine={ false }
					/>
					<Legend
						content={ <MainLegend /> }
						payload={ Object.keys( LABEL_MAP ).map( label => ( {
							id: label,
							dataKey: label,
							value: label,
							type: 'none',
							color: LABEL_MAP[ label ].color,
							// @ts-ignore
							inactive: ! this.state[ label ] || false,
						} ) ) }
						onClick={ this.onClickLegend }
					/>
					<Tooltip
						content={ <MainTooltip /> }
						isAnimationActive={ false }
						position={ { y: 0 } }
					/>

					{ this.state.latency && (
						<Area
							dataKey="latency"
							hide={ ! this.state.latency }
							isAnimationActive={ false }
							name={ LABEL_MAP.latency.legend }
							stroke={ LABEL_MAP.latency.color }
							strokeWidth={ 2 }
							type="linear"
						/>
					) }
					{ this.state.db_latency && (
						<Area
							dataKey="db_latency"
							fill={ LABEL_MAP.db_latency.color }
							hide={ ! this.state.db_latency }
							isAnimationActive={ false }
							name={ LABEL_MAP.db_latency.legend }
							stackId="1"
							stroke={ LABEL_MAP.db_latency.color }
							type="linear"
						/>
					) }
					{ this.state.db_replica_latency && (
						<Area
							dataKey="db_replica_latency"
							fill={ LABEL_MAP.db_replica_latency.color }
							hide={ ! this.state.db_replica_latency }
							isAnimationActive={ false }
							name={ LABEL_MAP.db_replica_latency.legend }
							stackId="1"
							stroke={ LABEL_MAP.db_replica_latency.color }
							type="linear"
						/>
					) }
					{ this.state.cache_latency && (
						<Area
							dataKey="cache_latency"
							fill={ LABEL_MAP.cache_latency.color }
							hide={ ! this.state.cache_latency }
							isAnimationActive={ false }
							name={ LABEL_MAP.cache_latency.legend }
							stackId="1"
							stroke={ LABEL_MAP.cache_latency.color }
							type="linear"
						/>
					) }
				</AreaChart>
			</ResponsiveContainer>
		);
	}
}
