import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Loader from './Loader';

import './AppLoader.css';

export function AppLoader( props ) {
	const className = props.loading ? 'AppLoader AppLoader--loading' : 'AppLoader';
	return (
		<div className={ className }>
			{ props.loading ? (
				<Loader />
			) : (
				<div
					style={ {
						height: '130px',
						margin: '70px auto 20px',
					} }
				/>
			) }
			<p>Loading…</p>
		</div>
	);
}

AppLoader.propTypes = {
	loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
	return {
		loading: state.currentUser.loading,
	};
};

export default connect( mapStateToProps )( AppLoader );
