import React from 'react';
import Vivus from 'vivus';

import './Loader.css';

export default class Loader extends React.Component {
	timeout = null;
	velocity = -1;
	ref = null;
	anim = null;

	componentDidMount() {
		if ( ! this.ref ) {
			return;
		}

		let velocity = -1;
		const options = {
			start: 'manual',
			speed: 400,
			animTimingFunction: Vivus.EASE,
		};

		this.anim = new Vivus( this.ref, options, this.onCompleteAnimation );
		this.anim.play( velocity );
	}

	componentWillUnmount() {
		clearTimeout( this.timeout );
		this.anim.destroy();
	}

	onCompleteAnimation = () => {
		this.velocity = this.velocity * -1;
		let time = this.velocity > 0 ? 100 : 300;
		this.timeout = setTimeout( () => this.anim.play( this.velocity ), time );
	};

	render() {
		return (
			<svg
				ref={ ref => this.ref = ref }
				className="Loader"
				version="1.1"
				viewBox="0 0 243 226.8"
			>
				<g id="Layer_1-2">
					<path className="cls-1" d="M22.05,38.81,220.44,3.86C218.63,8.77,159.21,169.94,155.18,181c-4.65,12.8-12.37,27.8-28.61,25.79A24.35,24.35,0,0,1,108,195.67a25.23,25.23,0,0,1-3.44-9.14c-2.78-15.74-19-97.66-61.12-134.4,0,0-12.11-10.52-21.4-13.32a40.15,40.15,0,0,0-7.84,3.09C-4.91,53.05,3.3,77.11,17.49,86,32,95,90.11,96.73,133.73,102.58s40.34,27.19,40.34,27.19l-2.51,6.8c-3.27,7.54-20.31,15.69-29.16-2.47S132.06,53.44,87.69,27.24"/>
				</g>
			</svg>
		);
	}
}
