import { Action as ReduxAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '../api';
import { AppState } from '../reducers';
import { SupportTicketCommentResponse } from '../types/rest-api';

interface SupportTicketCommentEndpointParams {
	content: string;
	public?: boolean;
}

interface CreatingSupportTicketCommentsAction extends ReduxAction {
	type: 'CREATING_SUPPORT_TICKET_COMMENT';
}

interface CreatedSupportTicketCommentsAction extends ReduxAction {
	type: 'CREATED_SUPPORT_TICKET_COMMENT';
	comment: SupportTicketCommentResponse;
	ticketId: string;
}

export type Action = CreatingSupportTicketCommentsAction | CreatedSupportTicketCommentsAction;

export default function createSupportTicketReply(
	api: API,
	ticketId: string,
	data: SupportTicketCommentEndpointParams
): ThunkAction<Promise<SupportTicketCommentResponse>, AppState, null, Action> {
	return dispatch => {
		dispatch( { type: 'CREATING_SUPPORT_TICKET_COMMENT' } );

		window.analytics.track( 'support_ticket_comment_created', {} );

		return api
			.post( `/stack/support-tickets/${ticketId}/comments`, data )
			.then( ( comment: SupportTicketCommentResponse ) => {
				dispatch( {
					type: 'CREATED_SUPPORT_TICKET_COMMENT',
					ticketId,
					comment,
				} );
				return comment;
			} );
	};
}
