import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { fetchApplications } from '../../actions';
import { ApplicationResponse } from '../../types/rest-api';
import BigTitle from '../BigTitle';
import TabBar from '../TabBar';
import TabBarItem from '../TabBarItem';

import Activity from './Activity';
import Alarms from './Alarms';
import StatusTable from './StatusTable';

import './index.css';

export interface Alarm {
	id: string;
	type: string;
	date: string;
	description: string;
}

export type ExtendedAppResponse = ApplicationResponse & {
	_embedded: {
		[ key: string ]: any;
		'https://hm-stack.hm/alarm': Alarm[][];
	}
}

type AppMap = {
	[ id: string ]: ExtendedAppResponse;
}

interface Props {
	applications: AppMap;
	canReadActivity: boolean;
	onFetchApplications(): void,
}

export function Overview( props: RouteComponentProps & Props ) {
	const { canReadActivity, location, match, onFetchApplications } = props;
	const base = match.url;

	useEffect( () => {
		onFetchApplications();
	}, [ onFetchApplications ] );

	return (
		<div className="Overview">
			<BigTitle name="Overview" />

			<TabBar>
				<TabBarItem
					isActive={ location.pathname === base }
					name="Information"
					to={ base }
				/>
				<TabBarItem
					isActive={ location.pathname === `${ base }/alarms` }
					name="Alarms"
					to={ `${ base }/alarms` }
				/>
				{ canReadActivity && (
					<TabBarItem
						isActive={ location.pathname === `${ base }/activity` }
						name="Activity"
						to={ `${ base }/activity` }
					/>
				) }
			</TabBar>

			<Switch>
				<Route
					exact
					path={ `${ base }/alarms` }
				>
					<Alarms
						applications={ props.applications }
					/>
				</Route>
				<Route
					exact
					path={ base }
				>
					<StatusTable
						applications={ props.applications }
						location={ props.location }
						match={ props.match }
					/>
				</Route>
				{ canReadActivity && (
					<Route
						component={ Activity }
						exact
						path={ `${ base }/activity` }
					/>
				) }
				<Route>
					<p>404.</p>
				</Route>
			</Switch>
		</div>
	);
}

const mapStateToProps = ( state: any ) => ( {
	applications: state.applications,
	canReadActivity: state.currentUser.capabilities.read_activity,
} );

const mapDispatchToProps = ( dispatch: any ) => ( {
	onFetchApplications: () => {
		dispatch( fetchApplications() );
	},
} );

export default connect( mapStateToProps, mapDispatchToProps )( Overview );
