import React from 'react';

import { ApplicationResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';
import ApplicationLogsWarning from '../ApplicationLogsWarning';
import NoItemsFoundBlock from '../NoItemsFoundBlock';
import SortableTable, { Columns } from '../SortableTable';

import Header from './Header';
import Timestamp from './Timestamp';
import { Filter, BasicLogEntry, TimeProps } from './util';

type NodejsEntry = BasicLogEntry & {
	stream: string,
};

const COLUMNS: Columns<NodejsEntry> = {
	date: {
		title: 'Date',
		className: 'ApplicationLogs-Nodejs__date w-[13em]',
	},
	stream: {
		title: 'Stream',
		className: 'ApplicationLogs-Nodejs__stream font-mono w-[8em] tabular-nums',
		sort: [
			i => i.stream,
			i => i.date,
		],
	},
	message: {
		title: 'Message',
		className: 'ApplicationLogs-Nodejs__message text-sm font-mono whitespace-pre',
		sort: [
			i => i.message,
		],
	},
};

interface LogTableProps {
	application: ApplicationResponse,
	data: NodejsEntry[],
	rootUrl: string,
}

function LogTable( props: LogTableProps ) {
	if ( ! props.data.length ) {
		return (
			<NoItemsFoundBlock
				message="🌴 Observe a beautiful, blank, empty paradise, free of distraction, worries of the world, and log entries. (Zero results found.)"
			/>
		);
	}

	return (
		<SortableTable<NodejsEntry>
			className="ApplicationLogs-Nodejs__table w-full"
			columns={ COLUMNS }
			data={ props.data }
			defaultReverse
			defaultSort="date"
			isDense
		>
			{ item => (
				<tr
					key={ item.id }
				>
					<td className="ApplicationLogs-Nginx__date w-[13em]">
						<Timestamp
							date={ new Date( item.date ) }
						/>
					</td>
					<td className="ApplicationLogs-Nodejs__stream font-mono w-[8em] tabular-nums">
						<abbr
							title={ `Stream ${ item.stream }` }
						>
							{ item.stream.substring( 0, 6 ) }
						</abbr>
					</td>
					<td className="ApplicationLogs-Nodejs__message text-sm font-mono whitespace-pre">
						{ item.message }
					</td>
				</tr>
			) }
		</SortableTable>
	);
}

type ConnectedLogsProps = Filter & {
	application: ApplicationResponse,
	rootUrl: string
};
const ConnectedLogs = withApiData(
	( props: ConnectedLogsProps ) => ( {
		logs: `/stack/applications/${ props.application.id }/nodejs/logs/nodejs?after=${ props.after }&before=${ props.before }&search=${ props.search }`,
	} )
)( ( props: ConnectedLogsProps & { logs: ApiResponse<NodejsEntry[]> } ) => (
	<ApplicationLogsWarning
		logs={ props.logs }
		renderLogs={ ( logs: NodejsEntry[] ) => (
			<LogTable
				application={ props.application }
				data={ logs }
				rootUrl={ props.rootUrl }
			/>
		) }
	/>
) );
type OwnProps = TimeProps & {
	application: ApplicationResponse,
	onTimeUpdate( t: any ): void,
};

type AllProps = OwnProps;

export default class NodejsLogs extends React.Component<AllProps, Filter> {
	apiData: typeof ConnectedLogs['prototype'] | null = null;

	constructor( props: AllProps ) {
		super( props );
		this.state = {
			after: this.props.after || '4 hours ago',
			before: this.props.before || 'now',
			search: '',
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}

	onChangeFilter = ( filter: Filter ) => {
		this.apiData?.onInvalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	};

	render() {
		return (
			<div className="ApplicationLogs-Nginx">
				<Header
					after={ this.state.after }
					before={ this.state.before }
					search={ this.state.search }
					onChangeFilter={ this.onChangeFilter }
				/>

				<ConnectedLogs
					ref={ ref => this.apiData = ref }
					after={ this.state.after }
					application={ this.props.application }
					before={ this.state.before }
					search={ this.state.search }
				/>
			</div>
		);
	}
}
